import createApiInstance from "./createApiInstance";
import { ApiRoutes } from "./apiRoutes";

export const extendedApi = createApiInstance.injectEndpoints({
  endpoints: (builder) => ({
    mainEventUserCount: builder.mutation({
      query: (body) => {
        return {
          url: ApiRoutes.event.mainEventUserCount.url,
          method: ApiRoutes.event.mainEventUserCount.method,
          body,
        };
      },
      transformResponse: (response) => response,
    }),
    getAllEventCities: builder.query({
      query: () => {
        return {
          url: ApiRoutes.event.getAllCities.url,
          method: ApiRoutes.event.getAllCities.method,
        };
      },
      transformResponse: (response) => response,
    }),
    getAllAttendeeListForRules: builder.query({
      query: () => {
        return {
          url: ApiRoutes.event.getAllAttendeeListForRules.url,
          method: ApiRoutes.event.getAllAttendeeListForRules.method,
        };
      },
      transformResponse: (response) => response,
    }),
  }),
});

export const {
  useMainEventUserCountMutation,
  useGetAllEventCitiesQuery,
  useGetAllAttendeeListForRulesQuery,
} = extendedApi;
