import React, { useEffect, useRef, useState } from "react";
import { emptyObject } from "./CommonFunction";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Frame from "../../../Images/Frame.svg";
import Dropzone from "react-dropzone";
import {
  useEditAddonsMutation,
  useLazyGetAddonsByAddonIdQuery,
} from "../../../Services/addons";
import TinyEditor from "../../AdminCommonComponent/TinyEditor";
import Loader2 from "../../../Images/loader2.gif";
import Cropper from "cropperjs";
import { CompressImageFunction } from "../../AdminNotification/CommonFunction";
import ImageCropperReact from "../../ImageCropper/ImageCropperReact";

const EditAddons = ({ forceRender, editId, isCloseAddModal }) => {
  //hooks
  const navigate = useNavigate();
  const editDescriptionRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();

  //redux states
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const stayOnPage = useSelector((state) => {
    return state.ReduStayOnPage;
  });

  const [editEvent, setEditEvent] = useState(emptyObject);
  const [editErr, setEditErr] = useState(emptyObject);
  const [validate, setValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createInprocess, setCreateInprocess] = useState(false);
  const [userConfirmationCheck, setUserConfirmationCheck] = useState(false);
  const [priceType, setPriceType] = useState("");
  const [selectedId, setSelectedId] = useState("");

  //  image cropping state
  const [cropedFile, setcropedFile] = useState();
  const [fileName, setFileName] = useState("");
  const [modalOpen, setModalOpen] = useState();
  const [thumbnail, setThumbnail] = useState([]);
  const [cropedFileData, setcropedFileData] = useState();
  const [fileDimension, setFileDimension] = useState();
  const [requiredImageSize, setRequiredImageSize] = useState({ width: 480, height: 225 });
  const [requiredImageAspectRatio, setRequiredImageAspectRatio] = useState(16 / 9);
  //event id
  const eventId = new URLSearchParams(location.search).get("id");

  //get addon by id
  const [addonById] = useLazyGetAddonsByAddonIdQuery();
  //guest ticket edit api
  const [editAddon] = useEditAddonsMutation();
  //close create session module
  const handleCloseAddModel = (e) => {
    if (editDescriptionRef.current) {
      editDescriptionRef.current.setContent("");
    }
    setEditErr(emptyObject);
    setEditEvent(emptyObject);
    setUserConfirmationCheck(false);
    setcropedFile();
    setCreateInprocess(false);
    handleClearAllStates();
  };

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  useEffect(() => {
    handleEdit();
  }, [forceRender]);

  //clear edit state when click on create main ticket
  useEffect(() => {
    handleCloseAddModel();
  }, [isCloseAddModal]);

  const handleEdit = async () => {
    setSelectedId(editId);
    try {
      const response = await addonById({ ticket_id: editId });
      if (response?.data?.status) {
        let data = response?.data?.data;
        let priceTypeCheck = data?.type === "FREE" ? "free" : "paid";

        let salePriceCheck =
          data?.salesPriceType === "FLAT"
            ? "flat"
            : data?.salesPriceType === "PERCENTAGE"
              ? "percentage"
              : "";

        let visibilityChange =
          data?.visibility === "VISIBLE"
            ? "public"
            : data?.visibility === "HIDDEN"
              ? "hidden"
              : "custom";

        const validDateFormate = (date) => {
          if (!date) return;
          const newDate = new Date(date);
          const formattedDate = `${newDate.getFullYear()}-${(
            newDate.getMonth() + 1
          )
            .toString()
            .padStart(2, "0")}-${newDate
              .getDate()
              .toString()
              .padStart(2, "0")}`;
          return formattedDate;
        };

        const getTime = (dateTimeString) => {
          let inputDate = new Date(dateTimeString);
          let hours = inputDate.getHours();
          let minutes = inputDate.getMinutes();
          minutes = minutes < 10 ? "0" + minutes : minutes;
          let formattedTime = hours + ":" + minutes;
          return formattedTime;
        };

        let imgUrl =
          data?.coverImage !== undefined && data?.coverImage !== ""
            ? process.env.REACT_APP_AWS_IMG_VID_PATH + data?.coverImage
            : "";
        setEditEvent({
          image: imgUrl,
          name: data?.name,
          description: data?.details,
          quantity: data?.quantity,
          priceType: priceTypeCheck,
          quantityPerOrder: data?.quantity,
          maxQuantity: data?.maximumQuantity,
          minQuantity: data?.minimumQuantity,
          salePrice: salePriceCheck,
          saleStart: data.saleStart ? validDateFormate(data.saleStart) : "",
          saleEnd: data.saleEnd ? validDateFormate(data.saleEnd) : "",
          salesValue: data?.salesValue ? data?.salesValue : "",
          visibility: visibilityChange,
          visibilityStartDate: data.visibilityStartDate
            ? validDateFormate(data.visibilityStartDate)
            : "",
          visibilityEndDate: data.visibilityEndDate
            ? validDateFormate(data.visibilityEndDate)
            : "",
          variationType: data?.isAddVariation
            ? "withVariation"
            : "withoutVariation",
          discount:
            salePriceCheck === "percentage"
              ? data?.salesValue
                ? data?.salesValue
                : ""
              : "",
          flatAmount:
            salePriceCheck === "flat"
              ? data?.salesValue
                ? data?.salesValue
                : ""
              : "",
          visibilityStartTime: data.visibilityStartDate
            ? getTime(data.visibilityStartDate)
            : "",
          visibilityEndTime: data.visibilityEndDate
            ? getTime(data.visibilityEndDate)
            : "",
          salesStartTime: data.saleStart ? getTime(data.saleStart) : "",
          salesEndTime: data.saleEnd ? getTime(data.saleEnd) : "",
          mainAmount: data.price ? data.price : "",
        });
        setUserConfirmationCheck(data?.userConfirmationRequired);
        setPriceType(priceTypeCheck);
      }
    } catch (err) {
      console.log("Error:", err);
    }
  };

  //onChange event for edit addons
  const handleEditChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setEditEvent({ ...editEvent, [e.target.name]: e.target.value });
    if (e.target.name === "name") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "quantityPerOrder") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, quantityPerOrder: "" });
      }
      if (isNaN(e.target.value) || e.target.value <= 0) {
        setEditErr({
          ...editErr,
          quantityPerOrder: "Please enter a valid number",
        });
      } else if (e.target.value.includes(".")) {
        setEditErr({
          ...editErr,
          [e.target.name]: "Quantity should not be a decimal number",
        });
      }
    } else if (e.target.name === "maxQuantity") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
      if (isNaN(e.target.value)) {
        setEditErr({
          ...editErr,
          [e.target.name]: "Please enter a valid number",
        });
      }
    } else if (e.target.name === "minQuantity") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
      if (isNaN(e.target.value)) {
        setEditErr({
          ...editErr,
          [e.target.name]: "Please enter a valid number",
        });
      }
    } else if (e.target.name === "discount") {
      if (e.target.value.trim().length >= 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }

      if (
        isNaN(e.target.value) ||
        e.target.value < 0 ||
        e.target.value >= 100
      ) {
        setEditErr({
          ...editErr,
          [e.target.name]:
            "Discount should be a valid number between 1 and 100",
        });
      }
    } else if (e.target.name === "flatAmount") {
      if (e.target.value.trim().length >= 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }

      if (isNaN(e.target.value)) {
        setEditErr({
          ...editErr,
          [e.target.name]: "Flat Price should be a valid number greater than 0",
        });
      }
    } else if (e.target.name === "mainAmount") {
      if (e.target.value.trim().length >= 0) {
        setEditErr((prevAddErr) => ({
          ...prevAddErr,
          [e.target.name]: "",
        }));
      }
      if (isNaN(e.target.value) || e.target.value <= 0) {
        setEditErr((prevAddErr) => ({
          ...prevAddErr,
          [e.target.name]: "Please enter a valid amount",
        }));
      }
    }

    if (e.target.name === "maxQuantity") {
      if (Number(e.target.value) > Number(editEvent.minQuantity)) {
        setEditErr({ ...editErr, [e.target.name]: "", minQuantity: "" });
      } else if (Number(e.target.value) <= Number(editEvent.minQuantity)) {
        setEditErr({
          ...editErr,
          [e.target.name]:
            "Max quantity should be larger than the min quantity",
        });
      }
      if (e.target.value.includes(".")) {
        setEditErr({
          ...editErr,
          [e.target.name]: "Quantity should not be a decimal number",
        });
      }
    } else if (e.target.name === "minQuantity") {
      if (Number(e.target.value) < Number(editEvent.maxQuantity)) {
        setEditErr({ ...editErr, [e.target.name]: "", maxQuantity: "" });
      } else if (Number(e.target.value) >= Number(editEvent.maxQuantity)) {
        setEditErr({
          ...editErr,
          [e.target.name]:
            "Min quantity should not be larger than the max quantity",
        });
      }
      if (e.target.value.includes(".")) {
        setEditErr({
          ...editErr,
          [e.target.name]: "Quantity should not be a decimal number",
        });
      }
    }

    if (e.target.name === "variationType" && e.target.value !== "") {
      setEditErr({
        ...editErr,
        variationType: "",
      });
    }

    if (e.target.name === "priceType" && e.target.value !== "") {
      setEditErr({
        ...editErr,
        priceType: "",
      });
      if (e.target.value === "free") {
        setEditEvent((prevEditEvent) => {
          const updatedAddEvent = {
            ...prevEditEvent,
            flatAmount: "",
            discount: "",
            mainAmount: "",
            salePrice: "",
            saleEnd: "",
            salesEndTime: "",
            saleStart: "",
            salesStartTime: "",
          };
          return updatedAddEvent;
        });
        setEditErr({
          ...editErr,
          flatAmount: "",
          discount: "",
          mainAmount: "",
          salePrice: "",
          saleEnd: "",
          salesEndTime: "",
          saleStart: "",
          salesStartTime: "",
        });
      } else if (e.target.value === "paid") {
        setUserConfirmationCheck(false);
      }
    } else if (e.target.name === "priceType" && e.target.value === "") {
      setPriceType("");
      setUserConfirmationCheck(false);
    }

    if (e.target.name === "salePrice" && e.target.value !== "") {
      setEditErr({
        ...editErr,
        salePrice: "",
      });
    } else if (e.target.name === "salePrice" && e.target.value === "") {
      setEditEvent((prevEditEvent) => {
        const updatedAddEvent = {
          ...prevEditEvent,
          flatAmount: "",
          discount: "",
        };
        return updatedAddEvent;
      });
      setEditErr({ ...editErr, flatAmount: "", discount: "" });
    }

    if (e.target.value === "free") {
      setPriceType("free");
      setEditEvent((prevEditEvent) => {
        const updatedAddEvent = {
          ...prevEditEvent,
          paid: "",
        };
        return updatedAddEvent;
      });
      setEditErr({ ...editErr, [e.target.name]: "" });
    } else if (e.target.value === "paid") {
      setPriceType("paid");
      setEditEvent((prevEditEvent) => {
        const updatedAddEvent = {
          ...prevEditEvent,
          free: "",
        };
        return updatedAddEvent;
      });
      setEditErr({ ...editErr, [e.target.name]: "" });
    }

    if (e.target.name === "userConfirmation") {
      setUserConfirmationCheck(e.target.checked);
    }

    if (e.target.value === "flat") {
      setEditEvent((prevEditEvent) => {
        const updatedAddEvent = {
          ...prevEditEvent,
          discount: "",
        };
        return updatedAddEvent;
      });
      setEditErr({ ...editErr, discount: "", salePrice: "" });
    } else if (e.target.value === "percentage") {
      setEditEvent((prevEditEvent) => {
        const updatedAddEvent = {
          ...prevEditEvent,
          flatAmount: "",
        };
        return updatedAddEvent;
      });
      setEditErr({ ...editErr, flatAmount: "", salePrice: "" });
    }

    if (e.target.name === "mainAmount") {
      if (Number(e.target.value) > Number(editEvent.flatAmount)) {
        setEditErr((prevAddErr) => ({
          ...prevAddErr,
          flatAmount: "",
        }));
      } else if (Number(e.target.value) <= Number(editEvent.flatAmount)) {
        setEditErr((prevAddErr) => ({
          ...prevAddErr,
          flatAmount: "Flat Price should not be greater than main amount",
        }));
      }
    } else if (e.target.name === "flatAmount") {
      if (Number(e.target.value) < Number(editEvent.mainAmount)) {
        setEditErr((prevAddErr) => ({
          ...prevAddErr,
          flatAmount: "",
        }));
      } else if (Number(e.target.value) >= Number(editEvent.mainAmount)) {
        setEditErr((prevAddErr) => ({
          ...prevAddErr,
          flatAmount: "Flat Price should not be greater than main amount",
        }));
      }
    }

    if (e.target.name === "visibility") {
      setEditErr({ ...editErr, visibility: "" });
    }
    if (e.target.name === "visibility" && e.target.value !== "custom") {
      setEditEvent((prevEditEvent) => {
        const updatedAddEvent = {
          ...prevEditEvent,
          visibilityEndDate: "",
          visibilityStartDate: "",
          visibilityStartTime: "",
          visibilityEndTime: "",
        };
        return updatedAddEvent;
      });
      setEditErr({
        ...editErr,
        visibilityEndDate: "",
        visibilityStartDate: "",
        visibilityStartTime: "",
        visibilityEndTime: "",
      });
    }

    if (e.target.name === "saleStart") {
      setEditErr({ ...editErr, saleStart: "" });
    }
    if (e.target.name === "saleEnd") {
      setEditErr({ ...editErr, saleEnd: "" });
    }
    if (e.target.name === "salesEndTime") {
      setEditErr({ ...editErr, salesEndTime: "" });
    }
    if (e.target.name === "salesStartTime") {
      setEditErr({ ...editErr, salesStartTime: "" });
    }
    if (e.target.name === "visibilityStartDate") {
      setEditErr({ ...editErr, visibilityStartDate: "" });
    }
    if (e.target.name === "visibilityStartTime") {
      setEditErr({ ...editErr, visibilityStartTime: "" });
    }
    if (e.target.name === "visibilityEndTime") {
      setEditErr({ ...editErr, visibilityEndTime: "" });
    }
    if (e.target.name === "visibilityEndDate") {
      setEditErr({ ...editErr, visibilityEndDate: "" });
    }
    if (
      e.target.name === "visibilityStartDate" ||
      e.target.name === "visibilityEndDate"
    ) {
      setEditErr((prevEditErr) => ({
        ...prevEditErr,
        visibilityStartDate:
          e.target.name === "visibilityStartDate"
            ? ""
            : prevEditErr.visibilityStartDate,
        visibilityEndDate:
          e.target.name === "visibilityEndDate"
            ? ""
            : prevEditErr.visibilityEndDate,
        visibilityStartTime:
          editEvent.visibilityStartDate === editEvent.visibilityEndDate
            ? ""
            : prevEditErr.visibilityStartTime,
        visibilityEndTime:
          editEvent.visibilityStartDate === editEvent.visibilityEndDate
            ? ""
            : prevEditErr.visibilityEndTime,
      }));
    }

    // Additional validation logic for start and end time
    if (
      e.target.name === "visibilityStartTime" ||
      e.target.name === "visibilityEndTime"
    ) {
      const startDate = editEvent.visibilityStartDate;
      const startTime =
        e.target.name === "visibilityStartTime"
          ? e.target.value
          : editEvent.visibilityStartTime;
      const endTime =
        e.target.name === "visibilityEndTime"
          ? e.target.value
          : editEvent.visibilityEndTime;

      // Compare start and end time only if both are defined and date is the same
      if (
        startDate &&
        startTime &&
        endTime &&
        startDate === editEvent.visibilityEndDate
      ) {
        const startDateTime = new Date(`${startDate}T${startTime}`);
        const endDateTime = new Date(`${startDate}T${endTime}`);

        if (startDateTime >= endDateTime) {
          setEditErr((prevEditErr) => ({
            ...prevEditErr,
            [e.target.name === "visibilityStartTime"
              ? "visibilityStartTime"
              : "visibilityEndTime"]: "Start time should be less than end time",
          }));
          return;
        } else {
          setEditErr((prevAddErr) => ({
            ...prevAddErr,
            visibilityEndTime: "",
            visibilityStartTime: "",
          }));
        }
      }
    }
  };
  //submit edited form
  const handleEditSubmit = () => {
    let status = true;
    let temp = {};
    if (editEvent?.name?.trim().length === 0) {
      temp = { ...temp, name: "Enter name" };
      status = false;
    }

    if (
      isNaN(editEvent?.quantityPerOrder) ||
      editEvent?.quantityPerOrder === "" ||
      editEvent?.quantityPerOrder <= 0
    ) {
      temp = {
        ...temp,
        quantityPerOrder: "Please enter a valid number",
      };
      status = false;
    }

    if (String(editEvent?.quantityPerOrder).includes(".")) {
      temp = {
        ...temp,
        quantityPerOrder: "Quantity should not be a decimal number",
      };
      status = false;
    }

    if (isNaN(editEvent?.maxQuantity) || editEvent?.maxQuantity === "") {
      temp = {
        ...temp,
        maxQuantity: "Please enter a valid number",
      };
      status = false;
    }

    if (String(editEvent?.maxQuantity).includes(".")) {
      temp = {
        ...temp,
        maxQuantity: "Quantity should not be a decimal number",
      };
      status = false;
    }

    if (String(editEvent?.minQuantity).includes(".")) {
      temp = {
        ...temp,
        minQuantity: "Quantity should not be a decimal number",
      };
      status = false;
    }

    if (isNaN(editEvent?.minQuantity) || editEvent?.minQuantity === "") {
      temp = {
        ...temp,
        minQuantity: "Please enter a valid number",
      };
      status = false;
    }

    if (Number(editEvent?.minQuantity) > Number(editEvent.maxQuantity)) {
      temp = {
        ...temp,
        minQuantity: "min quantity should not be larger than the max quantity ",
      };
      status = false;
    }

    if (editEvent?.variationType === "") {
      temp = {
        ...temp,
        variationType: "Select variation type",
      };
      status = false;
    }

    if (editEvent?.priceType === "") {
      temp = {
        ...temp,
        priceType: "Select price type",
      };
      status = false;
    }

    if (
      editEvent?.priceType === "paid" &&
      (isNaN(editEvent?.mainAmount) ||
        editEvent?.mainAmount === "" ||
        editEvent?.mainAmount <= 0)
    ) {
      temp = {
        ...temp,
        mainAmount: "Please enter a valid amount",
      };
      status = false;
    }

    if (
      editEvent?.priceType === "paid" &&
      editEvent?.salePrice === "percentage"
    ) {
      const discountValue = Number(editEvent?.discount);
      if (isNaN(discountValue) || discountValue <= 0 || discountValue >= 100) {
        temp = {
          ...temp,
          discount: "Discount should be a valid number between 1 and 100",
        };
        status = false;
      }
    } else if (
      editEvent?.priceType === "paid" &&
      editEvent?.salePrice === "flat"
    ) {
      const flatAmountValue = Number(editEvent?.flatAmount);
      if (isNaN(flatAmountValue) || flatAmountValue <= 0) {
        temp = {
          ...temp,
          flatAmount: "Flat Price should be a valid number greater than 0",
        };
        status = false;
      }
    }

    if (Number(editEvent?.flatAmount) > Number(editEvent?.mainAmount)) {
      temp = {
        ...temp,
        flatAmount: "Flat Price should not be greater than main amount",
      };
      status = false;
    }

    if (editEvent?.visibility === "") {
      temp = {
        ...temp,
        visibility: "Select visibility",
      };
      status = false;
    }

    if (editEvent?.priceType === "paid" && editEvent?.saleStart === "") {
      temp = {
        ...temp,
        saleStart: "Select start date",
      };
      status = false;
    }

    if (editEvent?.priceType === "paid" && editEvent?.saleEnd === "") {
      temp = {
        ...temp,
        saleEnd: "Select end date",
      };
      status = false;
    }
    if (editEvent?.priceType === "paid" && editEvent?.salesStartTime === "") {
      temp = {
        ...temp,
        salesStartTime: "Select start time",
      };
      status = false;
    }
    if (editEvent?.priceType === "paid" && editEvent?.salesEndTime === "") {
      temp = {
        ...temp,
        salesEndTime: "Select end time",
      };
      status = false;
    }
    if (
      editEvent?.visibility === "custom" &&
      editEvent?.visibilityStartDate === ""
    ) {
      temp = {
        ...temp,
        visibilityStartDate: "Select start date",
      };
      status = false;
    }
    if (
      editEvent?.visibility === "custom" &&
      editEvent?.visibilityEndDate === ""
    ) {
      temp = {
        ...temp,
        visibilityEndDate: "Select end date",
      };
      status = false;
    }
    if (
      editEvent?.visibility === "custom" &&
      editEvent?.visibilityStartTime === ""
    ) {
      temp = {
        ...temp,
        visibilityStartTime: "Select start time",
      };
      status = false;
    }
    if (
      editEvent?.visibility === "custom" &&
      editEvent?.visibilityEndTime === ""
    ) {
      temp = {
        ...temp,
        visibilityEndTime: "Select end time",
      };
      status = false;
    }

    if (editEvent?.visibilityEndTime || editEvent?.visibilityStartTime) {
      const startDate = editEvent.visibilityStartDate;
      // Compare start and end time only if both are defined and date is the same
      if (
        editEvent?.visibilityStartTime >= editEvent?.visibilityEndTime &&
        startDate === editEvent.visibilityEndDate
      ) {
        status = false;
        temp = {
          ...temp,
          visibilityEndTime: "Start time should be less than end time",
        };
      }
    }

    if (status) {
      addonEditApi();
      // editDescriptionRef.current.setContent("");
    } else {
      setEditErr(temp);
    }
  };
  //addon edit api
  const addonEditApi = async () => {
    let priceType = "";
    if (editEvent?.priceType === "free") {
      priceType = "FREE";
    } else if (editEvent?.priceType === "paid") {
      priceType = "PAID";
    }

    let salePriceType;
    if (editEvent?.salePrice === "flat") {
      salePriceType = "FLAT";
    } else if (editEvent?.salePrice === "percentage") {
      salePriceType = "PERCENTAGE";
    } else if (editEvent?.salePrice === "") {
      salePriceType = "";
    }

    let salesValue;
    if (editEvent?.flatAmount) {
      salesValue = editEvent?.flatAmount;
    } else if (editEvent?.discount) {
      salesValue = editEvent?.discount;
    } else {
      salesValue = "";
    }

    let visibility;
    if (editEvent?.visibility === "public") {
      visibility = "VISIBLE";
    } else if (editEvent?.visibility === "hidden") {
      visibility = "HIDDEN";
    } else if (editEvent?.visibility === "custom") {
      visibility = "CUSTOM";
    }

    //convert string date to formatted date
    const dateConvertor = (date, time) => {
      if (!date) return;
      const combinedDateTimeString = date + "T" + time + ":00";
      const combinedDateTime = new Date(combinedDateTimeString);
      const isoFormattedDateTime = combinedDateTime.toISOString();
      return isoFormattedDateTime;
    };

    setCreateInprocess(true);
    let imageCompress;
    const formdata = new FormData();
    if (cropedFileData) {
      imageCompress = await CompressImageFunction(cropedFileData);
    }
    imageCompress && formdata.append("coverImage", imageCompress);
    // formdata.append("coverImage", cropedFile);
    formdata.append("name", editEvent?.name);
    formdata.append("details", editDescriptionRef.current.getContent());
    formdata.append("quantity", editEvent?.quantityPerOrder);
    formdata.append("type", priceType);
    formdata.append("minimumQuantity", editEvent?.minQuantity);
    formdata.append("maximumQuantity", editEvent?.maxQuantity);
    formdata.append("visibility", visibility);

    formdata.append("userConfirmationRequired", userConfirmationCheck);
    formdata.append(
      "isAddVariation",
      editEvent?.variationType === "withVariation" ? true : false
    );
    formdata.append("price", editEvent.mainAmount || 0);
    formdata.append("eventId", eventId);
    if (editEvent?.visibility === "custom") {
      formdata.append(
        "visibilityStartDate",
        dateConvertor(
          editEvent.visibilityStartDate,
          editEvent.visibilityStartTime
        )
      );
      formdata.append(
        "visibilityEndDate",
        dateConvertor(editEvent.visibilityEndDate, editEvent.visibilityEndTime)
      );
    }

    if (salePriceType) {
      formdata.append("salesPriceType", salePriceType);
      formdata.append("salesValue", salesValue);
    }

    if (editEvent?.priceType === "paid") {
      formdata.append(
        "saleStart",
        dateConvertor(editEvent.saleStart, editEvent.salesStartTime)
      );
      formdata.append(
        "saleEnd",
        dateConvertor(editEvent.saleEnd, editEvent.salesEndTime)
      );
    }
    try {
      const editApiResponse = await editAddon({
        id: selectedId,
        body: formdata,
      });
      if (editApiResponse?.data?.data) {
        setTimeout(() => {
          document.querySelector("#editModalAddons .close").click();
        }, 1500);
        setCreateInprocess(false);
        handleClearAllStates();
      } else {
        setCreateInprocess(false);
      }
    } catch (err) {
      console.log("Edit submit err:", err);
    }
  };

  //stayonpage  popup for do you want to leave page
  useEffect(() => {
    if (stayOnPage !== null && stayOnPage) {
      if ($("#editModalAddons").hasClass("show")) {
        $("#editModalAddons").modal("show");
      }
    } else if (stayOnPage !== null && !stayOnPage) {
      if ($("#editModalAddons").hasClass("show")) {
        $("#editModalAddons").modal("hide");
      }
    }
  }, [stayOnPage]);

  // thumbnail image upload function
  const thumbnailImages = async (acceptedfile) => {
    setFileDimension(acceptedfile);
    dispatch({ type: "SETMODALINPROCESS" });
    setFileName(Math.random().toString() + acceptedfile.name);
    setModalOpen(true);
  };
  return (
    <>
      {modalOpen && (
        <ImageCropperReact
          file={thumbnail}
          setModalOpen={setModalOpen}
          setcropedFile={setcropedFile}
          setFile={setThumbnail}
          fileName={fileName}
          setcropedFileData={setcropedFileData}
          fileDimension={fileDimension}
          requiredImageSize={requiredImageSize}
          requiredImageAspectRatio={requiredImageAspectRatio}
          setRequiredImageSizeError={() => setEditErr
            ({ ...editErr, image: "Allow only 480 x 225" })}
        />
      )}
      <div
        className="modal fade edit-user-details-popup"
        id="editModalAddons"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Edit Addon</h2>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#editModalAddons").modal("hide");
                    handleCloseAddModel();
                  }
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="cstm-video-upload">
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    const file = acceptedFiles[0];
                    if (file.type.startsWith("image/")) {
                      const reader = new FileReader();
                      reader.onload = function (event) {
                        const img = new Image();
                        img.onload = function () {
                          setThumbnail(event.target.result);
                          thumbnailImages(file);
                          setEditErr({ ...editErr, image: "" })
                        };
                        img.src = event.target.result;
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                  name="thumbnail"
                  multiple={false}
                  accept="image/jpeg, image/png,  image/tiff, image/jpg"
                  maxSizeBytes={10485760}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />

                      <div className="cstm-video-upload-img">
                        {cropedFile ? (
                          <img className="channel-img w-100 h-100" src={cropedFile} alt="Uploaded video thumbnail" />
                        ) : editEvent.image !== undefined && editEvent.image !== "" ? (
                          <img className="channel-img w-100 h-100" src={editEvent.image} alt="Event image" />
                        ) : (
                          <img className="empty-img" src={Frame} alt="Placeholder image" />
                        )}
                      </div>

                    </div>
                  )}
                </Dropzone>

                {/* Display error message for file selection */}
                {validate && (
                  <span
                    className="error cstm-error44"
                    style={{ marginTop: "5px" }}
                  >
                    {"Invalid file type. Please select an image file."}
                  </span>
                )}

                {/* Display error message related to notificationDetailErr.image */}
                {!validate && editErr.image && (
                  <span
                    className="error cstm-error44"
                    style={{ marginTop: "5px" }}
                  >
                    {editErr.image}
                  </span>
                )}
              </div>
              <div className="group-info-user-link">
                <div className="cover-image-upload-ttl">Upload image</div>
              </div>

              <div className="form-group">
                <div className="form-group-label">Name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter name"
                    name="name"
                    onChange={handleEditChange}
                    value={editEvent.name}
                    handleEditCha
                  />
                  <span className="error">{editErr.name}</span>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">Description</div>
                  <div className="cstm-editor-tool-ds">
                    <TinyEditor
                      valueRef={editDescriptionRef}
                      value={editEvent.description}
                      handleEditorChange={(value, editor) => {
                        dispatch({ type: "SETMODALINPROCESS" });
                        setEditErr({
                          ...editErr,
                          description: "",
                        });
                      }}
                      handleKeyPress={() => {
                        dispatch({ type: "SETMODALINPROCESS" });
                      }}
                    />
                  </div>
                  <span className="error">{editErr.description}</span>
                </div>
              </div>

              <div className="form-group">
                <div className="form-group-label">Variation type*</div>
                <div className="form-group-ct">
                  <select
                    value={editEvent.variationType}
                    onChange={handleEditChange}
                    name="variationType"
                  >
                    <option value="">Select addons variation</option>
                    <option value="withVariation">Addons with variation</option>
                    <option value="withoutVariation">
                      Addons without variation
                    </option>
                  </select>
                </div>
                <span className="error cstm-error4">
                  {editErr.variationType}
                </span>
              </div>

              <div className="form-group cstm-admin-access-grp">
                <div className="form-group-label">Price*</div>
                <div className="form-group-ct">
                  <select
                    name="priceType"
                    value={editEvent.priceType}
                    onChange={handleEditChange}
                  >
                    <option value="">Select price</option>
                    <option value="free">Free</option>
                    <option value="paid">Paid</option>
                  </select>
                </div>
                <span className="error cstm-error4">{editErr.priceType}</span>
              </div>
              {priceType === "free" && (
                <div className="row">
                  <div className="col-sm-12 form-group">
                    <div className="cstm-chk-preregistration-container">
                      <input
                        type="checkbox"
                        name="userConfirmation"
                        id="userConfirmation"
                        onChange={handleEditChange}
                        checked={userConfirmationCheck === true}
                      />
                      <label
                        for="chk-preregistration"
                        htmlFor="userConfirmation"
                        onChange={handleEditChange}
                      >
                        Free addon required confirmation from user for
                        headcount?
                      </label>
                    </div>
                  </div>
                </div>
              )}
              {priceType === "paid" && (
                <>
                  <div className="form-group">
                    <div className="form-group-label">Amount*</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter amount"
                        name="mainAmount"
                        onChange={handleEditChange}
                        value={editEvent.mainAmount}
                      />
                      <span className="error">{editErr.mainAmount}</span>
                    </div>
                  </div>

                  <div className="form-group cstm-admin-access-grp">
                    <div className="form-group-label">Sale Price</div>
                    <div className="form-group-ct">
                      <select
                        name="salePrice"
                        value={editEvent.salePrice}
                        onChange={handleEditChange}
                      >
                        <option value="">Select sale price</option>
                        <option value="flat">Flat</option>
                        <option value="percentage">Percentage</option>
                      </select>
                    </div>
                    <span className="error cstm-error4">
                      {editErr.salePrice}
                    </span>
                  </div>

                  {editEvent.salePrice === "flat" && (
                    <>
                      <div className="form-group">
                        <div className="form-group-label">Flat($)</div>
                        <div className="form-group-ct">
                          <input
                            type="text"
                            placeholder="Enter flat price"
                            name="flatAmount"
                            onChange={handleEditChange}
                            value={editEvent?.flatAmount}
                          />
                          <span className="error">{editErr.flatAmount}</span>
                        </div>
                      </div>
                    </>
                  )}

                  {editEvent.salePrice === "percentage" && (
                    <>
                      <div className="form-group">
                        <div className="form-group-label">Discount(%)</div>
                        <div className="form-group-ct">
                          <input
                            type="text"
                            placeholder="Enter discount"
                            name="discount"
                            onChange={handleEditChange}
                            value={editEvent?.discount}
                          />
                          <span className="error">{editErr.discount}</span>
                        </div>
                      </div>
                    </>
                  )}

                  <div class="form-group">
                    <div class="cstm-channel-type-title partner-icon-rf-only">
                      Sale duration
                    </div>
                    <div className="form-group">
                      <div className="form-group-label">Start date*</div>
                      <div className="form-group-ct">
                        <input
                          type="date"
                          name="saleStart"
                          onChange={handleEditChange}
                          value={editEvent.saleStart}
                        />
                        <span className="error">{editErr.saleStart}</span>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="form-group-label">Start time*</div>
                      <div className="form-group-ct">
                        <input
                          type="time"
                          name="salesStartTime"
                          onChange={handleEditChange}
                          value={editEvent.salesStartTime}
                          disabled={
                            !editEvent.saleStart ||
                            editEvent.saleStart.length === 0
                          }
                        />
                        <span className="error">{editErr.salesStartTime}</span>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="form-group-label">End date*</div>
                      <div className="form-group-ct">
                        <input
                          type="date"
                          name="saleEnd"
                          onChange={handleEditChange}
                          value={editEvent.saleEnd}
                          min={editEvent.saleStart}
                        />
                        <span className="error">{editErr.saleEnd}</span>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="form-group-label">End time*</div>
                    <div className="form-group-ct">
                      <input
                        type="time"
                        name="salesEndTime"
                        onChange={handleEditChange}
                        value={editEvent.salesEndTime}
                        disabled={
                          !editEvent.saleStart ||
                          editEvent.saleStart.length === 0
                        }
                      />
                      <span className="error">{editErr.salesEndTime}</span>
                    </div>
                  </div>
                </>
              )}

              <div className="form-group">
                <div className="form-group-label">Quantity per order*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter quantity per order"
                    name="quantityPerOrder"
                    onChange={handleEditChange}
                    value={editEvent.quantityPerOrder}
                  />
                  <span className="error">{editErr.quantityPerOrder}</span>
                </div>
              </div>

              <div className="form-group">
                <div className="form-group-label">Max. quantity*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter maximum quantity"
                    name="maxQuantity"
                    onChange={handleEditChange}
                    value={editEvent.maxQuantity}
                  />
                  <span className="error">{editErr.maxQuantity}</span>
                </div>
              </div>

              <div className="form-group">
                <div className="form-group-label">Min. quantity*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter minimum quantity"
                    name="minQuantity"
                    onChange={handleEditChange}
                    value={editEvent.minQuantity}
                  />
                  <span className="error">{editErr.minQuantity}</span>
                </div>
              </div>

              <div class="form-group">
                <div class="cstm-channel-type-title partner-icon-rf-only">
                  Select visibility*
                </div>
                <div class="cstm-pm-list">
                  <label class="subcat-addvideo">
                    <input
                      type="radio"
                      value="public"
                      name="visibility"
                      checked={editEvent?.visibility === "public"}
                      onChange={handleEditChange}
                    />
                    Public
                  </label>
                  <label class="subcat-addvideo">
                    {" "}
                    <input
                      type="radio"
                      value="hidden"
                      name="visibility"
                      checked={editEvent?.visibility === "hidden"}
                      onChange={handleEditChange}
                    />
                    Hidden
                  </label>
                  <label class="subcat-addvideo">
                    <input
                      type="radio"
                      value="custom"
                      name="visibility"
                      checked={editEvent?.visibility === "custom"}
                      onChange={handleEditChange}
                    />
                    Custom
                  </label>
                </div>
                <span className="error cstm-pm-list-msg">
                  {editErr.visibility}
                </span>
              </div>

              {editEvent?.visibility === "custom" && (
                <>
                  <div className="form-group">
                    <div className="form-group-label">Start date*</div>
                    <div className="form-group-ct">
                      <input
                        type="date"
                        name="visibilityStartDate"
                        onChange={handleEditChange}
                        value={editEvent.visibilityStartDate}
                      />
                      <span className="error">
                        {editErr.visibilityStartDate}
                      </span>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="form-group-label">Start time*</div>
                    <div className="form-group-ct">
                      <input
                        type="time"
                        name="visibilityStartTime"
                        onChange={handleEditChange}
                        value={editEvent.visibilityStartTime}
                        disabled={
                          !editEvent.visibilityStartDate ||
                          editEvent.visibilityStartDate.length === 0
                        }
                      />
                      <span className="error">
                        {editErr.visibilityStartTime}
                      </span>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="form-group-label">End date*</div>
                    <div className="form-group-ct">
                      <input
                        type="date"
                        name="visibilityEndDate"
                        onChange={handleEditChange}
                        value={editEvent.visibilityEndDate}
                        disabled={
                          !editEvent.visibilityStartDate ||
                          editEvent.visibilityStartDate.length === 0
                        }
                        min={editEvent.visibilityStartDate}
                      />
                      <span className="error">{editErr.visibilityEndDate}</span>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="form-group-label">End time*</div>
                    <div className="form-group-ct">
                      <input
                        type="time"
                        name="visibilityEndTime"
                        onChange={handleEditChange}
                        value={editEvent.visibilityEndTime}
                        disabled={
                          !editEvent.visibilityStartDate ||
                          editEvent.visibilityStartDate.length === 0
                        }
                      />
                      <span className="error">{editErr.visibilityEndTime}</span>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="edit-user-details-footer cstm-create-button">
              {createInprocess ? (
                <div className="loader-button1">
                  <button>In process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleEditSubmit}
                  disabled={createInprocess}
                >
                  Update Changes
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAddons;
