import React, { useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { useNavigate } from "react-router-dom";
import Frame from "../../../../Images/Frame.svg";
import { Editor } from "@tinymce/tinymce-react";
import Loader2 from "../../../../Images/loader2.gif";
import authService from "../../../../Services/auth.service";
import Services from "../../../../Services/auth.service";
import authServicePartner from "../../../../Services/auth.service.partner";
import { useDispatch, useSelector } from "react-redux";
// import SaveDraftModal from '../PartnerModals/SaveDraftModal';
import undo from "../../../../Images/undo.svg";
import TinyEditor from "../../../AdminCommonComponent/TinyEditor";
import AlertComponent from "../../../../Hooks/AlertComponent";
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";
import authServiceEvent from "../../../../Services/auth.service.event";
import authAdminNotification from "../../../../Services/auth.admin.notification";
import NotifiedUsertable from "../../../AdminNotification/NotifiedUsertable";
import { useUserCountMutation } from "../../../../Services/ruleUserCount";
import AddRules from "../../../CommonRules/AddRules";
import ImageCropperReact from "../../../ImageCropper/ImageCropperReact";
import { CompressImageFunction } from "../../../AdminNotification/CommonFunction";

// to get a memershipt data
function createData(
  plan_name,
  plan_price,
  automatic_renewal,
  plan_status,
  recurring_timeframe,
  total_member_who_purchased_plan,
  action
) {
  return {
    plan_name,
    plan_price,
    automatic_renewal,
    plan_status,
    recurring_timeframe,
    total_member_who_purchased_plan,
    action,
  };
}

const CreatePartner = () => {
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const descriptionRef = useRef(null);
  const offerdescriptionRef = useRef(null);
  const claimdescriptionRef = useRef(null);
  const multiSelectRef = useRef();

  // redux states
  const partnerinprocess = useSelector((state) => {
    return state.ReduPartnerInProcess;
  });
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner);
  const NAVIGATEPAGE = useSelector((state) => {
    return state.partnerNavigate;
  });
  // blank objects state
  const [partnerDetail, setPartnerDetail] = useState({
    companyName: "",
    shortDescription: "",
    contactName: "",
    PhoneNumber: "",
    email: "",
    website: "",
    facebook: "",
    linkedin: "",
    description: "",
    mdsPartner: true,
    partnerType: "",
    type: "",
    category: [],
    tag: [],
    subCategory: [],
    offer_desc: "",
    offer_instru: "",
    value: "",
    status: "draft",
    membership: "",
    accessType: "",
    accessTypeIds: "",
    event: "",
  });
  const [partnerDetailErr, setPartnerDetailErr] = useState({
    logo: "",
    shortDescription: "",
    email: "",
    website: "",
    facebook: "",
    linkedin: "",
    companyName: "",
    contactName: "",
    description: "",
    offer_desc: "",
    offer_instru: "",
    mdsPartner: "",
    category: "",
    partnerType: "",
    type: "",
    status: "",
    mobileBanner: "",
    perkThumbnail: "",
    offerThumbnail: "",
    webBanner: "",
    value: "",
    darkCompanyLogo: "",
    membership: "",
    accessType: "",
    accessTypeIds: "",
    event: "",
  });

  const [logo, setLogo] = useState("");
  const [darkCompanyLogo, setDarkCompanyLogo] = useState("");
  const [thumbnail, setThumbnail] = useState([]);
  const [mobileBanner, setMobileBanner] = useState([]);
  const [webBanner, setWebBanner] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [badgeList, setBadgeList] = useState([]);
  const [createInprocess, setcreateInprocess] = useState(false);
  const [showalertAdd, setshowalertAdd] = useState(false);
  const [alertmsgAdd, setalertmsgAdd] = useState("");
  const [tagList, setTagList] = useState([]);

  const [thumbnailHeight, setThumbnalHeight] = useState(0);
  const [thumbnailWidth, setThumbnalWidth] = useState(0);
  const [categoryData, setCategoryData] = useState([]);
  const [inProcess, setInProcess] = useState(false);
  const [saveInProcess, setSaveInProcess] = useState(false);
  const [addtag, setaddtag] = useState("");
  const [adderr, setadderr] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [showSaveDraftModal, setShowSaveDraftModal] = useState(false);
  const [membership, setMembership] = useState([]);
  const [selecteduser, setSelectedUser] = useState([]);
  const [showOtherRules, setShowOtherRules] = useState(true);
  const [allGrp, setAllGrp] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [row, setrow] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [allUser, setAllUser] = useState([]);
  const [searchUsers, setSearchUsers] = useState("");
  const [totalUsers, setTotalUsers] = useState(0);
  const [isModal, setModal] = useState(false);
  const [ruleError, setRuleError] = useState(false);
  const [noRuleFieldSelected, setNoRuleFieldSelected] = useState(false);
  const [totalUserList, setTotalUserList] = useState([]);
  const [resetRulesFields, setResetRulesFields] = useState(false);
  const [dataFromRules, setDataFromRules] = useState([]);

  //  image cropping state
  const [thumbnailFile, setThumbnailFile] = useState([]);
  const [fileDimension, setFileDimension] = useState();
  const [fileName, setFileName] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectImageType, setSelectImageType] = useState("");
  const [parntnerImageFileData, setParntnerImageFileData] = useState({
    logoFileData: "",
    darkCompanyLogoFileData: "",
    thumbnailFileData: "",
    webBannerFileData: "",
    mobileBannerFileData: ""
  })
  const [requiredImageSize, setRequiredImageSize] = useState({
    logoForLightThemeWidth: 564,
    logoForLightThemeHeight: 216,
    logoForDarkThemeWidth: 564,
    logoForDarkThemeHeight: 216,
    thumbnailImageWidth: 1080,
    thumbnailImageHeight: 608,
    webBannerWidth: 2976,
    webBannerHeight: 576,
    mobileBannerWidth: 780,
    mobileBannerHeight: 200
  });
  const [requiredImageAspectRatio, setRequiredImageAspectRatio] = useState(16 / 9);

  const latestRequest = useRef(0);
  //User count mutation
  const [userCountApi] = useUserCountMutation();

  const countUserApi = async (bodyData, requestId) => {
    try {
      const countRes = await userCountApi(bodyData);
      if (latestRequest.current === requestId) {
        setTotalUserList(countRes?.data?.data);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    const bodyData = {
      restrictedAccessUserId: dataFromRules.user_id,
      restrictedAccessMembershipPlanId: dataFromRules.membership_plan_id,
      restrictedAccessGroupId: dataFromRules.group_id,
      restrictedAccessTagId: dataFromRules.tags_id,
      restrictionAccess:
        dataFromRules.userAccess === "all" ? "public" : "restricted",
    };
    const requestId = ++latestRequest.current; // Increment the request ID
    countUserApi(bodyData, requestId);
  }, [dataFromRules]);

  //email regex
  const emailRegex = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  //weburl regex
  const webRegex = new RegExp(/^https?:\/\/.+/i);
  //facebook regex
  const facebookRegex = new RegExp(
    /^(https?:\/\/){0,1}(www\.){0,1}facebook\.com/
  );
  //linkedin regex
  const linkedinRegex = new RegExp(
    /^(https?:\/\/){0,1}(www\.){0,1}linkedin\.com/
  );

  // useEffect(() => {
  //   window.addEventListener("beforeunload", alertUser);
  //   window.history.pushState(null, null, window.location.href);
  //   window.onpopstate = function () {
  //     if (partnerinprocess) {
  //       dispatch({ type: "PAGECHANGE", payload: true })
  //     }
  //   };
  //   return () => {
  //     window.removeEventListener("beforeunload", alertUser);
  //   };
  // }, [partnerinprocess]);

  // //alert user when user we leave or reload page
  // const alertUser = (e) => {
  //   if (partnerinprocess) {
  //     e.preventDefault();
  //     e.returnValue = ""
  //     dispatch({ type: "PAGECHANGE", payload: true })
  //     return "";
  //   } else {
  //     window.location.reload(true);
  //   }
  // };

  useEffect(() => {
    getAllPlanApi(1, 100, searchUsers);
    handleClearAllStates();
    setShowSaveDraftModal(false);
    categoryListApi();
    badgeListApi();
  }, []);

  // get all plan list api function
  async function getAllPlanApi(page, rowsPerPage, searchText, col, sort) {
    let query = `?page=${page}&limit=${rowsPerPage}&search=${searchText}`;
    query +=
      col !== undefined && col !== null && col !== ""
        ? `&sortField=${col}`
        : "";
    query +=
      sort !== undefined && sort !== null && sort !== ""
        ? `&sortType=${sort}`
        : "";
    try {
      const [getPlanRes, allUserRes, groupListRes] = await Promise.all([
        Services.getAllPlan(query),
        Services.getallusers(""),
        Services.getallgroup(),
      ]);

      if (getPlanRes.data.status) {
        const data = getPlanRes.data.data.plans;

        var arr = [];
        for (var i = 0; i < data.length; i++) {
          arr[i] = createData(
            data[i].plan_name,
            "$ " + data[i].plan_price.toLocaleString(),
            data[i].automatic_renewal,
            data[i].plan_status,
            data[i].recurring_timeframe,
            data[i].total_member_who_purchased_plan.length,
            data[i]._id
          );
        }
        setrow(arr);
      }
      if (groupListRes.data.status) {
        if (groupListRes.data.data.length > 0) {
          setAllGrp(groupListRes.data.data);
        }
      }
      if (allUserRes.status) {
        let userList = allUserRes.data;
        let arr = [];
        if (userList !== undefined && userList.length > 0) {
          userList
            .map((user) => {
              const firstName = user.first_name;
              const lastName = user.last_name;
              return { ...user, firstName, lastName };
            })
            .filter((user) => user.display_name !== null)
            .map((user) =>
              arr.push({
                value: user._id,
                label: `${user.display_name} (${user["Preferred Email"]})`,
              })
            );
          setAllUser(arr);
        }
      } else {
        setAllUser([]);
      }
    } catch (error) { }
  }

  //catgorylist api call
  async function categoryListApi() {
    try {
      const [categoryRes, tagRes] = await Promise.all([
        authServicePartner.categoryList(""),
        Services.getalltags_byadmin(""),
      ]);
      if (categoryRes.status) {
        var arr = categoryRes.data.data;
        setCategoryData(arr);
      }
      if (tagRes.data.status) {
        setTagList(tagRes.data.data);
      }
    } catch (error) {
      return false;
    }
  }

  //badge list api call
  async function badgeListApi() {
    try {
      const badgeListRes = await authServicePartner.allBadgeList();
      if (badgeListRes.data.status) {
        // setBadgeList(badgeListRes.data.data)
        const badgeList = badgeListRes.data.data;
        let arr = [
          {
            value: "",
            label: "Select badge",
          },
        ];
        if (badgeList !== undefined && badgeList.length > 0) {
          badgeList.map((badges) => {
            if (badges.name !== "nobadge") {
              arr.push({
                value: badges._id,
                label: badges.name,
              });
            }
          });

          setBadgeList(arr);
        } else {
          setBadgeList([]);
        }
      }
    } catch (error) { }
  }

  //call when partner type select for add partner
  const onhandleBadgeChange = (selectedOption) => {
    dispatch({ type: "SETMODALINPROCESS" });
    // setPartnerDetailErr({ ...partnerDetailErr, partnerType: "" })
    setPartnerDetail({ ...partnerDetail, partnerType: selectedOption });
  };

  //check description validation
  const checkDesciptionValue = (state) => {
    var text = descriptionRef.current.getContent();
    if (text.length > 0 && text !== "") {
      return true;
    } else {
      return false;
    }
  };

  //check validation for offer description
  const checkOfferDesc = () => {
    var text = offerdescriptionRef.current.getContent();
    if (text.length > 0 && text !== "") {
      return true;
    } else {
      return false;
    }
  };

  //check validation for offer instruction
  const checkOfferInstruc = () => {
    var text = claimdescriptionRef.current.getContent();
    if (text.length > 0 && text !== "") {
      return true;
    } else {
      return false;
    }
  };

  //validation function
  const validate = () => {
    let isValid = false;
    let partnerError = {};
    if (partnerDetail.companyName.trim() === "") {
      isValid = true;
      partnerError.companyName = "Enter company name";
    }
    if (partnerDetail.shortDescription.trim() === "") {
      isValid = true;
      partnerError.shortDescription = "Enter short description";
    }

    if (partnerDetail.shortDescription.trim() === "") {
      isValid = true;
      partnerError.shortDescription = "Enter short description";
    }

    if (!checkDesciptionValue(partnerDetail.description)) {
      isValid = true;
      partnerError.description = "Enter description";
    } else {
      partnerError.offer_desc = "";
    }
    if (logo === "") {
      isValid = true;
      partnerError.logo = "Add logo for light theme";
    } else if (partnerDetailErr.logo !== "") {
      isValid = true;
      partnerError.logo = "Recommended size 376 x 144";
    } else {
      partnerError.logo = "";
    }
    if (!darkCompanyLogo) {
      isValid = true;
      partnerError.darkCompanyLogo = "Add logo for dark theme";
    } else if (partnerDetailErr.darkCompanyLogo !== "") {
      isValid = true;
      partnerError.darkCompanyLogo = "Recommended size 376 x 144";
    } else {
      partnerError.darkCompanyLogo = "";
    }
    if (partnerDetail.email !== "" && !emailRegex.test(partnerDetail.email)) {
      isValid = true;
      partnerError.email = "Enter valid email";
    }
    if (partnerDetail.website !== "" && !webRegex.test(partnerDetail.website)) {
      isValid = true;
      partnerError.website = "Enter valid url";
    }
    if (
      partnerDetail.facebook !== "" &&
      !webRegex.test(partnerDetail.facebook)
    ) {
      isValid = true;
      partnerError.facebook = "Enter valid url";
    }
    if (
      partnerDetail.linkedin !== "" &&
      !webRegex.test(partnerDetail.linkedin)
    ) {
      isValid = true;
      partnerError.linkedin = "Enter valid url";
    }
    if (partnerDetail.mdsPartner) {
      // if (thumbnail.length === 0) {
      //   isValid = true;
      //   partnerError.thumbnail = "Add thumbnail banner";
      // } else if (partnerDetailErr.thumbnail !== "") {
      //   isValid = true;
      //   partnerError.thumbnail = `Recommended size ${
      //     partnerDetail.type === "perk"
      //       ? "720 x 522"
      //       : partnerDetail.type === "offer"
      //       ? "624 x 336"
      //       : "720 x 522"
      //   }`;
      // } else {
      //   partnerError.thumbnail = "";
      // }
      if (webBanner.length === 0) {
        isValid = true;
        partnerError.webBanner = "Add web banner";
      } else if (partnerDetailErr.webBanner !== "") {
        isValid = true;
        partnerError.webBanner = "Recommended size 2256 x 576";
      } else {
        partnerError.webBanner = "";
      }
      if (partnerDetail.category.length === 0) {
        isValid = true;
        partnerError.category = "Enter category";
      }
      if (partnerDetail.value.trim() === "") {
        isValid = true;
        partnerError.value = "Enter value";
      }
      if (!checkOfferDesc(partnerDetail.offer_desc)) {
        isValid = true;
        partnerError.offer_desc = "Enter offer description";
      } else {
        partnerError.offer_desc = "";
      }
      if (!checkOfferInstruc(partnerDetail.offer_instru)) {
        isValid = true;
        partnerError.offer_instru = "Enter offer claim instruction";
      } else {
        partnerError.offer_instru = "";
      }
      // if (partnerDetail.type !== "perk" && partnerDetail.partnerType === "") {
      //   partnerError.partnerType = "Select partner type"
      //   isValid = true
      // }
      if (partnerDetail.status === "draft") {
        partnerError.status = "Select status";
        isValid = true;
      }
      if (partnerDetail.type === "") {
        partnerError.type = "Select type";
        isValid = true;
      }
    }

    if (Object.keys(partnerError).length > 0) {
      const emptyKeys = Object.keys(partnerError).filter(
        (key) => partnerError[key] !== ""
      );
      const inputElement = document.getElementsByName(emptyKeys[0])[0];
      if (inputElement) {
        inputElement.focus(); // Focus the input element
      }
    }
    setPartnerDetailErr(partnerError);
    return isValid;
  };

  //handle onchange
  const handlePartnerDetailChange = (e) => {
    const { name, value } = e.target;
    setPartnerDetail({ ...partnerDetail, [name]: value });
    dispatch({ type: "SETMODALINPROCESS" });
    switch (name) {
      case "companyName":
        value.trim() === ""
          ? setPartnerDetailErr({
            ...partnerDetailErr,
            companyName: "Enter company name",
          })
          : setPartnerDetailErr({ ...partnerDetailErr, companyName: "" });
        break;
      case "shortDescription":
        value.trim() === ""
          ? setPartnerDetailErr({
            ...partnerDetailErr,
            shortDescription: "Enter short description",
          })
          : setPartnerDetailErr({ ...partnerDetailErr, shortDescription: "" });
        break;
      case "category":
        value.trim() === ""
          ? setPartnerDetailErr({
            ...partnerDetailErr,
            category: "Enter category",
          })
          : setPartnerDetailErr({ ...partnerDetailErr, category: "" });
        break;
      // case "partnerType":
      //   value === "" ? setPartnerDetailErr({ ...partnerDetailErr, partnerType: "Select partnerType" }) : setPartnerDetailErr({ ...partnerDetailErr, partnerType: "" })
      //   break;
      case "type":
        if (value === "") {
          setPartnerDetailErr({ ...partnerDetailErr, type: "Select type" });
        } else if (
          value === "offer" &&
          (thumbnailWidth !== 720 || thumbnailHeight !== 336)
        ) {
          setRequiredImageSize({ ...requiredImageSize, thumbnailImageWidth: 1080, thumbnailImageHeight: 783 })
          setPartnerDetailErr({
            ...partnerDetailErr,
            thumbnail: "Recommended size 1080 x 783",
            type: "",
          });
        } else if (
          value === "perk" &&
          (thumbnailWidth !== 720 || thumbnailHeight !== 522)
        ) {
          setRequiredImageSize({ ...requiredImageSize, thumbnailImageWidth: 1080, thumbnailImageHeight: 783 })
          setPartnerDetailErr({
            ...partnerDetailErr,
            thumbnail: "Recommended size 720 x 522",
            type: "",
          });
        } else {
          setPartnerDetailErr({
            ...partnerDetailErr,
            thumbnail: "",
            type: "",
          });
        }
        break;

      case "value":
        value === ""
          ? setPartnerDetailErr({ ...partnerDetailErr, value: "Enter value" })
          : setPartnerDetailErr({ ...partnerDetailErr, value: "" });
        break;
      case "status":
        value === ""
          ? setPartnerDetailErr({
            ...partnerDetailErr,
            status: "Select status 222",
          })
          : setPartnerDetailErr({ ...partnerDetailErr, status: "" });
        break;
      case "website":
        value !== "" && !webRegex.test(value)
          ? setPartnerDetailErr({
            ...partnerDetailErr,
            website: "Enter valid url",
          })
          : setPartnerDetailErr({ ...partnerDetailErr, website: "" });
        break;
      default:
        break;
    }
  };

  //handle onchange for ismdspartner
  const handleMdsPartner = (e) => {
    setPartnerDetail({ ...partnerDetail, mdsPartner: e.target.checked });
    switch (e.target.name) {
      case "mdsPartner":
        e.target.checked === false
          ? setPartnerDetailErr({
            ...partnerDetailErr,
            mdsPartner: "Select MDSpartner",
          })
          : setPartnerDetailErr({ ...partnerDetailErr, mdsPartner: "" });
        break;
    }
  };

  //create partner api call
  async function createPartnerApi() {
    const formDataCreatePartner = new FormData();
    let logoImageCompress;
    let darkCompanyLogoCompress;
    let thumbnailCompress;
    let webBannerCompress;
    let mobileBannerCompress;

    if (parntnerImageFileData?.logoFileData) {
      logoImageCompress = await CompressImageFunction(parntnerImageFileData?.logoFileData);
      logoImageCompress && formDataCreatePartner.append("partnerIcon", logoImageCompress);
    }
    if (parntnerImageFileData?.darkCompanyLogoFileData) {
      darkCompanyLogoCompress = await CompressImageFunction(parntnerImageFileData?.darkCompanyLogoFileData);
      formDataCreatePartner.append(
        "darkCompanyLogo",
        darkCompanyLogoCompress);
    }
    if (parntnerImageFileData?.thumbnailFileData) {
      thumbnailCompress = await CompressImageFunction(parntnerImageFileData?.thumbnailFileData);
      formDataCreatePartner.append("thumbnail", thumbnailCompress);
    }
    if (parntnerImageFileData?.webBannerFileData) {
      webBannerCompress = await CompressImageFunction(parntnerImageFileData?.webBannerFileData);
      formDataCreatePartner.append("webBanner", webBannerCompress);
    }
    if (parntnerImageFileData?.mobileBannerFileData) {
      mobileBannerCompress = await CompressImageFunction(parntnerImageFileData?.mobileBannerFileData);
      formDataCreatePartner.append("mobileBanner", mobileBannerCompress);
    }
    if (partnerDetail.shortDescription) {
      formDataCreatePartner.append(
        "shortDescription",
        partnerDetail.shortDescription
      );
    }
    if (partnerDetail.companyName) {
      formDataCreatePartner.append("companyName", partnerDetail.companyName);
    }

    if (partnerDetail.contactName) {
      formDataCreatePartner.append(
        "contactInfo[contactName]",
        partnerDetail.contactName
      );
    }
    if (partnerDetail.PhoneNumber) {
      formDataCreatePartner.append(
        "contactInfo[phoneNumber]",
        partnerDetail.PhoneNumber
      );
    }
    if (partnerDetail.email) {
      formDataCreatePartner.append("contactInfo[email]", partnerDetail.email);
    }
    if (partnerDetail.website) {
      formDataCreatePartner.append(
        "contactInfo[website]",
        partnerDetail.website
      );
    }
    if (partnerDetail.facebook) {
      formDataCreatePartner.append(
        "contactInfo[facebook]",
        partnerDetail.facebook
      );
    }
    if (partnerDetail.linkedin) {
      formDataCreatePartner.append(
        "contactInfo[linkedin]",
        partnerDetail.linkedin
      );
    }
    formDataCreatePartner.append("isMDSPartner", partnerDetail.mdsPartner);
    if (partnerDetail.status) {
      formDataCreatePartner.append("status", partnerDetail.status);
    }
    if (partnerDetail.type) {
      formDataCreatePartner.append("MDSType", partnerDetail.type);
    }
    if (
      !partnerDetail.partnerType &&
      partnerDetail.partnerType.value !== undefined &&
      partnerDetail.partnerType.value !== "" &&
      partnerDetail.partnerType.value !== null
    ) {
      formDataCreatePartner.append(
        "partnerType",
        partnerDetail.partnerType.value
      );
    }
    if (partnerDetail.category.length) {
      partnerDetail.category.map((item) =>
        formDataCreatePartner.append("category", item._id)
      );
    }
    if (subcategories.length) {
      subcategories.map((item) =>
        formDataCreatePartner.append("subcategory", item)
      );
    }
    if (partnerDetail.value) {
      formDataCreatePartner.append("offerValue", partnerDetail.value);
    }
    if (descriptionRef.current !== null) {
      const description = descriptionRef.current.getContent();
      formDataCreatePartner.append("description", description);
    }
    if (offerdescriptionRef.current !== null) {
      const offer_desc = offerdescriptionRef.current.getContent();
      formDataCreatePartner.append("OfferDescription", offer_desc);
    }
    if (partnerDetail.tag.length > 0) {
      partnerDetail.tag.map((tag) =>
        formDataCreatePartner.append("tag[]", tag._id)
      );
    }

    //Data for rules
    dataFromRules?.userAccess?.length !== 0 &&
      formDataCreatePartner.append(
        `restrictionAccess`,
        dataFromRules?.userAccess === "all" ? "public" : "restricted"
      );
    if (dataFromRules?.group_id) {
      dataFromRules?.group_id.length > 0 &&
        dataFromRules?.group_id?.map((id) => {
          formDataCreatePartner.append(`restrictedAccessGroupId[]`, id);
        });
    }
    if (dataFromRules?.tags_id) {
      dataFromRules?.tags_id?.length > 0 &&
        dataFromRules?.tags_id?.map((id) => {
          formDataCreatePartner.append(`restrictedAccessTagId[]`, id);
        });
    }
    if (dataFromRules?.membership_plan_id) {
      dataFromRules?.membership_plan_id.length > 0 &&
        dataFromRules?.membership_plan_id?.map((id) => {
          formDataCreatePartner.append(
            `restrictedAccessMembershipPlanId[]`,
            id
          );
        });
    }
    dataFromRules.userAccess === "restricted" &&
      dataFromRules?.user_id.length > 0 &&
      dataFromRules?.user_id?.map((id) => {
        formDataCreatePartner.append(`restrictedAccessUserId[]`, id);
      });

    if (claimdescriptionRef.current !== null) {
      const offer_instru = claimdescriptionRef.current.getContent();
      formDataCreatePartner.append("OfferInstructions", offer_instru);
    }
    const createPartnerRes = await authServicePartner.addPartner(
      formDataCreatePartner
    );
    try {
      if (createPartnerRes.status) {
        setInProcess(false);
        setSaveInProcess(false);
        setResponseMessage(createPartnerRes.data.message);
        handleClearAllStates();
        setPartnerDetailErr({
          logo: "",
          companyName: "",
          contactName: "",
          description: "",
          offer_desc: "",
          offer_instru: "",
          mdsPartner: "",
          category: "",
          partnerType: "",
          type: "",
          status: "",
          mobileBanner: "",
          thumbnail: "",
        });
        setTimeout(() => {
          setResponseMessage("");
          partnerDetail.mdsPartner
            ? navigate(NAVIGATEPAGE)
            : navigate("/user/partnerlist");
        }, 2000);
      } else {
        setInProcess(false);
      }
    } catch (e) {
      handleClearAllStates();
      setInProcess(false);
      setSaveInProcess(false);
      setResponseMessage(e);
    }
  }
  //create partner handle
  const handleCreatePartner = async (e) => {
    e.preventDefault();
    if (!validate(partnerDetail) && !ruleError && !noRuleFieldSelected) {
      setInProcess(true);
      createPartnerApi();
    } else {
      noRuleFieldSelected && setRuleError(true);
    }
  };
  //handle save as draft partner
  const handleSaveDraft = (e) => {
    e.preventDefault();
    setPartnerDetail({ ...partnerDetail, status: "draft" });
    if (partnerDetail.companyName.trim() === "") {
      setPartnerDetailErr({
        ...partnerDetailErr,
        companyName: "Enter companyname",
      });
      dispatch({ type: "PAGECHANGE", payload: false });
      setShowSaveDraftModal(false);
    } else {
      dispatch({ type: "PAGECHANGE", payload: false });
      dispatch({ type: "REMOVEPARTNERINPROCESS" });
      setShowSaveDraftModal(false);
      setSaveInProcess(true);
      createPartnerApi();
    }
  };

  // handle tag onchange
  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setaddtag(e.target.value.trim());
    if (e.target.value.length > 0) {
      setadderr("");
    } else {
      setadderr("Enter tags! ");
    }
  };

  // handle create tag
  const handleCreate = () => {
    if (addtag.length > 0) {
      setcreateInprocess(true);

      Services.createtag({
        name: addtag.trim(),
      })
        .then((res) => {
          setcreateInprocess(false);
          if (res.data.status) {
            setshowalertAdd(true);
            setalertmsgAdd(res.data.message);
            categoryListApi();
            setTimeout(() => {
              setshowalertAdd(false);
              setalertmsgAdd("");
            }, 3000);
          } else {
            setshowalertAdd(true);
            setalertmsgAdd(res.data.message);
            setTimeout(() => {
              setshowalertAdd(false);
              setalertmsgAdd("");
            }, 3000);
          }
          handleClearAllStates();
          setaddtag("");
        })
        .catch((e) => {
          handleClearAllStates();
          setcreateInprocess(false);
          console.log(e);
        });
      setadderr("");
    } else {
      setadderr("Enter tags!");
    }
  };

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  //called when Esc key press
  const onEscCloseModal = (e) => {
    if (isModal && e.key === "Escape") {
      setModal(false);
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", onEscCloseModal);
    }
  };
  // thumbnail image upload function
  const thumbnailImages = async (acceptedfile) => {
    setFileDimension(acceptedfile);
    dispatch({ type: "SETMODALINPROCESS" });
    setFileName(Math.random().toString() + acceptedfile.name);
    setModalOpen(true);
  };

  document.addEventListener("keydown", onEscCloseModal);

  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main">
          {responseMessage.length > 0 && (
            <div className="alert alert-info">{responseMessage}</div>
          )}
          {modalOpen && (
            <ImageCropperReact
              file={thumbnailFile}
              setModalOpen={setModalOpen}
              setFile={setThumbnailFile}
              fileName={fileName}
              fileDimension={fileDimension}
              setLogo={setLogo}
              selectImageType={selectImageType}
              setDarkCompanyLogo={setDarkCompanyLogo}
              setThumbnail={setThumbnail}
              setWebBanner={setWebBanner}
              setMobileBanner={setMobileBanner}
              parntnerImageFileData={parntnerImageFileData}
              setParntnerImageFileData={setParntnerImageFileData}
              requiredImageSize={requiredImageSize}
              requiredImageAspectRatio={requiredImageAspectRatio}
            // setRequiredImageSizeError={() => {
            //   const errorMessages = {
            //     logoForLightTheme: `Recommended size ${requiredImageSize.logoForLightThemeWidth} x ${requiredImageSize.logoForLightThemeHeight}`,
            //     logoForDarkTheme: `Recommended size ${requiredImageSize.logoForDarkThemeWidth} x ${requiredImageSize.logoForDarkThemeHeight}`,
            //     partnerThumbnailImage: `Recommended size ${requiredImageSize.thumbnailImageWidth} x ${requiredImageSize.thumbnailImageHeight}`,
            //     partnerWebBanner: `Recommended size ${requiredImageSize.webBannerWidth} x ${requiredImageSize.webBannerHeight}`,
            //     partnerMobileBanner: `Recommended size ${requiredImageSize.mobileBannerWidth} x ${requiredImageSize.mobileBannerHeight}`
            //   };

            //   const fieldMapping = {
            //     logoForLightTheme: 'logo',
            //     logoForDarkTheme: 'darkCompanyLogo',
            //     partnerThumbnailImage: partnerDetail.type === "perk" ? 'perkThumbnail' : partnerDetail.type === "offer" ? 'offerThumbnail' : 'thumbnail',
            //     partnerWebBanner: 'webBanner',
            //     partnerMobileBanner: 'mobileBanner'
            //   };
            //   const errorMessage = errorMessages[selectImageType];
            //   const errorField = fieldMapping[selectImageType];

            //   setPartnerDetailErr({
            //     ...partnerDetailErr,
            //     [errorField]: errorMessage
            //   });
            // }}
            />
          )}
          <div className="uic-title">
            <h1>Partner Info</h1>
          </div>
          <div className="group-info-user">
            <div className="cstm-video-upload">
              {/* Light theme Dropzone */}
              <Dropzone
                onDrop={(acceptedFiles) => {
                  setSelectImageType("logoForLightTheme");
                  setRequiredImageAspectRatio(47 / 18)
                  const file = acceptedFiles[0];
                  if (file.type.startsWith("image/")) {
                    const reader = new FileReader();
                    reader.onload = function (event) {
                      const img = new Image();
                      img.onload = function () {
                        dispatch({ type: "SETMODALINPROCESS" });
                        thumbnailImages(file);
                        setThumbnailFile(reader.result);
                        setPartnerDetailErr({ ...partnerDetailErr, logo: "" });
                      };
                      img.src = event.target.result;
                    };
                    reader.readAsDataURL(file);
                  } else {
                    setPartnerDetailErr({
                      ...partnerDetailErr,
                      logo: "File type not supported. Please upload an image."
                    });
                  }
                }}
                name="logo"
                multiple={false}
                accept="image/png, image/gif, image/jpeg, image/jpg"
                maxSizeBytes="10485760"
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />

                    <div className="cstm-video-upload-img partner-logo-375-144">
                      <img
                        className={logo === "" && "cstm-vdo"}
                        src={
                          logo !== "" ? logo : Frame
                        }
                      />
                    </div>
                  </div>
                )}
              </Dropzone>
              <span className="error">{partnerDetailErr.logo}</span>
            </div>
            <div className="group-info-user-link">
              <div className="cover-image-upload-ttl">
                Logo for light theme*
              </div>
              <div className="cover-image-upload-size">
                {`Recommended size ${requiredImageSize.logoForLightThemeWidth} x ${requiredImageSize.logoForLightThemeHeight}`}
              </div>
            </div>
            <div className="cstm-video-upload">
              {/* Dark theme Dropzone */}
              <Dropzone
                onDrop={(acceptedFiles) => {
                  setSelectImageType("logoForDarkTheme")
                  setRequiredImageAspectRatio(47 / 18)
                  const file = acceptedFiles[0];
                  if (file.type.startsWith("image/")) {
                    const reader = new FileReader();
                    reader.onload = function (event) {
                      const img = new Image();
                      img.onload = function () {
                        dispatch({ type: "SETMODALINPROCESS" });
                        thumbnailImages(file);
                        setThumbnailFile(reader.result);
                        setPartnerDetailErr({ ...partnerDetailErr, darkCompanyLogo: "" });
                      };
                      img.src = event.target.result;
                    };
                    reader.readAsDataURL(file);
                  } else {
                    setPartnerDetailErr({
                      ...partnerDetailErr,
                      darkCompanyLogo: "File type not supported. Please upload an image."
                    });
                  }
                }}
                name="logo"
                multiple={false}
                accept="image/png, image/gif, image/jpeg, image/jpg"
                maxSizeBytes="10485760"
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />

                    <div className="cstm-video-upload-img partner-logo-375-144">
                      <img
                        className={darkCompanyLogo === "" && "cstm-vdo"}
                        src={
                          darkCompanyLogo !== "" ? darkCompanyLogo : Frame
                        }
                      />
                    </div>
                  </div>
                )}
              </Dropzone>
              <span className="error">{partnerDetailErr.darkCompanyLogo}</span>
            </div>
            <div className="group-info-user-link">
              <div className="cover-image-upload-ttl">Logo for dark theme*</div>
              <div className="cover-image-upload-size">
                {`Recommended size ${requiredImageSize.logoForDarkThemeWidth} x ${requiredImageSize.logoForDarkThemeHeight}`}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Company Name*</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  name="companyName"
                  value={partnerDetail.companyName}
                  onChange={handlePartnerDetailChange}
                />
              </div>
              <span className="error cstm-error4">
                {partnerDetailErr.companyName}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Short Description*</div>
              <div className="form-group-ct">
                <textarea
                  maxLength={250}
                  name="shortDescription"
                  value={partnerDetail.shortDescription}
                  onChange={handlePartnerDetailChange}
                />
                <span className="text-limitaion">
                  The maximum character limit is 250.
                </span>
              </div>
              <span className="error cstm-error4">
                {partnerDetailErr.shortDescription}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Description*</div>
              <div className="cstm-editor-tool-ds">
                <TinyEditor
                  valueRef={descriptionRef}
                  value={partnerDetail.description}
                  handleEditorChange={(value, editor) => {
                    dispatch({ type: "SETMODALINPROCESS" });
                    setPartnerDetailErr({
                      ...partnerDetailErr,
                      description: "",
                    });
                  }}
                  handleKeyPress={() => {
                    dispatch({ type: "SETMODALINPROCESS" });
                  }}
                />
              </div>
              <span className="error">{partnerDetailErr.description}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 form-group">
              <div className="form-group-label">Contact Name</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  name="contactName"
                  value={partnerDetail.contactName}
                  onChange={handlePartnerDetailChange}
                />
              </div>
              <span className="error cstm-error4">
                {partnerDetailErr.contactName}
              </span>
            </div>
            <div className="col-sm-4 form-group">
              <div className="form-group-label">Phone Number</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  name="PhoneNumber"
                  value={partnerDetail.PhoneNumber}
                  onChange={handlePartnerDetailChange}
                />
              </div>
            </div>
            <div className="col-sm-4 form-group">
              <div className="form-group-label">Email</div>
              <div className="form-group-ct">
                <input
                  type="email"
                  name="email"
                  value={partnerDetail.email}
                  onChange={handlePartnerDetailChange}
                />
              </div>
              <span className="error cstm-error4">
                {partnerDetailErr.email}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 form-group">
              <div className="form-group-label">Website</div>
              <div className="form-group-ct">
                <input
                  type="url"
                  name="website"
                  value={partnerDetail.website}
                  onChange={handlePartnerDetailChange}
                />
              </div>
              <span className="error cstm-error4">
                {partnerDetailErr.website}
              </span>
            </div>
            <div className="col-sm-4 form-group">
              <div className="form-group-label">Facebook URL</div>
              <div className="form-group-ct">
                <input
                  type="url"
                  name="facebook"
                  value={partnerDetail.facebook}
                  onChange={handlePartnerDetailChange}
                />
              </div>
              <span className="error cstm-error4">
                {partnerDetailErr.facebook}
              </span>
            </div>
            <div className="col-sm-4 form-group">
              <div className="form-group-label">LinkedIn URL</div>
              <div className="form-group-ct">
                <input
                  type="url"
                  name="linkedin"
                  value={partnerDetail.linkedin}
                  onChange={handlePartnerDetailChange}
                />
              </div>
              <span className="error cstm-error4">
                {partnerDetailErr.linkedin}
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="cstm-chk-preregistration-container">
                <input
                  type="checkbox"
                  name="mdsPartner"
                  defaultChecked
                  value={partnerDetail.mdsPartner}
                  id="chk-preregistration"
                  onChange={handleMdsPartner}
                />
                <label for="chk-preregistration">
                  Is this a partner of MDS?
                </label>
              </div>
            </div>
          </div>
          {partnerDetail.mdsPartner && (
            <>
              <div class="form-group">
                <div class="cstm-channel-type-title">Partner's Type*</div>
                <div class="cstm-pm-list">
                  <label class="subcat-addvideo">
                    <input
                      type="radio"
                      value="perk"
                      name="type"
                      checked={partnerDetail.type === "perk"}
                      onChange={handlePartnerDetailChange}
                    />
                    Perk
                  </label>
                  <label class="subcat-addvideo">
                    <input
                      type="radio"
                      value="offer"
                      name="type"
                      checked={partnerDetail.type === "offer"}
                      onChange={handlePartnerDetailChange}
                    />
                    Offer
                  </label>
                </div>
                <span className="error cstm-pm-list-msg">
                  {partnerDetailErr.type}
                </span>
              </div>

              <AddRules
                title="Rules*"
                ruleError={ruleError}
                setRuleError={setRuleError}
                totalUserList={totalUserList}
                noRuleFieldSelected={noRuleFieldSelected}
                setNoRuleFieldSelected={setNoRuleFieldSelected}
                dataFromRules={dataFromRules}
                setDataFromRules={setDataFromRules}
                resetRulesFields={resetRulesFields}
                showEventField={true}
                showTagsField={true}
              />

              <div class="form-group">
                <div class="cstm-channel-type-title partner-icon-rf-only">
                  Partner Status*{" "}
                  <a
                    className="status-undo"
                    onClick={() =>
                      setPartnerDetail({ ...partnerDetail, status: "draft" })
                    }
                  >
                    <img src={undo} />
                  </a>
                </div>
                <div class="cstm-pm-list">
                  <label class="subcat-addvideo">
                    <input
                      type="radio"
                      value="paused"
                      name="status"
                      checked={partnerDetail.status === "paused"}
                      onChange={handlePartnerDetailChange}
                    />
                    Pause
                  </label>
                  <label class="subcat-addvideo">
                    {" "}
                    <input
                      type="radio"
                      value="published"
                      name="status"
                      checked={partnerDetail.status === "published"}
                      onChange={handlePartnerDetailChange}
                    />
                    Live
                  </label>
                </div>
                <span className="error cstm-pm-list-msg">
                  {partnerDetailErr.status}
                </span>
              </div>

              {partnerDetail.type !== "perk" && (
                <div className="row">
                  <div class="col-sm-4 form-group">
                    <div class="form-group-label cstm-channel-type-title">
                      Partner's Badge
                    </div>
                    <div className="form-group-ctst event-type-drop-only">
                      <Select
                        isSearchable={true}
                        options={badgeList}
                        onChange={onhandleBadgeChange}
                        value={partnerDetail.partnerType}
                        styles={{ zIndex: 9999 }}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-sm-4 form-group">
                  <div className="form-group-label">Category*</div>
                  <div className="form-group-ct">
                    <input
                      list="category"
                      type="text"
                      name="browser"
                      onKeyUp={(e) => {
                        dispatch({ type: "SETMODALINPROCESS" });
                        var selType = categoryData.filter((type) => {
                          if (type.name === e.target.value) return type.name;
                        });
                        var eventType = partnerDetail.category.find(
                          (type) => type.name === e.target.value
                        );

                        if (selType.length > 0 && !eventType) {
                          setPartnerDetail({
                            ...partnerDetail,
                            category: [...partnerDetail.category, ...selType],
                            subCategory: [
                              ...partnerDetail.subCategory,
                              ...selType[0].subcategory.map((cat) => {
                                return cat._id;
                              }),
                            ],
                          });

                          e.target.value = "";
                          setPartnerDetailErr({
                            ...partnerDetailErr,
                            category: "",
                          });
                        }
                      }}
                    />
                  </div>
                  <span className="error cstm-error4">
                    {partnerDetailErr.category}
                  </span>
                </div>
              </div>
              <div>
                {partnerDetail.category.map((type) => {
                  return (
                    <span className="topic-label-in">
                      <b>{type.name}</b>
                      <button
                        onClick={() => {
                          setPartnerDetail({
                            ...partnerDetail,
                            category: partnerDetail.category.filter(
                              (typeFilter) => {
                                if (typeFilter.name !== type.name)
                                  return typeFilter;
                              }
                            ),
                          });
                        }}
                      >
                        ×
                      </button>
                    </span>
                  );
                })}
                <datalist id="category">
                  {categoryData.map((item) => {
                    if (
                      partnerDetail.category.filter((category) => {
                        if (category.name === item.name) return category.name;
                      }).length === 0
                    )
                      return <option>{item.name}</option>;
                  })}
                </datalist>
                {partnerDetail.category.filter((cat) => {
                  if (cat.subcategory) return cat;
                }).length > 0 ? (
                  <div className="row">
                    <div className="col-sm-12 form-group mb-0">
                      <div class="cstm-add-video-title-sub">
                        Select Subcategory
                      </div>
                      <div className="form-group-ctst">
                        {partnerDetail.category.map((cat) => {
                          if (cat.subcategory)
                            return cat.subcategory.map((sCat) => {
                              return (
                                <label className="subcat-addvideo">
                                  <input
                                    type="checkbox"
                                    name="subcategory"
                                    value={sCat.name}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setSubcategories([
                                          ...subcategories,
                                          sCat._id,
                                        ]);
                                      } else {
                                        setSubcategories([
                                          ...subcategories.filter((cat) => {
                                            if (cat !== sCat._id) return cat;
                                          }),
                                        ]);
                                      }
                                    }}
                                    defaultChecked={
                                      subcategories.filter((cat) => {
                                        if (cat === sCat._id) return cat;
                                      }).length > 0
                                    }
                                  />
                                  {sCat.name}
                                </label>
                              );
                            });
                        })}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="row">
                <div className="col-sm-4 form-group">
                  <div className="form-group-label">
                    Add Tag or{" "}
                    <a
                      className="create-speaker-link"
                      data-toggle="modal"
                      data-target="#addtag"
                    >
                      Create Tag
                    </a>
                  </div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      autoComplete="off"
                      list="tag"
                      name="browser"
                      onKeyUp={(e) => {
                        var selType = tagList.filter((type) => {
                          if (type.name === e.target.value) return type.name;
                        });
                        var eventType = partnerDetail.tag.find(
                          (type) => type.name === e.target.value
                        );

                        if (selType.length > 0 && !eventType) {
                          setPartnerDetail({
                            ...partnerDetail,
                            tag: [...partnerDetail.tag, ...selType],
                          });
                          e.target.value = "";
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div>
                {partnerDetail.tag.map((type) => {
                  return (
                    <span className="topic-label-in">
                      <b>{type.name}</b>
                      <button
                        onClick={() => {
                          setPartnerDetail({
                            ...partnerDetail,
                            tag: partnerDetail.tag.filter((typeFilter) => {
                              if (typeFilter.name !== type.name)
                                return typeFilter;
                            }),
                          });
                        }}
                      >
                        ×
                      </button>
                    </span>
                  );
                })}
                <datalist id="tag">
                  {tagList.map((tag) => {
                    if (
                      partnerDetail.tag.filter((category) => {
                        if (category.name === tag.name) return category;
                      }).length === 0
                    )
                      return <option>{tag.name}</option>;
                  })}
                </datalist>
              </div>
              <div className="row cstm-mds-partner-only">
                <div class="cstm-channel-type-title">{`Upload Thumbnail ${partnerDetail.type === "perk"
                  ? "For Perk"
                  : partnerDetail.type === "offer"
                    ? "For Offer"
                    : ""
                  }`}</div>
                <div className="col-sm-4 form-group">
                  <div className="group-info-user">
                    <div className="cstm-video-upload">
                      {/* Thumbnail image Dropzone */}
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          setSelectImageType("partnerThumbnailImage")
                          if (partnerDetail.type === "perk") {
                            setRequiredImageAspectRatio(16 / 9)
                          } else {
                            setRequiredImageAspectRatio(16 / 9)
                          }
                          const file = acceptedFiles[0];
                          if (file.type.startsWith("image/")) {
                            const reader = new FileReader();
                            reader.onload = function (event) {
                              const img = new Image();
                              img.onload = function () {
                                dispatch({ type: "SETMODALINPROCESS" });
                                thumbnailImages(file);
                                setThumbnailFile(reader.result);
                                setPartnerDetailErr({
                                  ...partnerDetailErr,
                                  perkThumbnail: ``,
                                  offerThumbnail: ``,
                                  thumbnail: ``
                                })
                              };
                              img.src = event.target.result;
                            };
                            reader.readAsDataURL(file);
                          } else {
                            setPartnerDetailErr({
                              ...partnerDetailErr,
                              thumbnail: "File type not supported. Please upload an image."
                            });
                          }
                        }}
                        name="thumbnail"
                        multiple={false}
                        accept="image/png, image/gif, image/jpeg, image/jpg"
                        maxSizeBytes="10485760"
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <div
                              className={`cstm-video-upload-img ${partnerDetail.type === "perk"
                                ? "partner-logo-720-522"
                                : "partner-logo-624-336"
                                }`}
                            >
                              <img
                                className={thumbnail.length === 0 && "cstm-vdo"}
                                src={
                                  thumbnail.length !== 0
                                    ? thumbnail
                                    : Frame
                                }
                              />
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      {(
                        <span className="error">
                          {(partnerDetail.type === "" || partnerDetail.type === "offer")
                            ? partnerDetailErr?.offerThumbnail
                            : partnerDetail.type === "perk"
                              ? partnerDetailErr?.perkThumbnail
                              : ""}
                        </span>
                      )}
                    </div>
                    <div className="group-info-user-link">
                      <div className="cover-image-upload-ttl">
                        Thumbnail Image
                      </div>
                      <div className="cover-image-upload-size">
                        {`Recommended size ${requiredImageSize.thumbnailImageWidth} x ${requiredImageSize.thumbnailImageHeight}`}

                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 form-group">
                  <div className="group-info-user">
                    <div className="cstm-video-upload">
                      {/* Web banner Dropzone */}
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          setSelectImageType("partnerWebBanner");
                          setRequiredImageAspectRatio(31 / 6)
                          const file = acceptedFiles[0];
                          if (file.type.startsWith("image/")) {
                            const reader = new FileReader();
                            reader.onload = function (event) {
                              const img = new Image();
                              img.onload = function () {
                                dispatch({ type: "SETMODALINPROCESS" });
                                thumbnailImages(file);
                                setThumbnailFile(reader.result);
                                setPartnerDetailErr({ ...partnerDetailErr, webBanner: "" });
                              };
                              img.src = event.target.result;
                            };
                            reader.readAsDataURL(file);
                          } else {
                            setPartnerDetailErr({
                              ...partnerDetailErr,
                              webBanner: "File type not supported. Please upload an image."
                            });
                          }
                        }}
                        name="thumbnail"
                        multiple={false}
                        accept="image/png, image/gif, image/jpeg, image/jpg"
                        maxSizeBytes="10485760"
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <div className="cstm-video-upload-img partner-logo-2256-576">
                              <img
                                className={webBanner.length === 0 && "cstm-vdo"}
                                src={
                                  webBanner.length !== 0
                                    ? webBanner
                                    : Frame
                                }
                              />
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <span className="error">
                        {partnerDetailErr.webBanner}
                      </span>
                    </div>
                    <div className="group-info-user-link">
                      <div className="cover-image-upload-ttl">
                        Banner (Web)*
                      </div>
                      <div className="cover-image-upload-size">
                        {`Recommended size ${requiredImageSize.webBannerWidth} x ${requiredImageSize.webBannerHeight}`}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 form-group">
                  <div className="group-info-user">
                    <div className="cstm-video-upload">
                      {/* Mobile banner Dropzone */}
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          setSelectImageType("partnerMobileBanner");
                          setRequiredImageAspectRatio(39 / 10)
                          const file = acceptedFiles[0];
                          if (file.type.startsWith("image/")) {
                            const reader = new FileReader();
                            reader.onload = function (event) {
                              const img = new Image();
                              img.onload = function () {
                                dispatch({ type: "SETMODALINPROCESS" });
                                thumbnailImages(file);
                                setThumbnailFile(reader.result);
                                setPartnerDetailErr({ ...partnerDetailErr, mobileBanner: "" });
                              };
                              img.src = event.target.result;
                            };
                            reader.readAsDataURL(file);
                          } else {
                            setPartnerDetailErr({
                              ...partnerDetailErr,
                              mobileBanner: "File type not supported. Please upload an image."
                            });
                          }
                        }}
                        name="mobileBanner"
                        multiple={false}
                        accept="image/png, image/gif, image/jpeg, image/jpg"
                        maxSizeBytes="10485760"
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <div className="cstm-video-upload-img partner-logo-780-552">
                              <img
                                className={
                                  mobileBanner.length === 0 && "cstm-vdo"
                                }
                                src={
                                  mobileBanner.length !== 0
                                    ? mobileBanner
                                    : Frame
                                }
                              />
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <span className="error">
                        {partnerDetailErr.mobileBanner}
                      </span>
                    </div>
                    <div className="group-info-user-link">
                      <div className="cover-image-upload-ttl">
                        Banner (Mobile)
                      </div>
                      <div className="cover-image-upload-size">
                        {`Recommended size ${requiredImageSize.mobileBannerWidth} x ${requiredImageSize.mobileBannerHeight}`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">Value*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      name="value"
                      value={partnerDetail.value}
                      onChange={handlePartnerDetailChange}
                      maxLength={50}
                    />
                  </div>
                  <span className="text-limitaion">
                    The maximum character limit is 50.
                  </span>
                  <span className="error cstm-error4">
                    {partnerDetailErr.value}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">Offer Description*</div>
                  <div className="cstm-editor-tool-ds">
                    <TinyEditor
                      valueRef={offerdescriptionRef}
                      value={partnerDetail.offer_desc}
                      handleEditorChange={(value, editor) => {
                        dispatch({ type: "SETMODALINPROCESS" });
                        setPartnerDetailErr({
                          ...partnerDetailErr,
                          offer_desc: "",
                        });
                      }}
                      handleKeyPress={() => {
                        dispatch({ type: "SETMODALINPROCESS" });
                      }}
                    />
                  </div>
                  <span className="error">{partnerDetailErr.offer_desc}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">
                    Offer Claim Instructions*
                  </div>
                  <div className="cstm-editor-tool-ds">
                    <TinyEditor
                      valueRef={claimdescriptionRef}
                      value={partnerDetail.offer_instru}
                      handleEditorChange={(value, editor) => {
                        dispatch({ type: "SETMODALINPROCESS" });
                        setPartnerDetailErr({
                          ...partnerDetailErr,
                          offer_instru: "",
                        });
                      }}
                      handleKeyPress={() => {
                        dispatch({ type: "SETMODALINPROCESS" });
                      }}
                    />
                  </div>
                  <span className="error">{partnerDetailErr.offer_instru}</span>
                </div>
              </div>
            </>
          )}

          {totalUsers > 0 && (
            <span
              className="notification-user-list"
              onClick={() => setModal(true)}
            >
              Review the profiles of {totalUsers} individuals who currently meet
              the specified criteria.
            </span>
          )}

          <div className="cyp-btm-btn">
            {inProcess ? (
              <div className="loader-button1">
                <button>In Process...</button>
                <span className="loader-btn">
                  <img src={Loader2} />
                </span>
              </div>
            ) : (
              <button className="btn" onClick={handleCreatePartner}>
                Create Partner
              </button>
            )}
            {partnerDetail.status === "draft" && (
              <>
                {saveInProcess ? (
                  <div className="loader-button1 save-draft-loader">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <button className="btn ms-4" onClick={handleSaveDraft}>
                    Save as draft
                  </button>
                )}
              </>
            )}
            <button
              className="btn2"
              onClick={() => {
                navigate(-1);
              }}
            >
              Discard
            </button>
          </div>
        </div>
      </div>
      {/* {PAGECHANGE &&
        <SaveDraftModal handleSaveDraft={handleSaveDraft} />
      } */}
      <AlertComponent />
      <div
        className="modal fade edit-user-details-popup"
        id="addtag"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Create tag</h2>
              <button
                type="button"
                className="close"
                //data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#addtag").modal("hide");
                    handleCloseAddModel(e);
                  }
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {showalertAdd && (
                <div className="alert alert-info">{alertmsgAdd}</div>
              )}
              <div className="form-group">
                <div className="form-group-label">Tag name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="enter tags name"
                    name="tag"
                    onChange={handleAddChange}
                    value={addtag}
                  />
                </div>
                <span className="error">{adderr}</span>
              </div>
            </div>
            <div className="edit-user-details-footer cstm-create-button">
              {createInprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleCreate}
                  disabled={createInprocess}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {isModal && (
        <NotifiedUsertable setModal={setModal} users={totalUserList} />
      )}
    </>
  );
};
export default CreatePartner;
