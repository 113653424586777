import React from "react";
import PropTypes from "prop-types"
function NavPartnerIcon({ height = 24, width = 24, iconColor = "#000", isFilled }) {
    return (
        <>
            {isFilled ?
                <svg
                    xmlns="https://www.w3.org/2000/svg"
                    width={width}
                    height={height}
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill={iconColor}
                        fillRule="evenodd"
                        d="M7.25 3A.75.75 0 0 1 8 2.25h8a.75.75 0 0 1 0 1.5H8A.75.75 0 0 1 7.25 3ZM5.5 5A4.5 4.5 0 0 0 1 9.5v1l1.5.352 6.697 1.674A2.5 2.5 0 0 1 11.5 11h1a2.5 2.5 0 0 1 2.303 1.526l6.697-1.674L23 10.5v-1A4.5 4.5 0 0 0 18.5 5h-13ZM23 12l-1.5.398-6.5 1.625v.477a2.5 2.5 0 0 1-2.5 2.5h-1A2.5 2.5 0 0 1 9 14.5v-.477l-6.5-1.625L1 12v5.5A4.5 4.5 0 0 0 5.5 22h13a4.5 4.5 0 0 0 4.5-4.5V12Zm-10.5.5h-1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1Z"
                        clipRule="evenodd"
                    />
                </svg > : <svg
                    xmlns="https://www.w3.org/2000/svg"
                    width={width}
                    height={height}
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <path fill={iconColor} d="M8 2.25a.75.75 0 0 0 0 1.5h8a.75.75 0 0 0 0-1.5H8Z" />
                    <path
                        fill={iconColor}
                        fillRule="evenodd"
                        d="M1 9.5A4.5 4.5 0 0 1 5.5 5h13A4.5 4.5 0 0 1 23 9.5v8a4.5 4.5 0 0 1-4.5 4.5h-13A4.5 4.5 0 0 1 1 17.5v-8Zm1.5 2.898V17.5a3 3 0 0 0 3 3h13a3 3 0 0 0 3-3v-5.102L15 14.023v.477a2.5 2.5 0 0 1-2.5 2.5h-1A2.5 2.5 0 0 1 9 14.5v-.477l-6.5-1.625Zm6.697.128A2.5 2.5 0 0 1 11.5 11h1a2.5 2.5 0 0 1 2.303 1.526l6.697-1.674V9.5a3 3 0 0 0-3-3h-13a3 3 0 0 0-3 3v1.352l6.697 1.674ZM11.5 12.5h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1Z"
                        clipRule="evenodd"
                    />
                </svg>
            }
        </>
    );
}
NavPartnerIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
    isFilled: PropTypes.bool
}
export default NavPartnerIcon;
