import { useEffect, useState } from "react";
import Services from "../../../Services/auth.service.event";
import Loader from "../../../Images/loader.gif";
import { useDropzone } from "react-dropzone";
import { Link, useSearchParams } from "react-router-dom";
import CloseGmv from "../../../Images/CloseGmv.svg";
import DownloadGmv from "../../../Images/DownloadGmv.png";
import DeleteGmv from "../../../Images/DeleteGmv.svg";
import loading from "../../../Images/loader.gif";
import imgBigDelete from "../../../Images/BackendImages/delete-icon.svg";

//Event Photos module
const UploadPhotos = ({ tabInformation }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const queryVariable = searchParams.get("id");
    const [loader, setLoader] = useState(false);
    const [uploadImgLoader, setUploadImgLoader] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [allGalleryPhotos, setAllGalleryPhotos] = useState([]);
    const [selectedPhotoes, setSelectedPhotoes] = useState({});
    const [alertMsg, setAlertMsg] = useState("");
    const [selectedImg, setSelectedImg] = useState("");
    const [chkSelectAll, setChkSelectAll] = useState(false)
    const [bulkDeleteFlag, setBulkDeleteFlag] = useState(true)
    //get all contact support listing
    const getAllGalleryImageApi = () => {
        Services.getAllGalleryByEventId(queryVariable)
            .then((res) => {
                setLoader(false);
                if (res.data.status) {
                    setAllGalleryPhotos(res.data.data.photos)
                }
            })
            .catch((e) => {
                setLoader(false);
                console.log(e);
            });
    };

    useEffect(() => {
        if (tabInformation === "Photos") {
            setLoader(true);
            getAllGalleryImageApi();
        }
    }, [tabInformation]);

    //Called on dropping any file
    const onDrop = (acceptedFiles) => {
        var formData = new FormData();
        acceptedFiles.map((img) => {
            formData.append("photo", img);
        })
        setUploadImgLoader(true)
        var data = {
            id: queryVariable,
            data: formData
        }
        if (acceptedFiles.length > 0) {
            Services.addEventGalleryPhoto(data)
                .then((res) => {
                    setUploadImgLoader(false)
                    if (res.data.status) {
                        setAllGalleryPhotos(res.data.data.photos)
                    }
                    setAlertMsg(res.data.message)
                })
                .catch((e) => {
                    setUploadImgLoader(false)
                    console.log(e);
                });
        }
        else {
            setAlertMsg("Only png, gif, jpeg, jpg formate is allowed!")
        }
    };

    //Make alert message empty after 3 seconds
    useEffect(() => {
        setTimeout(() => {
            setAlertMsg("");
        }, 3000);
    }, [alertMsg])
    //File dropzone functions
    const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
        accept: "image/png, image/gif, image/jpeg, image/jpg",
        maxSizeBytes: 10485760,
        onDrop,
    });

    //Handle delete call
    const handleDelete = () => {
        setDeleteLoader(true);
        const data = {
            id: queryVariable,
            photos: [selectedImg]
        }
        Services.deleteGalleryPhoto(data)
            .then((res) => {
                if (res.data.status) {
                    setSelectedImg("");
                    setDeleteLoader(false)
                    document.querySelector('#close-modal-delete').click();
                    document.querySelector('#close-modal-image').click();
                    setAllGalleryPhotos(res.data.data.photos)
                }
                setAlertMsg(res.data.message)
            })
            .catch((e) => {
                setUploadImgLoader(false)
                console.log(e);
            });
    }

    const handleSelectAllPhotoes = (e) => {
        setChkSelectAll(e.target.checked)
        let arr = {}

        allGalleryPhotos.map((imageUrl) => {
            arr[imageUrl] = e.target.checked ? true : false;
        })
        setSelectedPhotoes(arr)

    }

    useEffect(() => {
        if (allGalleryPhotos !== undefined && allGalleryPhotos !== null && allGalleryPhotos.length > 0) {
            let arr = {}
            allGalleryPhotos.map((imageUrl) => {
                arr[imageUrl] = false
            })
            setSelectedPhotoes(arr)
        }
    }, [allGalleryPhotos]);

    const handleSelectSinglePhoto = (e, imgUrl) => {

        let arr = { ...selectedPhotoes }
        arr[imgUrl] = e.target.checked ? true : false
        setSelectedPhotoes(arr)
    }

    const handleBulkDelete = (e) => {
        setDeleteLoader(true);
        let arr = []

        Object.keys(selectedPhotoes).map((key, index) => {
            if (selectedPhotoes[key] == true) {
                arr.push(key)
            }
        })

        const data = {
            id: queryVariable,
            photos: arr
        }
        Services.deleteGalleryPhoto(data)
            .then((res) => {
                if (res.data.status) {
                    setSelectedImg("");
                    setDeleteLoader(false)
                    setChkSelectAll(false)
                    document.querySelector('#bulkDeleteClose').click();
                    setAllGalleryPhotos(res.data.data.photos)

                }
                setAlertMsg(res.data.message)
            })
            .catch((e) => {
                setUploadImgLoader(false)
                console.log(e);
            });
    }

    useEffect(() => {
        setBulkDeleteFlag(true)

        let arr = []
        // alert(e.target)
        Object.keys(selectedPhotoes).map((key, index) => {
            if (selectedPhotoes[key] == true) {
                setBulkDeleteFlag(false)
                return;
            }
        })

    }, [selectedPhotoes])

    const handleBulkDeleteModalOpen = (e) => {
        let arr = []
        // alert(e.target)
        Object.keys(selectedPhotoes).map((key, index) => {
            if (selectedPhotoes[key] == true) {
                arr.push(key)
            }
        })
        if (arr.length > 0) {
            $('#BulkDeleteImg').modal('show');
        } else {
            setAlertMsg("No photoes selected to delete!")
            $('#BulkDeleteImg').modal('hide');
        }
    }

    return (
        <>

            <div className="uic-fl-mn">
                <div className="uic-fl-mn-left">
                    {allGalleryPhotos !== undefined && allGalleryPhotos.length > 0 && <> <div className="select-all-checkbox"><label><input type="checkbox" name="chkSelectAll" checked={chkSelectAll} onChange={handleSelectAllPhotoes} />Select All</label></div>
                    </>}</div>
                <div className="uic-fl-mn-right cstm-sec-button">
                    <div className="uic-fl-links">
                        <a class={`btn ${bulkDeleteFlag ? 'disable' : ''}`} onClick={handleBulkDeleteModalOpen}   >Bulk delete</a> </div>
                    <div class="uic-fl-btn">

                        <div className="upload-photo-button" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <span className="import text">Upload Photos</span>
                            {uploadImgLoader && <span className="loader-btn">
                                <img src={Loader} />
                            </span>}
                        </div>

                    </div>

                </div>
            </div>


            {alertMsg && <div className="alert alert-info">{alertMsg}</div>}
            {/* Modal Delete */}
            <div
                className="modal fade msg-popup cstm-up-pp-delete"
                id="DeleteImg"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            &times;
                        </button>
                        <div className="msg-icon">
                            <img src={imgBigDelete} />
                        </div>
                        <h1>Are you sure you want to delete this photo?</h1>
                        <div className="row">
                            <div className="col-6">
                                <span className="msg-btn">
                                    <button
                                        type="button"
                                        className="btn btn-red cstm-cgl"
                                        onClick={handleDelete}
                                    >
                                        Delete
                                        {deleteLoader && <span className='create-group-loader'>
                                            <img src={loading} />
                                        </span>}
                                    </button>
                                </span>
                            </div>
                            <div className="col-6">
                                <span className="msg-btn">
                                    <button type="button" id="close-modal-delete" className="btn2" data-dismiss="modal">
                                        Discard
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* View image modal */}
            <div
                className="modal fade cstm-view-photo"
                id="ImageModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button
                            id="close-modal-image"
                            type="button"
                            className="cstm-gmv-close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <img src={CloseGmv} />
                        </button>

                        <div className="upload-photo-container">
                            <img src={selectedImg} alt="" />
                        </div>
                        <div className="upload-photo-icon">
                            <a className="cstm-up-icon" href={selectedImg}><img src={DownloadGmv} /></a>
                            <a
                                className="cstm-up-icon"
                                data-toggle="modal"
                                data-target="#DeleteImg"
                            >
                                <img src={DeleteGmv} />
                            </a>
                        </div>
                    </div>
                </div>
            </div >
            {/*modal popup for bulk delete*/}
            <div
                className="modal fade msg-popup cstm-up-pp-delete"
                id="BulkDeleteImg"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button
                            type="button"
                            id="bulkDeleteClose"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            &times;
                        </button>
                        <div className="msg-icon">
                            <img src={imgBigDelete} />
                        </div>
                        <h1>Are you sure you want to delete the selected images?</h1>
                        <div className="row">
                            <div className="col-6">
                                <span className="msg-btn">
                                    <button
                                        type="button"
                                        className="btn btn-red cstm-cgl"
                                        onClick={handleBulkDelete}
                                    >
                                        Delete
                                        {deleteLoader && <span className='create-group-loader'>
                                            <img src={loading} />
                                        </span>}
                                    </button>
                                </span>
                            </div>
                            <div className="col-6">
                                <span className="msg-btn">
                                    <button type="button" id="close-modal-delete" className="btn2" data-dismiss="modal">
                                        Discard
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Gallery image listing */}
            {loader ? <div className="cstm-upload-photo-event-loader"><img src={Loader} /></div> : allGalleryPhotos.length > 0 ?
                <div className="cstm-upload-photo-event">
                    {allGalleryPhotos.map((imageUrl) => {
                        return <>
                            <div className="cstm-upload-photo-event-in" onClick={() => setSelectedImg(imageUrl)}>
                                <span class="cstm-eventimage-wrapper"><input type="checkbox" name="chkEventImage" onChange={(e) => handleSelectSinglePhoto(e, imageUrl)}
                                    checked={selectedPhotoes !== undefined && selectedPhotoes !== null
                                        && selectedPhotoes[imageUrl] !== undefined ? selectedPhotoes[imageUrl] : false} /></span>
                                <img data-toggle="modal"
                                    data-target="#ImageModal" src={imageUrl} alt="" />
                            </div>
                        </>
                    })}
                </div> : <span className="no-data-found">No Photos Found</span>
            }
        </>
    )

}
export default UploadPhotos;