import React, { useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { useLocation, useNavigate } from "react-router-dom";
import Frame from "../../../../Images/Frame.svg";
import { Editor } from "@tinymce/tinymce-react";
import Loader2 from "../../../../Images/loader2.gif";
import Loader from "../../../../Images/loader.gif";
import Services from "../../../../Services/auth.service";
import authServiceDocument from "../../../../Services/auth.service.document";
import { useDispatch, useSelector } from "react-redux";
import undo from "../../../../Images/undo.svg";
import TinyEditor from "../../../AdminCommonComponent/TinyEditor";
import AlertComponent from "../../../../Hooks/AlertComponent";
import uploadIcon from "../../../../Images/icn_m_upload.svg";
import authService from "../../../../Services/auth.service";
import $ from "jquery";
import Button from "../../../Button/Button";
import PdfIcon from "../../../Icons/FileIcons/PdfIcon";
import DocIcon from "../../../Icons/FileIcons/DocIcon";
import ImageIcon from "../../../Icons/FileIcons/ImageIcon";
import VideoIcon from "../../../Icons/GeneralIcons/VideoIcon";
import VideoFileIcon from "../../../Icons/FileIcons/VideoFileIcon";
import AddCategory from "../Components/AddCategory";
import AddContentType from "../Components/AddContentType";
import AddTag from "../Components/AddTag";
import ExcelFileIcon from "../../../Icons/FileIcons/ExcelFileIcon";
import ExcelIcon from "../../../Icons/FileIcons/ExcelIcon";
import NotDefinedFileIcon from "../../../Icons/FileIcons/NotDefinedFileIcon";
import AudioFileIcon from "../../../Icons/FileIcons/AudioFileIcon";
import PptIcon from "../../../Icons/FileIcons/PptIcon";
import Select from "react-select";
import moment from "moment";
import PreventionCopyModal from "../DocumentModals/PreventionCopyModal";
import DownloadIcon from "../../../Icons/GeneralIcons/DownloadIcon";
import ToastNotification from "../../../ToastNotification/ToastNotification";
import { cancelDownload } from "../../../../Pages/DocumentManagement/commonFunctions/downloadFunction";
import axios from "axios";
import AddRules from "../../../CommonRules/AddRules";
import { useUserCountMutation } from "../../../../Services/ruleUserCount";

const EditDocumentInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const descriptionRef = useRef(null);
  const NAVIGATEPAGE = useSelector((state) => {
    return state.partnerNavigate;
  });
  const [subcategories, setSubcategories] = useState([]);
  const [fileDetails, setFileDetails] = useState({
    permanantFileName: "",
    fileSize: "",
    fileType: "",
  });
  const [preiviewFileDetails, setPreviewFileDetails] = useState({
    permanantFileName: "",
    fileSize: "",
    fileType: "",
  });
  const [documentDetail, setDocumentDetail] = useState({
    fileName: "",
    fileUrl: "",
    shortDescription: "",
    description: "",
    status: "Draft",
    documentType: "",
    category: [],
    subCategory: [],
    contentType: [],
    hashTagIds: [],
    user: {},
    dateUploaded: "",
    downloadUrl: "",
    mediaPreviewFileUrl: "",
    mediaPreviewFile: "",
  });
  const [documentDetailErr, setDocumentDetailErr] = useState({
    file: "",
    fileName: "",
    fileUrl: "",
    owner: "",
    description: "",
    documentType: "",
    category: "",
    contentType: "",
    hashTagIds: "",
    submissionType: "",
    dateUploaded: "",
    mediaPreviewFileUrl: "",
    mediaPreviewFile: "",
  });
  const [fileUrl, setFileUrl] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [inProcess, setInProcess] = useState(false);
  const [saveInProcess, setSaveInProcess] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [contentTypeData, setContentTypeData] = useState([]);
  const [hashTagData, setHashTagData] = useState([]);
  const [progress, setProgress] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [dataLoader, setDataLoader] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [allUser, setAllUser] = useState([]);
  const location = useLocation().search;
  const id = new URLSearchParams(location).get("id");
  const [showContinueModal, setShowContinueModal] = useState(false);
  const [mediafilePreviewUrl, setMediaFilePreviewUrl] = useState("");
  const [uploadPreviewProgress, setUploadPreviewProgress] = useState(0);
  const [uploadingPreview, setUploadingPreview] = useState(false);
  const [dataFromRules, setDataFromRules] = useState([]);
  const [rulesDataForEdit, setRulesDataForEdit] = useState({});
  const [ruleError, setRuleError] = useState(false);
  const [noRuleFieldSelected, setNoRuleFieldSelected] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalUserList, setTotalUserList] = useState([]);
  const latestRequest = useRef(0);
  //User count mutation
  const [userCountApi] = useUserCountMutation();

  const countUserApi = async (bodyData, requestId) => {
    try {
      const countRes = await userCountApi(bodyData);
      if (latestRequest.current === requestId) {
        // Only update state if this is the latest request
        setTotalUserList(countRes?.data?.data);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    const bodyData = {
      restrictedAccessUserId: dataFromRules.user_id,
      restrictedAccessMembershipPlanId: dataFromRules.membership_plan_id,
      restrictedAccessGroupId: dataFromRules.group_id,
      restrictedAccessTagId: dataFromRules.tags_id,
      restrictedAccessEventId: dataFromRules.event_id,
      restrictionAccess:
        dataFromRules.userAccess === "all" ? "public" : "restricted",
    };
    const requestId = ++latestRequest.current; // Increment the request ID
    countUserApi(bodyData, requestId);
  }, [dataFromRules]);

  //to render on pageload
  useEffect(() => {
    handleClearAllStates();
    tagListApi();
    docDetailApi();
    setDataLoader(true);
    authService
      .getAllAttendeeList("")
      .then((res) => {
        if (res.status) {
          let userList = res.data;
          let arr = [];
          if (userList !== undefined && userList.length > 0) {
            userList.map((user) => {
              arr.push({
                value: user._id,
                label:
                  `${user.display_name}` + " (" + user["Preferred Email"] + ")",
                userDetail: `${user.display_name}`,
              });
            });
            setAllUser(arr);
          }
        } else {
          setAllUser([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  async function docDetailApi() {
    try {
      const docDetailRes = await authServiceDocument.getDocumentDetail(id);
      if (docDetailRes.data.data.status) {
        setDocumentDetail({
          ...docDetailRes.data.data.data,
          status: docDetailRes.data.data.data.documentStatus,
          hashTagIds: docDetailRes.data.data.data.finalHashTagIds
            ? docDetailRes.data.data.data.finalHashTagIds
            : [],
          user: {
            value: docDetailRes.data.data.data.owner,
            label: docDetailRes.data.data.data.display_name,
            userDetail: docDetailRes.data.data.data.display_name,
          },
          dateUploaded: docDetailRes.data.data.data.dateUploaded
            ? docDetailRes.data.data.data.dateUploaded
            : "",
          downloadUrl: docDetailRes.data.data.data.presignedURLfileUrl,
        });
        setFileUrl(docDetailRes.data.data.data.fileUrl);
        setMediaFilePreviewUrl(docDetailRes.data.data.data.mediaPreviewUrl);
        setFileDetails({
          ...fileDetails,
          permanantFileName: docDetailRes.data.data.data.fileName,
          fileSize: docDetailRes.data.data.data.fileSize,
          fileType: docDetailRes.data.data.data.documentType,
        });
        setPreviewFileDetails({
          ...preiviewFileDetails,
          permanantFileName: docDetailRes.data.data.data.previewFileName,
          fileSize: docDetailRes.data.data.data.previewFileSize,
          fileType: "pdfs",
        });
        //set the rules selected
        setRulesDataForEdit({
          selectedUser: docDetailRes.data.data.data?.restrictedAccessUserId,
          selectedGroups: docDetailRes.data.data.data?.restrictedAccessGroupId,
          selectedEvents: docDetailRes.data.data.data?.restrictedAccessEventId,
          selectedMembership:
            docDetailRes.data.data.data?.restrictedAccessMembershipPlanId,
          selectedUserAccess:
            docDetailRes.data.data.data?.restrictionAccess === "public"
              ? "all"
              : docDetailRes.data.data.data?.restrictionAccess,
          selectedTags: docDetailRes.data.data.data.restrictedAccessTagId,
        });
        const subCateData =
          docDetailRes.data.data.data.subcategory !== undefined
            ? docDetailRes.data.data.data.subcategory
            : [];
        let subCateArr = [];
        subCateData.length > 0 &&
          subCateData.map((subCat) => subCateArr.push(subCat._id));
        setSubcategories(subCateArr);
        setDataLoader(false);
      } else {
        setDataLoader(false);
      }
    } catch (error) {
      setDataLoader(false);
    }
  }

  //tag api call
  async function tagListApi() {
    try {
      const [hashTagRes, docTypeListRes, categoryListRes] = await Promise.all([
        authService.getalltags_byadmin(""),
        authServiceDocument.contentTypeList(),
        authServiceDocument.categoryList(),
      ]);
      if (hashTagRes.data.status) {
        setHashTagData(hashTagRes.data.data);
      }
      if (docTypeListRes.data.data.status) {
        setContentTypeData(docTypeListRes.data.data.data);
      }
      if (categoryListRes.data.data.status) {
        setCategoryData(categoryListRes.data.data.data);
      }
    } catch (error) {
      return false;
    }
  }

  // convert bytes to kb, mb
  function formatBytes(bytes, fileName, decimals = 2, isPreview = false) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [
      "Bytes",
      "KiB",
      "MiB",
      "GiB",
      "TiB",
      "PiB",
      "EiB",
      "ZiB",
      "YiB",
    ];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const fileSize = `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${
      sizes[i]
    }`;
    const fileExtension = fileName.split(".").pop().toLowerCase();

    const isPhoto = ["png", "jpg", "jpeg", "gif", "heic"].some((ext) =>
      fileExtension.includes(ext)
    );
    const isVideo = [
      "mp4",
      "mov",
      "webm",
      "avi",
      "mkv",
      "wmv",
      "flv",
      "mpeg",
      "mpg",
      "3gp",
      "m4v",
    ].some((ext) => fileExtension.includes(ext));
    const isDocument = [
      "doc",
      "docx",
      "wps",
      "wpd",
      "tex",
      "txt",
      "rtf",
      "odt",
    ].some((ext) => fileExtension.includes(ext));
    const isPDF = ["pdf"].some((ext) => fileExtension.includes(ext));
    const isTable = ["xlsx", "csv", "xls", "ods"].some((ext) =>
      fileExtension.includes(ext)
    );
    const isPresentation = ["ppt", "pptx", "odp"].some((ext) =>
      fileExtension.includes(ext)
    );
    const isAudio = [
      "mp3",
      "wav",
      "aac",
      "ogg",
      "wma",
      "flac",
      "m4a",
      "aiff",
      "aif",
      "mid",
      "midi",
      "amr",
    ].some((ext) => fileExtension.includes(ext));

    const docType = isPhoto
      ? "photos"
      : isVideo
      ? "videos"
      : isDocument
      ? "documents"
      : isPDF
      ? "pdfs"
      : isTable
      ? "tables"
      : isPresentation
      ? "presentations"
      : isAudio
      ? "audio"
      : "";

    if (isPreview)
      setPreviewFileDetails({
        ...preiviewFileDetails,
        fileSize: fileSize,
        permanantFileName: fileName,
        fileType: docType,
      });
    else
      setFileDetails({
        ...fileDetails,
        fileSize: fileSize,
        permanantFileName: fileName,
        fileType: docType,
      });
  }

  // upload progress function
  const uploadProgressFunction = (progressEvent) => {
    let percentCompleted = Math.floor(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    setUploadProgress(percentCompleted);
  };

  // upload preview progress function
  const uploadPreviewProgressFunction = (progressEvent) => {
    let percentCompleted = Math.floor(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    setUploadPreviewProgress(percentCompleted);
  };

  // mediaFile upload function
  const mediaFile = async (acceptedfile) => {
    formatBytes(acceptedfile.size, acceptedfile.name);
    if (fileUrl !== undefined && fileUrl !== "") {
      const bodyData = {
        fileUrl: fileUrl,
      };
      await authServiceDocument.deleteMediaFie(bodyData);
    }
    if (acceptedfile.size <= 20000000) {
      setUploading(true);
      setUploadProgress(0);
      setDocumentDetail({
        ...documentDetail,
        fileName: acceptedfile.name.split(".").slice(0, -1).join("."),
      });
      setDocumentDetailErr({ ...documentDetailErr, file: "" });
      const formData = new FormData();
      formData.append("fileName", acceptedfile);
      const uploadRes = await authServiceDocument.uploadMediaFie(
        formData,
        uploadProgressFunction
      );
      if (uploadRes.status === 200) {
        setFileUrl(uploadRes.data.fileUrl);
        setUploading(false);
      }
    } else {
      //setDocumentDetail({...documentDetail, fileName: ""})
      setDocumentDetailErr({
        ...documentDetailErr,
        file: "Allow only 20 MB size",
      });
    }
  };

  // thumnail image upload - for preview function
  const previewMediaFile = async (acceptedfile) => {
    if (acceptedfile.type === "application/pdf") {
      formatBytes(acceptedfile.size, acceptedfile.name, 2, true);
      if (mediafilePreviewUrl !== undefined && mediafilePreviewUrl !== "") {
        const bodyData = {
          fileUrl: mediafilePreviewUrl,
        };
        await authServiceDocument.deleteMediaFie(bodyData);
      }
      if (acceptedfile.size <= 20000000) {
        dispatch({ type: "SETMODALINPROCESS" });
        setUploadPreviewProgress(0);
        setDocumentDetail({
          ...documentDetail,
          mediaPreviewFile: acceptedfile.name.split(".").slice(0, -1).join("."),
        });
        setDocumentDetailErr({ ...documentDetailErr, mediaPreviewFile: "" });
        setUploadingPreview(true);
        const formData = new FormData();
        formData.append("mediaPreview", acceptedfile);
        const uploadRes = await authServiceDocument.uploadFiePreview(
          formData,
          uploadPreviewProgressFunction
        );

        if (uploadRes.status === 200) {
          setMediaFilePreviewUrl(uploadRes.data.mediaPreviewUrl);
          setUploadingPreview(false);
        }
      } else {
        //setDocumentDetail({...documentDetail, mediaPreviewFile: ""})
        setDocumentDetailErr({
          ...documentDetailErr,
          mediaPreviewFile: "Allow only 20 MB size.",
        });
      }
    } else {
      setDocumentDetailErr({
        ...documentDetailErr,
        mediaPreviewFile: "Only pdf type is allowed for preview upload.",
      });
    }
  };

  //handle onchange
  const handleDocumentDetailChange = (e) => {
    const { name, value } = e.target;

    setDocumentDetail({ ...documentDetail, [name]: value });
    dispatch({ type: "SETMODALINPROCESS" });
    switch (name) {
      case "fileName":
        value.trim() === ""
          ? setDocumentDetailErr({
              ...documentDetailErr,
              fileName: "Enter file name",
            })
          : setDocumentDetailErr({ ...documentDetailErr, fileName: "" });
        break;
      case "category":
        value.trim() === ""
          ? setDocumentDetailErr({
              ...documentDetailErr,
              category: "Enter category",
            })
          : setDocumentDetailErr({ ...documentDetailErr, category: "" });
        break;
      case "status":
        value === ""
          ? setDocumentDetailErr({
              ...documentDetailErr,
              status: "Select status",
            })
          : setDocumentDetailErr({ ...documentDetailErr, status: "" });
      default:
        break;
    }
  };

  // handle user change for dropdown
  const handleUserChange = (selectedOption) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setDocumentDetail({ ...documentDetail, user: selectedOption });
  };

  //validation function
  const validate = () => {
    let isValid = false;
    let documentError = {};
    if (documentDetail.fileName.trim() === "") {
      isValid = true;
      documentError.fileName = "Enter file name";
    }
    if (documentDetail.contentType.length === 0) {
      isValid = true;
      documentError.contentType = "Enter content type";
    }
    if (documentDetailErr.file !== "" && documentDetailErr.file !== undefined) {
      documentError.file = documentDetailErr.file;
      isValid = true;
    }
    if (
      documentDetailErr.mediaPreviewFile !== "" &&
      documentDetailErr.mediaPreviewFile !== undefined
    ) {
      documentError.mediaPreviewFile = documentDetailErr.mediaPreviewFile;
      isValid = true;
    }
    if (documentDetail.category.length === 0) {
      isValid = true;
      documentError.category = "Enter category";
    }
    if (documentDetail.status === "Draft") {
      documentError.status = "Select status";
      isValid = true;
    }
    if (documentDetail.dateUploaded.length === 0) {
      documentError.dateUploaded = "Enter uploaded date";
      isValid = true;
    }
    if (Object.keys(documentError).length > 0) {
      const emptyKeys = Object.keys(documentError).filter(
        (key) => documentError[key] !== ""
      );
      const inputElement = document.getElementsByName(emptyKeys[0])[0];
      if (inputElement) {
        inputElement.focus(); // Focus the input element
      }
    }
    setDocumentDetailErr(documentError);
    return isValid;
  };

  //show continue modal state change
  useEffect(() => {
    if (showContinueModal) jQuery("#confirmPrevention").modal("show");
    else jQuery("#confirmPrevention").modal("hide");
  }, [showContinueModal]);

  //create partner api call
  async function editDocumentApi(confirmOK) {
    const formDataCreateDocument = new FormData();
    if (documentDetail.fileName) {
      formDataCreateDocument.append("fileName", documentDetail.fileName);
    }
    if (documentDetail.mediaPreviewFile) {
      formDataCreateDocument.append(
        "previewFileName",
        documentDetail.mediaPreviewFile
      );
    }
    if (preiviewFileDetails.fileSize) {
      formDataCreateDocument.append(
        "previewFileSize",
        preiviewFileDetails.fileSize
      );
    }
    formDataCreateDocument.append(
      "shortDescription",
      documentDetail.shortDescription
    );
    if (descriptionRef.current !== null) {
      const description = descriptionRef.current.getContent();
      formDataCreateDocument.append("description", description);
    }
    if (documentDetail.status) {
      formDataCreateDocument.append("documentStatus", documentDetail.status);
    }
    if (documentDetail.category.length) {
      documentDetail.category.map((item) =>
        formDataCreateDocument.append("category", item._id)
      );
    }
    if (subcategories.length) {
      subcategories.map((item) =>
        formDataCreateDocument.append("subcategory", item)
      );
    }
    if (documentDetail.hashTagIds.length > 0) {
      documentDetail.hashTagIds.map((tag) =>
        formDataCreateDocument.append("hashTagIds", tag._id)
      );
    }
    if (documentDetail.contentType.length > 0) {
      documentDetail.contentType.map((tag) =>
        formDataCreateDocument.append("contentType", tag._id)
      );
    }
    if (fileUrl) {
      formDataCreateDocument.append("fileUrl", fileUrl);
    }
    if (fileDetails.fileSize) {
      formDataCreateDocument.append("fileSize", fileDetails.fileSize);
    }
    if (fileDetails.fileType) {
      formDataCreateDocument.append("documentType", fileDetails.fileType);
    }
    if (Object.keys(documentDetail.user).length > 0) {
      formDataCreateDocument.append("owner", documentDetail.user.value);
      formDataCreateDocument.append(
        "display_name",
        documentDetail.user.userDetail
      );
      formDataCreateDocument.append("ownerRole", "user");
    }
    formDataCreateDocument.append("dateUploaded", documentDetail.dateUploaded);

    if (mediafilePreviewUrl) {
      formDataCreateDocument.append("mediaPreviewUrl", mediafilePreviewUrl);
    }

    if (confirmOK) {
      formDataCreateDocument.append("adminDecision", "continue");
    }

    //edited rules
    dataFromRules?.userAccess.length !== 0 &&
      formDataCreateDocument.append(
        `restrictionAccess`,
        dataFromRules?.userAccess === "all" ? "public" : "restricted"
      );
    if (dataFromRules?.tags_id) {
      dataFromRules?.tags_id?.length > 0 &&
        dataFromRules?.tags_id?.map((id) => {
          formDataCreateDocument.append(`restrictedAccessTagId[]`, id);
        });
    }
    if (dataFromRules?.group_id) {
      dataFromRules?.group_id.length > 0 &&
        dataFromRules?.group_id?.map((id) => {
          formDataCreateDocument.append(`restrictedAccessGroupId[]`, id);
        });
    }

    if (dataFromRules?.membership_plan_id) {
      dataFromRules?.membership_plan_id.length > 0 &&
        dataFromRules?.membership_plan_id?.map((id) => {
          formDataCreateDocument.append(
            `restrictedAccessMembershipPlanId[]`,
            id
          );
        });
    }

    dataFromRules.userAccess === "restricted" &&
      dataFromRules?.user_id.length > 0 &&
      dataFromRules?.user_id?.map((id) => {
        formDataCreateDocument.append(`restrictedAccessUserId[]`, id);
      });

    if (dataFromRules?.event_id) {
      dataFromRules.userAccess === "restricted" &&
        dataFromRules?.event_id.length > 0 &&
        formDataCreateDocument.append(
          `restrictedAccessEventId[]`,
          dataFromRules.event_id[0]
        );
    }

    const createDocumentrRes = await authServiceDocument.editDocument(
      id,
      formDataCreateDocument
    );
    try {
      if (createDocumentrRes.data.data.status) {
        setInProcess(false);
        setSaveInProcess(false);
        setResponseMessage(createDocumentrRes.data.data.message);
        handleClearAllStates();
        setDocumentDetailErr({
          fileName: "",
          fileUrl: "",
          owner: "",
          description: "",
          documentType: "",
          category: "",
          contentType: "",
          hashTagIds: "",
          submissionType: "",
          dateUploaded: "",
          mediaPreviewFile: "",
          mediafilePreviewUrl: "",
        });
        setDocumentDetail({
          fileName: "",
          fileUrl: "",
          shortDescription: "",
          description: "",
          status: "Draft",
          documentType: "",
          category: [],
          subCategory: [],
          contentType: [],
          hashTagIds: [],
          dateUploaded: "",
          mediaPreviewFile: "",
          mediafilePreviewUrl: "",
        });
        setTimeout(() => {
          setResponseMessage("");
          NAVIGATEPAGE !== null && NAVIGATEPAGE !== ""
            ? navigate(NAVIGATEPAGE)
            : navigate("/document/list?search=");
        }, 2000);
      } else if (
        !createDocumentrRes.data.data.status &&
        createDocumentrRes.data.data.data &&
        createDocumentrRes.data.data.data.documentExistStatus
      ) {
        setInProcess(false);
        setShowContinueModal(true);
      } else {
        setInProcess(false);
        setSaveInProcess(false);
      }
    } catch (e) {
      handleClearAllStates();
      setInProcess(false);
      setSaveInProcess(false);
      setResponseMessage(e);
    }
  }
  //create partner handle
  const handleUpdateDocument = async (e) => {
    e.preventDefault();
    if (!validate() && !ruleError && !noRuleFieldSelected) {
      setInProcess(true);
      editDocumentApi();
    } else {
      noRuleFieldSelected && setRuleError(true);
    }
  };

  //handle save as draft document
  const handleSaveDraft = (e) => {
    e.preventDefault();
    setDocumentDetail({ ...documentDetail, status: "Draft" });
    if (documentDetail.fileName.trim() === "") {
      setDocumentDetailErr({
        ...documentDetailErr,
        fileName: "Enter file name",
      });
      dispatch({ type: "PAGECHANGE", payload: false });
    } else {
      dispatch({ type: "PAGECHANGE", payload: false });
      dispatch({ type: "REMOVEPARTNERINPROCESS" });
      setSaveInProcess(true);
      editDocumentApi();
    }
  };

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  //handle when confirmation ok
  const handleConfirmOk = async () => {
    setInProcess(true);
    setShowContinueModal(false);
    editDocumentApi(true);
  };
  //handle when confirmation cancel
  const handleConfirmCancel = async () => {
    setInProcess(false);
    setShowContinueModal(false);
  };

  const extractFilenameFromUrl = (url) => {
    const urlObject = new URL(url);
    var str = urlObject.pathname.split("/")[3];
    str = str
      .substring(str.lastIndexOf("-") + 1, str.length)
      .split(".")
      .pop();
    return str;
  };

  let cancelTokenSource;

  // handle download document
  const handleDownloadDoc = async () => {
    // Create a new cancel token source for each download
    cancelTokenSource = axios.CancelToken.source();

    const fileNameExtension = extractFilenameFromUrl(
      documentDetail.downloadUrl
    );
    const config = {
      onDownloadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setDownloading(true);
        setProgress(percentCompleted);
        percentCompleted === 100 && setDownloading(false);
      },
      responseType: "arraybuffer",
      cancelToken: cancelTokenSource.token,
    };
    try {
      const response = await axios.get(documentDetail.downloadUrl, config);
      const blob = new Blob([response.data]);
      //update download count
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = documentDetail.fileName + "." + fileNameExtension; // Specify the desired file name
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setDownloading(false);
      console.error("Error downloading file:", error);
    }
  };
  return (
    <>
      {responseMessage.length > 0 && (
        <div className="alert alert-info">{responseMessage}</div>
      )}
      {dataLoader ? (
        <div className="cstm-data-edit-load">
          <img src={Loader} />{" "}
        </div>
      ) : (
        <>
          <div className="upload-document-main">
            <div className="upload-document-main-title">
              <h4>Upload document</h4>
              <h5>Upload a documents that can benefits community.</h5>
            </div>

            <div className="upload-document-main-body-row">
              <div className="position-relative w-100">
                <div className="upload-document-main-body">
                  <Dropzone
                    onDrop={(acceptedFiles, rejectedFiles) => {
                      mediaFile(acceptedFiles[0]);
                    }}
                    name="fileUrl"
                    multiple={false}
                    accept={
                      "image/png, image/gif, image/jpeg, image/jpg, video/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    }
                    disabled={uploading}
                    maxSizeBytes="20000000"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps({
                          className: `dropzone file-uploader-body`,
                        })}
                      >
                        <div className="file-uploader-body-drag">
                          <input multiple={false} {...getInputProps()} />
                          {uploading ? (
                            <div className={`cstm-progress-bar `}>
                              <div className="cstm-progress-bar-info">
                                <div className="cstm-progress-bar-info-filename">
                                  {documentDetail.fileName}
                                </div>
                                <div className="cstm-progress-bar-info-value">
                                  {uploadProgress}%
                                </div>
                              </div>
                              <div className="cstm-progress-bar-track">
                                <span
                                  className={`cstm-progress-bar-track-color`}
                                  style={{ width: `${uploadProgress}%` }}
                                />
                              </div>
                            </div>
                          ) : !uploading && fileUrl && fileUrl.length > 0 ? (
                            <>
                              <div className="file-uploader-body-drag-icon">
                                {fileDetails.fileType === "photos" ? (
                                  <ImageIcon scale={2} />
                                ) : fileDetails.fileType === "videos" ? (
                                  <VideoFileIcon scale={2} />
                                ) : fileDetails.fileType === "documents" ? (
                                  <DocIcon scale={2} />
                                ) : fileDetails.fileType === "pdfs" ? (
                                  <PdfIcon scale={2} />
                                ) : fileDetails.fileType === "tables" ? (
                                  <ExcelIcon scale={2} />
                                ) : fileDetails.fileType === "audio" ? (
                                  <AudioFileIcon scale={2} />
                                ) : fileDetails.fileType === "presentations" ? (
                                  <PptIcon scale={2} />
                                ) : (
                                  <NotDefinedFileIcon scale={2} />
                                )}
                              </div>
                              <div className="file-uploader-body-drag-text">
                                <h5>{fileDetails.permanantFileName}</h5>
                                <h6>{fileDetails.fileSize}</h6>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="file-uploader-body-drag-icon">
                                <img src={uploadIcon} />
                              </div>
                              <div className="file-uploader-body-drag-text">
                                <h5>
                                  Drag your document here to start uploading
                                </h5>
                                <h6>Maximum file size 20MB</h6>
                              </div>
                            </>
                          )}{" "}
                        </div>
                        <Button
                          label="Browse files"
                          className="small"
                          size="small"
                          variant="secondary"
                        />
                      </div>
                    )}
                  </Dropzone>
                </div>
                {documentDetailErr.file && documentDetailErr.file !== "" && (
                  <span className="upload-document-error position-relative mt-2 bottom-0">
                    {documentDetailErr.file}
                  </span>
                )}
              </div>
              <Button
                elementIcon={<DownloadIcon isFilled={true} />}
                size="medium"
                variant="primary"
                hasText={false}
                hasToolTip={true}
                toolTipId="downloadToolTip"
                buttonId="downloadButton"
                toolTipText="Download"
                handleClick={handleDownloadDoc}
              />
            </div>

            <div className="upload-document-main-title upload-document-preview">
              <h5>Upload document to preview the file from website.</h5>
            </div>
            <div className="position-relative w-100">
              <div className="upload-document-main-body upload-document-preview">
                <Dropzone
                  onDrop={(acceptedFiles, rejectedFiles) => {
                    previewMediaFile(acceptedFiles[0]);
                  }}
                  name="mediaPreviewUrl"
                  multiple={false}
                  accept={"application/pdf"}
                  disabled={uploadingPreview}
                  maxSizeBytes="20000000"
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps({
                        className: `dropzone file-uploader-body`,
                      })}
                    >
                      <div className="file-uploader-body-drag">
                        <input multiple={false} {...getInputProps()} />
                        {uploadingPreview ? (
                          <div className={`cstm-progress-bar `}>
                            <div className="cstm-progress-bar-info">
                              <div className="cstm-progress-bar-info-filename">
                                {preiviewFileDetails.permanantFileName}
                              </div>
                              <div className="cstm-progress-bar-info-value">
                                {uploadPreviewProgress}%
                              </div>
                            </div>
                            <div className="cstm-progress-bar-track">
                              <span
                                className={`cstm-progress-bar-track-color`}
                                style={{ width: `${uploadPreviewProgress}%` }}
                              />
                            </div>
                          </div>
                        ) : !uploadingPreview &&
                          mediafilePreviewUrl.length > 0 ? (
                          <>
                            <div className="file-uploader-body-drag-icon">
                              {preiviewFileDetails.fileType === "photos" ? (
                                <ImageIcon scale={2} />
                              ) : preiviewFileDetails.fileType === "pdfs" ? (
                                <PdfIcon scale={2} />
                              ) : (
                                <NotDefinedFileIcon scale={2} />
                              )}
                            </div>
                            <div className="file-uploader-body-drag-text">
                              <h5>{preiviewFileDetails.permanantFileName}</h5>
                              <h6>{preiviewFileDetails.fileSize}</h6>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="file-uploader-body-drag-icon">
                              <img src={uploadIcon} />
                            </div>
                            <div className="file-uploader-body-drag-text">
                              <h5>Drag your document to preview the file</h5>
                              <h6>Maximum file size 20MB</h6>
                            </div>
                          </>
                        )}{" "}
                      </div>
                      <Button
                        className="small"
                        size="small"
                        variant="secondary"
                      >
                        Browse files
                      </Button>
                    </div>
                  )}
                </Dropzone>
              </div>
              {documentDetailErr.mediaPreviewFile &&
                documentDetailErr.mediaPreviewFile !== "" && (
                  <span className="upload-document-error position-relative mt-2 bottom-0">
                    {documentDetailErr.mediaPreviewFile}
                  </span>
                )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">File Name*</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  name="fileName"
                  placeholder="Enter document name"
                  value={documentDetail.fileName}
                  onChange={handleDocumentDetailChange}
                />
              </div>
              <span className="error cstm-error4">
                {documentDetailErr.fileName}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Short Description</div>
              <div className="form-group-ct">
                <textarea
                  placeholder="Share a brief description of your document."
                  maxLength={250}
                  name="shortDescription"
                  value={documentDetail.shortDescription}
                  onChange={handleDocumentDetailChange}
                />
                <span className="text-limitaion">
                  The maximum character limit is 250.
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">
                Describe your document(optional)
              </div>
              <div className="cstm-editor-tool-ds">
                <TinyEditor
                  placeholder="Add an additional description for the document"
                  valueRef={descriptionRef}
                  value={documentDetail.description}
                  handleEditorChange={(value, editor) => {
                    setDocumentDetailErr({
                      ...documentDetailErr,
                      description: "",
                    });
                  }}
                  handleKeyPress={() => {
                    dispatch({ type: "SETMODALINPROCESS" });
                  }}
                />
              </div>
              <span className="error">{documentDetailErr.description}</span>
            </div>
          </div>
          <div className="form-group">
            <div className="cstm-channel-type-title partner-icon-rf-only">
              Document Status*
              <a
                className="status-undo"
                onClick={() =>
                  setDocumentDetail({ ...documentDetail, status: "Draft" })
                }
              >
                <img src={undo} />
              </a>
            </div>
            <div className="cstm-pm-list">
              <label className="subcat-addvideo">
                <input
                  type="radio"
                  value="Unlisted"
                  name="status"
                  checked={documentDetail.status === "Unlisted"}
                  onChange={handleDocumentDetailChange}
                />
                Unlisted
              </label>
              <label className="subcat-addvideo">
                {" "}
                <input
                  type="radio"
                  value="Public"
                  name="status"
                  checked={documentDetail.status === "Public"}
                  onChange={handleDocumentDetailChange}
                />
                Public
              </label>
            </div>
            <span className="error cstm-pm-list-msg">
              {documentDetailErr.status}
            </span>
          </div>

          <div className="row">
            <div className="col-sm-4 form-group">
              <div className="form-group-label">
                Content Type* or{" "}
                <a
                  className="create-speaker-link"
                  data-toggle="modal"
                  data-target="#addContentType"
                >
                  Create Content Type
                </a>
              </div>
              <div className="form-group-ct">
                <input
                  type="text"
                  autoComplete="off"
                  list="contentId"
                  name="contentType"
                  onKeyUp={(e) => {
                    dispatch({ type: "SETMODALINPROCESS" });
                    var selType = contentTypeData.filter((type) => {
                      if (type.name === e.target.value) return type.name;
                    });
                    var hashTagId = documentDetail.contentType.find(
                      (item) => item.name === e.target.value
                    );

                    if (selType.length > 0 && !hashTagId) {
                      setDocumentDetail({
                        ...documentDetail,
                        contentType: [
                          ...documentDetail.contentType,
                          ...selType,
                        ],
                      });
                      e.target.value = "";
                      setDocumentDetailErr({
                        ...documentDetailErr,
                        contentType: "",
                      });
                    }
                  }}
                />
              </div>
              <span className="error cstm-error4">
                {documentDetailErr.contentType}
              </span>
            </div>
          </div>
          <div>
            {documentDetail.contentType.map((type) => {
              return (
                <span className="topic-label-in">
                  <b>{type.name}</b>
                  <button
                    onClick={() => {
                      setDocumentDetail({
                        ...documentDetail,
                        contentType: documentDetail.contentType.filter(
                          (typeFilter) => {
                            if (typeFilter.name !== type.name)
                              return typeFilter;
                          }
                        ),
                      });
                    }}
                  >
                    ×
                  </button>
                </span>
              );
            })}
            <datalist id="contentId">
              {contentTypeData.map((tag) => {
                if (
                  documentDetail.contentType.filter((content) => {
                    if (content.name === tag.name) return content;
                  }).length === 0
                )
                  return <option>{tag.name}</option>;
              })}
            </datalist>
          </div>
          <div className="row">
            <div className="col-sm-4 form-group">
              <div className="form-group-label">
                Category* or{" "}
                <a
                  className="create-speaker-link"
                  data-toggle="modal"
                  data-target="#addCategory"
                >
                  Create Category
                </a>
              </div>
              <div className="form-group-ct">
                <input
                  list="category"
                  type="text"
                  name="browser"
                  autoComplete="off"
                  onKeyUp={(e) => {
                    dispatch({ type: "SETMODALINPROCESS" });
                    var selType = categoryData.filter((type) => {
                      if (type.name === e.target.value) return type.name;
                    });
                    var eventType = documentDetail.category.find(
                      (type) => type.name === e.target.value
                    );

                    if (selType.length > 0 && !eventType) {
                      setDocumentDetail({
                        ...documentDetail,
                        category: [...documentDetail.category, ...selType],
                      });
                      // setSubCategories([
                      //   ...subCategories,
                      //   ...selType[0].subcategory.map((cat) => {
                      //     return cat._id;
                      //   }),
                      // ]);
                      e.target.value = "";
                      setDocumentDetailErr({
                        ...documentDetailErr,
                        category: "",
                      });
                    }
                  }}
                  onChange={handleDocumentDetailChange}
                />
              </div>
              <span className="error cstm-error4">
                {documentDetailErr.category}
              </span>
            </div>
          </div>
          <div>
            {documentDetail.category.map((type) => {
              return (
                <span className="topic-label-in">
                  <b>{type.name}</b>
                  <button
                    onClick={() => {
                      setDocumentDetail({
                        ...documentDetail,
                        category: documentDetail.category.filter(
                          (typeFilter) => {
                            if (typeFilter.name !== type.name)
                              return typeFilter;
                          }
                        ),
                      });
                    }}
                  >
                    ×
                  </button>
                </span>
              );
            })}
            <datalist id="category">
              {categoryData.map((item) => {
                if (
                  documentDetail.category.filter((category) => {
                    if (category === item.name) return category;
                  }).length === 0
                )
                  return <option>{item.name}</option>;
              })}
            </datalist>
            {documentDetail.category.filter((cat) => {
              if (cat.subcategory) return cat;
            }).length > 0 ? (
              <div className="row">
                <div className="col-sm-12 form-group mb-0">
                  <div className="cstm-add-video-title-sub">
                    Select Subcategory
                  </div>
                  <div className="form-group-ctst">
                    {documentDetail.category.map((cat) => {
                      if (cat.subcategory)
                        return cat.subcategory.map((sCat) => {
                          return (
                            <label className="subcat-addvideo">
                              <input
                                type="checkbox"
                                name="subcategory"
                                value={sCat.name}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSubcategories([
                                      ...subcategories,
                                      sCat._id,
                                    ]);
                                  } else {
                                    setSubcategories([
                                      ...subcategories.filter((cat) => {
                                        if (cat !== sCat._id) return cat;
                                      }),
                                    ]);
                                  }
                                }}
                                defaultChecked={
                                  subcategories.filter((cat) => {
                                    if (cat === sCat._id) return cat;
                                  }).length > 0
                                }
                              />
                              {sCat.name}
                            </label>
                          );
                        });
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="row">
            <div className="col-sm-4 form-group">
              <div className="form-group-label">
                Add Tag or{" "}
                <a
                  className="create-speaker-link"
                  data-toggle="modal"
                  data-target="#addtag"
                >
                  Create Tag
                </a>
              </div>
              <div className="form-group-ct">
                <input
                  type="text"
                  autoComplete="off"
                  list="hashtags"
                  name="browser"
                  onKeyUp={(e) => {
                    var selType = hashTagData.filter((type) => {
                      if (type.name === e.target.value) return type.name;
                    });
                    var eventType = documentDetail.hashTagIds.find(
                      (type) => type.name === e.target.value
                    );

                    if (selType.length > 0 && !eventType) {
                      setDocumentDetail({
                        ...documentDetail,
                        hashTagIds: [...documentDetail.hashTagIds, ...selType],
                      });
                      e.target.value = "";
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            {documentDetail.hashTagIds.map((type) => {
              return (
                <span className="topic-label-in">
                  <b>{type.name}</b>
                  <button
                    onClick={() => {
                      setDocumentDetail({
                        ...documentDetail,
                        hashTagIds: documentDetail.hashTagIds.filter(
                          (typeFilter) => {
                            if (typeFilter.name !== type.name)
                              return typeFilter;
                          }
                        ),
                      });
                    }}
                  >
                    ×
                  </button>
                </span>
              );
            })}
            <datalist id="hashtags">
              {hashTagData.map((tag) => {
                if (
                  documentDetail.hashTagIds.filter((tags) => {
                    if (tags.name === tag.name) return tags;
                  }).length === 0
                )
                  return <option>{tag.name}</option>;
              })}
            </datalist>
          </div>
          <div className="row">
            <div className="col-sm-4 form-group">
              <div className="form-group-label">Select Member</div>
              <div className="form-group-ctst event-type-drop-only">
                <Select
                  isSearchable={true}
                  options={allUser}
                  value={documentDetail.user}
                  styles={{ zIndex: 9999 }}
                  onChange={handleUserChange}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 form-group">
              <div className="form-group-label">Date Uploaded*</div>
              <div className="form-group-ctst">
                <input
                  type="date"
                  placeholder="Select uploaded date"
                  name="dateUploaded"
                  onChange={handleDocumentDetailChange}
                  value={moment(documentDetail.dateUploaded).format(
                    "YYYY-MM-DD"
                  )}
                  max={moment().format("YYYY-MM-DD")}
                />
              </div>

              <span className="error cstm-error4">
                {documentDetailErr.dateUploaded}
              </span>
            </div>
            <AddRules
              title="Rules*"
              ruleError={ruleError}
              setRuleError={setRuleError}
              totalUserList={totalUserList}
              noRuleFieldSelected={noRuleFieldSelected}
              setNoRuleFieldSelected={setNoRuleFieldSelected}
              dataFromRules={dataFromRules}
              setDataFromRules={setDataFromRules}
              addedRules={rulesDataForEdit}
              showTagsField={true}
            />
          </div>
          <div className="cyp-btm-btn">
            {inProcess ? (
              <div className="loader-button1">
                <button>In Process...</button>
                <span className="loader-btn">
                  <img src={Loader2} />
                </span>
              </div>
            ) : (
              <button className="btn" onClick={handleUpdateDocument}>
                Update document
              </button>
            )}
            {documentDetail.status === "Draft" && (
              <>
                {saveInProcess ? (
                  <div className="loader-button1 save-draft-loader">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <button className="btn ms-4" onClick={handleSaveDraft}>
                    Save as draft
                  </button>
                )}
              </>
            )}
            <button
              className="btn2"
              onClick={() => {
                navigate(-1);
              }}
            >
              Discard
            </button>
          </div>
        </>
      )}
      <AlertComponent />
      {/* Add tag modal */}
      <AddTag recallApi={tagListApi} />
      {/* add content type modal */}
      <AddContentType recallApi={tagListApi} />
      {/* add category modal */}
      <AddCategory recallApi={tagListApi} />

      <PreventionCopyModal
        title={`Do you want to continue?`}
        message={"We have found the duplicate entry for this document."}
        modalId={`confirmPrevention`}
        handleConfirmOk={handleConfirmOk}
        handleConfirmCancel={handleConfirmCancel}
      />
      {downloading && (
        <div className="doc-download-progress">
          <ToastNotification
            onCancel={() => cancelDownload(setDownloading, setProgress)}
            isProgress
            progress={progress}
            title={documentDetail.fileName}
            variant="neutral"
            size="medium"
          />
        </div>
      )}
    </>
  );
};
export default EditDocumentInfo;
