import { useState, useEffect } from "react";
import AdminSidebar from "../AdminSidebar";
import AdminListing from "./AdminListing";
import CreateAdmin from "./CreateAdmin";
import EditAdmin from "./EditAdmin";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
const AdminManagement = () => {
    const [downarrow, setdownarrow] = useState(true)
    const [selecteddiv, setselecteddiv] = useState('listing');
    const [selectedid, setselectedid] = useState('');
    const dispatch = useDispatch();
    const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
  useEffect(()=>{
    if (!modalInProcess)
    {
      handleClearAllStates()
    }
    
  },[selecteddiv])
  

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
  }
  
    return(
        <>
        <div className="cstm-all-admin">
            <AdminSidebar />
            <div className="content-main">
                <div className="users-info">
                    <div className="users-info-sidebar">
                    <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <button
                      className="btn-link sd-active2"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      onClick={()=>{setdownarrow(!downarrow)}}
                    >
                      Admin info {downarrow ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-up"></i>}
                    </button>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="cstm-ac-in-menu">
                      <ul>
                        <li>
                          <Link
                            className="sd-active"
                            onClick={() => {
                                setselecteddiv('listing')
                            }}
                          >
                            Admin profiles Listing
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
             
              </div>
                    </div>
                 
                        {
                            selecteddiv === "listing" ? <>
                            <AdminListing setselecteddiv={setselecteddiv} setselectedid={setselectedid}/>
                            </> : selecteddiv === "create" ? <><CreateAdmin setselecteddiv={setselecteddiv}/></>  : selecteddiv === "edit" ? <><EditAdmin setselecteddiv={setselecteddiv} selectedid={selectedid}/></> : <></>
                        }

                </div>
            </div>
        </div>
    </>)
    }
export default AdminManagement;