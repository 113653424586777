import React, { useEffect, useState } from 'react'
import AdminSidebar from '../../AdminSidebar'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DocumentList from './MainDocumentManagement/DocumentList';
import CreateDocument from './MainDocumentManagement/CreateDocument';
import PageNotFound from '../../PageNotFound';
import DocumentDetail from './MainDocumentManagement/DocumentDetail';
import CategoriesManagement from './CategoriesManagement/CategoriesManagement';
import ReasonManagement from './ReasonManagement/ReasonManagement';
import ContentTypeManagement from './ContentTypesManagement/ContentTypeManagement';
import DownloadManagement from './DownloadManagement/DownloadManagement';

const index = () => {

    const history = useNavigate();
    const dispatch = useDispatch();
    const { action } = useParams()

    const [selectedDiv, setselectedDiv] = useState("");
    const [changePage, setchangePage] = useState("");
    const [showPopup, setshowPopup] = useState(false);
    const [pageNotFound, setPageNotFound] = useState(false);

    useEffect(() => {
        dispatch({ type: "HISTORYPAGE", payload: "/document/list" })
        if (action !== undefined && action.length > 0) setselectedDiv(action);
        if (action !== undefined && action !== null) {
            if (!["list", "createdocument", "detail", "documentcategories", "marcros", "contenttypes", "userdownloads"].includes(action)) {
                setPageNotFound(true)
            }
        }else
        {
            setselectedDiv("list");
        }

    }, [action]);
    return (
        <>
            {!pageNotFound && <div className="cstm-all-admin">
                <AdminSidebar />
                <div className="content-main">
                    <div className="users-info">
                        <div className="users-info-sidebar">
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <Link to={"/document/list?search="} className={`btn-link collapsed ${selectedDiv === "list" || selectedDiv === "createdocument" || selectedDiv === "detail" ? "sd-active" : ""}`}
                                                onClick={() => {
                                                    dispatch({ type: "HISTORYPAGE", payload: "/document/list?search=" })
                                                }}
                                            >All documents</Link>
                                        </div>
                                        <div className="card-header" id="headingThree">
                                            <Link to={"/document/documentcategories"} className={`btn-link collapsed ${selectedDiv === "documentcategories" ? "sd-active" : ""}`}
                                                onClick={() => {
                                                    dispatch({ type: "HISTORYPAGE", payload: "/document/documentcategories" })
                                                }}
                                            >Categories</Link>
                                        </div>
                                        <div className="card-header" id="headingThree">
                                            <Link to={'/document/marcros'} className={`btn-link collapsed ${selectedDiv === "marcros" ? "sd-active" : ""}`}
                                                onClick={() => {
                                                    dispatch({ type: "HISTORYPAGE", payload: "/document/marcros" })
                                                }}
                                            >Marcros (Saved replies)</Link>
                                        </div>
                                        <div className="card-header" id="headingThree">
                                            <Link to={"/document/contenttypes"} className={`btn-link collapsed ${selectedDiv === "contenttypes" ? "sd-active" : ""}`}
                                                onClick={() => {
                                                    dispatch({ type: "HISTORYPAGE", payload: "/document/contenttypes" })
                                                }}
                                            >Content types</Link>
                                        </div>
                                        <div className="card-header" id="headingThree">
                                            <Link to={"/document/userdownloads"} className={`btn-link collapsed ${selectedDiv === "userdownloads" ? "sd-active" : ""}`}
                                                onClick={() => {
                                                    dispatch({ type: "HISTORYPAGE", payload: "/document/userdownloads" })
                                                }}
                                            >Users download</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {selectedDiv === "list" ? (
                            <DocumentList />
                        ) : selectedDiv === "createdocument" ? (
                            <CreateDocument />
                        ) : selectedDiv === "detail" ? (
                            <DocumentDetail />
                        ) : selectedDiv === "documentcategories" ? (
                            <CategoriesManagement />
                        ) :selectedDiv === "marcros" ? (
                            <ReasonManagement />
                        ) : selectedDiv === "contenttypes" ? (
                            <ContentTypeManagement />
                        ) : selectedDiv === "userdownloads" ? (
                            <DownloadManagement />
                        ) : <></>}
                    </div>
                </div>
            </div>}
            {pageNotFound && <PageNotFound />}
        </>
    )
}

export default index