import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

// Components
import Avtar from "../../../components/Avtar/Avtar";
import OutgoingMessageTime from "./HelperComponets/OutgoingMessageTime";
import Reaction from "./HelperComponets/Reaction";
import IncomingMessageActions from "./HelperComponets/IncomingMessageActions";
import MessageTail from "./HelperComponets/MessageTail";
import OutgoingMessageActions from "./HelperComponets/OutgoingMessageActions";
import IncomingMessageTime from "./HelperComponets/IncomingMessageTime";
import loader from "../../../Images/loader.gif";

// Style
import "../../../Styles/Theme/Theme.css";
import "./Styles/ChatMessagesBubble.css";
import "./Styles/ChatMessageLink.css";

import authService from "../../../Services/auth.service";

import DropDownItem from "../../../components/DropDownItem/DropDownItem";

const ChatMessageLink = ({
  menuSelectedDivRef,
  uniqueId = uuidv4(),
  // meta detail
  metaImageSrc = "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
  metaTitle = "mds.com",
  metaLink = "https://mds.com",
  metaDescription = "Financelab X - Webflow Ecommerce Website Template",
  metaShortDescription = "If you are a fintech startup looking for a great finance Webflow Template, search no more. Presenting Financelab X, the next-gen template for companies in the finance sector.",

  // Main Components Prompts
  type = "Channel/Group",
  isOutgoing = false,
  isHighlighted = false,
  isStacked = false,
  hasName = true,
  name = "Jane",
  message = "Hii I am really good.",

  // Avatar
  AvatarType = "photo",
  Avtarsrc = "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
  AvtarLetter = "M",
  AvtarIcon = "",

  // OutgoingMessageTime
  isRead = true,
  hasBackdrop = false,
  isEdited = false,
  time = "09:12 AM",
  handleEmojiClick,
  handleReplyClick,
  handleDotsClick,
  handleReactionClick,
  actionModel,
  setActionModel,
  // reaction
  hasAction = false,
  showAllEmogis,
  toggleShowEmojis,
  initialEmojis,
  reactions,
  setReactions,
  showEmojis,
  showDropdown,
  handleSelectEmoji,
  setShowDropdown,
  setShowEmojis,
  reactionMsg,
  chatId,
  dropDownOptions,
  onSelectOptions,
  chatData,
  hasCounter = true,
  hasReactions = false,
  hasReaction2 = false,
  hasReaction3 = false,
  hasReaction4 = false,
  emoji1 = "👍",
  emoji2 = "😂",
  emoji3 = "👌",
  emoji4 = "🙌",
  counter = 5,
  handleReactionUsersList,
  isInProgress,
  messageType,
  messageInArray,
}) => {
  const [hoverEffect, setHoverEffect] = useState(hasAction);
  const [dropDownClass, setDropDownClass] = useState("bottom-drop-down");
  const linkMetadataCache = {};
  const [isLoading, setIsLoading] = useState(false);
  const [urlMetadata, setUrlMetadata] = useState({
    metaImageSrc: "",
    metaTitle: "",
    metaLink: "",
    metaDescription: "",
    metaShortDescription: "",
  });
  const dropdownRef = useRef(null);
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  // Function to truncate the message text to 100 words
  const truncateMessage = (text) => {
    const words = text.split(" ");
    if (words.length > 100) {
      return words.slice(0, 100).join(" ") + "...";
    }
    return text;
  };

  // Render the message content
  const renderMessageContent = () => {
    const words = message.split(/\s+/);
    const linkWords = [];
    const nonLinkWords = [];
    words.forEach((word) => {
      if (/^(https?:\/\/|www\.)\S+$/.test(word)) {
        linkWords.push(word);
      } else {
        nonLinkWords.push(word);
      }
    });

    if (nonLinkWords.length > 100 && !expanded) {
      return (
        <>
          {truncateMessage(nonLinkWords.join(" "))}
          <button onClick={toggleExpand} className="see-button">
            See more
          </button>
        </>
      );
    } else {
      return (
        <>
          {/* {messageInArray.map((messagePart, index) => {
            // Split message part by whitespace
            const words = messagePart.split(/\s+/);
          {messageInArray.map((messagePart, index) => {
            // Split message part by newline
            const lines = messagePart.split(/\n/);

            return (
              <span key={index}>
                {lines.map((line, lineIndex) => {
                  // Split each line by whitespace
                  const words = line.split(/\s+/);

                  return (
                    <span key={lineIndex}>
                      {words.map((word, innerIndex) => {
                        if (line.startsWith("@")) {
                          return (
                            <span key={innerIndex} className="mention-users">
                              {word}
                            </span>
                          );
                        } else if (
                          /^(http|https):\/\/[^ "]+$/.test(word) ||
                          /^(www\.)[^ "]+$/.test(word)
                        ) {
                          return (
                            <a
                              key={innerIndex}
                              className="chat-metaLink text-decoration-underline mr-1"
                              href={word}
                              target="_blank"
                            >
                              {word}
                            </a>
                          );
                        } else {
                          return <span key={innerIndex}>{word} </span>;
                        }
                      })}
                      <br /> {/* Add line break after each line 
                    </span>
                  );
                })}
              </span>
            );
          })} */}

          {messageInArray.map((messagePart, index) => {
            // Split message part by newline
            const lines = messagePart.split(/\n/);

            return (
              <span key={index}>
                {lines.map((line, lineIndex) => {
                  // Split each line by whitespace
                  const words = line.split(/\s+/);

                  return (
                    <span key={lineIndex}>
                      {words.map((word, innerIndex) => {
                        if (line.startsWith("@")) {
                          return (
                            <span key={innerIndex} className="mention-users">
                              {word}
                            </span>
                          );
                        } else if (
                          /^(http|https):\/\/[^ "]+$/.test(word) ||
                          /^(www\.)[^ "]+$/.test(word)
                        ) {
                          return (
                            <a
                              key={innerIndex}
                              className="chat-metaLink text-decoration-underline mr-1"
                              href={word}
                              target="_blank"
                            >
                              {word}
                            </a>
                          );
                        } else {
                          return <span key={innerIndex}>{word} </span>;
                        }
                      })}
                      <br /> {/* Add line break after each line */}
                    </span>
                  );
                })}
              </span>
            );
          })}
          {expanded && (
            <button onClick={toggleExpand} className="see-button">
              See less
            </button>
          )}
        </>
      );
    }
  };

  useEffect(() => {
    if (
      message &&
      new RegExp(
        "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"
      ).test(message)
    ) {
      setIsLoading(true); // Set loading status to true

      // const urls = message.split(" ").find((word) => {
      //   return new RegExp(
      //     "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"
      //   ).test(word);
      // });
      const urls = message.match(/((http|https):\/\/\S+|www\.\S+)/g);
      // Check if metadata for this link is already cached
      if (linkMetadataCache[urls]) {
        setUrlMetadata(linkMetadataCache[urls]);
        setIsLoading(false); // Set loading status to false once metadata is retrieved from cache
      } else {
        // Fetch metadata for the URL
        authService
          .getUrlMetadata(urls)
          .then((res) => {
            if (res.data.data) {
              let data = {
                metaImageSrc:
                  res.data.data?.meta?.image &&
                  res.data.data?.meta?.image.length
                    ? res.data.data?.meta?.image
                    : res.data.data?.og?.image &&
                      res.data.data?.og?.image.length
                    ? res.data.data?.og?.image
                    : res.data.data?.images && res.data.data?.images[0]
                    ? res.data.data?.images[0].src
                    : "",
                metaTitle: res.data.data?.meta?.title ?? "",
                metaLink: res.data.data?.meta?.url ?? "",
                metaDescription: res.data.data?.meta?.description ?? "",
                metaShortDescription:
                  res.data.data?.meta?.shortdescription ?? "",
              };

              // Update the state with the metadata
              setUrlMetadata(data);

              // Cache the metadata
              linkMetadataCache[urls] = data;
            }
          })
          .catch((err) => console.log(err)) // Handle error and set loading state back to false
          .finally(() => {
            setIsLoading(false); // Set loading status to false regardless of success or failure
          });
      }
    }
  }, [message]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // event.preventDefault();
      let emojiDiv = document.getElementById("REACTION_EMOJIS");
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        emojiDiv &&
        !emojiDiv.contains(event.target) &&
        setActionModel
      ) {
        setHoverEffect(false);
        setActionModel(false);
        setShowEmojis(false);
        setShowDropdown(false);
        menuSelectedDivRef = null;
      }
    };
    function handleScroll(event) {
      // setHoverEffect(false);
    }
    const scrollableDiv = document.getElementById("NEW_MAIN_CHAT_CONTAINER");
    scrollableDiv.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      scrollableDiv.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (menuSelectedDivRef?.current === null) {
      setHoverEffect(false);
    }
  }, [menuSelectedDivRef?.current]);

  const manageMouseLeave = () => {
    if (
      menuSelectedDivRef === null ||
      menuSelectedDivRef.current !== uniqueId
    ) {
      setHoverEffect(false);
    } else if (showEmojis || actionModel) {
      setHoverEffect(true);
    } else {
      setHoverEffect(false);
    }
  };

  const manageMouseEnter = () => {
    setHoverEffect(true);
  };

  const handleOptionSelect = (selectedOption) => {
    if (onSelectOptions) {
      onSelectOptions(selectedOption, chatData); // Pass chat data as an argument
    }
  };

  return (
    <>
      <div
        id={uniqueId}
        className="chat-message-bubble-sec"
        onMouseEnter={manageMouseEnter}
        onMouseLeave={manageMouseLeave}
        ref={dropdownRef}
      >
        <div
          className={`chat-message-bubble-inner flex  chat-message-link ${
            type !== "Personal" ? "chat-channel-group" : ""
          } ${!isOutgoing ? "incoming" : "outgoing"} ${
            isStacked ? "stacked-item" : ""
          }`}
        >
          {/* <div>Outgoing False </div> */}
          {isHighlighted && <div className="highlight"></div>}
          <div
            className={`chat-message-container ${
              hasReactions ? "reaction-on" : ""
            }`}
          >
            <div className="chat-avtar-message flex items-end">
              <div className="chat-avtar flex">
                {type !== "Personal" && !isOutgoing && (
                  <Avtar
                    type={AvatarType}
                    src={Avtarsrc}
                    letter={AvtarLetter}
                    icon={AvtarIcon}
                    size="40"
                  />
                )}
              </div>
              <div className="chat-body flex items-end relative">
                <div className="chat-tail">
                  <MessageTail isOutgoing={isOutgoing} />
                </div>

                <div className={`chat-message ${isStacked ? "stacked" : ""}`}>
                  <div className="chat-message-text-sec flex flex-col">
                    {type !== "Personal" && !isOutgoing && hasName && (
                      <p className="chat-avtar-name">{name}</p>
                    )}
                    <p className="chat-message-text">
                      <div>{renderMessageContent()}</div>

                      <span className="extra-time">
                        {isOutgoing ? (
                          <OutgoingMessageTime
                            isRead={isRead}
                            hasBackdrop={hasBackdrop}
                            isEdited={isEdited}
                            time={time}
                            isInProgress={isInProgress}
                          />
                        ) : (
                          <IncomingMessageTime
                            hasBackdrop={hasBackdrop}
                            isEdited={isEdited}
                            time={time}
                            isInProgress={isInProgress}
                          />
                        )}
                      </span>
                    </p>
                    {isLoading ? ( // Conditionally render based on isLoading state
                      <div className="chat-link-message-loader link-skeleton skeleton-box"></div> // You can replace this with your loading indicator
                    ) : (
                      <div className="chat-messagelink-inner">
                        <div className="relative chat-messagelink-wrapper">
                          <div
                            className={`message-link-content ${
                              urlMetadata.metaImageSrc &&
                              urlMetadata.metaImageSrc.length
                                ? ""
                                : "meta-src-notExist"
                            }`}
                          >
                            <p className="metaTitle">{urlMetadata.metaTitle}</p>
                            <p className="metaShortDescription">
                              {urlMetadata.metaShortDescription}
                            </p>
                            <p className="metaDescription">
                              {urlMetadata.metaDescription}
                            </p>
                          </div>
                          {urlMetadata.metaImageSrc &&
                            urlMetadata.metaImageSrc.trim().length && (
                              <div className="message-link-img-container">
                                <div className="message-link-img">
                                  {urlMetadata.metaImageSrc.length === 0 ? (
                                    <img src={loader} alt="URL Metadata" />
                                  ) : (
                                    <img
                                      src={urlMetadata.metaImageSrc}
                                      alt="URL Metadata"
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="chat-message-time">
                    {isOutgoing ? (
                      <OutgoingMessageTime
                        isRead={isRead}
                        hasBackdrop={hasBackdrop}
                        isEdited={isEdited}
                        time={time}
                        isInProgress={isInProgress}
                      />
                    ) : (
                      <IncomingMessageTime
                        hasBackdrop={hasBackdrop}
                        isEdited={isEdited}
                        time={time}
                        isInProgress={isInProgress}
                      />
                    )}
                  </div>
                </div>

                <div className="chat-actions">
                  {!isInProgress && (hasAction || hoverEffect) && isOutgoing ? (
                    <OutgoingMessageActions
                      handleDotsClick={(e) => {
                        menuSelectedDivRef.current = uniqueId;

                        handleDotsClick(e);
                        let heights = window.innerHeight - e.pageY;
                        if (heights < 500) {
                          setDropDownClass("top-drop-down");
                        }
                      }}
                      handleReplyClick={handleReplyClick}
                      handleEmojiClick={(e) => {
                        handleEmojiClick(chatId, e);
                        menuSelectedDivRef.current = uniqueId;
                      }}
                      chatId={chatId}
                    />
                  ) : !isInProgress && (hasAction || hoverEffect) ? (
                    <IncomingMessageActions
                      handleDotsClick={(e) => {
                        handleDotsClick(e);
                        let heights = window.innerHeight - e.pageY;
                        if (heights < 500) {
                          setDropDownClass("top-drop-down");
                        }
                      }}
                      handleReplyClick={handleReplyClick}
                      handleEmojiClick={(e) => {
                        handleEmojiClick(chatId, e);
                        menuSelectedDivRef.current = uniqueId;
                      }}
                      chatId={chatId}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            {hasReactions && (
              <div className="chat-reaction">
                <Reaction
                  isOutgoing={isOutgoing}
                  hasCounter={hasCounter}
                  counter={counter}
                  handleReactionClick={handleReactionClick}
                  handleReactionUsersList={handleReactionUsersList}
                  hasReactions={hasReactions}
                  hasReaction2={hasReaction2}
                  hasReaction3={hasReaction3}
                  hasReaction4={hasReaction4}
                  emoji1={emoji1}
                  emoji2={emoji2}
                  emoji3={emoji3}
                  emoji4={emoji4}
                  chatId={chatId}
                />
              </div>
            )}

            <div className={`chat-action-menu ${dropDownClass}`}>
              {actionModel && (
                <DropDownItem
                  size="small"
                  options={dropDownOptions}
                  isIcon={true}
                  onSelect={handleOptionSelect}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ChatMessageLink.propTypes = {
  type: PropTypes.oneOf(["Channel/Group", "Personal"]),
  // Main Component prompts
  isOutgoing: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  isStacked: PropTypes.bool,
  hasTime: PropTypes.bool,
  hasName: PropTypes.bool,
  hasReactions: PropTypes.bool,
  hasAction: PropTypes.bool,
  name: PropTypes.string,
  message: PropTypes.string,

  // Avatar
  AvatarType: PropTypes.oneOf(["photo", "icon", "letter", "logo", "mds"]),
  Avtarsrc: PropTypes.string,
  AvtarLetter: PropTypes.string,
  AvtarIcon: PropTypes.element,

  // OutgoingMessageTime
  isRead: PropTypes.bool,
  hasBackdrop: PropTypes.bool,
  isEdited: PropTypes.bool,
  time: PropTypes.string,

  // Reaction
  hasCounter: PropTypes.bool,
  hasReaction2: PropTypes.bool,
  counter: PropTypes.number,

  // Actions
  handleEmojiClick: PropTypes.func,
  handleReplyClick: PropTypes.func,
  handleDotsClick: PropTypes.func,
  handleReactionClick: PropTypes.func,

  // meta detail
  metaTitle: PropTypes.string,
  metaLink: PropTypes.string,
  metaImageSrc: PropTypes.string,
  metaDescription: PropTypes.string,
  metaShortDescription: PropTypes.string,
  actionModel: PropTypes.any,
  setActionModel: PropTypes.func,
  showAllEmogis: PropTypes.any,
  toggleShowEmojis: PropTypes.func,
  initialEmojis: PropTypes.any,
  reactions: PropTypes.any,
  setReactions: PropTypes.func,
  showEmojis: PropTypes.bool,
  showDropdown: PropTypes.any,
  handleSelectEmoji: PropTypes.func,
  setShowDropdown: PropTypes.func,
  setShowEmojis: PropTypes.func,
  reactionMsg: PropTypes.any,
  chatId: PropTypes.string,
  //DropDown items
  dropDownOptions: PropTypes.array,
  onSelectOptions: PropTypes.func,
  chatData: PropTypes.object,
  handleReactionUsersList: PropTypes.func,
  isInProgress: PropTypes.bool,
};

export default ChatMessageLink;
