import React from "react";
import Header from "../../../../components/UserComponents/Dashboard/Header";
import LeftSidebar from "../../../../components/UserComponents/Dashboard/LeftSidebar";
import EventHomePage from "./EventHomePage";

const index = () => {
  return (
    <>
      <Header />
      <div className="all-content-main-section">
        <LeftSidebar />
        <div className="all-content-main-section-in">
          <div className="doc-management-main">
            <EventHomePage />
          </div>
        </div>
      </div>
    </>
  );
};

export default index;
