import React from "react";
import AdminSidebar from "../AdminSidebar";
import MediaListing from "./MainMediaManagement/MediaListing";
import "./mediaUpload.css";

const index = () => {
  return (
    <>
      <div className="cstm-all-admin">
        <AdminSidebar />
        <div className="content-main">
          <div className="users-info">
            <div className="users-info-sidebar">
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <button className={`btn-link collapsed ${"sd-active"} `}>
                        Media Listing
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <MediaListing />
          </div>
        </div>
      </div>
    </>
  );
};

export default index;
