import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import AdminSidebar from "../AdminSidebar";
import PageNotFound from "../PageNotFound";
import Sessions from "./SessionManagement/Sessions";
import Activities from "./ActivityManagement/Activities";
import Events from "./MainEventManagement";
import CreateEvent from "./MainEventManagement/CreateEvent";
import EventDetail from "./MainEventManagement/EventDetail";
import { Support } from "@mui/icons-material";
import SupportDetails from "./Support/SupportDetails";
import EventType from "./EventTypeManagement/EventType";
import CategoriesManagement from "./CategoriesManagement/CategoriesManagement";

const EventManagement = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { action } = useParams();
  const [selectedDiv, setselectedDiv] = useState("");
  const videoInprocess = useSelector((state) => {
    return state.ReduVideoInProcess;
  });
  const [changePage, setchangePage] = useState("");
  const [showPopup, setshowPopup] = useState(false);
  const [pageNotFound, setPageNotFound] = useState(false);

  useEffect(() => {}, []);
  useEffect(() => {
    dispatch({ type: "HISTORYPAGE", payload: "/events/list?search=" });
    if (action !== undefined && action.length > 0) setselectedDiv(action);
    if (action !== undefined && action !== null) {
      if (
        ![
          "list",
          "createEvent",
          "detail",
          "support",
          "eventtype",
          "categories",
        ].includes(action)
      ) {
        setPageNotFound(true);
      }
    } else {
      setselectedDiv("list");
    }
  }, [action]);

  return (
    <>
      {!pageNotFound && (
        <div className="cstm-all-admin">
          <AdminSidebar />
          <div className="content-main">
            <div className="users-info">
              <div className="users-info-sidebar">
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card">
                      <div className="card-header" id="headingThree">
                        <Link
                          className={`btn-link collapsed ${
                            selectedDiv === "createEvent" ? "sd-active" : ""
                          } `}
                          to={"/events/createEvent"}
                        >
                          Create event
                        </Link>
                      </div>
                    </div>
                    <div className="card-header" id="headingThree">
                      <Link
                        to={"/events/list?search="}
                        className={`btn-link collapsed ${
                          selectedDiv === "list" || selectedDiv === "detail"
                            ? "sd-active"
                            : ""
                        } `}
                      >
                        All events
                      </Link>
                    </div>
                    {/* <div className="card-header" id="headingThree">
                    <Link
                      to={"/events/eventtype"}
                      className={`btn-link collapsed ${selectedDiv === "eventtype" ? "sd-active" : ""} `}
                    >
                      Event types
                    </Link>
                  </div> */}
                    <div className="card-header" id="headingThree">
                      <Link
                        to={"/events/categories"}
                        className={`btn-link collapsed ${
                          selectedDiv === "categories" ? "sd-active" : ""
                        } `}
                      >
                        Categories
                      </Link>
                    </div>
                    {/* ************************************************************************** */}
                    {/* <div className="card-header" id="headingThree">
                    <Link
                      to={"/events/allparticipants"}
                      className={`btn-link collapsed ${selectedDiv === "eventtype" ? "sd-active" : ""} `}
                    >
                      All Participants
                    </Link>
                  </div>

                  <div className="card-header" id="headingThree">
                    <Link
                      to={"/events/addparticipants"}
                      className={`btn-link collapsed ${selectedDiv === "eventtype" ? "sd-active" : ""} `}
                    >
                      Add Participants
                    </Link>
                  </div> */}
                  </div>
                </div>
              </div>
              {selectedDiv === "list" ? (
                <Events />
              ) : selectedDiv === "createEvent" ? (
                <CreateEvent />
              ) : selectedDiv === "detail" ? (
                <EventDetail />
              ) : selectedDiv === "eventtype" ? (
                <EventType />
              ) : selectedDiv === "categories" ? (
                <CategoriesManagement />
              ) : (
                <></>
              )}
            </div>
          </div>
          {showPopup && (
            <div className="desk-globle-model-box globle-model-box ">
              <div className="desk-globle-model-box-inner cstm-deactivate-account-only">
                <div className="cstm-deactivate-account-main">
                  <div class="cstm-deactivate-account-main-title">
                    Video upload is still in progress, Are you sure want to
                    leave?
                  </div>

                  <div className="ays-cancel-btn">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch({ type: "REMOVEINPROCESS" });
                        history(changePage);
                        setshowPopup(false);
                      }}
                    >
                      Yes
                    </button>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setshowPopup(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>{" "}
            </div>
          )}
        </div>
      )}
      {pageNotFound && <PageNotFound />}
    </>
  );
};
export default EventManagement;
