import React, { useState } from "react";
import AdminSidebar from "../components/AdminSidebar";
import "../components/AdminMediaManagement/mediaUpload.css";
import MediaUploadCommon from "./mediaUploadCommon";

const commonMediaPage = () => {
    const [showCropType, setCropType] = useState("");
    const [isChooseMediaPopupOpen, setChooseMediaPopupOpen] = useState(false);
    const [showType, setType] = useState("")
    const [showTypeData, setTypeData] = useState("")
    const [showCroppedImageURL, setCroppedImageURL] = useState("")


    const handleChooseMediaClick = (typeData, data, cropType) => {
        setChooseMediaPopupOpen(true);
        setType(typeData);
        setTypeData(data);
        setCropType(cropType)
    };

    const handleCloseChooseMediaPopup = () => {
        setChooseMediaPopupOpen(false);
    };

    const setCroppedImageUrl = (data) => {
        setCroppedImageURL(data)
    }
    return (
        <>
            <div className="cstm-all-admin">
                <AdminSidebar />
                <div className="content-main">
                    <div className="users-info">
                        <div className="users-info-sidebar">
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <button className={`btn-link collapsed ${"sd-active"} `}>
                                                Media Listing
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="users-info-content">
                            <div className="users-info-content-main">
                                <button className="upload-media-btn" data-toggle="modal" data-target="#chooseMediaModel" onClick={() => handleChooseMediaClick("image", "single", "Square")}>
                                    Image Select (Square Cropper)
                                </button>
                                <br />
                                <button className="upload-media-btn" data-toggle="modal" data-target="#chooseMediaModel" onClick={() => handleChooseMediaClick("image", "single", "Rectangle")}>
                                    Image Select (Rectangle Cropper)
                                </button>
                                <br />
                                <button className="upload-media-btn" data-toggle="modal" data-target="#chooseMediaModel" onClick={() => handleChooseMediaClick("image", "multiple")}>
                                    Images Select
                                </button>
                                <br />
                                <button className="upload-media-btn" data-toggle="modal" data-target="#chooseMediaModel" onClick={() => handleChooseMediaClick("video", "single")}>
                                    Video Select
                                </button>
                                <br />
                                <button className="upload-media-btn" data-toggle="modal" data-target="#chooseMediaModel" onClick={() => handleChooseMediaClick("video", "multiple")}>
                                    Videos Select
                                </button>
                            </div>
                            <div id="imageuploadurl" className="">
                                <img src={showCroppedImageURL} />
                            </div>
                        </div>
                        {isChooseMediaPopupOpen && (
                            <MediaUploadCommon
                                type={showType}
                                buttonType={showTypeData}
                                cropType={showCropType}
                                onClose={handleCloseChooseMediaPopup}
                                setCroppedImageUrl={setCroppedImageUrl}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default commonMediaPage;
