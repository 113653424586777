import React from "react";
import Header from "../../../../components/UserComponents/Dashboard/Header";
import EventDetailsPage from "./EventDetailsPage";

const index = () => {
  return (
    <>
      <Header />

      <EventDetailsPage />
    </>
  );
};

export default index;
