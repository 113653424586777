import React, { useEffect, useRef, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";
import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import Frame from "../../Images/Frame.svg";
import authServiceEvent from "../../Services/auth.service.event";
import authNotification from "../../Services/auth.admin.notification";
import Services from "../../Services/auth.service";
import AlertComponent from "../../Hooks/AlertComponent";
import Loader2 from "../../Images/loader2.gif";
import moment from "moment";
import AddRules from "../CommonRules/AddRules";
import ModalPopup from "../ModalPopup/ModalPopup";
import { CompressImageFunction } from "./CommonFunction";
import authAdminNotification from "../../Services/auth.admin.notification";
import ImageCropperReact from "../ImageCropper/ImageCropperReact";

const CreateNotification = () => {
  const emptyObjectModal = {
    title: "",
    subtitle: "",
    deepLinkUrl: "",
    image: "",
    event: { value: -1, label: "Select event" },
    eventid: "",
    group: "",
    membership: "",
    triggerAt: "",
    noEventUsers: "",
    channelType: false,
    accessPermission: "",
    Member: false,
    Guest: false,
    Speaker: false,
    Partner: false,
    Users: false,
    status: "published",
    notificationType: "immediate",
    deepLinkUrlForMobile: "",
    userAccess: "",
    pushNotificationStatus: false,
  };

  const emptyObjectModalErr = {
    title: "",
    subtitle: "",
    deepLinkUrl: "",
    image: "",
    event: "",
    group: "",
    membership: "",
    triggerAt: "",
    noEventUsers: "",
    accessPermission: "",
    status: "",
    notificationType: "",
    deepLinkUrlForMobile: "",
    userAccess: "",
    userAccessIds: "",
    eventid: "",
  };

  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // redux state
  const NAVIGATEPAGE = useSelector((state) => {
    return state.partnerNavigate;
  });
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });

  //empty object states
  const [notificationDetail, setNotificationDetail] =
    useState(emptyObjectModal);
  const [notificationDetailErr, setNotificationDetailErr] =
    useState(emptyObjectModalErr);

  const [eventList, setEventList] = useState([]);
  const [inProcess, setInProcess] = useState(false);
  const [saveInProcess, setSaveInProcess] = useState(false);
  const [selecteduser, setSelectedUser] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [membership, setMembership] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");
  const [validate, setValidate] = useState(false);
  const [manupulateNotification, setmanupulateNotification] = useState("immediate");
  const [validateTime, setvalidateTime] = useState("");
  const [popupLoading, setPopupLoading] = useState(false);


  const [totalUsers, setTotalUsers] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showOtherRules, setShowOtherRules] = useState(true);
  const [dataFromRules, setDataFromRules] = useState([]);
  const [totalUserList, setTotalUserList] = useState([]);
  const latestRequest = useRef(0);

  const [ruleError, setRuleError] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [noRuleFieldSelected, setNoRuleFieldSelected] = useState(false);

  //  image cropping state
  const [modalOpen, setModalOpen] = useState();
  const [fileName, setFileName] = useState("");
  const [fileDimension, setFileDimension] = useState("");
  const [cropedFile, setcropedFile] = useState();
  const [cropedFileData, setcropedFileData] = useState();
  const [thumbnail, setThumbnail] = useState([]);

  //email validation function
  function isValidURL(url) {
    var urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    return urlPattern.test(url);
  }

  // useEffect will call on first render
  useEffect(() => {
    handleClearAllStates();
  }, []);

  // useEffect will call when user access is changed
  useEffect(() => {
    if (
      notificationDetail.userAccess === "all" ||
      notificationDetail.userAccess === ""
    ) {
      setSelectedUsers([]);
      setMembership([]);
      setSelectedUser([]);
      setNotificationDetail({
        ...notificationDetail,
        event: { value: -1, label: "Select event" },
        eventid: "",
      });
      setShowOtherRules(false);
    } else {
      setShowOtherRules(true);
    }
  }, [notificationDetail.userAccess]);

  const countUserApi = async (bodyData, requestId) => {
    try {
      const countRes = await authAdminNotification.notificationUserList(
        bodyData
      );
      if (latestRequest.current === requestId) {
        // Only update state if this is the latest request
        setTotalUserList(countRes?.data?.data);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    const bodyData = {
      user_id: dataFromRules.user_id,
      membership_plan_id: dataFromRules.membership_plan_id,
      group_ids: dataFromRules.group_id,
      event_id: dataFromRules.event_id,
      userAccess: dataFromRules.userAccess,
    };
    const requestId = ++latestRequest.current; // Increment the request ID
    countUserApi(bodyData, requestId);
  }, [dataFromRules]);

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  // handle send notification changes
  const handleNotificationChange = (e) => {
    setmanupulateNotification(e.target.value);
    setvalidateTime(e.target.value);
    dispatch({ type: "SETMODALINPROCESS" });
    if (e.target.name == "pushNotificationStatus") {
      setNotificationDetail({
        ...notificationDetail,
        [e.target.name]: e.target.checked,
      });
    } else {
      setNotificationDetail({
        ...notificationDetail,
        [e.target.name]: e.target.value,
      });
    }

    if (
      e.target.name === "title" ||
      e.target.name === "subtitle" ||
      e.target.name === "deepLinkUrl" ||
      e.target.name === "deepLinkUrlForMobile" ||
      e.target.name === "status" ||
      e.target.name === "notificationType" ||
      e.target.name === "triggerAt" ||
      e.target.name === "userAccess"
    ) {
      if (e.target.value.length === 0)
        setNotificationDetailErr({
          ...notificationDetailErr,
          [e.target.name]:
            e.target.name === "title"
              ? "Enter title"
              : // : e.target.name === "subtitle"
              //   ? "Enter sub title"
              e.target.name === "deepLinkUrl"
                ? "Enter deep link url for web"
                : e.target.name === "deepLinkUrlForMobile"
                  ? "Enter deep link url for mobile"
                  : "",
        });
      else {
        if (e.target.name === "title") {
          if (e.target.value.length > 75) {
            setNotificationDetailErr({
              ...notificationDetailErr,
              [e.target.name]: "Title exceeds maximum 75 character limit!",
            });
          } else {
            setNotificationDetailErr({
              ...notificationDetailErr,
              [e.target.name]: "",
            });
          }
        } else if (e.target.name === "subtitle") {
          if (e.target.value.length > 225) {
            setNotificationDetailErr({
              ...notificationDetailErr,
              [e.target.name]: "Subtitle exceeds maximum 225 character limit!",
            });
          } else {
            setNotificationDetailErr({
              ...notificationDetailErr,
              [e.target.name]: "",
            });
          }
        } else if (e.target.name === "deepLinkUrl") {
          if (!isValidURL(e.target.value)) {
            setNotificationDetailErr({
              ...notificationDetailErr,
              deepLinkUrl: "Enter valid deep link url for web!",
            });
          } else {
            setNotificationDetailErr({
              ...notificationDetailErr,
              deepLinkUrl: "",
            });
          }
        } else if (e.target.name === "deepLinkUrlForMobile") {
          if (!isValidURL(e.target.value)) {
            setNotificationDetailErr({
              ...notificationDetailErr,
              deepLinkUrlForMobile: "Enter valid deep link url for mobile!",
            });
          } else {
            setNotificationDetailErr({
              ...notificationDetailErr,
              deepLinkUrlForMobile: "",
            });
          }
        } else if (e.target.name === "status") {
          if (notificationDetail.notificationType === "immediate") {
            if (e.target.value === "draft")
              setNotificationDetailErr({
                ...notificationDetailErr,
                status: "Immediate notification status can not be draft!",
              });
            else
              setNotificationDetailErr({
                ...notificationDetailErr,
                status: "",
              });
          }
        } else if (e.target.name === "notificationType") {
          if (e.target.value === "schedule") {
            setNotificationDetail({
              ...notificationDetail,
              [e.target.name]: e.target.value,
              status: "draft",
            });
            setNotificationDetailErr({ ...notificationDetailErr, status: "" });
          } else {
            setNotificationDetail({
              ...notificationDetail,
              [e.target.name]: e.target.value,
              status: "published",
            });
          }
        } else if (e.target.name === "userAccess") {
          if (
            membership.length === 0 &&
            selectedUsers.length === 0 &&
            e.target.value.length === 0 &&
            notificationDetail.eventid.length === 0
          ) {
            setNotificationDetailErr({
              ...notificationDetailErr,
              membership: "Please select rule!",
            });
          } else {
            setNotificationDetailErr({
              ...notificationDetailErr,
              membership: "",
              userAccessIds: "",
            });
          }
        } else if (e.target.name === "triggerAt") {
          if (
            e.target.value.length > 0 &&
            notificationDetail.notificationType == "schedule"
          ) {
            const isValid = moment(
              e.target.value,
              moment.HTML5_FMT.DATETIME_LOCAL,
              true
            ).isValid();

            if (!isValid) {
              setNotificationDetailErr({
                ...notificationDetailErr,
                triggerAt: "Please select valid date & time!",
              });
            } else {
              if (moment(e.target.value) <= moment()) {
                setNotificationDetailErr({
                  ...notificationDetailErr,
                  triggerAt: "Please select valid future date & time!",
                });
              }
            }
          }
          if (
            e.target.value.length > 0 &&
            notificationDetail.notificationType == "schedule" &&
            moment(e.target.value) > moment()
          ) {
            setNotificationDetailErr({
              ...notificationDetailErr,
              triggerAt: "",
            });
          }
        } else {
          setNotificationDetailErr({
            ...notificationDetailErr,
            [e.target.name]: "",
          });
          setNotificationDetail({
            ...notificationDetail,
            [e.target.name]: e.target.value,
          });
        }
      }
    }
  };

  // event list api call
  const getEventListApi = () => {
    authServiceEvent
      .getAllEventsLimitedFiedls()
      .then((res) => {
        if (res.data.status) {
          const eventData = res.data.data;
          let arr = [{ value: -1, label: "Select event" }];
          if (eventData !== undefined && eventData.length > 0) {
            eventData.map((evnt) => {
              arr.push({
                value: evnt._id,
                label: evnt.title,
              });
            });

            setEventList(arr);
          } else {
            setEventList([]);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //handle when confirmation ok
  const handleConfirmOk = async () => {
    setPopupLoading(true)
    addNotification();
  };

  // called when click on create notification button
  const handleCreateNotification = async () => {
    var status = true;
    var temp = {};

    if (notificationDetail.title.trim().length === 0) {
      temp = { ...temp, title: "Enter title!" };
      status = false;
    } else if (notificationDetail.title.length > 75) {
      temp = { ...temp, title: "Title exceeds maximum 75 character limit!" };
      status = false;
    }
    if (notificationDetail.deepLinkUrl.trim().length === 0) {
      temp = { ...temp, deepLinkUrl: "Enter deep link url for web!" };
      status = false;
    } else if (!isValidURL(notificationDetail.deepLinkUrl)) {
      temp = { ...temp, deepLinkUrl: "Enter valid deep link url for web!" };
      status = false;
    }

    if (notificationDetail.deepLinkUrlForMobile.trim().length === 0) {
      temp = {
        ...temp,
        deepLinkUrlForMobile: "Enter deep link url for mobile!",
      };
      status = false;
    } else if (!isValidURL(notificationDetail.deepLinkUrlForMobile)) {
      temp = {
        ...temp,
        deepLinkUrlForMobile: "Enter valid deep link url for mobile!",
      };
      status = false;
    }

    if (notificationDetail.status.trim().length === 0) {
      temp = { ...temp, status: "Select status!" };
      status = false;
    }
    if (
      notificationDetail.notificationType &&
      notificationDetail.notificationType === "schedule"
    ) {
      if (
        !notificationDetail.triggerAt ||
        (notificationDetail.triggerAt &&
          notificationDetail.triggerAt.length === 0)
      ) {
        temp = { ...temp, triggerAt: "Please select date & time!" };
        status = false;
      }
    }

    if (
      notificationDetail.triggerAt &&
      notificationDetail.triggerAt.length > 0 &&
      notificationDetail.notificationType == "schedule"
    ) {
      const isValid = moment(
        notificationDetail.triggerAt,
        moment.HTML5_FMT.DATETIME_LOCAL,
        true
      ).isValid();

      if (!isValid) {
        temp = { ...temp, triggerAt: "Please select valid date & time!" };
        status = false;
      } else {
        if (moment(notificationDetail.triggerAt) <= moment()) {
          temp = {
            ...temp,
            triggerAt: "Please select valid future date & time!",
          };
          status = false;
        }
      }
    }

    if (notificationDetail.subtitle.length > 225) {
      temp = {
        ...temp,
        subtitle: "Subtitle exceeds maximum 225 character limit!",
      };
      status = false;
    }
    if (
      (notificationDetail.userAccess.length === 0 ||
        (notificationDetail.userAccess.length > 0 &&
          notificationDetail.userAccess === "restricted")) &&
      membership.length === 0 &&
      selectedUsers.length === 0 &&
      selecteduser.length === 0 &&
      notificationDetail.eventid.length === 0
    ) {
      temp = { ...temp, membership: "Please select rule!" };
    }

    if (
      notificationDetail.notificationType === "immediate" &&
      notificationDetail.status === "draft"
    ) {
      temp = {
        ...temp,
        status: "Immediate notification status can not be draft!",
      };
      status = false;
    }

    if (status && !noRuleFieldSelected) {
      if (notificationDetail.status !== "draft") {
        setShowConfirmModal(true);
      } else {
        setInProcess(true);
        addNotification();
        setShowConfirmModal(false);
      }
    } else {
      if (Object.keys(temp).length > 0) {
        const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
        const inputElement = document.getElementsByName(emptyKeys[0])[0];
        if (inputElement) {
          inputElement.focus(); // Focus the input element
        }
      }
      setNotificationDetailErr(temp);
      noRuleFieldSelected && setRuleError(true);
    }
  };

  // submit form create notification data through api
  const addNotification = async () => {
    const formdata = new FormData();
    let imageCompress;
    formdata.append("title", notificationDetail.title);
    formdata.append("subtitle", notificationDetail.subtitle);
    formdata.append("deepLinkUrl", notificationDetail.deepLinkUrl);
    formdata.append(
      "deepLinkUrlForMobile",
      notificationDetail.deepLinkUrlForMobile
    );
    formdata.append(
      "status",
      notificationDetail.status ? notificationDetail.status : "draft"
    );

    if (notificationDetail.notificationType === "schedule") {
      formdata.append("sendType", "schedule");
      if (
        notificationDetail.triggerAt &&
        notificationDetail.triggerAt.length > 0
      ) {
        formdata.append(
          "triggerAt",
          moment(notificationDetail.triggerAt).utc()
        );
      }
    } else {
      formdata.append("sendType", "immediate");
    }
    if (cropedFileData) {
      imageCompress = await CompressImageFunction(cropedFileData);
    }
    imageCompress && formdata.append("uploadImg", imageCompress);

    dataFromRules.event_id.length > 0 &&
      dataFromRules.event_id.map((id) => {
        formdata.append(`event_id`, id);
      });

    dataFromRules.group_id.length > 0 &&
      dataFromRules.group_id.map((id) => {
        formdata.append(`group_id`, id);
      });

    dataFromRules.membership_plan_id.length > 0 &&
      dataFromRules.membership_plan_id.map((id) => {
        formdata.append(`membership_plan_id`, id);
      });

    dataFromRules.userAccess.length !== 0 &&
      formdata.append(
        `userAccess`,
        dataFromRules.userAccess.toLocaleLowerCase()
      );

    dataFromRules.userAccess === "restricted" &&
      dataFromRules.user_id.length > 0 &&
      dataFromRules.user_id.map((id) => {
        formdata.append(`user_id`, id);
      });

    formdata.append(
      `pushNotificationStatus`,
      notificationDetail.pushNotificationStatus
    );

    authNotification
      .createNotification(formdata)
      .then((res) => {
        if (res.data.status) {
          setPopupLoading(false)
          setSaveInProcess(false);
          setInProcess(false);
          setShowConfirmModal(false)
          setResponseMessage(res.data.message);
          setTimeout(() => {
            setResponseMessage("");
            navigate("/notification/list");
          }, 3000);
        }
        setSaveInProcess(false);
        setInProcess(false);
        handleClearAllStates();
        setNotificationDetail(emptyObjectModal);
        setNotificationDetailErr(emptyObjectModalErr);
        setcropedFile();
        setSelectedUsers([]);
      })
      .catch((e) => {
        setInProcess(false);
        setSaveInProcess(false);
        handleClearAllStates();
        console.log(e);
        setResponseMessage(e.message);
        setTimeout(() => {
          setResponseMessage("");
        }, 3000);
      });
  };

  // handle save draft
  const handleSaveDraft = () => {
    if (notificationDetail.title.trim() === "") {
      setNotificationDetailErr({
        ...notificationDetailErr,
        title: "Enter title!",
      });
    } else {
      setSaveInProcess(true);
      addNotification();
    }
  };

  //get current date time
  const getCurrentDateTime = () => {
    const now = new Date();
    return now.toISOString().slice(0, 16);
  };
  // thumbnail image upload function
  const thumbnailImages = async (acceptedfile) => {
    setFileDimension(acceptedfile);
    dispatch({ type: "SETMODALINPROCESS" });
    setFileName(Math.random().toString() + acceptedfile.name);
    setModalOpen(true);
  };
  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main">
          {responseMessage && (
            <div className="alert alert-info">{responseMessage}</div>
          )}
          {modalOpen && (
            <ImageCropperReact
              file={thumbnail}
              setModalOpen={setModalOpen}
              setcropedFile={setcropedFile}
              setNotificationDetail={setNotificationDetail}
              notificationDetail={notificationDetail}
              fileName={fileName}
              setcropedFileData={setcropedFileData}
              fileDimension={fileDimension}
              requiredImageAspectRatio={16 / 9}
            />
          )}
          <div className="um-back">
            <button
              type="button"
              className="back-btn"
              onClick={() => {
                if (modalInProcess) {
                  dispatch({ type: "SETCUSTOMPAGECHANGE" });
                  dispatch({
                    type: "NAVIGATEPAGE",
                    payload: "/notification/list?search=",
                  });
                } else {
                  navigate(
                    NAVIGATEPAGE !== null && NAVIGATEPAGE !== ""
                      ? NAVIGATEPAGE
                      : "/notification/list?search="
                  );
                }
              }}
            >
              <ArrowLeftLineIcon size="20px" style={{ marginRight: "4px" }} />
              Back
            </button>
          </div>
          <div className="uic-title">
            <h1>Add Notification</h1>
          </div>
          <div className="group-info-user">
            <div className="cstm-video-upload">
              <Dropzone
                onDrop={(acceptedFiles) => {
                  // Check if any of the dropped files are not allowed
                  const file = acceptedFiles[0];
                  if (file.type.startsWith("image/")) {
                    thumbnailImages(file);
                    const reader = new FileReader();
                    reader.onload = () => setThumbnail(reader.result);
                    reader.readAsDataURL(file);
                    // setThumbnail(URL.createObjectURL(file));
                    setValidate(false);
                  } else {
                    // If the dropped file is not an image, display an error message
                    setValidate(true);
                  }
                }}
                name="thumbnail"
                multiple={false}
                accept="image/jpeg, image/png,  image/tiff, image/jpg"
                maxSizeBytes={10485760}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />

                    <div className="cstm-video-upload-img channel-img-container">
                      <img src={cropedFile ? cropedFile : Frame} />
                    </div>
                  </div>
                )}
              </Dropzone>

              {/* Display error message for file selection */}
              {validate && (
                <span
                  className="error cstm-error44"
                  style={{ marginTop: "5px" }}
                >
                  {"Invalid file type. Please select an image file."}
                </span>
              )}

              {/* Display error message related to notificationDetailErr.image */}
              {!validate && notificationDetailErr.image && (
                <span
                  className="error cstm-error44"
                  style={{ marginTop: "5px" }}
                >
                  {notificationDetailErr.image}
                </span>
              )}
            </div>
            <div className="group-info-user-link">
              <div className="cover-image-upload-ttl">Upload image</div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Title*</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  name="title"
                  value={notificationDetail.title}
                  onChange={handleNotificationChange}
                  maxLength={75}
                />
              </div>
              <span className="error cstm-error4">
                {notificationDetailErr.title}
              </span>
              <span className="cstm-title-note-msg">
                The maximum character limit is 75.
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Subtitle</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  name="subtitle"
                  value={notificationDetail.subtitle}
                  maxLength={225}
                  onChange={handleNotificationChange}
                />
              </div>
              <span className="error cstm-error4">
                {notificationDetailErr.subtitle}
              </span>
              <span className="cstm-title-note-msg">
                The maximum character limit is 225.
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Deep link url for web*</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  name="deepLinkUrl"
                  value={notificationDetail.deepLinkUrl}
                  onChange={handleNotificationChange}
                />
              </div>
              <span className="error cstm-error4">
                {notificationDetailErr.deepLinkUrl}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Deep link url for mobile*</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  name="deepLinkUrlForMobile"
                  value={notificationDetail.deepLinkUrlForMobile}
                  onChange={handleNotificationChange}
                />
              </div>
              <span className="error cstm-error4">
                {notificationDetailErr.deepLinkUrlForMobile}
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Frequency*</div>
              <div className="form-group-ct">
                <select
                  name="notificationType"
                  value={notificationDetail.notificationType}
                  onChange={handleNotificationChange}
                >
                  <option value="immediate">Send Immediately</option>
                  <option value="schedule">Set Custom Date</option>
                </select>
              </div>
            </div>
          </div>
          {notificationDetail.notificationType === "schedule" && (
            <div className="row">
              <div className="col-sm-4 form-group">
                <div className="form-group-label">Select date & time*</div>
                <div className="form-group-ct">
                  <input
                    id="datePicker"
                    type="datetime-local"
                    name="triggerAt"
                    onChange={handleNotificationChange}
                    min={getCurrentDateTime()}
                  />
                </div>
                <span className="error cstm-error4">
                  {notificationDetailErr.triggerAt}
                </span>
              </div>
            </div>
          )}

          <div class="form-group">
            <div class="cstm-channel-type-title partner-icon-rf-only">
              Notification status*
            </div>
            <div class="cstm-pm-list">
              <label class="subcat-addvideo">
                <input
                  type="radio"
                  value="draft"
                  name="status"
                  onChange={handleNotificationChange}
                  checked={notificationDetail.status === "draft"}
                />
                Draft
              </label>
              <label class="subcat-addvideo">
                {" "}
                <input
                  type="radio"
                  value="published"
                  name="status"
                  onChange={handleNotificationChange}
                  checked={notificationDetail.status === "published"}
                />
                Published
              </label>
            </div>
            <span className="error cstm-error4">
              {notificationDetailErr.status}
            </span>
          </div>

          {/* groups */}
          <AddRules
            title="Rules*"
            totalUserList={totalUserList}
            ruleError={ruleError}
            setRuleError={setRuleError}
            noRuleFieldSelected={noRuleFieldSelected}
            setNoRuleFieldSelected={setNoRuleFieldSelected}
            dataFromRules={dataFromRules}
            setDataFromRules={setDataFromRules}
          />

          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="cstm-chk-preregistration-container">
                <input
                  type="checkbox"
                  name="pushNotificationStatus"
                  checked={notificationDetail.pushNotificationStatus}
                  onChange={handleNotificationChange}
                />
                <label for="pushNotificationStatus">
                  Should we send this as a mobile push notification to the
                  selected users?
                </label>
              </div>
            </div>
          </div>

          <div className="notification-btm-btn">
            <button
              className="btn2"
              onClick={() => {
                handleClearAllStates();
                navigate("/notification/list");
              }}
            >
              Cancel
            </button>
            {notificationDetail.notificationType !== "immediate" &&
              (notificationDetail.status === "draft" ||
                notificationDetail.status === "") && (
                <>
                  {saveInProcess ? (
                    <div className="loader-button1 save-draft-loader">
                      <button>In Process...</button>
                      <span className="loader-btn">
                        <img src={Loader2} />
                      </span>
                    </div>
                  ) : (
                    <button className="btn2" onClick={handleSaveDraft}>
                      Save as draft
                    </button>
                  )}
                </>
              )}

            {inProcess ? (
              <div className="loader-button1">
                <button>In Process...</button>
                <span className="loader-btn">
                  <img src={Loader2} />
                </span>
              </div>
            ) : (
              <button
                className="btn"
                onClick={() => {
                  handleCreateNotification();
                }}
              >
                {notificationDetail.notificationType === "immediate"
                  ? `Set live`
                  : `Add Notification`}
              </button>
            )}
          </div>
        </div>
      </div>
      <AlertComponent />

      <ModalPopup
        onClose={() => setShowConfirmModal(false)}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        buttonText1="Set Live"
        isOpen={showConfirmModal}
        hasLoading={popupLoading}
        disabled={popupLoading}
        className=""
        handleClick2ndButton={handleConfirmOk}
        headingLable={"Ready to set your notification live?"}
      >
        <div className="ctsm-modal-body">
          <p>Your notification will reach {totalUserList?.length} people.</p>
        </div>
      </ModalPopup>
    </>
  );
};

export default CreateNotification;
