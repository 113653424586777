import { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Services1 from "../../../Services/auth.service.news";
import authService from "../../../Services/auth.service";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import imgBigDelete from "../../../Images/BackendImages/delete-icon.svg";
import Loader2 from "../../../Images/loader2.gif";
import Loader from "../../../Images/loader.gif";
import moment from "moment";
import loading from "../../../Images/loader.gif";
import SearchAndSorting from "../../AdminCommonComponent/TableComponent/SearchAndSorting";
import TableHeader from "../../AdminCommonComponent/TableComponent/TableHeader";
import TableBodyComponent from "../../AdminCommonComponent/TableComponent/TableBodyComponent";
import DeleteModal from "../../AdminCommonComponent/deleteModal";
import AlertComponent from "../../../Hooks/AlertComponent";
import TableBodyComponentModal from "../../AdminCommonComponent/TableComponent/TableBodyComponentModal";
import ModalPopup from "../../ModalPopup/ModalPopup";
import AddRules from "../../CommonRules/AddRules";
import { useUserCountMutation } from "../../../Services/ruleUserCount";

const columns = [
  { id: "name", label: "Author name", width: 25 },
  { id: "title", label: "Title", width: 25 },
  { id: "url", label: "URL", width: 20 },
  { id: "date", label: "Date", width: 9 },
  { id: "action", label: "Actions", width: 7 },
];

function createData(name, title, url, date, action) {
  return {
    name,
    title,
    url,
    date,
    action,
  };
}
const Posts = () => {
  const emptyObjectModal = {
    name: "",
    title: "",
    url: "",
    date: moment(new Date()).format("YYYY-MM-DD"),
  };
  const emptyObjectErr = { name: "", title: "", url: "", date: "" };
  const [rows, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [deleteId, setDeleteId] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [showAlertAdd, setShowAlertAdd] = useState(false);
  const [showAlertEdit, setShowAlertEdit] = useState(false);
  const [alertMsgEdit, setAlertMsgEdit] = useState("");
  const [allPosts, setAllPosts] = useState([]);
  const [addPost, setAddPost] = useState(emptyObjectModal);
  const [addErr, setAddErr] = useState(emptyObjectErr);
  const [editPost, setEditPost] = useState("");
  const [editErr, setEditErr] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [createInProcess, setCreateInProcess] = useState(false);
  const [editInProcess, setEditInProcess] = useState(false);
  const [addAlertMsg, setAddAlertMsg] = useState("");
  const [editModalLoader, setEditModalLoader] = useState(false);
  const [dataLoader, setDataLoader] = useState(false);
  const [dataFromRules, setDataFromRules] = useState([]);
  const [rulesDataForEdit, setRulesDataForEdit] = useState({});
  const [ruleError, setRuleError] = useState(false);
  const [noRuleFieldSelected, setNoRuleFieldSelected] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [resetRulesFields, setResetRulesFields] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const stayOnPage = useSelector((state) => {
    return state.ReduStayOnPage;
  });
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner);
  const NAVIGATEPAGE = useSelector((state) => {
    return state.partnerNavigate;
  });
  const [searchText, setSearchText] = useState("");
  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [listIndex, setListIndex] = useState(-1);
  const [actualsearch, setActualsearch] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [totalUserList, setTotalUserList] = useState([]);
  const latestRequest = useRef(0);

  //User count mutation
  const [userCountApi] = useUserCountMutation();

  const countUserApi = async (bodyData, requestId) => {
    try {
      const countRes = await userCountApi(bodyData);
      if (latestRequest.current === requestId) {
        // Only update state if this is the latest request
        setTotalUserList(countRes?.data?.data);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    const bodyData = {
      restrictedAccessUserId: dataFromRules.user_id,
      restrictedAccessMembershipPlanId: dataFromRules.membership_plan_id,
      restrictedAccessGroupId: dataFromRules.group_id,
      restrictedAccessTagId: dataFromRules.tags_id,
      restrictedAccessEventId: dataFromRules.event_id,
      restrictionAccess:
        dataFromRules.userAccess === "all" ||
        dataFromRules.userAccess === undefined
          ? "public"
          : "restricted",
    };
    const requestId = ++latestRequest.current; // Increment the request ID
    countUserApi(bodyData, requestId);
  }, [dataFromRules]);

  const getAllNewsPost = (searchText, col, sort) => {
    setSearchText(searchText);
    let query = `?search=${searchText}`;
    query +=
      col !== undefined && col !== null && col !== ""
        ? `&sortField=${col}`
        : "";
    query +=
      sort !== undefined && sort !== null && sort !== ""
        ? `&sortType=${sort}`
        : "";
    Services1.getAllNewsPost(query)
      .then((res) => {
        if (res.data.status) {
          var data = res.data.data;
          var tbl = [];
          for (var i = 0; i < data.length; i++) {
            tbl[i] = createData(
              data[i].name,
              data[i].title,
              data[i].url,
              moment(data[i].date).format("YYYY-MM-DD"),
              data[i]._id
            );
          }
          setRow(tbl);
          setAllPosts(tbl);
        }
        setDataLoader(false);
      })
      .catch((e) => {
        setDataLoader(false);
        console.log(e);
      });
  };
  useEffect(() => {
    setDataLoader(true);
    getAllNewsPost(searchText);
    handleClearAllStates();
    getallusersSuggestionListApi();
  }, []);
  async function getallusersSuggestionListApi() {
    try {
      const getSuggestionRes = await authService.getAllPostSuggestionList();

      console.log(getSuggestionRes, "getSuggestionRes");
      let data = getSuggestionRes.data.data;
      var arr = [];
      if (
        data &&
        data.length !== undefined &&
        data.length !== null &&
        data.length > 0
      ) {
        data.map((item) => arr.push({ name: item.name }));
        console.log(arr);
        setSuggestionList(arr);
      }
    } catch (err) {}
  }
  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      var currentList =
        document.getElementById("suggestion-list").children[listIndex];
      // .firstElementChild.firstElementChild;
      if (currentList) {
        var text = currentList.innerText;
        setSearchText(text);
      }
    }
  }, [listIndex]);

  const handleCloseAddModel = (e) => {
    setEditPost(emptyObjectModal);
    setEditErr(emptyObjectErr);
    setAddPost(emptyObjectModal);
    setAddErr(emptyObjectErr);
    setResetRulesFields(true);
    setTimeout(() => {
      setResetRulesFields(false);
    }, 2000);
    handleClearAllStates();
    setDataFromRules([]);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const urlPatternValidation = (URL) => {
    const regex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    return regex.test(URL);
  };

  //Create news post validation and api call
  const handleCreate = () => {
    var status = true;
    var tempObj = {};
    if (addPost.name.trim().length === 0) {
      tempObj = { ...tempObj, name: "Enter name!" };
      status = false;
    }
    if (addPost.title.trim().length === 0) {
      tempObj = { ...tempObj, title: "Enter a title!" };
      status = false;
    }
    if (addPost.url.trim().length === 0) {
      tempObj = { ...tempObj, url: "Enter valid url!" };
      status = false;
    } else if (!urlPatternValidation(addPost.url)) {
      tempObj = { ...tempObj, url: "Enter valid url!" };
      status = false;
    }
    if (addPost.date.trim().length === 0) {
      tempObj = { ...tempObj, date: "Enter Date!" };
      status = false;
    }
    if (Object.keys(tempObj).length > 0) {
      const emptyKeys = Object.keys(tempObj).filter(
        (key) => tempObj[key] !== ""
      );
      const inputElement = document.getElementsByName(emptyKeys[0])[0];
      if (inputElement) {
        inputElement.focus(); // Focus the input element
      }
    }
    if (status && !ruleError && !noRuleFieldSelected) {
      setCreateInProcess(true);
      createApiCall();
    } else {
      setAddErr(tempObj);
      noRuleFieldSelected && setRuleError(true);
    }
  };

  const createApiCall = () => {
    const convertedData = {};
    if (dataFromRules?.group_id && dataFromRules?.group_id.length > 0) {
      convertedData.restrictedAccessGroupId = dataFromRules.group_id;
    }
    if (
      dataFromRules?.membership_plan_id &&
      dataFromRules?.membership_plan_id.length > 0
    ) {
      convertedData.restrictedAccessMembershipPlanId =
        dataFromRules.membership_plan_id;
    }
    if (
      dataFromRules.userAccess === "restricted" &&
      dataFromRules.user_id.length > 0
    ) {
      convertedData.restrictedAccessUserId = dataFromRules.user_id;
    }
    if (dataFromRules.event_id && dataFromRules.event_id.length > 0) {
      convertedData.restrictedAccessEventId = dataFromRules.event_id;
    }
    if (dataFromRules.tags_id && dataFromRules.tags_id.length > 0) {
      convertedData.restrictedAccessTagId = dataFromRules.tags_id;
    }
    if (dataFromRules.userAccess && dataFromRules.userAccess.length !== 0) {
      convertedData.restrictionAccess =
        dataFromRules.userAccess === "all" ? "public" : "restricted";
    }

    Services1.createNewsPost({
      name: addPost.name,
      title: addPost.title,
      url: addPost.url,
      date: addPost.date,
      ...convertedData,
    })
      .then((res) => {
        console.log("response from news post (post) >>", res);
        setCreateInProcess(false);
        if (res.data.status) {
          setShowAlertAdd(true);
          setAddAlertMsg(res.data.message);
          setTimeout(() => {
            setShowAlertAdd(false);
            setAddAlertMsg("");
          }, 3000);
          getAllNewsPost(searchText);
        } else {
          setShowAlertAdd(true);
          setAddAlertMsg(res.data.message);
          setTimeout(() => {
            setShowAlertAdd(false);
            setAddAlertMsg("");
          }, 3000);
        }
        handleClearAllStates();
        setAddPost(emptyObjectModal);
      })
      .catch((e) => {
        handleClearAllStates();
        setCreateInProcess(false);
        console.log(e);
      });
    setAddErr(emptyObjectErr);
  };
  //handle onchange for create news post
  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setAddPost({ ...addPost, [e.target.name]: e.target.value });
    if (e.target.name === "name") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "title") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "url") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "date") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    }
  };

  //called when edit button is clicked
  const handleEdit = (value) => {
    setSelectedId(value);
    setEditModalLoader(true);
    Services1.getNewsPostById(value)
      .then((res) => {
        console.log("from handle edit>>", res);
        setEditModalLoader(false);
        if (res.data.status) {
          var data = res.data.data;
          setEditPost({
            name: data.name,
            title: data.title,
            url: data.url,
            date: moment(data.date).format("YYYY-MM-DD"),
            id: data._id,
          });
          setRulesDataForEdit({
            selectedUser: data?.restrictedAccessUserId,
            selectedGroups: data?.restrictedAccessGroupId,
            selectedEvents: data?.restrictedAccessEventId,
            selectedMembership: data?.restrictedAccessMembershipPlanId,
            selectedTags: data?.restrictedAccessTagId,
            selectedUserAccess:
              data?.restrictionAccess === "public"
                ? "all"
                : data?.restrictionAccess,
          });
        }
      })
      .catch((e) => {
        setEditModalLoader(false);
        console.log(e);
      });
  };
  //handle on change for edit modal
  const handleEditChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setEditPost({ ...editPost, [e.target.name]: e.target.value });
    if (e.target.name === "name") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "title") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "url") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "date") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    }
  };
  //Called when user click on update changes
  const handleEditSubmit = () => {
    var status = true;
    var tempObj = {};
    if (editPost.name.trim().length === 0) {
      tempObj = { ...tempObj, name: "Enter name!" };
      status = false;
    }
    if (editPost.title.trim().length === 0) {
      tempObj = { ...tempObj, title: "Enter a title!" };
      status = false;
    }
    if (editPost.url.trim().length === 0) {
      tempObj = { ...tempObj, url: "Enter valid url!" };
      status = false;
    } else if (!urlPatternValidation(editPost.url)) {
      tempObj = { ...tempObj, url: "Enter valid url!" };
      status = false;
    }

    if (editPost.date.length === 0) {
      tempObj = { ...tempObj, date: "Enter valid date!" };
      status = false;
    }
    if (Object.keys(tempObj).length > 0) {
      const emptyKeys = Object.keys(tempObj).filter(
        (key) => tempObj[key] !== ""
      );
      const inputElement = document.querySelectorAll(
        `[name="${emptyKeys[0]}"]`
      );
      if (inputElement) {
        inputElement[1].focus(); // Focus the input element
      }
    }
    if (status && !ruleError && !noRuleFieldSelected) {
      setEditInProcess(true);
      editApiCall();
    } else {
      setEditErr(tempObj);
      noRuleFieldSelected && setRuleError(true);
    }
  };
  //editApiCall
  const editApiCall = () => {
    const convertedData = {};
    if (dataFromRules?.group_id && dataFromRules?.group_id.length > 0) {
      convertedData.restrictedAccessGroupId = dataFromRules.group_id;
    }
    if (
      dataFromRules?.membership_plan_id &&
      dataFromRules?.membership_plan_id.length > 0
    ) {
      convertedData.restrictedAccessMembershipPlanId =
        dataFromRules.membership_plan_id;
    }
    if (
      dataFromRules.userAccess === "restricted" &&
      dataFromRules.user_id.length > 0
    ) {
      convertedData.restrictedAccessUserId = dataFromRules.user_id;
    }
    if (dataFromRules.event_id && dataFromRules.event_id.length > 0) {
      convertedData.restrictedAccessEventId = dataFromRules.event_id;
    }
    if (dataFromRules.tags_id && dataFromRules.tags_id.length > 0) {
      convertedData.restrictedAccessTagId = dataFromRules.tags_id;
    }
    if (dataFromRules.userAccess && dataFromRules.userAccess.length !== 0) {
      convertedData.restrictionAccess =
        dataFromRules.userAccess === "all" ? "public" : "restricted";
    }
    const data = {
      postId: selectedId,
      formData: {
        name: editPost.name,
        title: editPost.title,
        url: editPost.url,
        date: editPost.date,
        ...convertedData,
      },
    };
    Services1.editNewsPostDetail(data)
      .then((res) => {
        if (res.data.status) {
          setShowAlertEdit(true);
          setAlertMsgEdit(res.data.message);
          setTimeout(() => {
            setShowAlertEdit(false);
            setAlertMsgEdit("");
          }, 3000);
          getAllNewsPost(searchText);
        } else {
          setShowAlertEdit(true);
          setAlertMsgEdit(res.data.message);
          setTimeout(() => {
            setShowAlertEdit(false);
            setAlertMsgEdit("");
          }, 3000);
        }
        setEditInProcess(false);
        handleClearAllStates();
      })
      .catch((e) => {
        console.log(e);
        setEditInProcess(false);
        handleClearAllStates();
      });
    setEditErr(emptyObjectErr);
  };

  //deletion post api call
  const handleDelete = () => {
    Services1.deleteNewsPost(deleteId)
      .then((res) => {
        if (res.data.status) {
          setShowDeleteModal(false);
          var table = [];
          var n = 0;
          for (var i = 0; i < allPosts.length; i++) {
            if (allPosts[i].action !== deleteId) {
              table[n] = createData(
                allPosts[i].name,
                allPosts[i].title,
                allPosts[i].url,
                allPosts[i].date,
                allPosts[i].action
              );
              n++;
            }
          }
          setAllPosts(table);
          setRow(table);
        }
        setShowAlert(true);
        setAlertMsg(res.data.message);
        setTimeout(() => {
          setShowAlert(false);
          setAlertMsg("");
        }, 3000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const createObjectFromArray = (arr, updateAllEventArray = true) => {
    var tbl = [];
    for (var i = 0; i < arr.length; i++) {
      tbl[i] = createData(
        arr[i].name,
        arr[i].title,
        arr[i].url,
        arr[i].date,
        arr[i].action
      );
    }
    setRow(tbl);
    setPage(0);
    if (updateAllEventArray) setAddPost(tbl);
  };

  //pop up state for do you want to leave....
  useEffect(() => {
    if (stayOnPage !== null && stayOnPage) {
      if ($("#addNewsPost").hasClass("show")) {
        $("#addNewsPost").modal("show");
      }
      if ($("#editModal").hasClass("show")) {
        $("#editModal").modal("show");
      }
    } else if (stayOnPage !== null && !stayOnPage) {
      if ($("#addNewsPost").hasClass("show")) {
        $("#addNewsPost").modal("hide");
        handleCloseAddModel();
      }

      if ($("#editModal").hasClass("show")) {
        $("#editModal").modal("hide");
        handleCloseAddModel();
      }
    }
  }, [stayOnPage]);

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  //search using text
  const handleSearch = (text) => {
    setSuggestionBox(false);
    setSearchText(text);
    setDataLoader(true);
    getAllNewsPost(text);
  };

  const deletePostName = (deleteId) => {
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].action === deleteId) {
        return rows[i].name;
      }
    }
  };
  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main">
          <div className="uic-title">
            <h1>Posts</h1>
            <div className="uic-title-rt-only">
              <button
                className="btn"
                data-toggle="modal"
                data-target="#addNewsPost"
                onClick={(e) => {
                  handleCloseAddModel();
                }}
              >
                Add post
              </button>
            </div>
          </div>
          <div className="uic-fl">
            <div className="uic-fl-search">
              <input
                type="text"
                placeholder="Search"
                className="search-user-input"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  var currentValue;
                  if (e.target.value) {
                    currentValue = e.target.value;
                  } else {
                    currentValue = e.target.value;
                    setSearchText(currentValue);
                  }
                  setActualsearch(currentValue);
                  if (
                    suggestionList !== undefined &&
                    suggestionList.length > 0
                  ) {
                    setListIndex(-1);
                    setSuggestionBox(true);
                    var searchResult = suggestionList.filter((user) => {
                      return user.name
                        .toLowerCase()
                        .includes(e.target.value.trim().toLowerCase());
                    });
                    setSuggestions(searchResult);
                  }
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(e.target.value);
                  } else if (e.key === "ArrowDown") {
                    const listLength =
                      suggestions.length > 15 ? 15 : suggestions.length;
                    if (listIndex < listLength - 1) {
                      setListIndex(listIndex + 1);
                    } else if (listIndex === listLength - 1) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    }
                  } else if (e.key === "ArrowUp") {
                    e.preventDefault();
                    const listLength =
                      suggestions.length > 15 ? 15 : suggestions.length;
                    if (listIndex === 0) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    } else if (listIndex === -1) {
                      setListIndex(listLength - 1);
                    } else {
                      setListIndex(listIndex - 1);
                    }
                  }
                }}
                onBlur={() => setSuggestionBox(false)}
              />

              {suggestionBox &&
                suggestions.length > 0 &&
                searchText.length > 0 && (
                  <div className="suggestion-box">
                    <ul id="suggestion-list">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onMouseDown={() => handleSearch(suggestion.name)}
                          className={
                            index === listIndex ? "selected-active" : ""
                          }
                        >
                          {searchText !== null &&
                          searchText.length > 0 &&
                          suggestion.name
                            .toLowerCase()
                            .startsWith(searchText.toLowerCase()) ? (
                            <>
                              <b>
                                {suggestion.name.slice(0, searchText.length)}
                              </b>
                              {suggestion.name &&
                                suggestion.name.slice(searchText.length)}{" "}
                            </>
                          ) : (
                            suggestion.name
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

              <i
                className="fa fa-search"
                onClick={() => handleSearch(searchText)}
              ></i>
            </div>
          </div>
          <Paper className="uic-tb-mn">
            <TableContainer className="uic-table-main">
              <Table className="uic-table">
                <TableHeader
                  columns={columns}
                  createObjectFromArray={createObjectFromArray}
                  allEventList={allPosts}
                  columnsWithoutSoritng={["makeFeatured", "action"]}
                  listApi={getAllNewsPost}
                  setLoader={setDataLoader}
                  searchText={searchText}
                />
                {dataLoader ? (
                  <div className="cstm-data-load">
                    <img src={loading} />
                  </div>
                ) : rows.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).length > 0 ? (
                  <TableBodyComponentModal
                    rows={rows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    columns={columns}
                    editCallBack={(value) => handleEdit(value)}
                    setDeleteId={(id) => {
                      setDeleteId(id);
                      setShowDeleteModal(true);
                    }}
                  />
                ) : (
                  <div className="admin-no-found">
                    <p>No Posts Found </p>
                  </div>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              className="uic-pagination"
              rowsPerPageOptions={[20, 50, 100, 200]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
      {/* create post */}
      <div
        className="modal fade edit-user-details-popup"
        id="addNewsPost"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Create News Post</h2>
              <button
                type="button"
                className="close"
                //data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#addNewsPost").modal("hide");
                    handleCloseAddModel(e);
                  }
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {showAlertAdd && (
                <div className="alert alert-info">{addAlertMsg}</div>
              )}
              <div className="form-group">
                <div className="form-group-label">Name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter name"
                    name="name"
                    onChange={handleAddChange}
                    value={addPost.name}
                  />
                  <span className="error">{addErr.name}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Title*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter Title"
                    name="title"
                    onChange={handleAddChange}
                    value={addPost.title}
                  />

                  <span className="error">{addErr.title}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Url*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter url"
                    name="url"
                    onChange={handleAddChange}
                    value={addPost.url}
                  />
                  <span className="error">{addErr.url}</span>
                </div>
              </div>

              <div className="form-group">
                <div className="form-group-label">Date*</div>
                <div className="form-group-ct">
                  <input
                    type="date"
                    placeholder="Enter date"
                    name="date"
                    onChange={handleAddChange}
                    value={addPost.date}
                    min={moment().format("MM/DD/YYYY")}
                  />

                  <span className="error">{addErr.date}</span>
                </div>
              </div>
              <AddRules
                title="Rules*"
                ruleError={ruleError}
                totalUserList={totalUserList}
                setRuleError={setRuleError}
                noRuleFieldSelected={noRuleFieldSelected}
                setNoRuleFieldSelected={setNoRuleFieldSelected}
                dataFromRules={dataFromRules}
                setDataFromRules={setDataFromRules}
                resetRulesFields={resetRulesFields}
                showTagsField={true}
              />
            </div>
            <div className="edit-user-details-footer cstm-create-button">
              {createInProcess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleCreate}
                  disabled={createInProcess}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* editmodal */}
      <div
        className="modal fade edit-user-details-popup"
        id="editModal"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          {!editModalLoader ? (
            <div className="modal-content">
              <div className="edit-user-details-header">
                <h2>Edit Post</h2>
                <button
                  type="button"
                  className="close"
                  //data-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => {
                    if (modalInProcess) {
                      dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                    } else {
                      $("#editModal").modal("hide");
                      handleCloseAddModel(e);
                    }
                  }}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                {showAlertEdit && (
                  <div className="alert alert-info">{alertMsgEdit}</div>
                )}
                <div className="form-group">
                  <div className="form-group-label">Name*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter name"
                      name="name"
                      onChange={handleEditChange}
                      value={editPost.name}
                    />
                    <span className="error">{editErr.name}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Title*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter Title"
                      name="title"
                      onChange={handleEditChange}
                      value={editPost.title}
                    />
                    <span className="error">{editErr.title}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Url*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter url"
                      name="url"
                      onChange={handleEditChange}
                      value={editPost.url}
                    />
                  </div>
                  <span className="error">{editErr.url}</span>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Date*</div>
                  <div className="form-group-ct">
                    <input
                      type="date"
                      placeholder="Enter Date"
                      name="date"
                      onChange={handleEditChange}
                      value={editPost.date}
                    />
                  </div>
                  <span className="error">{editErr.date}</span>
                </div>
                <AddRules
                  title="Rules*"
                  ruleError={ruleError}
                  setRuleError={setRuleError}
                  totalUserList={totalUserList}
                  noRuleFieldSelected={noRuleFieldSelected}
                  setNoRuleFieldSelected={setNoRuleFieldSelected}
                  dataFromRules={dataFromRules}
                  setDataFromRules={setDataFromRules}
                  addedRules={rulesDataForEdit}
                  showTagsField={true}
                />
              </div>
              <div className="edit-user-details-footer cstm-create-button">
                {editInProcess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn"
                    onClick={handleEditSubmit}
                  >
                    Update Changes
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="edit-modal-loader">
              <img src={Loader} />
            </div>
          )}
        </div>
      </div>
      {/* Modal Delete */}
      <ModalPopup
        onClose={() => setShowDeleteModal(false)}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        buttonText1="Delete"
        isOpen={showDeleteModal}
        btn2variant="danger"
        className=""
        handleClick2ndButton={handleDelete}
        headingLable={"Delete post"}
      >
        <div className="ctsm-modal-body">
          <p>
            Are you sure you want to delete this "{deletePostName(deleteId)}"?
            This action cannot be undone.
          </p>
        </div>
      </ModalPopup>
      {/* <DeleteModal message={"Are you sure you want to delete this post?"} handleDelete={handleDelete} /> */}
      <AlertComponent />
    </>
  );
};
export default Posts;
