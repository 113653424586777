import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Services from "../../Services/auth.service";
// Import images
import logo from "../../Images/logo_mds_small.svg";

import user from "../../Images/BackendImages/user.png";
import Logouticon from "../../Images/logouticon.svg";
// icons
import User3FillIcon from "remixicon-react/User3FillIcon";
import CalendarEventFillIcon from "remixicon-react/CalendarEventFillIcon";
import FileList3FillIcon from "remixicon-react/FileList3FillIcon";
import Message2FillIcon from "remixicon-react/Message2FillIcon";
import ImageFillIcon from "remixicon-react/ImageFillIcon";
import MedalFillIcon from "remixicon-react/MedalFillIcon";
import GroupFillIcon from "remixicon-react/GroupFillIcon";
import Chat4FillIcon from "remixicon-react/Chat4FillIcon";
import Settings3FillIcon from "remixicon-react/Settings3FillIcon";
import VideoFillIcon from "remixicon-react/MovieFillIcon";
import ChartFillIcon from "remixicon-react/BarChartBoxFillIcon";
import { useDispatch, useSelector } from "react-redux";
import authServicePartner from "../../Services/auth.service.partner";
import NotificationIcon from '../../components/Icons/GeneralIcons/NotificationIcon'
import authService from "../../Services/auth.service";

const AdminSidebar = () => {
  let history = useNavigate();
  const dispatch = useDispatch();
  const videoInprocess = useSelector((state) => {
    return state.ReduVideoInProcess;
  });
  const partnerLabels = useSelector((state) => { return state.ReduPartnerDynamicLabel })

  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const partnerinprocess = useSelector((state) => {
    return state.ReduPartnerInProcess;
  });
  const [changePage, setchangePage] = useState("");
  const [showPopup, setshowPopup] = useState(false);

  const allReview = useSelector((state) => {
    return state.allPendingReview;
  });


  useEffect(() => {
    authServicePartner.allReviewList("?filtertype=").then((allReviewRes) => {
      const data = allReviewRes.data.data;
      const pendingReview = data.filter((item) => item.status === "none");
      dispatch({ type: "allReview", payload: pendingReview.length });
    });

    const partnerLabels = null
    authServicePartner.getPartnerSetting().then((response) => {
      response.data.data.map((item) => {
        if (response.data.status) {
          const payload = {
            featuredLabel:
              item.featurePartner && item.featurePartner !== ""
                ? item.featurePartner
                : partnerLabels ? partnerLabels.featuredLabel : "",
            featuredSubLabel:
              item.featurePartnerSubTitle && item.featurePartnerSubTitle !== ""
                ? item.featurePartnerSubTitle
                : partnerLabels ? partnerLabels.featuredSubLabel : "",
            freshDealLabel:
              item.freshDealTitle && item.freshDealTitle !== ""
                ? item.freshDealTitle
                : partnerLabels ? partnerLabels.freshDealLabel : "",
            freshDealSubLabel:
              item.freshDealSubTitle && item.freshDealSubTitle !== ""
                ? item.freshDealSubTitle
                : partnerLabels ? partnerLabels.freshDealSubLabel : "",
          };
          dispatch({
            type: "SETDYNAMICLABEL",
            payload: payload,
          });
          // setFeatureTitle(item.featurePartner)
          // setFreshTitle(item.freshDealTitle)
        }
      });
    });
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("adminidtoken") === null ||
      localStorage.getItem("adminidtoken").length === 0 ||
      localStorage.getItem("isAdmin") === null ||
      localStorage.getItem("isAdmin") !== "true" ||
      localStorage.getItem("adminuserid") === null ||
      localStorage.getItem("adminuserid").length === 0
    ) {
      history("/adminlogin");
    }
  }, []);
  const handleLogout = () => {
    if (videoInprocess) {
      setshowPopup(true);
      setchangePage("/adminlogin");
    } else {
      Services.setlastlogin();
      localStorage.removeItem("adminidtoken");
      localStorage.removeItem("adminuserid");
      localStorage.removeItem("isAdmin");
      localStorage.removeItem("adminusername");
      localStorage.removeItem("adminaccessToken");
      localStorage.removeItem("adminuserprofile");
      history("/adminlogin");
    }
  };
  return (
    <>
      <div>
        <div className="header-bk">
          <div className="header-bk-left">
            <a>
              <img src={logo} />
            </a>
          </div>
          <div className="header-bk-right">
            <div className="header-bk-right-user  dropdown">
              <a
                className="dropdown-toggle"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img src={user} className="user-icon" />
                {localStorage.getItem("adminusername")}
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a onClick={handleLogout}>
                  Logout <img src={Logouticon} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sidebar-menu">
        <ul>
          <li>
            <Link
              data-tip="Userlisting"
              data-for="userlist"
              data-place="top"
              to={!videoInprocess && "/user/listing"}
              onClick={() => {
                if (videoInprocess) {
                  setshowPopup(true);
                  setchangePage("/user/listing");
                  //} else if (modalInProcess) {
                  // console.log("modalInProcess");
                }
                //  else if (partnerinprocess) {
                //   dispatch({ type: "PAGECHANGE", payload: true })
                //   dispatch({ type: "NAVIGATEPAGE", payload: "/userlisting" })
                // }
                else {
                  history("/user/listing");
                }
                dispatch({ type: "PAGECHANGE", payload: true });
                dispatch({ type: "NAVIGATEPAGE", payload: "/user/listing" });
              }}
              className={
                window.location.pathname.includes("user") ? "menu-active" : ""
              }
            >
              <User3FillIcon size="24px" className="admin-left-icons" />
              <span className="admin-left-title">Users</span>
            </Link>
          </li>
          <li>
            <Link
              data-tip="Userlisting"
              data-for="userlist"
              data-place="top"
              to={!videoInprocess && "/events/list?search="}
              onClick={() => {
                if (videoInprocess) {
                  setshowPopup(true);
                  setchangePage("/user/listing");
                  //} else if (modalInProcess) {
                }
                // else if (partnerinprocess) {
                //   dispatch({ type: "PAGECHANGE", payload: true })
                //   dispatch({ type: "NAVIGATEPAGE", payload: "/events/list" })
                // }
                else {
                  history("/events/list?search=");
                }
                dispatch({ type: "PAGECHANGE", payload: true });
                dispatch({
                  type: "NAVIGATEPAGE",
                  payload: "/events/list?search=",
                });
              }}
              className={
                window.location.pathname.includes("events") ? "menu-active" : ""
              }
            >
              <CalendarEventFillIcon size="24px" className="admin-left-icons" />
              <span className="admin-left-title">Events</span>
            </Link>
          </li>
          <li>
            <Link
              data-tip="Channel"
              data-for="Channel"
              data-place="top"
              to={!videoInprocess && "/channel/channelListing"}
              onClick={() => {
                if (videoInprocess) {
                  setshowPopup(true);
                  setchangePage("/user/listing");
                  //} else if (modalInProcess) {
                }
                // else if (partnerinprocess) {
                //   dispatch({ type: "PAGECHANGE", payload: true })
                //   dispatch({ type: "NAVIGATEPAGE", payload: "/channel/channelListing" })
                // }
                else {
                  history("/channel/channelListing");
                }
                dispatch({ type: "PAGECHANGE", payload: true });
                dispatch({
                  type: "NAVIGATEPAGE",
                  payload: "/channel/channelListing",
                });
              }}
              className={
                window.location.pathname.includes("channel")
                  ? "menu-active"
                  : ""
              }
            >
              <Chat4FillIcon size="24px" className="admin-left-icons" />
              <span className="admin-left-title">Channel</span>
            </Link>
          </li>
          <li>
            <Link
              data-tip="news"
              data-for="news"
              data-place="top"
              to={!videoInprocess && "/news/banners"}
              onClick={() => {
                if (videoInprocess) {
                  setshowPopup(true);
                  setchangePage("/news/banners");
                  //} else if (modalInProcess) {
                }
                // else if (partnerinprocess) {
                //   dispatch({ type: "PAGECHANGE", payload: true })
                //   dispatch({ type: "NAVIGATEPAGE", payload: "/news/banners" })
                // }
                else {
                  history("/news/banners");
                }
                dispatch({ type: "PAGECHANGE", payload: true });
                dispatch({ type: "NAVIGATEPAGE", payload: "/news/banners" });
              }}
              className={
                window.location.pathname.includes("news") ? "menu-active" : ""
              }
            >
              <CalendarEventFillIcon size="24px" className="admin-left-icons" />
              <span className="admin-left-title">News</span>
            </Link>
          </li>
          <li>
            <Link
              data-tip="partener"
              data-for="partener"
              data-place="top"
              to={!videoInprocess && "/partner/list?search="}
              onClick={() => {
                if (videoInprocess) {
                  setshowPopup(true);
                  setchangePage("/partner/list?search=");
                  //} else if (modalInProcess) {
                }
                // else if (partnerinprocess) {
                //   dispatch({ type: "PAGECHANGE", payload: true })
                //   dispatch({ type: "NAVIGATEPAGE", payload: "/partner/list" })
                // }
                else {
                  history("/partner/list?search=");
                }
                dispatch({ type: "PAGECHANGE", payload: true });
                dispatch({
                  type: "NAVIGATEPAGE",
                  payload: "/partner/list?search=",
                });
              }}
              className={
                window.location.pathname.includes("partner/")
                  ? "menu-active"
                  : ""
              }
            >
              <svg
                xmlns="https://www.w3.org/2000/svg"
                width={24}
                height={24}
                className="admin-left-icons"
              >
                <path
                  fillRule="evenodd"
                  d="M12 5.95a1.65 1.65 0 1 0 0-3.3 1.65 1.65 0 0 0 0 3.3Zm3.224-.944a3.3 3.3 0 1 0-6.449 0 7.73 7.73 0 0 0-3.77 3.77 3.29 3.29 0 0 1 1.474.746 6.073 6.073 0 0 1 3.043-3.043A3.292 3.292 0 0 0 12 7.6c.987 0 1.873-.434 2.478-1.12a6.073 6.073 0 0 1 3.042 3.042 3.29 3.29 0 0 1 1.474-.747 7.73 7.73 0 0 0-3.77-3.77ZM19.7 13.65a1.65 1.65 0 1 0 0-3.3 1.65 1.65 0 0 0 0 3.3Zm0 1.65a3.3 3.3 0 1 0-2.18-.822 6.073 6.073 0 0 1-3.042 3.043c.363.412.625.917.746 1.473a7.73 7.73 0 0 0 3.77-3.77c.227.05.464.076.706.076ZM5.95 12a1.65 1.65 0 1 1-3.3 0 1.65 1.65 0 0 1 3.3 0Zm-.944 3.224a3.3 3.3 0 1 1 1.474-.746 6.073 6.073 0 0 0 3.042 3.043 3.29 3.29 0 0 0-.747 1.473 7.73 7.73 0 0 1-3.77-3.77ZM12 21.35a1.65 1.65 0 1 0 0-3.3 1.65 1.65 0 0 0 0 3.3ZM12 23a3.3 3.3 0 1 0 0-6.6 3.3 3.3 0 0 0 0 6.6Z"
                  clipRule="evenodd"
                />
              </svg>
              {/* <CalendarEventFillIcon size="24px" className="admin-left-icons" /> */}
              <span className="admin-left-title">
                Partners{allReview > 0 && <span className="partners-dot-hl" />}
              </span>
            </Link>
          </li>
          <li>
            <Link
              data-tip="document"
              data-for="document"
              data-place="top"
              to={!videoInprocess && "/document/list?search="}
              onClick={() => {
                if (videoInprocess) {
                  setshowPopup(true);
                  setchangePage("/document/list?search=");
                  //} else if (modalInProcess) {
                }
                // else if (partnerinprocess) {
                //   dispatch({ type: "PAGECHANGE", payload: true })
                //   dispatch({ type: "NAVIGATEPAGE", payload: "/partner/list" })
                // }
                else {
                  history("/document/list?search=");
                }
                dispatch({ type: "PAGECHANGE", payload: true });
                dispatch({
                  type: "NAVIGATEPAGE",
                  payload: "/document/list?search=",
                });
              }}
              className={
                window.location.pathname.includes("document/")
                  ? "menu-active"
                  : ""
              }
            >
              <svg
                xmlns="https://www.w3.org/2000/svg"
                width={24}
                height={24}
                className="admin-left-icons"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.5 3.5H6.5C4.84315 3.5 3.5 4.84315 3.5 6.5V17.5C3.5 19.1569 4.84315 20.5 6.5 20.5H17.5C19.1569 20.5 20.5 19.1569 20.5 17.5V6.5C20.5 4.84315 19.1569 3.5 17.5 3.5ZM6.5 2C4.01472 2 2 4.01472 2 6.5V17.5C2 19.9853 4.01472 22 6.5 22H17.5C19.9853 22 22 19.9853 22 17.5V6.5C22 4.01472 19.9853 2 17.5 2H6.5Z"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.25 12C7.25 11.5858 7.58579 11.25 8 11.25H13C13.4142 11.25 13.75 11.5858 13.75 12C13.75 12.4142 13.4142 12.75 13 12.75H8C7.58579 12.75 7.25 12.4142 7.25 12Z"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.25 15C7.25 14.5858 7.58579 14.25 8 14.25H15C15.4142 14.25 15.75 14.5858 15.75 15C15.75 15.4142 15.4142 15.75 15 15.75H8C7.58579 15.75 7.25 15.4142 7.25 15Z"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.25 9C7.25 8.58579 7.58579 8.25 8 8.25H15C15.4142 8.25 15.75 8.58579 15.75 9C15.75 9.41421 15.4142 9.75 15 9.75H8C7.58579 9.75 7.25 9.41421 7.25 9Z"
                />
              </svg>
              <span className="admin-left-title">Documents</span>
            </Link>
          </li>
          {/* 
          <li>
            <a
              data-tip="Categories"
              data-for="contentcategoy"
              data-place="top"
              onClick={() => {
                if (videoInprocess) {
                  setshowPopup(true);
                  setchangePage("/categories/categorylisting");
                } else {
                  history("/categories/categorylisting");
                }
                dispatch({ type: "PAGECHANGE", payload: true })
                dispatch({ type: "NAVIGATEPAGE", payload: "/categories/categorylisting" })
                dispatch({ type: "HISTORYPAGE", payload: "/categories/categorylisting" })

              }}
              className={
                window.location.pathname.includes("categories")
                  ? "menu-active"
                  : ""
              }
            >
              <GroupFillIcon size="24px" className="admin-left-icons" />
              <span className="admin-left-title">Categories</span>
            </a>
          </li> */}

          {/* <li>
            <a>
              <FileList3FillIcon size="24px" className="admin-left-icons" />
              <span className="admin-left-title">Forums</span>
            </a>
          </li>
          <li>
            <a>
              <Message2FillIcon size="24px" className="admin-left-icons" />
              <span className="admin-left-title">Admin Chat</span>
            </a>
          </li> */}
          {/* <li>
            <Link
              to={!videoInprocess && "/media"}
              onClick={() => {
                if (videoInprocess) {
                  setshowPopup(true);
                  setchangePage("/media");
                  //} else if (modalinprocess) {
                } else {
                  history("/media");
                }
                // dispatch({ type: "PAGECHANGE", payload: true })
                dispatch({ type: "NAVIGATEPAGE", payload: "/media" });
                dispatch({ type: "HISTORYPAGE", payload: "/media" });
              }}
              className={
                window.location.pathname.includes("media") ? "menu-active" : ""
              }
            >
              <ImageFillIcon size="24px" className="admin-left-icons" />
              <span className="admin-left-title">Media</span>
            </Link>
          </li> */}
          <li>
            <Link
              to={!videoInprocess && "/media"}
              onClick={() => {
                if (videoInprocess) {
                  setshowPopup(true);
                  setchangePage("/media");
                  //} else if (modalinprocess) {
                } else {
                  history("/media");
                }
                // dispatch({ type: "PAGECHANGE", payload: true })
                dispatch({ type: "NAVIGATEPAGE", payload: "/media" });
                dispatch({ type: "HISTORYPAGE", payload: "/media" });
              }}
              className={
                window.location.pathname.includes("media") ? "menu-active" : ""
              }
            >
              <ImageFillIcon size="24px" className="admin-left-icons" />
              <span className="admin-left-title">Media</span>
            </Link>
          </li>
          {/* <li>
            <Link
              to={!videoInprocess && "/upload/media"}
              onClick={() => {
                if (videoInprocess) {
                  setshowPopup(true);
                  setchangePage("/upload/media");
                  //} else if (modalinprocess) {
                } else {
                  history("/upload-media");
                }
                // dispatch({ type: "PAGECHANGE", payload: true })
                dispatch({ type: "NAVIGATEPAGE", payload: "/upload/media" });
                dispatch({ type: "HISTORYPAGE", payload: "/upload/media" });
              }}
              className={
                window.location.pathname.includes("upload/media")
                  ? "menu-active"
                  : ""
              }
            >
              <ImageFillIcon size="24px" className="admin-left-icons" />
              <span className="admin-left-title">Upload Media</span>
            </Link>
          </li> */}
          <li>
            <Link
              data-tip="Membership Plan"
              data-for="membership"
              data-place="top"
              to={!videoInprocess && "/membership/list"}
              onClick={() => {
                if (videoInprocess) {
                  setshowPopup(true);
                  setchangePage("/user/listing");
                }
                // else if (partnerinprocess) {
                //   dispatch({ type: "PAGECHANGE", payload: true })
                //   dispatch({ type: "NAVIGATEPAGE", payload: "/membership" })
                // }
                else {
                  history("/membership/list");
                }
                dispatch({ type: "PAGECHANGE", payload: true });
                dispatch({ type: "NAVIGATEPAGE", payload: "/membership/list" });
              }}
              className={
                window.location.pathname.includes("membership")
                  ? "menu-active"
                  : ""
              }
            >
              <MedalFillIcon size="24px" className="admin-left-icons" />
              <span className="admin-left-title">Membership</span>
            </Link>
          </li>
          <li>
            <Link
              data-tip="Notification"
              data-for="Notification"
              data-place="top"
              to={!videoInprocess && "/notification/list"}
              onClick={() => {
                if (videoInprocess) {
                  setshowPopup(true);
                  setchangePage("/notification/list");
                }
                else {
                  history("/notification/list");
                }
                dispatch({ type: "PAGECHANGE", payload: true });
                dispatch({
                  type: "NAVIGATEPAGE",
                  payload: "/notification/list",
                });
              }}
              className={
                window.location.pathname.includes("notification")
                  ? "menu-active"
                  : ""
              }
            >
              {/* <ChartFillIcon size="24px" className="admin-left-icons" /> */}
              <NotificationIcon width="24px" height="24px" iconColor="#7b899e" isFilled className="admin-left-icons" />
              <span className="admin-left-title">Notification</span>
            </Link>
          </li>
          <li>
            <Link
              data-tip="Groups"
              data-for="groups"
              data-place="top"
              to={!videoInprocess && "/group"}
              onClick={() => {
                if (videoInprocess) {
                  setshowPopup(true);
                  setchangePage("/user/listing");

                  //} else if (modalInProcess) {
                }
                // else if (partnerinprocess) {
                //   dispatch({ type: "PAGECHANGE", payload: true })
                //   dispatch({ type: "NAVIGATEPAGE", payload: "/group" })

                // }
                else {
                  history("/group");
                }
                dispatch({ type: "PAGECHANGE", payload: true });
                dispatch({ type: "NAVIGATEPAGE", payload: "/group" });
              }}
              className={
                window.location.pathname.includes("group") ? "menu-active" : ""
              }
            >
              <GroupFillIcon size="24px" className="admin-left-icons" />
              <span className="admin-left-title">Groups</span>
            </Link>
          </li>

          <li>
            <Link
              data-tip="Manage Admin"
              data-for="adminmng"
              data-place="top"
              to={!videoInprocess && "/adminmanagement"}
              onClick={() => {
                if (videoInprocess) {
                  setshowPopup(true);
                  setchangePage("/user/listing");
                  //} else if (modalInProcess) {
                }
                // else if (partnerinprocess) {
                //   dispatch({ type: "PAGECHANGE", payload: true })
                //   dispatch({ type: "NAVIGATEPAGE", payload: "/adminmanagement" })

                // }
                else {
                  history("/adminmanagement");
                }
                dispatch({ type: "PAGECHANGE", payload: true });
                dispatch({ type: "NAVIGATEPAGE", payload: "/adminmanagement" });
              }}
              className={
                window.location.pathname.includes("adminmanagement")
                  ? "menu-active"
                  : ""
              }
            >
              <Settings3FillIcon size="24px" className="admin-left-icons" />
              <span className="admin-left-title">Manage Admin</span>
            </Link>
          </li>
          <li>
            <Link
              data-tip="Content Library"
              data-for="contentlib"
              data-place="top"
              to={!videoInprocess && "/contentlibrary/listing/noid/0"}
              onClick={() => {
                if (videoInprocess) {
                  setshowPopup(true);
                  setchangePage("/user/listing");
                  //} else if (modalInProcess) {
                }
                // else if (partnerinprocess) {
                //   dispatch({ type: "PAGECHANGE", payload: true })
                //   dispatch({ type: "NAVIGATEPAGE", payload: "/contentlibrary/listing/noid/0" })
                // }
                else {
                  history("/contentlibrary/listing/noid/0");
                }
                dispatch({ type: "PAGECHANGE", payload: true });
                dispatch({
                  type: "NAVIGATEPAGE",
                  payload: "/contentlibrary/listing/noid/0",
                });
              }}
              className={
                window.location.pathname.includes("contentlibrary")
                  ? "menu-active"
                  : ""
              }
            >
              <VideoFillIcon size="24px" className="admin-left-icons" />
              <span className="admin-left-title">Videos</span>
            </Link>
          </li>
          <li>
            <Link
              data-tip="Statistic"
              data-for="Statisticlib"
              data-place="top"
              to={!videoInprocess && "/statistic/videostatistic"}
              onClick={() => {
                if (videoInprocess) {
                  setshowPopup(true);
                  setchangePage("/user/listing");
                  //} else if (modalInProcess) {
                }
                //  else if (partnerinprocess) {
                //   dispatch({ type: "PAGECHANGE", payload: true })
                //   dispatch({ type: "NAVIGATEPAGE", payload: "/statistic/videostatistic" })
                // }
                else {
                  history("/statistic/videostatistic");
                }
                dispatch({ type: "PAGECHANGE", payload: true });
                dispatch({
                  type: "NAVIGATEPAGE",
                  payload: "/statistic/videostatistic",
                });
              }}
              className={
                window.location.pathname.split("/")[1] === "statistic"
                  ? "menu-active"
                  : ""
              }
            >
              <ChartFillIcon size="24px" className="admin-left-icons" />
              <span className="admin-left-title">Statistics</span>
            </Link>
          </li>
          <li>
            <Link
              data-tip="Statistic"
              data-for="Statisticlib"
              data-place="top"
              to={!videoInprocess && "/badge"}
              onClick={() => {
                if (videoInprocess) {
                  setshowPopup(true);
                  setchangePage("/user/listing");
                  //} else if (modalInProcess) {
                }
                //  else if (partnerinprocess) {
                //   dispatch({ type: "PAGECHANGE", payload: true })
                //   dispatch({ type: "NAVIGATEPAGE", payload: "/badge" })
                // }
                else {
                  history("/badge");
                }
                dispatch({ type: "PAGECHANGE", payload: true });
                dispatch({ type: "NAVIGATEPAGE", payload: "/badge" });
              }}
              className={
                window.location.pathname.includes("badge") ? "menu-active" : ""
              }
            >
              <ChartFillIcon size="24px" className="admin-left-icons" />
              <span className="admin-left-title">Tags</span>
            </Link>
          </li>
          <li>
            <Link
              data-tip="Statistic"
              data-for="Statisticlib"
              data-place="top"
              to={!videoInprocess && "/messagereport"}
              onClick={() => {
                if (videoInprocess) {
                  setshowPopup(true);
                  setchangePage("/user/listing");
                  //} else if (modalInProcess) {
                }
                //  else if (partnerinprocess) {
                //   dispatch({ type: "PAGECHANGE", payload: true })
                //   dispatch({ type: "NAVIGATEPAGE", payload: "/badge" })
                // }
                else {
                  history("/messagereport");
                }
                dispatch({ type: "PAGECHANGE", payload: true });
                dispatch({ type: "NAVIGATEPAGE", payload: "/messagereport" });
              }}
              className={
                window.location.pathname.includes("messagereport") ? "menu-active" : ""
              }
            >
              <ChartFillIcon size="24px" className="admin-left-icons" />
              <span className="admin-left-title">Reports</span>
            </Link>
          </li>
        </ul>
      </div>
      {showPopup && (
        <div className="desk-globle-model-box globle-model-box ">
          <div className="desk-globle-model-box-inner cstm-deactivate-account-only">
            <div className="cstm-deactivate-account-main">
              <div class="cstm-deactivate-account-main-title">
                Video upload is still in progress.
                <br />
                Are you sure you want to leave?
              </div>

              <div className="ays-cancel-btn">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    history(changePage);
                    setshowPopup(false);
                    dispatch({ type: "REMOVEINPROCESS" });
                  }}
                >
                  Yes
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setshowPopup(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>{" "}
        </div>
      )}
    </>
  );
};

export default AdminSidebar;
