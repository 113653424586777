import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import Frame from "../../../Images/Frame.svg";
import Loader2 from "../../../Images/loader2.gif";
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";
import authHeaderChannel from "../../../Services/auth.header.channel";
import AlertComponent from "../../../Hooks/AlertComponent";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import loader from "../../../Images/loader.gif";
import AddRulesForChannel from "./AddRulesForChannel";
import ImageCropperReact from "../../ImageCropper/ImageCropperReact";
import { CompressImageFunction } from "../../AdminNotification/CommonFunction";

const CreateChannel = () => {
  //hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //redux states
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });

  const emptyObjectModal = {
    name: "",
    image: "",
    event: {},
    noEventUsers: "",
    channelType: false,
    accessPermission: "",
  };
  const emptyObjectErr = {
    name: "",
    image: "",
    event: "",
    noEventUsers: "",
    eventUsers: "",
    channelType: "",
    accessPermission: "",
    restrictedOptions: "",
  };
  const emptyObjectModalRules = {
    event: "",
    eventId: "",
    tagId: [],
    userAccess: "all",
    userAccessIds: "",
    accessPermission: "all",
    restrictedOptions: [],
  };

  const emptyObjectErrModal = {
    event: "",
    userAccess: "",
    userAccessIds: "",
    Users: "",
    accessPermission: "",
    restrictedOptions: "",
  };

  const [channelDetail, setChannelDetail] = useState(emptyObjectModal);
  const [channelDetailErr, setChannelDetailErr] = useState(emptyObjectModal);
  const [rulesDetail, setRulesDetail] = useState(emptyObjectModalRules);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [inProcess, setInProcess] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  const [ruleError, setRuleError] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [noRuleFieldSelected, setNoRuleFieldSelected] = useState(false);
  const [dataFromRules, setDataFromRules] = useState([]);
  const [rulesDetailErr, setRulesDetailErr] = useState(emptyObjectErrModal);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditForChannelAccess, setIsEditForChannelAccess] = useState(false);

  //  image cropping state
  const [modalOpen, setModalOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [cropedFile, setcropedFile] = useState();
  const [thumbnail, setThumbnail] = useState([]);
  const [cropedFileData, setcropedFileData] = useState();
  const [fileDimension, setFileDimension] = useState();


  useEffect(() => {
    handleClearAllStates();
  }, []);

  // handle event detail changes
  const handleChannelDetailChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setChannelDetail({ ...channelDetail, [e.target.name]: e.target.value });
    if (e.target.value.length > 0) {
      setChannelDetailErr({ ...channelDetailErr, [e.target.name]: "" });
    }
  };

  // called when click on create event button
  const handleCreateEvent = async () => {
    var status = true;
    var temp = {};
    if (!cropedFile) {
      temp = { ...temp, image: "Select image!" };
      status = false;
    }
    if (channelDetail.name.trim().length === 0) {
      temp = { ...temp, name: "Enter name!" };
      status = false;
    }

    if (
      rulesDetail.userAccess === "restricted" &&
      rulesDetail.eventId === "" &&
      rulesDetail.userAccessIds.length === 0 &&
      rulesDetail.tagId.length === 0 &&
      selectedUsers.length === 0
    ) {
      console.log("test rrrr");
      setRuleError(true);
      setRulesDetailErr({
        ...rulesDetailErr,
        event: "Please select rule!",
      });

      status = false;
    } else {
      setRuleError(false);
      setRulesDetailErr({
        ...rulesDetailErr,
        event: "",
      });
    }

    if (
      rulesDetail.userAccess === "restricted" &&
      rulesDetail.accessPermission === "restricted" &&
      rulesDetail.restrictedOptions.length === 0
    ) {
      console.log("test");
      setRulesDetailErr({
        ...rulesDetailErr,
        restrictedOptions: "Please select rule!",
      });
    }

    if (Object.keys(temp).length > 0) {
      const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
      const inputElement = document.getElementsByName(emptyKeys[0])[0];
      if (inputElement) {
        inputElement.focus(); // Focus the input element
      }
    }
    if (status) {
      setInProcess(true);
      createApiCall();
    } else {
      setChannelDetailErr(temp);
    }
  };

  //create api
  const createApiCall = async () => {
    let imageCompress;
    const formdata = new FormData();
    formdata.append("channelName", channelDetail.name);
    if (cropedFileData) {
      imageCompress = await CompressImageFunction(cropedFileData);
    }
    imageCompress && formdata.append("channelIconFile", imageCompress);
    // formdata.append("channelIconFile", cropedFile);
    formdata.append("channelType", rulesDetail.userAccess);

    if (rulesDetail.userAccess === "restricted") {
      formdata.append("withEvent", rulesDetail.eventId ? true : false);
      rulesDetail.eventId &&
        formdata.append("accessPermission", rulesDetail.accessPermission);

      rulesDetail.eventId && formdata.append("eventId", rulesDetail.eventId);

      if (selectedUsers?.length) {
        selectedUsers.map((u) => {
          formdata.append(`Users[]`, u._id);
        });
      }
      formdata.append("withUser", selectedUsers?.length === 0 ? false : true);
      rulesDetail.restrictedOptions &&
        rulesDetail.restrictedOptions.map((data) => {
          formdata.append("restrictedAccess[]", data);
        });
      formdata.append("withTag", rulesDetail.tagId.length === 0 ? false : true);
      rulesDetail.tagId &&
        rulesDetail.tagId.map((tagData) => {
          formdata.append("TaggedId[]", tagData);
        });
    }
    authHeaderChannel
      .createChannel(formdata)
      .then((res) => {
        setInProcess(false);
        if (res.data.status) {
          setResponseMessage(res.data.message);
          authHeaderChannel.createChannelSendMessage({
            channelMessage: res.data.chatData.channelMessage,
            chatChannelData: res.data.chatData.chatChannelData,
            memberIds:
              rulesDetail.userAccess === "all"
                ? []
                : res.data.chatData.memberIds,
            eventType: "create-channel-receive",
            channelType: rulesDetail.userAccess === "all" ? "all" : "",
          });
          setTimeout(() => {
            setResponseMessage("");
          }, 3000);
          navigate("/channel/channelListing");
        }
        handleClearAllStates();
        setChannelDetail(emptyObjectModal);
        setSelectedUsers([]);
        setRulesDetailErr({});
        setRulesDetail({});
      })
      .catch((e) => {
        setInProcess(false);
        handleClearAllStates();
        console.log(e);
      });
    setChannelDetailErr(emptyObjectErr);
  };


  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };
  // thumbnail image upload function
  const thumbnailImages = async (acceptedfile) => {
    setFileDimension(acceptedfile);
    dispatch({ type: "SETMODALINPROCESS" });
    setFileName(Math.random().toString() + acceptedfile.name);
    setModalOpen(true);
  };
  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main">
          {responseMessage && (
            <div
            // className={isError ? "alert alert-danger" : "alert alert-info"}
            >
              {responseMessage}
            </div>
          )}
          {modalOpen && (
            <ImageCropperReact
              file={thumbnail}
              setModalOpen={setModalOpen}
              setcropedFile={setcropedFile}
              setFile={setThumbnail}
              fileName={fileName}
              setcropedFileData={setcropedFileData}
              fileDimension={fileDimension}
              requiredImageAspectRatio={1 / 1}
            />
          )}
          <div className="um-back">
            <button
              type="button"
              className="back-btn"
              onClick={() => {
                if (modalInProcess) {
                  dispatch({ type: "SETCUSTOMPAGECHANGE" });
                  dispatch({
                    type: "NAVIGATEPAGE",
                    payload: "/channel/channelListing",
                  });
                } else {
                  navigate("/channel/channelListing");
                }
              }}
            >
              <ArrowLeftLineIcon size="20px" style={{ marginRight: "4px" }} />
              Back
            </button>
          </div>
          <div className="uic-title">
            <h1>Add channel</h1>
          </div>
          <div className="group-info-user flex-wrap">
            <div className="cstm-video-upload">
              <Dropzone
                onDrop={(acceptedFiles) => {
                  // Check if any of the dropped files are not allowed
                  const file = acceptedFiles[0];
                  if (file.type.startsWith("image/")) {
                    thumbnailImages(file);
                    const reader = new FileReader();
                    reader.onload = () => setThumbnail(reader.result);
                    reader.readAsDataURL(file);
                    // setThumbnail(URL.createObjectURL(file));
                    setValidate(false);
                    setChannelDetailErr({ ...channelDetailErr, image: "" });
                  } else {
                    // If the dropped file is not an image, display an error message
                    setValidate(true);
                  }
                }}
                name="thumbnail"
                multiple={false}
                accept="image/jpeg, image/png,  image/tiff, image/jpg"
                maxSizeBytes={10485760}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <div className="cstm-video-upload-img square">
                      {cropedFile ?
                        <img className="channel-img square w-100 h-100" src={cropedFile} />
                        :
                        <img className="empty-img" src={Frame} />
                      }
                </div>
                  </div>
                )}
              </Dropzone>
              <span className="error">{channelDetailErr.notAccept}</span>
            </div>
            <div className="group-info-user-link">
              <div className="cover-image-upload-ttl">Thumbnail*</div>
            </div>
            {/* Display error message for file selection */}
            {validate && (
              <span className="error error-rule w-100 mt-1">
                {"Invalid file type. Please select an image file."}
              </span>
            )}
            {/* Display error message related to channelDetailErr.image */}
            {!validate && channelDetailErr.image && (
              <span className="error error-rule w-100 mt-1">
                {channelDetailErr.image}
              </span>
            )}
          </div>

          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Name*</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  name="name"
                  placeholder="Enter name"
                  value={channelDetail.name}
                  onChange={handleChannelDetailChange}
                />
              </div>
              <span className="error error-rule">{channelDetailErr.name}</span>
            </div>
          </div>
          <AddRulesForChannel
            ForWhichPurpose="create"
            title="Rules Selection*"
            ruleError={ruleError}
            setRuleError={setRuleError}
            rulesDetail={rulesDetail}
            setRulesDetail={setRulesDetail}
            totalUsers={totalUsers}
            setTotalUsers={setTotalUsers}
            setNoRuleFieldSelected={setNoRuleFieldSelected}
            setDataFromRules={setDataFromRules}
            setRulesDetailErr={setRulesDetailErr}
            rulesDetailErr={rulesDetailErr}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            isEdit={isEdit}
            isEditForChannelAccess={isEditForChannelAccess}
          />
          <div className="cyp-btm-btn">
            {inProcess ? (
              <div className="loader-button1">
                <button>In Process...</button>
                <span className="loader-btn">
                  <img src={Loader2} />
                </span>
              </div>
            ) : (
              <button
                className="btn"
                onClick={() => {
                  handleCreateEvent();
                }}
              >
                Add channel
              </button>
            )}
            <button
              className="btn2"
              onClick={() => {
                handleClearAllStates();
                navigate("/channel/channelListing");
              }}
            >
              Discard
            </button>
          </div>
        </div>
      </div>
      <>
        <AlertComponent />
      </>
    </>
  );
};
export default CreateChannel;
