import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import Frame from "../../../Images/Frame.svg";
import Loader2 from "../../../Images/loader2.gif";
import Loader from "../../../Images/loader.gif";
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";
import authHeaderChannel from "../../../Services/auth.header.channel";
import AlertComponent from "../../../Hooks/AlertComponent";
import AddRulesForChannel from "../CreateChannelManagement/AddRulesForChannel";
import moment from "moment";
import ImageCropperReact from "../../ImageCropper/ImageCropperReact";
import { CompressImageFunction } from "../../AdminNotification/CommonFunction";
import ToastNotification from "../../ToastNotification/ToastNotification";

//Edit channel page
const EditChannel = () => {
  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [copy, setCopy] = useState(false);

  //redux states
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const NAVIGATEPAGE = useSelector((state) => {
    return state.partnerNavigate;
  });

  const emptyObjectModal = {
    name: "",
    image: "",
    event: "",
    noEventUsers: "",
    channelType: false,
    accessPermission: "",
    Member: false,
    Guest: false,
    Speaker: false,
    Partner: false,
    Users: false,
  };
  const emptyObjectErr = {
    thumbnail: "",
    image: "",
    event: "",
    noEventUsers: "",
    eventUsers: "",
    channelType: "",
    accessPermission: "",
  };
  const emptyObjectModalRules = {
    event: "",
    eventId: "",
    tagId: [],
    userAccess: "all",
    userAccessIds: "",
    accessPermission: "all",
    restrictedOptions: [],
  };
  const emptyObjectErrModal = {
    event: "",
    userAccess: "",
    userAccessIds: "",
    Users: "",
    accessPermission: "",
    restrictedOptions: "",
  };
  const queryVariable = searchParams.get("id");
  const [channelDetail, setChannelDetail] = useState(emptyObjectModal);
  const [channelDetailErr, setChannelDetailErr] = useState(emptyObjectModal);
  const [rulesDetail, setRulesDetail] = useState(emptyObjectModalRules);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [inProcess, setInProcess] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [pageLoader, setPageLoader] = useState(false);
  const [validate, setValidate] = useState(false);
  const [isError, setIsError] = useState([]);
  const [ruleError, setRuleError] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [noRuleFieldSelected, setNoRuleFieldSelected] = useState(false);
  const [dataFromRules, setDataFromRules] = useState([]);
  const [rulesDetailErr, setRulesDetailErr] = useState(emptyObjectErrModal);
  const [editState, setEditState] = useState(false);
  const [eventCurrentSelectedAttendees, setEventCurrentSelectedAttendees] =
    useState([]);
  const [originalChannelDetail, setOriginalChannelDetail] = useState({});
  const [originalRulesDetail, setOriginalRulesDetail] = useState({});
  const [selectedTags, setSelectedTags] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditForChannelAccess, setIsEditForChannelAccess] = useState(false);

  //  image cropping state
  const [thumbnail, setThumbnail] = useState([]);
  const [cropedFileData, setcropedFileData] = useState();
  const [fileDimension, setFileDimension] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [cropedFile, setcropedFile] = useState(null);

  //get channel detail for given id
  const getChannelDetailApi = () => {
    setPageLoader(true);
    let arr = [];
    authHeaderChannel
      .getChannelDetailById(queryVariable)
      .then((res) => {
        if (res.data.status) {
          const data = res.data;
          const channelData = data.channelData;
          const selectedUserIds = data.membersList
            .filter((user) => user.withUser && user.user_type !== "adminuser")
            .map((user) => user._id);
          const selectedUserData = data.membersList
            .filter((user) => user.withUser && user.user_type !== "adminuser")
            .map((user) => user);
          //without event
          const newChannelDetail = {
            name: channelData.channelName,
            image:
              process.env.REACT_APP_AWS_IMG_VID_PATH + channelData.channelIcon,
          };
          const newRulesDetail = {
            event: channelData.withEvent
              ? {
                value: channelData?.eventId?._id,
                label: channelData?.eventId?.title,
              }
              : "",
            eventId: channelData.withEvent ? channelData?.eventId?._id : "",
            userAccess: channelData.channelType,
            userAccessIds: selectedUserIds,
            accessPermission:
              channelData.accessPermission === ""
                ? "all"
                : channelData.accessPermission,
            restrictedOptions: channelData.restrictedAccess.map(
              (user) => user._id
            ),
            tagId: channelData.tagIds?.map((tag) => tag._id),
          };
          setIsEdit(channelData?.eventId?._id ? true : false);
          setIsEditForChannelAccess(true);
          setSelectedTags(
            channelData.tagIds?.map((tag) => ({
              label: tag.name,
              value: tag._id,
            }))
          );
          setSelectedUsers(selectedUserData);
          setEventCurrentSelectedAttendees(selectedUserData);
          setEditState(true);
          setChannelDetail(newChannelDetail);
          setRulesDetail(newRulesDetail);

          // Store the original details
          setOriginalChannelDetail(newChannelDetail);
          setOriginalRulesDetail(newRulesDetail);
          setPageLoader(false);
        }
      })
      .catch((e) => {
        setPageLoader(false);
        console.log(e);
      });
  };
  useEffect(() => {
    handleClearAllStates();
    if (queryVariable) {
      setPageLoader(true);
      getChannelDetailApi();
    }
  }, []);

  const getOriginalChannelDetail = () => originalChannelDetail;
  const getOriginalRulesDetail = () => originalRulesDetail;

  // handle event detail changes
  const handleChannelDetailChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setChannelDetail({ ...channelDetail, [e.target.name]: e.target.value });
    if (e.target.value.length > 0) {
      setChannelDetailErr({ ...channelDetailErr, [e.target.name]: "" });
    }
  };

  // called when click on create event button
  const handleEditChannel = async () => {
    var status = true;
    var temp = {};
    if (channelDetailErr.image) {
      status = false;
      temp = { ...temp, image: "Recommended size 100 x 100" };
    }

    if (channelDetail.name.trim().length === 0) {
      temp = { ...temp, name: "Enter name!" };
      status = false;
    }

    if (
      rulesDetail.userAccess === "restricted" &&
      rulesDetail.eventId === "" &&
      rulesDetail.userAccessIds.length === 0 &&
      rulesDetail.tagId.length === 0 &&
      selectedUsers.length === 0
    ) {
      setRuleError(true);
      setRulesDetailErr({
        ...rulesDetailErr,
        event: "Please select rule!",
      });
      status = false;
    } else {
      setRuleError(false);
      setRulesDetailErr({
        ...rulesDetailErr,
        event: "",
      });
    }

    if (
      rulesDetail.userAccess === "restricted" &&
      rulesDetail.accessPermission === "restricted" &&
      rulesDetail.restrictedOptions.length === 0
    ) {
      setRulesDetailErr({
        ...rulesDetailErr,
        restrictedOptions: "Please select rule!",
      });
    }

    if (Object.keys(temp).length > 0) {
      const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
      const inputElement = document.getElementsByName(emptyKeys[0])[0];
      if (inputElement) {
        inputElement.focus(); // Focus the input element
      }
    }

    const isChannelDetailChanged =
      JSON.stringify(channelDetail) !==
      JSON.stringify(getOriginalChannelDetail());
    const isRulesDetailChanged =
      JSON.stringify(rulesDetail) !== JSON.stringify(getOriginalRulesDetail());
    const isImageChanged = cropedFile !== null;
    const selectedUserIds = selectedUsers.map((user) => user._id).sort();
    const originalUserAccessIds = rulesDetail.userAccessIds.sort();
    const isUserAccessIdsChanged =
      JSON.stringify(selectedUserIds) !== JSON.stringify(originalUserAccessIds);
    if (status) {
      setInProcess(true);
      editApiCall();
    } else {
      setChannelDetailErr(temp);
    }
  };
  //create api
  const editApiCall = async () => {
    let imageCompress;
    const formdata = new FormData();
    formdata.append("channelName", channelDetail.name);
    formdata.append(
      "time_stamp",
      moment.utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]")
    );
    if (cropedFileData) {
      imageCompress = await CompressImageFunction(cropedFileData);
    }
    imageCompress && formdata.append("channelIconFile", imageCompress);

    formdata.append("channelType", rulesDetail.userAccess);
    console.log(originalRulesDetail, "originalRulesDetail");

    if (rulesDetail.userAccess === "restricted") {
      rulesDetail.eventId &&
        formdata.append("accessPermission", rulesDetail.accessPermission);

      const isEventIdChanged =
        rulesDetail.eventId !== originalRulesDetail.eventId;
      if (isEventIdChanged) {
        formdata.append("withEvent", rulesDetail.eventId ? true : false);
        formdata.append("eventId", rulesDetail.eventId);
      }

      const existingUserIds = eventCurrentSelectedAttendees.map((u) => u._id);
      const selectedUsersIds = selectedUsers.map((u) => u._id);
      console.log(existingUserIds, "existingUserIds");
      console.log(selectedUsersIds, "selectedUsersIds");
      if (existingUserIds || selectedUsersIds) {
        selectedUsers?.map((u) => {
          if (!existingUserIds.includes(u._id)) {
            formdata.append(`participents[]`, u._id);
          }
        });

        existingUserIds?.map((u) => {
          if (!selectedUsersIds.includes(u)) {
            formdata.append(`removeParticipents[]`, u);
          }
        });
      }

      formdata.append("withUser", selectedUsers?.length === 0 ? false : true);
      rulesDetail.restrictedOptions &&
        rulesDetail.restrictedOptions.map((data) => {
          formdata.append("restrictedAccess[]", data);
        });
      formdata.append("withTag", rulesDetail.tagId.length === 0 ? false : true);

      rulesDetail.tagId &&
        rulesDetail.tagId.map((tagData) => {
          formdata.append("tagIds[]", tagData);
        });
    }

    authHeaderChannel
      .editChannel({
        id: queryVariable,
        data: formdata,
      })
      .then((res) => {
        setInProcess(false);
        if (res.data.status) {
          setIsError(false);
          setResponseMessage(res.data.message);
          setTimeout(() => {
            setResponseMessage("");
          }, 3000);
          if (res.data.listSocketEvents.length > 0) {
            authHeaderChannel.editChannelSendMessage({
              listSocketEvents: res.data.listSocketEvents,
              channelData: res.data.data,
            });
          }
          navigate("/channel/channelListing");
        }
        handleClearAllStates();
      })
      .catch((e) => {
        handleClearAllStates();
        setInProcess(false);
        console.log(e);
      });
    setChannelDetailErr(emptyObjectErr);
  };

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };
  // thumbnail image upload function
  const thumbnailImages = async (acceptedfile) => {
    setFileDimension(acceptedfile);
    dispatch({ type: "SETMODALINPROCESS" });
    setFileName(Math.random().toString() + acceptedfile.name);
    setModalOpen(true);
  };

  // copy url from channel
  const handleCopyChannelUrl = () => {
    // https://test.milliondollarsellers.com/channel/invite/:channel_id
    navigator.clipboard.writeText(
      `${window.location.origin}/channel/invite/${queryVariable}`
    );
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 2000);
  };

  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main">
          {responseMessage && (
            <div
              className={isError ? "alert alert-danger" : "alert alert-info"}
            >
              {responseMessage}
            </div>
          )}
          {modalOpen && (
            <ImageCropperReact
              file={thumbnail}
              setModalOpen={setModalOpen}
              setcropedFile={setcropedFile}
              setFile={setThumbnail}
              fileName={fileName}
              setcropedFileData={setcropedFileData}
              fileDimension={fileDimension}
              requiredImageAspectRatio={1 / 1}
            />
          )}

          <div className="um-back">
            <button
              type="button"
              className="back-btn"
              onClick={() => {
                if (modalInProcess) {
                  dispatch({ type: "SETCUSTOMPAGECHANGE" });
                  dispatch({
                    type: "NAVIGATEPAGE",
                    payload: "/channel/channelListing",
                  });
                } else {
                  navigate(
                    NAVIGATEPAGE !== null &&
                      NAVIGATEPAGE !== "" &&
                      !NAVIGATEPAGE.includes("null")
                      ? NAVIGATEPAGE
                      : "/channel/channelListing"
                  );
                }
              }}
            >
              <ArrowLeftLineIcon size="20px" style={{ marginRight: "4px" }} />
              Back
            </button>
          </div>
          <div className="d-flex mb-2 align-items-center justify-content-between">
            <h1>Edit Channel</h1>
            <div className="uic-fl-mn-right cstm-sec-button">
              <div className="uic-fl-links">
                <a class="btn px-0" onClick={handleCopyChannelUrl}>
                  Copy invite URL
                </a>
              </div>
              {copy && (
                <div className="toast-wrapper top right">
                  <ToastNotification
                    onCancel={() => setCopy(false)}
                    title={"URL COPIED!"}
                    hasAction={false}
                  />
                </div>
              )}
            </div>
          </div>
          {pageLoader ? (
            <div className="cstm-data-load">
              <img src={Loader} alt="" />{" "}
            </div>
          ) : (
            <>
              <div className="group-info-user">
                <div className="cstm-video-upload">
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      // Check if any of the dropped files are not allowed
                      const file = acceptedFiles[0];
                      if (file.type.startsWith("image/")) {
                        thumbnailImages(file);
                        const reader = new FileReader();
                        reader.onload = () => setThumbnail(reader.result);
                        reader.readAsDataURL(file);
                        // setThumbnail(URL.createObjectURL(file));
                        setValidate(false);
                        setChannelDetailErr({ ...channelDetailErr, image: "" });
                      } else {
                        // If the dropped file is not an image, display an error message
                        setValidate(true);
                      }
                    }}
                    name="thumbnail"
                    multiple={false}
                    accept="image/jpeg, image/png,  image/tiff, image/jpg"
                    maxSizeBytes={10485760}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <div className="cstm-video-upload-img square">
                          <img className={(cropedFile || channelDetail.image) ? "channel-img square w-100 h-100" : "empty-img"} src={
                            cropedFile ?
                              cropedFile
                              : channelDetail.image
                                ? channelDetail.image
                                : Frame
                          } />
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
                <div className="group-info-user-link">
                  <div className="cover-image-upload-ttl">Thumbnail*</div>
                </div>
              </div>
              {/* Display error message for file selection */}
              {validate && (
                <span className="error cstm-error44">
                  {"Invalid file type. Please select an image file."}
                </span>
              )}

              {/* Display error message related to notificationDetailErr.image */}
              {!validate && channelDetailErr.image && (
                <span className="error cstm-error44">
                  {channelDetailErr.image}
                </span>
              )}
              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">Name*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      name="name"
                      placeholder="Enter name"
                      value={channelDetail.name}
                      onChange={handleChannelDetailChange}
                    />
                  </div>
                  <span className="error cstm-error4">
                    {channelDetailErr.name}
                  </span>
                </div>
              </div>
              <AddRulesForChannel
                ForWhichPurpose="edit"
                title="Rules Selection*"
                ruleError={ruleError}
                setRuleError={setRuleError}
                rulesDetail={rulesDetail}
                setRulesDetail={setRulesDetail}
                totalUsers={totalUsers}
                setTotalUsers={setTotalUsers}
                setNoRuleFieldSelected={setNoRuleFieldSelected}
                setDataFromRules={setDataFromRules}
                setRulesDetailErr={setRulesDetailErr}
                rulesDetailErr={rulesDetailErr}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                editState={editState}
                setEditState={setEditState}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                isEdit={isEdit}
                isEditForChannelAccess={isEditForChannelAccess}
              />

              <div className="cyp-btm-btn">
                {inProcess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <button
                    className="btn"
                    onClick={() => {
                      handleEditChannel();
                    }}
                  >
                    Update Changes
                  </button>
                )}
                <button
                  className="btn2"
                  onClick={() => {
                    handleClearAllStates();
                    navigate("/channel/channelListing");
                  }}
                >
                  Discard
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <>
        <AlertComponent />
      </>
    </>
  );
};
export default EditChannel;
