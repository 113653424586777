import React, { useEffect, useState } from "react";
import AdminSidebar from "../AdminSidebar";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import CreateNotification from "./CreateNotification";
import EditChannel from "../ChannelManagement/EditChannelManagement/EditChannel";
import AllNotification from "./AllNotification";
import EditNotification from "./EditNotification";

const index = () => {
  const [pageNotFound, setPageNotFound] = useState(false);
  const [selectedDiv, setselectedDiv] = useState("");

  const dispatch = useDispatch();
  const { action } = useParams();

  useEffect(() => {
    dispatch({
      type: "HISTORYPAGE",
      payload: "/admin/createNotification",
    });
    if (action !== undefined && action.length > 0) setselectedDiv(action);
    if (!["list", "createNotification", "detail"].includes(action))
      setPageNotFound(true);
  }, [action]);
  return (
    <>
      <div className="cstm-all-admin">
        <AdminSidebar />
        <div className="content-main">
          <div className="users-info-sidebar">
            <div className="accordion" id="accordionExample">
              <div className="card">
                <div className="card-header" id="headingTwo">
                  <Link
                    className={`btn-link collapsed ${
                      selectedDiv === "list"  ||  selectedDiv === "createNotification" ||  selectedDiv === "detail"? "sd-active" : ""
                    } `}
                    to={"/notification/list?search="}
                  >
                    All notifications
                  </Link>
                </div>
              </div>
              {/* <div className="card">
                <div className="card-header" id="headingTwo">
                  <Link
                    className={`btn-link collapsed ${
                      selectedDiv === "createNotification" ? "sd-active" : ""
                    } `}
                    to={"/notification/createNotification"}
                  >
                    Add notification
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
          {selectedDiv === "list" ? (
            <AllNotification />
          ) : selectedDiv === "createNotification" ? (
            <CreateNotification />
          ) : selectedDiv === "detail" ? (
            <EditNotification />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
export default index;
