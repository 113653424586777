import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import moment from "moment";
import authServiceNews from "../../../Services/auth.service.news";
import Loader2 from "../../../Images/loader2.gif";
import Frame from "../../../Images/Frame.svg";
import Loader from "../../../Images/loader.gif";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const EditBanner = ({
  emptyObjectModal,
  emptyObjectErr,
  getAllBannerApi,
  searchText,
  isValidUrl,
  handleClearAllStates,
  selectedId,
}) => {
  const dispatch = useDispatch();
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const [alertMsgEdit, setAlertMsgEdit] = useState("");
  const [editEvent, setEditEvent] = useState(emptyObjectModal);
  const [editErr, setEditErr] = useState(emptyObjectErr);
  const [editInprocess, setEditInprocess] = useState(false);
  const [newImage, setNewImage] = useState("");
  const [newImageWeb, setNewImageWeb] = useState("");
  const [editModalLoader, setEditModalLoader] = useState(false);
  const [requiredImageSize, setRequiredImageSize] = useState({
    webBannerWidth: 2256,
    webBannerHeight: 760,
    mobileBannerWidth: 1500,
    mobileBannerHeight: 960
  });
  const [requiredImageAspectRatio, setRequiredImageAspectRatio] = useState(
    16 / 9
  );

  useEffect(() => {
    if (selectedId) {
      setEditModalLoader(true);
      authServiceNews
        .getNewsBannerById(selectedId)
        .then((res) => {
          setEditModalLoader(false);
          if (res.data.status) {
            var data = res.data.data;
            setEditEvent({
              image: data.bannerImage,
              imageWeb: data?.webBannerImage ?? "",
              url: data.bannerUrl,
              startDate: data.publicationStartDate
                ? moment(data.publicationStartDate, "MM-DD-YYYY").format(
                  "YYYY-MM-DD"
                )
                : "",
              startTime: data.publicationStartTime
                ? moment(data.publicationStartTime, "hh:mm A").format("HH:mm")
                : "",
              endDate: data.publicationEndDate
                ? moment(data.publicationEndDate, "MM-DD-YYYY").format(
                  "YYYY-MM-DD"
                )
                : "",
              endTime: data.publicationEndTime
                ? moment(data.publicationEndTime, "hh:mm A").format("HH:mm")
                : "",
              saveAs: data.saveAs,
            });
          }
        })
        .catch((e) => {
          setEditModalLoader(false);
          console.log(e);
        });
    }
  }, [selectedId]);

  const handleCloseAddModel = (e) => {
    setNewImageWeb("");
    setNewImage("");
    setEditEvent(emptyObjectModal);
    setEditErr(emptyObjectErr);
    handleClearAllStates();
  };

  //handle onChange for edit modal
  const handleEditChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setEditEvent({ ...editEvent, [e.target.name]: e.target.value });
    if (e.target.value.trim().length > 0) {
      setEditErr({ ...editErr, [e.target.name]: "" });
    }
  };

  //submit edited banner info and validate, call api
  const handleEditSubmit = () => {
    var status = true;
    var temp = {};

    if (editErr.image) {
      status = false;
      temp = { ...temp, image: "Allow only 1125 x 720" };
    }
    if (editErr.imageWeb) {
      status = false;
      temp = { ...temp, imageWeb: "Allow only 2256 x 760" };
    }
    if (editEvent.url.trim().length === 0) {
      temp = { ...temp, url: "Enter url!" };
      status = false;
    } else if (!isValidUrl(editEvent.url)) {
      temp = { ...temp, url: "Invalid url!" };
      status = false;
    }
    if (editEvent.startDate.length === 0) {
      temp = { ...temp, startDate: "Enter a start date!" };
      status = false;
    }
    if (editEvent.startTime.length === 0) {
      temp = { ...temp, startTime: "Enter a start time!" };
      status = false;
    }
    if (editEvent.endDate.length === 0) {
      temp = { ...temp, endDate: "Enter an end date!" };
      status = false;
    }
    if (editEvent.endTime.length === 0) {
      temp = { ...temp, endTime: "Enter an end time!" };
      status = false;
    }
    if (editEvent.saveAs.length === 0) {
      temp = { ...temp, saveAs: "Select option!" };
      status = false;
    }
    if (Object.keys(temp).length > 0) {
      const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
      const inputElement = document.querySelectorAll(`[name="${emptyKeys[0]}"]`);
      if (inputElement) {
        inputElement[1].focus(); // Focus the input element
      }
    }
    if (status) {
      setEditInprocess(true);
      const formdata = new FormData();
      if (newImage) {
        formdata.append("bannerImage", newImage);
      }
      if (newImageWeb) {
        formdata.append("webBannerImage", newImageWeb);
      }
      formdata.append("bannerUrl", editEvent.url);
      formdata.append(
        "publicationStartTime",
        moment(editEvent.startTime, "hh:mm A").format("hh:mm A")
      );
      formdata.append(
        "publicationEndTime",
        moment(editEvent.endTime, "hh:mm A").format("hh:mm A")
      );
      formdata.append(
        "publicationStartDate",
        moment(editEvent.startDate).format("MM-DD-YYYY")
      );
      formdata.append(
        "publicationEndDate",
        moment(editEvent.endDate).format("MM-DD-YYYY")
      );
      formdata.append("saveAs", editEvent.saveAs);
      authServiceNews
        .editNewsBannerDetail({
          id: selectedId,
          data: formdata,
        })
        .then((res) => {
          if (res.data.status) {
            getAllBannerApi(searchText);
          }
          setAlertMsgEdit(res.data.message);
          setTimeout(() => {
            setAlertMsgEdit("");
          }, 3000);
          setEditInprocess(false);
          handleClearAllStates();
        })
        .catch((e) => {
          console.log(e);
          setEditInprocess(false);
          handleClearAllStates();
        });
      setEditErr(emptyObjectErr);
    } else {
      setEditErr(temp);
    }
  };
  return (
    <div
      className="modal fade edit-user-details-popup"
      id="editBanner"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      data-backdrop="static"
      aria-hidden="true"
      data-keyboard="false"
    >
      <div className="modal-dialog" role="document">
        {!editModalLoader ? (
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Edit Banner</h2>
              <button
                type="button"
                className="close"
                //data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#editBanner").modal("hide");
                    handleCloseAddModel(e);
                  }
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {alertMsgEdit && (
                <div className="alert alert-info">{alertMsgEdit}</div>
              )}

              {/* Mobile dropzone */}
              <Dropzone
                onDrop={(acceptedFiles) => {
                  const file = acceptedFiles[0];
                  if (file.type.startsWith("image/")) {
                    const reader = new FileReader();
                    reader.onload = function (event) {
                      const img = new Image();
                      img.onload = function () {
                        // setNewImage(file);
                        // setEditErr({ ...editErr, image: "" })
                        const height = img.height;
                        const width = img.width;
                        const requiredWidth = requiredImageSize.mobileBannerWidth;
                        const requiredHeight = requiredImageSize.mobileBannerHeight;
                        if (width === requiredWidth && height === requiredHeight) {
                          setNewImage(file);
                          setEditErr({ ...editErr, image: "" })
                        } else {
                          setEditErr({ ...editErr, image: `Allow only ${requiredWidth} x ${requiredHeight} size image.` })
                        }
                      };
                      img.src = event.target.result;
                    };
                    reader.readAsDataURL(file);
                  } else {
                    setEditErr({
                      ...editErr,
                      image: "File type not supported. Please upload an image."
                    });
                  }
                }}
                name="thumbnail"
                multiple={false}
                accept="image/png, image/gif, image/jpeg, image/jpg"
                maxSizeBytes="10485760"
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <div className="group-info-user cstm-upload-box">
                      <div className="group-info-user-img">
                        <img
                          className={""}
                          alt="Speaker profile"
                          src={
                            newImage
                              ? URL.createObjectURL(newImage)
                              : editEvent.image
                                ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                                editEvent.image
                                : Frame
                          }
                        />
                      </div>
                      <div className="group-info-user-link">
                        <div className="cover-image-upload-ttl">
                          Add banner for mobile
                        </div>
                        <p>{`Allow only ${requiredImageSize.mobileBannerWidth} x ${requiredImageSize.mobileBannerHeight} size image.`}</p>
                        </div>
                      <span className="error">{editErr.image}</span>
                    </div>
                  </div>
                )}
              </Dropzone>

              {/* Web dropzone */}
              <Dropzone
                onDrop={(acceptedFiles) => {
                  const file = acceptedFiles[0];
                  if (file.type.startsWith("image/")) {
                    const reader = new FileReader();
                    reader.onload = function (event) {
                      const img = new Image();
                      img.onload = function () {
                        // setNewImageWeb(file);
                        // setEditErr({ ...editErr, imageWeb: "" })
                        const height = img.height;
                        const width = img.width;
                        const requiredWidth = requiredImageSize.webBannerWidth;
                        const requiredHeight = requiredImageSize.webBannerHeight;
                        if (width === requiredWidth && height === requiredHeight) {
                          setNewImageWeb(file);
                          setEditErr({ ...editErr, imageWeb: "" })
                        } else {
                          setEditErr({ ...editErr, image: `Allow only ${requiredWidth} x ${requiredHeight} size image.` })
                        }
                      };
                      img.src = event.target.result;
                    };
                    reader.readAsDataURL(file);
                  } else {
                    setEditErr({
                      ...editErr,
                      imageWeb: "File type not supported. Please upload an image."
                    });
                  }
                }}
                name="thumbnail"
                multiple={false}
                accept="image/png, image/gif, image/jpeg, image/jpg"
                maxSizeBytes="10485760"
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <div className="group-info-user cstm-upload-box">
                      <div className="group-info-user-img">
                        <img
                          className={""}
                          alt="Speaker profile"
                          src={
                            newImageWeb
                              ? URL.createObjectURL(newImageWeb)
                              : editEvent.imageWeb
                                ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                                editEvent.imageWeb
                                : Frame
                          }
                        />
                      </div>
                      <div className="group-info-user-link">
                        <div className="cover-image-upload-ttl">
                          Add banner for web
                        </div>
                          <p>{`Allow only ${requiredImageSize.webBannerWidth} x ${requiredImageSize.webBannerHeight} size image.`}</p>
                        </div>
                      <span className="error">{editErr.imageWeb}</span>
                    </div>
                  </div>
                )}
              </Dropzone>
              <div className="form-group">
                <div className="form-group-label">Url*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter url"
                    name="url"
                    onChange={handleEditChange}
                    value={editEvent.url}
                  />
                  <span className="error">{editErr.url}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Start date*</div>
                <div className="form-group-ct">
                  <input
                    type="date"
                    name="startDate"
                    onChange={handleEditChange}
                    value={editEvent.startDate}
                  />
                  <span className="error">{editErr.startDate}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Start time*</div>
                <div className="form-group-ct">
                  <input
                    type="time"
                    name="startTime"
                    onChange={handleEditChange}
                    value={editEvent.startTime}
                    disabled={editEvent.startDate.length === 0 ? true : false}
                  />
                  <span className="error">{editErr.startTime}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">End Date*</div>
                <div className="form-group-ct">
                  <input
                    type="date"
                    name="endDate"
                    onChange={handleEditChange}
                    value={editEvent.endDate}
                    disabled={editEvent.startDate.length === 0 ? true : false}
                    min={editEvent.startDate}
                  />
                  <span className="error">{editErr.endDate}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">End time*</div>
                <div className="form-group-ct">
                  <input
                    type="time"
                    name="endTime"
                    onChange={handleEditChange}
                    value={editEvent.endTime}
                    disabled={editEvent.startDate.length === 0 ? true : false}
                  />
                  <span className="error">{editErr.endTime}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="cstm-add-video-title-sub">Save As*</div>
                <div className="form-group-ct">
                  <label className="subcat-addvideo">
                    <input
                      type="radio"
                      id="draft"
                      name="saveAs"
                      value="draft"
                      onChange={handleEditChange}
                      defaultChecked={editEvent.saveAs === "draft"}
                      checked={editEvent.saveAs === "draft"}
                    />
                    Draft
                  </label>
                  <label className="subcat-addvideo">
                    <input
                      type="radio"
                      id="publish"
                      name="saveAs"
                      value="publish"
                      onChange={handleEditChange}
                      defaultChecked={editEvent.saveAs === "publish"}
                      checked={editEvent.saveAs === "publish"}
                    />
                    Publish
                  </label>
                </div>
                <span className="error">{editErr.saveAs}</span>
              </div>
            </div>
            <div className="edit-user-details-footer cstm-create-button">
              {editInprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleEditSubmit}
                >
                  Update Changes
                </button>
              )}
            </div>
          </div>
        ) : (
          <div className="edit-modal-loader">
            <img src={Loader} />
          </div>
        )}
      </div>
    </div>
  );
};

export default EditBanner;
