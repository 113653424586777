import { Rating } from '@mui/material';
import React, { useEffect, useState } from 'react'
import StarIcon from "@mui/icons-material/Star";
import ArrowLeftBack from "../../../../Images/arrow-left-back.svg";
import authService from '../../../../Services/auth.service';
import mdsLogoImgForCatelogry from "../../../../Images/logo_mds_vertical_light.png";
import Loader2 from "../../../../Images/loader2.gif";
import checkMark from "../../../../Images/check-smalls.svg";

const RatingModal = ({ reviewCount, setPendingReview, pendingReview, setReviewCount, isRating, setRating, selectedPartner, setList, setSuccess }) => {

    const [selectedPartnerIndex, setSelectedPartnerIndex] = useState(0);
    const [reviews, setReviews] = useState([]);
    const [error, setError] = useState([]);
    const [inProcess, setInProcess] = useState(false);

    useEffect(() => {
        // Update reviews state when selectedPartner changes
        setReviews(selectedPartner.map((partner) => ({
            rating: 0,
            feedBack: "",
            partnerId: partner._id,
            isAnonymous: false
        })));
        setError(selectedPartner.map(() => ({
            rating: '',
            feedBack: "",
        })));
    }, [selectedPartner]);

    // handle next
    const nextPartner = () => {
        if (selectedPartnerIndex < selectedPartner.length - 1) {
            setSelectedPartnerIndex(selectedPartnerIndex + 1);
        }
    };

    // handle previous
    const previousPartner = () => {
        setError({rating: "", feedBack: ""})
        if (selectedPartnerIndex > 0) {
            setSelectedPartnerIndex(selectedPartnerIndex - 1);
        } else {
            setList(true)
            setRating(false)
        }
    };

    //handle review change function
    const handleReviewChange = (e, i) => {
        setError({...error, feedBack: ""})
        const { name, value } = e.target;
        const updatedReviews = [...reviews];
        updatedReviews[selectedPartnerIndex][name] = value;
        setReviews(updatedReviews);
    };

     //handle review change function
     const handleRatingChange = (e, i) => {
        setError({...error, rating: ""})
        const { name, value } = e.target;
        const updatedReviews = [...reviews];
        updatedReviews[selectedPartnerIndex][name] = value;
        setReviews(updatedReviews);
    };

    //handle review change function
    const handleAnonymousChange = (e, i) => {
        // const updatedReviews = [...reviews]
        if (selectedPartnerIndex === 0) {
            const updatedReviews = reviews.map((review, index) => {
                // Check if selectedPartnerIndex is 0 or the current index
                if (selectedPartnerIndex === 0 || selectedPartnerIndex === index) {
                    return { ...review, isAnonymous: e };
                }
                return review;
            });
            setReviews(updatedReviews)
        } else {
            const updatedReviews = [...reviews];
            updatedReviews[selectedPartnerIndex]["isAnonymous"] = e
            setReviews(updatedReviews)
        }
    };

    // handle send review
    const handleSendReview = async () => {
        setInProcess(true)
        reviews.map((review) => {
            const bodyData = {
                star: review.rating,
                reviewNote: review.feedBack,
                partnerId: review.partnerId,
                isAnonymous: review.isAnonymous
            };
            authService.addPartnerReview(bodyData)
                .then((sendReviewRes) => {
                    if (sendReviewRes.data.status) {
                        setReviewCount(3)
                        setInProcess(false)
                        setRating(false)
                        setSuccess(true)
                    } else {
                        setInProcess(false)
                    }
                })
        })
    }
    return (
        <>
            <div className={`${isRating ? "modal-fadein" : "modal-fadeout"}`}>
                <div className="desk-globle-model-box globle-model-box ">
                    <div className="desk-globle-model-box-inner leave-review-popup-main">
                        <div className="select-partners-pp-only">
                            <span className='select-partners-pp-icon-bank' onClick={previousPartner}><img src={ArrowLeftBack} /></span>
                            <h3 className="partners-details-popup-title">Review {selectedPartnerIndex + 1} of {reviewCount !== 0 ? 3 - reviewCount : 3}</h3>
                        </div>

                        {selectedPartner.map((item, index) => {
                            return <>
                                <div key={index} style={{ display: index === selectedPartnerIndex ? 'block' : 'none' }}>
                                    <div className="leave-review-offer">
                                        <div className="leave-review-offer-left"><img src={item.thumbnail !== "" ? item.thumbnail : mdsLogoImgForCatelogry} /></div>
                                        <div className="leave-review-offer-right">
                                            <h5 className='leave-review-offer-title'>{item.offerValue}</h5>
                                            <p className='leave-review-offer-clickup'>{item.companyName}</p>
                                            <p className='leave-review-offer-clickup'>{item.shortDescription}</p>
                                        </div>
                                    </div>
                                    <div className="partners-details-popup-leave-a-review leave-review-offer-rating-only">
                                        <h5>Rating</h5>
                                        <div className="partners-details-popup-leave-star">
                                            <Rating
                                                value={reviews[index] && reviews[index].rating ? reviews[index].rating : 0}
                                                name="rating"
                                                onChange={(e) => handleRatingChange(e, index)}
                                                emptyIcon={
                                                    <StarIcon style={{ opacity: 1, color: "#84898F" }} />
                                                }
                                            />
                                            {error.rating && <span className="error">{error.rating}</span>}
                                        </div>
                                        <div className="cstm-rating-feedback">
                                            <h5>Anything else you want to share with the Community?</h5>
                                            <textarea
                                                className={`anything-else-you-textarea ${error?.feedBack && "leave-review-tex"
                                                    }`}
                                                placeholder="Share some feedback about this offer!"
                                                value={reviews[index] && reviews[index].feedBack ? reviews[index].feedBack : ""}
                                                name="feedBack"
                                                onChange={(e) => handleReviewChange(e, index)}
                                                maxLength="500"
                                            />
                                            <span className="characters-count">
                                                Сharacters: {reviews[index] && reviews[index].feedBack ? reviews[index].feedBack.length : 0}/500
                                            </span>
                                            {error.feedBack && (
                                                <span className="error">{error.feedBack}</span>
                                            )}
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 form-group">
                                                <div className="checkbox-container">
                                                    <input
                                                        type="checkbox"
                                                        name="isAnonymous"
                                                        id={`chk-preregistration-${index}`}
                                                        checked={reviews[index] && reviews[index].isAnonymous}
                                                        value={reviews[index] && reviews[index].isAnonymous ? reviews[index].isAnonymous : false}
                                                        onChange={(e) => handleAnonymousChange(e.target.checked, index)}
                                                    />
                                                    <label htmlFor={`chk-preregistration-${index}`}>
                                                        <span>
                                                            <img src={checkMark} /></span> Keep my review anonymous
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        })}
                        <div className="btn-footer-pp-only">
                            {inProcess ? (
                                <>
                                    <button className="disabled-btn">Continue<span className='continue-loaing-btn'><img src={Loader2} /></span></button>
                                </>
                            ) : 
                            (<button className="btn-pick-more"
                                // disabled={(reviews[selectedPartnerIndex] !== undefined && reviews[selectedPartnerIndex].rating === 0) || (reviews[selectedPartnerIndex] !== undefined && reviews[selectedPartnerIndex].feedBack.trim() === "")}
                                onClick={() => {
                                    let err = {
                                        rating : "",
                                        feedBack : ""
                                    }
                                    let validateReview = true
                                    if ((reviews[selectedPartnerIndex] !== undefined && reviews[selectedPartnerIndex].rating === 0) || (reviews[selectedPartnerIndex] !== undefined && reviews[selectedPartnerIndex].feedBack.trim() === ""))
                                    {
                                        if (reviews[selectedPartnerIndex] !== undefined && reviews[selectedPartnerIndex].rating === 0)
                                        {
                                            err = {...err, rating : "Please rate to continue"}  
                                            validateReview = false
                                        }
                                        if ((reviews[selectedPartnerIndex] !== undefined && reviews[selectedPartnerIndex].feedBack.trim() === ""))
                                        {
                                            err = {...err, feedBack : "Share your review to continue"}  
                                            validateReview = false
                                        }
                                        
                                    }

                                    if (validateReview)
                                    {
                                        setError({rating: "", feedBack: ""})
                                        if (reviewCount === 2 || (reviewCount === 1 && selectedPartnerIndex === 1) || selectedPartnerIndex === 2) {
                                            handleSendReview()
                                        } else {
                                            nextPartner()
                                        }
                                        
                                    }else
                                    {
                                        setError(err)
                                    }    
                                    
                                }}
                            >Continue</button>
                   
                        )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RatingModal