import React from 'react'
import AdminSidebar from '../AdminSidebar'
import Tags from './Tags'
import { Link } from 'react-router-dom'

const index = () => {
    return (
        <>
            <div className="cstm-all-admin">
                <AdminSidebar />
                <div className="content-main">
                    <div className="users-info-sidebar">
                        <div className="accordion" id="accordionExample">
                            <div className="card">
                                <div className="card-header" id="headingTwo">
                                    <Link
                                        className={`btn-link collapsed  sd-active`}>
                                        Tags
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Tags />
                </div>
            </div>
        </>
    )
}

export default index