import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import EmojiPicker from "emoji-picker-react";

// Component
import CommentEmpty from "../../../components/Illustrations/CommentEmpty";
import ChatDataContainer from "./ChatDataContainer";
import StackedMessagesContainer from "./StackedMessagesContainer";
import EmptyState from "../../../components/EmptyState/EmptyState";
import AddEmojiIcon from "../../../components/Icons/GeneralIcons/AddEmojiIcon";
import sadEmojiImg from '../../../Images/sad-but-relieved-face.png'
// CSS
import "../../../Styles/Theme/Theme.css";
import "./Styles/ChatContainer.css";
import useReactionHandlers from "../Hooks/useReactionHandlers";

const ChatContainer = ({
  menuSelectedDivRef,
  hoverStay,
  setModelPlace,
  modelPlace,
  findQuotedmessage,
  quoteMessageSearchIdRef,
  isUnreadMessageInDisplayArea = true,
  handleLoadMoreMessages,
  showScrollButton,
  unReadMessageRef,
  unreadMessageIndex = "",
  type = "NotSelected",
  chatType = "Channel/Group",
  isOutgoing = false,
  AvatarType = "photo",
  Avtarsrc = "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
  AvtarLetter = "M",
  AvtarIcon = "",
  actionModel,
  setActionModel,
  showAllEmogis,
  toggleShowEmojis,
  initialEmojis,
  reactions,
  setReactions,
  showEmojis,
  setShowEmojis,
  hoverEffects = false,
  setHoverEffects,
  reactionMsg,
  setReactionMsg,
  handleEmojiClick,
  handleReplyClick,
  handleDotsClick,
  showDropdown,
  handleSelectEmoji,
  setShowDropdown,
  handleReactionClick,
  handleDelete,
  handleReactionUsersList,
  chatData = [
    {
      messageType: "activity",
      data: [
        {
          variant: "Date",
          date: "19 Aug",
        },
        {
          variant: "GroupCreated",
          name: "Name",
          groupName: "Group Name",
        },
        {
          variant: "GroupLimit",
          memberCount: 5,
        },
        {
          variant: "UserJoinedGroup",
          name: "Name",
        },
        {
          variant: "UserLeaveGroup",
          name: "Name",
        },
        {
          variant: "ChannelCreated",
          name: "Name",
          channelName: "Channel Name",
        },
        {
          variant: "UserJoinedChannel",
          name: "Name",
        },
        {
          variant: "UserLeaveChannel",
          name: "Name",
        },
      ],
    },
    {
      messageType: "messages",
      data: [
        {
          messageType: "text", // text, reply, link, image, video, file, emojis, voice
          // Main Components Prompts
          isHighlighted: false,
          hasName: true,
          hasReactions: false,
          hasAction: false,
          name: "Jane",
          message: "Hii I am really good.",

          // OutgoingMessageTime
          isRead: true,
          hasBackdrop: false,
          isEdited: false,
          time: "09:12 AM",

          // Reaction
          hasCounter: true,
          hasReaction2: false,
          counter: 5,
        },
        {
          messageType: "reply",
          // Main Components Prompts

          isHighlighted: false,

          hasName: true,
          hasReactions: false,
          hasAction: false,
          name: "Jane",
          message: "Hii I am really good.",

          // OutgoingMessageTime
          isRead: true,
          hasBackdrop: false,
          isEdited: false,
          time: "09:12 AM",

          // Reaction
          hasCounter: true,
          hasReaction2: false,
          counter: 5,

          //  Reply Message type
          receiverName: "Bill Gaston",
          srcOfReplayMessage:
            "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
          messageToReply:
            "Really appreciate the and @Wade variations of your recipes. So thoughtful of you! 🌱",
          altReply: "Image",
          MessageReplyType: "MessageReply",
        },
        {
          messageType: "link",
          // meta detail
          metaImageSrc:
            "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
          metaTitle: "mds.com",
          metaLink: "https://mds.com",
          metaDescription: "Financelab X - Webflow Ecommerce Website Template",
          metaShortDescription:
            "If you are a fintech startup looking for a great finance Webflow Template, search no more. Presenting Financelab X, the next-gen template for companies in the finance sector.",

          // Main Components Prompts
          isHighlighted: false,

          hasName: true,
          hasReactions: false,
          hasAction: false,
          name: "Jane",
          message: "Hii I am really good.",

          // OutgoingMessageTime
          isRead: true,
          hasBackdrop: false,
          isEdited: false,
          time: "09:12 AM",

          // Reaction
          hasCounter: true,
          hasReaction2: false,
          counter: 5,
        },
        {
          messageType: "image",
          // Main Components Prompts
          isHighlighted: false,

          hasReactions: false,
          hasAction: false,
          hasTime: true,
          src: [
            "https://images.unsplash.com/photo-1705392852198-2a2334db0314?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          ],
          count: 2,

          // OutgoingMessageTime
          isRead: true,
          hasBackdrop: false,
          isEdited: false,
          time: "09:12 AM",

          // Reaction
          hasCounter: true,
          hasReaction2: false,
          counter: 5,
        },
        {
          messageType: "video",
          // Main Components Prompts
          isHighlighted: false,

          hasReactions: false,
          hasAction: false,
          hasTime: true,
          src: [
            "https://images.unsplash.com/photo-1705392852198-2a2334db0314?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            "https://images.unsplash.com/photo-1504415724575-56cef5e3da72?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          ],
          count: 2,
          videoType: "Horizontal",

          // OutgoingMessageTime
          isRead: true,
          hasBackdrop: false,
          isEdited: false,
          time: "09:12 AM",

          // Reaction
          hasCounter: true,
          hasReaction2: false,
          counter: 5,
        },
        {
          messageType: "file",
          // Main Components Prompts
          isHighlighted: false,

          hasReactions: false,
          hasAction: false,
          files: {
            fileName: "filename.svg",
            size: "55KB",
          },

          // OutgoingMessageTime
          isRead: true,
          hasBackdrop: false,
          isEdited: false,
          time: "09:12 AM",

          // Reaction
          hasCounter: true,
          hasReaction2: false,
          counter: 5,
        },
        {
          messageType: "voice",
          // Main Components Prompts

          isHighlighted: false,

          hasReactions: false,
          hasAction: false,
          voiceControlType: false,
          voiceWaveFilled: false,
          audioTime: "2:15",

          // OutgoingMessageTime
          isRead: true,
          hasBackdrop: false,
          isEdited: false,
          time: "09:12 AM",

          // Reaction
          hasCounter: true,
          hasReaction2: false,
          counter: 5,
        },
        {
          messageType: "emoji",
          // Main Components Prompts
          isHighlighted: false,
          hasReactions: false,
          hasAction: false,
          message: "😊😂🤯✏️",

          // Reaction
          hasCounter: true,
          hasReaction2: false,
          counter: 5,
        },
      ],
      isOutgoing: false,
    },
    {
      messageType: "activity",
      data: [
        {
          variant: "UserJoinedGroup",
          name: "Name",
        },
        {
          variant: "UserLeaveGroup",
          name: "Name",
        },
      ],
    },
    {
      messageType: "messages",
      data: [
        {
          messageType: "text", // text, reply, link, image, video, file, emojis, voice
          // Main Components Prompts
          isHighlighted: false,
          hasName: true,
          hasReactions: false,
          hasAction: false,
          name: "Jane",
          message: "Hii I am really good.",

          // OutgoingMessageTime
          isRead: true,
          hasBackdrop: false,
          isEdited: false,
          time: "09:12 AM",

          // Reaction
          hasCounter: true,
          hasReaction2: false,
          counter: 5,
        },
        {
          messageType: "reply",
          // Main Components Prompts

          isHighlighted: false,

          hasName: true,
          hasReactions: false,
          hasAction: false,
          name: "Jane",
          message: "Hii I am really good.",

          // OutgoingMessageTime
          isRead: true,
          hasBackdrop: false,
          isEdited: false,
          time: "09:12 AM",

          // Reaction
          hasCounter: true,
          hasReaction2: false,
          counter: 5,

          //  Reply Message type
          receiverName: "Bill Gaston",
          srcOfReplayMessage:
            "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
          messageToReply:
            "Really appreciate the and @Wade variations of your recipes. So thoughtful of you! 🌱",
          altReply: "Image",
          MessageReplyType: "MessageReply",
        },
        {
          messageType: "link",
          // meta detail
          metaImageSrc:
            "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
          metaTitle: "mds.com",
          metaLink: "https://mds.com",
          metaDescription: "Financelab X - Webflow Ecommerce Website Template",
          metaShortDescription:
            "If you are a fintech startup looking for a great finance Webflow Template, search no more. Presenting Financelab X, the next-gen template for companies in the finance sector.",

          // Main Components Prompts
          isHighlighted: false,

          hasName: true,
          hasReactions: false,
          hasAction: false,
          name: "Jane",
          message: "Hii I am really good.",

          // OutgoingMessageTime
          isRead: true,
          hasBackdrop: false,
          isEdited: false,
          time: "09:12 AM",

          // Reaction
          hasCounter: true,
          hasReaction2: false,
          counter: 5,
        },
        {
          messageType: "image",
          // Main Components Prompts
          isHighlighted: false,

          hasReactions: false,
          hasAction: false,
          hasTime: true,
          src: [
            "https://images.unsplash.com/photo-1705392852198-2a2334db0314?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          ],
          count: 2,

          // OutgoingMessageTime
          isRead: true,
          hasBackdrop: false,
          isEdited: false,
          time: "09:12 AM",

          // Reaction
          hasCounter: true,
          hasReaction2: false,
          counter: 5,
        },
        {
          messageType: "video",
          // Main Components Prompts
          isHighlighted: false,

          hasReactions: false,
          hasAction: false,
          hasTime: true,
          src: [
            "https://images.unsplash.com/photo-1705392852198-2a2334db0314?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            "https://images.unsplash.com/photo-1504415724575-56cef5e3da72?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          ],
          count: 2,
          videoType: "Horizontal",

          // OutgoingMessageTime
          isRead: true,
          hasBackdrop: false,
          isEdited: false,
          time: "09:12 AM",

          // Reaction
          hasCounter: true,
          hasReaction2: false,
          counter: 5,
        },
        {
          messageType: "file",
          // Main Components Prompts
          isHighlighted: false,

          hasReactions: false,
          hasAction: false,
          files: {
            fileName: "filename.svg",
            size: "55KB",
          },

          // OutgoingMessageTime
          isRead: true,
          hasBackdrop: false,
          isEdited: false,
          time: "09:12 AM",

          // Reaction
          hasCounter: true,
          hasReaction2: false,
          counter: 5,
        },
        {
          messageType: "voice",
          // Main Components Prompts

          isHighlighted: false,

          hasReactions: false,
          hasAction: false,
          voiceControlType: false,
          voiceWaveFilled: false,
          audioTime: "2:15",

          // OutgoingMessageTime
          isRead: true,
          hasBackdrop: false,
          isEdited: false,
          time: "09:12 AM",

          // Reaction
          hasCounter: true,
          hasReaction2: false,
          counter: 5,
        },
      ],
      isOutgoing: true,
    },
  ],
  chatContainerRef,
  handleScroll,
  onSelectOptions,
  handlePreviewClick,
  msgData,
  scrolltopdata,
}) => {
  return (
    <>
      <div
        id="NEW_MAIN_CHAT_CONTAINER"
        onScroll={handleScroll}
        ref={chatContainerRef}
        className={`chat-sec-inner scrollbar ${scrolltopdata} ${type === "Slots" || type === "NoMessages" ? "chat-slots-invert" : ""
          }`}
      >
        {type === "NotSelected" && (
          <div className="chat-empty-sec flex items-center">
            <EmptyState
              subTitle="Select who you would like to write to"
              title="No chats selected"
              icon={<CommentEmpty />}
              isClear={false}
            />
          </div>
        )}

        {type === "channelnotfound" && (
          <div className="chat-empty-sec flex items-center">
            <EmptyState
              extraClass={'empty-emoji-state'}
              subTitle="Sorry, this channel no longer exists"
              title=""
              icon={<img src={sadEmojiImg} className="emoji-img" width={90} height={90} />}
              isClear={false}
            />
          </div>
        )}

        {type === "NoMessages" && (
          <div className="chat-nomessage-sec flex justify-center">
            <ChatDataContainer
              chatArray={
                chatData
                  ? chatData.filter(
                    (data) => data.messageType === "activity"
                  )[0].data
                  : []
              }
              handleLoadMoreMessages={handleLoadMoreMessages}
            />
          </div>
        )}

        {type === "Slots" && (
          <div className="chat-slot-sec flex flex-col">
            {chatData.map((data) => {
              if (data.messageType === "activity") {
                return (
                  <ChatDataContainer
                    chatArray={data.data}
                    handleLoadMoreMessages={handleLoadMoreMessages}
                  />
                );
              } else {
                return (
                  <StackedMessagesContainer
                    menuSelectedDivRef={menuSelectedDivRef}
                    hoverStay={hoverStay}
                    findQuotedmessage={findQuotedmessage}
                    quoteMessageSearchIdRef={quoteMessageSearchIdRef}
                    handleLoadMoreMessages={handleLoadMoreMessages}
                    showScrollButton={showScrollButton}
                    unReadMessageRef={unReadMessageRef}
                    handleDelete={handleDelete}
                    actionModel={actionModel}
                    setActionModel={setActionModel}
                    unreadMessageIndex={unreadMessageIndex}
                    handleEmojiClick={handleEmojiClick}
                    handleReactionClick={handleReactionClick}
                    handleReactionUsersList={handleReactionUsersList}
                    showAllEmogis={showAllEmogis}
                    toggleShowEmojis={toggleShowEmojis}
                    initialEmojis={initialEmojis}
                    reactions={reactions}
                    setReactions={setReactions}
                    showEmojis={showEmojis}
                    showDropdown={showDropdown}
                    msgData={msgData}
                    handleSelectEmoji={handleSelectEmoji}
                    setShowDropdown={setShowDropdown}
                    setShowEmojis={setShowEmojis}
                    hoverEffects={hoverEffects}
                    setHoverEffects={setHoverEffects}
                    reactionMsg={reactionMsg}
                    setReactionMsg={setReactionMsg}
                    type={chatType}
                    isOutgoing={data.isOutgoing}
                    AvatarType={AvatarType}
                    Avtarsrc={Avtarsrc}
                    AvtarLetter={AvtarLetter}
                    AvtarIcon={AvtarIcon}
                    handleReplyClick={handleReplyClick}
                    handleDotsClick={handleDotsClick}
                    chatData={data.data}
                    onSelectOptions={onSelectOptions}
                    handlePreviewClick={handlePreviewClick}
                  />
                );
              }
            })}
          </div>
        )}
      </div>
      <div className="relative" id="REACTION_EMOJIS">
        {showEmojis && (
          <div
            className="chat-reaction-bubble absolute flex items-center"
            style={{
              top: modelPlace.y + "px",
              left: modelPlace.x + "px",
              position: "fixed",
            }}
          >
            {initialEmojis.map((emoji, index) => (
              <>
                <div
                  key={emoji}
                  className="reaction"
                  onClick={() => {
                    handleReactionClick(emoji);
                    menuSelectedDivRef.current = null;
                  }}
                >
                  <span key={index}>{emoji}</span>
                </div>
              </>
            ))}
            <span
              onClick={(e) => {
                setShowEmojis(false);
                setShowDropdown(true);
                let mainDivElement = document.getElementById(
                  "NEW_MAIN_CHAT_CONTAINER"
                );
                let x =
                  modelPlace.x + 350 > mainDivElement.clientWidth
                    ? modelPlace.x - 350
                    : modelPlace.x;
                let y =
                  modelPlace.y + 450 > mainDivElement.clientHeight
                    ? modelPlace.y - 450
                    : modelPlace.y;
                setModelPlace({
                  y: y,
                  x: x,
                });
              }}
            >
              <AddEmojiIcon
                width={32}
                height={32}
                iconColor="var(--color-icon-neutral-strong)"
              />
            </span>
          </div>
        )}
        {showDropdown && (
          <div
            id="SHOW_ALL_EMOJIS"
            style={{
              top: modelPlace.y + "px",
              left: modelPlace.x + "px",
              position: "fixed",
              zIndex: 2,
            }}
          >
            {showDropdown && (
              <EmojiPicker
                onEmojiClick={(e) => {
                  handleSelectEmoji(e);
                  menuSelectedDivRef = null;
                }}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

ChatContainer.propTypes = {
  unreadMessageIndex: PropTypes.string,
  type: PropTypes.string,
  chatType: PropTypes.string,
  isOutgoing: PropTypes.bool,
  AvatarType: PropTypes.string,
  Avtarsrc: PropTypes.string,
  AvtarLetter: PropTypes.string,
  AvtarIcon: PropTypes.string,
  actionModel: PropTypes.any,
  setActionModel: PropTypes.func,
  showAllEmogis: PropTypes.any,
  toggleShowEmojis: PropTypes.func,
  initialEmojis: PropTypes.any,
  reactions: PropTypes.any,
  setReactions: PropTypes.func,
  showEmojis: PropTypes.bool,
  setShowEmojis: PropTypes.func,
  reactionMsg: PropTypes.any,
  setReactionMsg: PropTypes.func,
  handleEmojiClick: PropTypes.func,
  handleReplyClick: PropTypes.func,
  handleDotsClick: PropTypes.func,
  showDropdown: PropTypes.any,
  handleSelectEmoji: PropTypes.func,
  setShowDropdown: PropTypes.func,
  handleReactionClick: PropTypes.func,
  chatData: PropTypes.array,
  onSelectOptions: PropTypes.func,
  handleReactionUsersList: PropTypes.func,
  hoverEffects: PropTypes.bool,
  setHoverEffects: PropTypes.func,
  scrolltopdata: PropTypes.any,
};

export default ChatContainer;
