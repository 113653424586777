import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import AdminSidebar from "../AdminSidebar";
import PageNotFound from "../PageNotFound";
import Banners from "./BannerManagement/Banners";
import News from "./NewsManagement/News";
import Posts from "./PostManagement/Posts";
import ContentListing from "./ContentManagement/ContentListing";

const NewsManagement = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const { action } = useParams();
    const [selectedDiv, setselectedDiv] = useState("");
    const videoInprocess = useSelector((state) => {
        return state.ReduVideoInProcess;
    })
    const [changePage, setchangePage] = useState("");
    const [showPopup, setshowPopup] = useState(false);
    const [tempVideoId, setTempVideoId] = useState("");
    const [pageNotFound, setPageNotFound] = useState(false);

    useEffect(() => {
        dispatch({ type: "HISTORYPAGE", payload: "/news/banners" })
        if (action !== undefined && action.length > 0) setselectedDiv(action);
        if (action !== undefined && action !== null) {
            if (action !== "posts" && action !== "banners" && action !== "news" && action !== "content")
            setPageNotFound(true)
        }else
        {
            setselectedDiv("banners");
        }
    }, [action]);


    return (
        <>
            {!pageNotFound && <div className="cstm-all-admin">
                <AdminSidebar />
                <div className="content-main">
                    <div className="users-info">
                        <div className="users-info-sidebar">
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <Link
                                            className={`btn-link collapsed ${selectedDiv === "banners" ? "sd-active" : ""} `}
                                            to={"/news/banners"}
                                        >
                                            Banners
                                        </Link>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <Link
                                            className={`btn-link collapsed ${selectedDiv === "posts" ? "sd-active" : ""} `}
                                            to={"/news/posts"}
                                        >
                                            Posts
                                        </Link>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <Link
                                            className={`btn-link collapsed ${selectedDiv === "news" ? "sd-active" : ""} `}
                                            to={"/news/news"}
                                        >
                                            News
                                        </Link>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <Link
                                            className={`btn-link collapsed ${selectedDiv === "content" ? "sd-active" : ""} `}
                                            to={"/news/content"}
                                        >
                                            Content
                                        </Link>
                                    </div>
                                </div>


                            </div>
                        </div>
                        {selectedDiv === "banners" ? (
                            <Banners />
                        ) : selectedDiv === "news" ? (
                            <News />
                        ) : selectedDiv === "posts" ? (
                            <Posts />
                        ) : selectedDiv === "content" ? (
                            <ContentListing />
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>}
            {pageNotFound && <PageNotFound />}
        </>
    );
};
export default NewsManagement;
