import imageCompression from "browser-image-compression";

export const CompressImageFunction = async (image) => {
  try {
    const imageSizeInMB = image.size / (1024 * 1024); // Convert image size to MB
    const targetSizeInMB = imageSizeInMB * 0.4; // Set target size to 40% of original size
    const options = {
      maxSizeMB: targetSizeInMB, // Set the maximum size dynamically
      useWebWorker: true, // Enable or disable web worker for better performance
    };
    const compressedBlob = await imageCompression(image, options);
    const extension = image?.type.split("/")[1];
    // Create a File object with a new filename
    const filename = `compressed_image_${image.name}.${extension}`; // specify the desired filename
    const compressedFile = new File([compressedBlob], filename, {
      type: compressedBlob.type,
    });
    return compressedFile;
  } catch (error) {
    console.error("Error compressing image:", error);
    return null; // Handle errors gracefully, you can adjust this as needed
  }
};
