import React, { useEffect, useState } from 'react'
import AdminSidebar from '../../AdminSidebar'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PartnerList from './MainPartnerManagement/PartnerList'
import CreatePartner from './MainPartnerManagement/CreatePartner'
import PageNotFound from '../../PageNotFound';
import PartnerDetail from './MainPartnerManagement/PartnerDetail';
import FeaturedPartner from './FeaturedPartnerManagement/FeaturedPartner';
import '../../../Styles/partnerManagement.css'
import FreshDeals from './FreshDealManagement/FreshDeals';
import AllReview from './ReviewManagement/AllReview';
import CategoryPartner from './CategoryPartnerManagement/CategoryPartner';
import Reason from './ReasonManagement/Reason';
import authServicePartner from '../../../Services/auth.service.partner';
import BadgeManagement from './BadgeManagement/BadgeManagement';
import MainContent from './MainContent/MainContent';
import PartnerCategory from './PartnerCategoryManagement/PartnerCategory';
import StatisticsManagement from './StatisticsManagement/StatisticsManagement';
const index = () => {

    const history = useNavigate();
    const dispatch = useDispatch();
    const { action } = useParams()

    const allReview = useSelector((state) => { return state.allPendingReview })
    const [selectedDiv, setselectedDiv] = useState("");
    const [changePage, setchangePage] = useState("");
    const [showPopup, setshowPopup] = useState(false);
    const [pageNotFound, setPageNotFound] = useState(false);
    const [partnerPopup, setPartnerPopup] = useState(false)
    const [pendingReview, setPendingReview] = useState(0)
    const [featureTitle, setFeatureTitle] = useState('');
    const [freshTitle, setFreshTitle] = useState('');
    const partnerLabels = useSelector((state) => { return state.ReduPartnerDynamicLabel })
    const videoInprocess = useSelector((state) => { return state.ReduVideoInProcess })
    // const PartnerTitle = useSelector((state) => { return state.allPartnerTitle })
    // console.log(PartnerTitle, "PartnerTitle")

    useEffect(() => {
        dispatch({ type: "HISTORYPAGE", payload: "/partner/list" })
        if (action !== undefined && action.length > 0) setselectedDiv(action);
        if (action !== undefined && action !== null) {
            if (!["list", "createpartner", "detail", "featuredlist", "freshdeals", "reason", "reviews", "badges", "maincontent", "partnercategories", "partnerstatistics"].includes(action)) {
                setPageNotFound(true)
            }
        } else {
            setselectedDiv("list");
        }
        authServicePartner.allReviewList("?filtertype=")
            .then((allReviewRes) => {
                const data = allReviewRes.data.data
                if (data) {
                    const pendingReview = data.filter((item) => item.status === "none")
                    dispatch({ type: 'allReview', payload: pendingReview.length })
                }
            })

    }, [action]);
    return (
        <>
            {!pageNotFound && <div className="cstm-all-admin">
                <AdminSidebar />
                <div className="content-main">
                    <div className="users-info">
                        <div className="users-info-sidebar">
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <Link to={"/partner/list?search="} className={`btn-link collapsed ${selectedDiv === "list" || selectedDiv === "createpartner" || selectedDiv === "detail" ? "sd-active" : ""}`}
                                                onClick={() => {
                                                    if (videoInprocess) {
                                                        setshowPopup(true);
                                                        setchangePage("/partner/list?search=");
                                                        dispatch({ type: "HISTORYPAGE", payload: "/partner/list?search=" })
                                                    } else {
                                                        // history("/partner/list?search=");
                                                        dispatch({ type: "HISTORYPAGE", payload: "/partner/list?search=" })
                                                    }


                                                }}
                                            >All partners</Link>
                                        </div>
                                        <div className="card-header" id="headingThree">
                                            <Link to={"/partner/featuredlist"} className={`btn-link collapsed ${selectedDiv === "featuredlist" ? "sd-active" : ""}`}
                                                onClick={() => {
                                                    if (videoInprocess) {
                                                        setshowPopup(true);
                                                        setchangePage("/partner/featuredlist");
                                                        dispatch({ type: "HISTORYPAGE", payload: "/partner/featuredlist" })
                                                    } else {
                                                        // history("/partner/featuredlist");
                                                        dispatch({ type: "HISTORYPAGE", payload: "/partner/featuredlist" })
                                                    }

                                                }}
                                            > {partnerLabels.featuredLabel}</Link>
                                        </div>
                                        <div className="card-header" id="headingThree">
                                            <Link to={'/partner/freshdeals'} className={`btn-link collapsed ${selectedDiv === "freshdeals" ? "sd-active" : ""}`}
                                                onClick={() => {
                                                    if (videoInprocess) {
                                                        setshowPopup(true);
                                                        setchangePage("/partner/freshdeals");
                                                        dispatch({ type: "HISTORYPAGE", payload: "/partner/freshdeals" })
                                                    } else {
                                                        // history("/partner/freshdeals");
                                                        dispatch({ type: "HISTORYPAGE", payload: "/partner/freshdeals" })
                                                    }

                                                }}
                                            >{partnerLabels.freshDealLabel}</Link>
                                        </div>
                                        <div className="card-header" id="headingThree">
                                            <Link to={'/partner/reason'} className={`btn-link collapsed ${selectedDiv === "reason" ? "sd-active" : ""}`}
                                                onClick={() => {
                                                    if (videoInprocess) {
                                                        setshowPopup(true);
                                                        setchangePage("/partner/reason");
                                                        dispatch({ type: "HISTORYPAGE", payload: "/partner/reason" })
                                                    } else {
                                                        // history("/partner/reason");
                                                        dispatch({ type: "HISTORYPAGE", payload: "/partner/reason" })
                                                    }

                                                }}
                                            >Marcros (Saved replies)</Link>
                                        </div>
                                        <div className="card-header" id="headingThree">
                                            <Link to={"/partner/reviews"} className={`btn-link collapsed ${selectedDiv === "reviews" ? "sd-active" : ""}`}
                                                onClick={() => {
                                                    if (videoInprocess) {
                                                        setshowPopup(true);
                                                        setchangePage("/partner/reviews");
                                                        dispatch({ type: "HISTORYPAGE", payload: "/partner/reviews" })
                                                    } else {
                                                        // history("/partner/reviews");
                                                        dispatch({ type: "HISTORYPAGE", payload: "/partner/reviews" })
                                                    }

                                                }}
                                            >Reviews {allReview > 0 && <span className='review-count'>{allReview}</span>}</Link>
                                        </div>
                                        <div className="card-header" id="headingThree">
                                            <Link to={"/partner/badges"} className={`btn-link collapsed ${selectedDiv === "badges" ? "sd-active" : ""}`}
                                                onClick={() => {

                                                    if (videoInprocess) {
                                                        setshowPopup(true);
                                                        setchangePage("/partner/badges");
                                                        dispatch({ type: "HISTORYPAGE", payload: "/partner/badges" })
                                                    } else {
                                                        // history("/partner/badges");
                                                        dispatch({ type: "HISTORYPAGE", payload: "/partner/badges" })
                                                    }

                                                }}
                                            >Types</Link>
                                        </div>
                                        <div className="card-header" id="headingThree">
                                            <Link to={"/partner/maincontent"} className={`btn-link collapsed ${selectedDiv === "maincontent" ? "sd-active" : ""}`}
                                                onClick={() => {

                                                    if (videoInprocess) {
                                                        setshowPopup(true);
                                                        setchangePage("/partner/maincontent");
                                                        dispatch({ type: "HISTORYPAGE", payload: "/partner/maincontent" })
                                                    } else {
                                                        // history("/partner/maincontent");
                                                        dispatch({ type: "HISTORYPAGE", payload: "/partner/maincontent" })
                                                    }

                                                }}
                                            >Banner</Link>
                                        </div>
                                        <div className="card-header" id="headingThree">
                                            <Link to={"/partner/partnercategories"} className={`btn-link collapsed ${selectedDiv === "partnercategories" ? "sd-active" : ""}`}
                                                onClick={() => {
                                                    if (videoInprocess) {
                                                        setshowPopup(true);
                                                        setchangePage("/partner/partnercategories");
                                                        dispatch({ type: "HISTORYPAGE", payload: "/partner/partnercategories" })
                                                    } else {
                                                        // history("/partner/partnercategories");
                                                        dispatch({ type: "HISTORYPAGE", payload: "/partner/partnercategories" })
                                                    }


                                                }}
                                            >Categories</Link>
                                        </div>
                                        <div className="card-header" id="headingThree">
                                            <Link to={"/partner/partnerstatistics"} className={`btn-link collapsed ${selectedDiv === "partnerstatistics" ? "sd-active" : ""}`}
                                                onClick={() => {
                                                    if (videoInprocess) {
                                                        setshowPopup(true);
                                                        setchangePage("/partner/partnerstatistics");
                                                        dispatch({ type: "HISTORYPAGE", payload: "/partner/partnerstatistics" })
                                                    } else {
                                                        // history("/partner/partnerstatistics");
                                                        dispatch({ type: "HISTORYPAGE", payload: "/partner/partnerstatistics" })
                                                    }


                                                }}
                                            >Statistics</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {selectedDiv === "list" ? (
                            <PartnerList />
                        ) : selectedDiv === "createpartner" ? (
                            <CreatePartner />
                        ) : selectedDiv === "detail" ? (
                            <PartnerDetail />
                        ) : selectedDiv === "featuredlist" ? (
                            <FeaturedPartner />
                        ) : selectedDiv === "freshdeals" ? (
                            <FreshDeals />
                        ) : selectedDiv === "reason" ? (
                            <Reason />
                        ) : selectedDiv === "reviews" ? (
                            <AllReview />
                        ) : selectedDiv === "badges" ? (
                            <BadgeManagement />
                        ) : selectedDiv === "maincontent" ? (
                            <MainContent />
                        ) : selectedDiv === "partnercategories" ? (
                            <PartnerCategory />
                        ) : selectedDiv === 'partnerstatistics' ? (
                            <StatisticsManagement />
                        ) : <></>}
                    </div>
                </div>
                {/* {partnerPopup &&
                    <div className="desk-globle-model-box globle-model-box ">
                        <div className="desk-globle-model-box-inner cstm-are-you">
                            <div className="cstm-deactivate-account-main">
                                <div class="cstm-deactivate-account-main-title">
                                    Are you sure you want to leave the page? <br />In order to save your changes click on <strong>Save as draft</strong> .
                                </div>

                                <div className="ays-cancel-btn">
                                    <button
                                    // onClick={handleSaveDraft}
                                    >
                                        Save as draft
                                    </button>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setPartnerPopup(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                } */}
                {showPopup && (
                    <div className="desk-globle-model-box globle-model-box ">
                        <div className="desk-globle-model-box-inner cstm-deactivate-account-only">

                            <div className="cstm-deactivate-account-main">
                                <div class="cstm-deactivate-account-main-title">
                                    Video upload is still in progress, Are you sure want to leave?
                                </div>

                                <div className="ays-cancel-btn">
                                    <button onClick={(e) => {
                                        e.preventDefault();
                                        dispatch({ type: "REMOVEINPROCESS" });
                                        history(changePage);
                                        setshowPopup(false);
                                    }}>Yes</button><button onClick={(e) => {
                                        e.preventDefault();
                                        setshowPopup(false);
                                    }}>Cancel</button>
                                </div>

                            </div>

                        </div>{" "}
                    </div>
                )}
            </div>}
            {pageNotFound && <PageNotFound />}
        </>
    )
}

export default index