import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import AdminSidebar from "../AdminSidebar";
import PageNotFound from "../PageNotFound";
import Channel from "./ChannelManagement/Channels";
import CreateChannel from "./CreateChannelManagement/CreateChannel";
import EditChannel from "./EditChannelManagement/EditChannel";

const ChannelManagement = () => {
    const history = useNavigate();
    const { action } = useParams();
    const [selectedDiv, setselectedDiv] = useState("");
    const [pageNotFound, setPageNotFound] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: "HISTORYPAGE", payload: "/channel/channelListing" })
        if (action !== undefined && action.length > 0) setselectedDiv(action);
        if (action !== undefined && action !== null) {
        if (!["channelListing", "createChannel", "detail"].includes(action))
            setPageNotFound(true)
        }else{
            setselectedDiv("channelListing");
        }
    }, [action]);

    return (
        <>
            {!pageNotFound && <div className="cstm-all-admin">
                <AdminSidebar />
                <div className="content-main">
                    <div className="users-info">
                        <div className="users-info-sidebar">
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <Link
                                            className={`btn-link collapsed ${selectedDiv === "createChannel" ? "sd-active" : ""} `}
                                            to={"/channel/createChannel"}
                                        >
                                            Add channel
                                        </Link>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <Link
                                            to={'/channel/channelListing'}
                                            className={`btn-link collapsed ${selectedDiv === "channelListing" ? "sd-active" : ""} `}
                                        >
                                            All channels
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {selectedDiv === "channelListing" ? (
                            <Channel />
                        ) : selectedDiv === "createChannel" ? (
                            <CreateChannel />
                        ) : selectedDiv === "detail" ? (
                            <EditChannel />
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>}
            {pageNotFound && <PageNotFound />}
        </>
    );
};
export default ChannelManagement;


// console.log("--allRegisterdUserList--->",allRegisterdUserList)
// console.log("--allParticipantsEmailArr--->",allParticipantsEmailArr)



  // allParticipantsEmailArr --> all participent
  // allRegisterdUserList --> registerlist