import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import Cropper from "cropperjs";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Loader from "../../Images/loader.gif";
import AlertComponent from "../../Hooks/AlertComponent";
import UserInfoSideBar from "./UserInfoSideBar";
import { useGetCancelledUsersQuery } from "../../Services/users";
import loading from "../../Images/loader.gif";
import moment from "moment";

const columns = [
  { id: "displayName", label: "Name" },
  { id: "firstName", label: "First name" },
  { id: "lastName", label: "Last name" },
  { id: "email", label: "Email" },
  

];

function createData(displayName, firstName,lastName, email, action) {
  return {
    displayName, firstName,lastName, email, action
  };
}
const CancelledUsers = () => {

  const [users, setUsers] = useState([]);
  const [rows, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [modalOpen, setModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [sortType, setSortType] = useState("Asc")
  const [sortField, setSortField] = useState("")
  const [viewType, setViewType] = useState("added")
  const [usersList, setUsersList] = useState([])
  const [rowId, setRowId] = useState("")
  const [totalRows, setTotalRows] = useState(0)


  //get imported user list
  const { data: cancelledUsersList, isFetching: dataLoader } =
    useGetCancelledUsersQuery({ page: (page + 1), limit: rowsPerPage, sortField: sortField, sortType: sortType })

  useEffect(() => {
    if (cancelledUsersList) {
      if (cancelledUsersList?.status && cancelledUsersList?.data) {
        const data = cancelledUsersList.data.userList
        if (data.length > 0) {
          var newarr = [];
          for (var i = 0; i < data.length; i++) {
            newarr[i] = createData(
              data[i].display_name,
              data[i].first_name ,
              data[i].last_name,
              data[i]["Preferred Email"],
              data[i]._id
            );
          }
          setTotalRows( cancelledUsersList.data.totalMessages )
          setRow(newarr)
          setUsersList(data)
        }
      }

    }
  }, [cancelledUsersList])

  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });

  const stayOnPage = useSelector((state) => {
    return state.ReduStayOnPage;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <div className="users-info">
        <UserInfoSideBar />
        <div className="users-info-content">
          <div className="users-info-content-main">

            <div className="uic-title">
              <h1>All cancelled users</h1>
            </div>
       
            {showAlert && <div className="alert alert-info">{alertMsg}</div>}

            <Paper className="uic-tb-mn">
              <TableContainer className="uic-table-main">
                <Table className="uic-table">
                  <TableHead className="uic-table-th">
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={
                            column.id === "action"
                              ? "uic-th-td w-8"
                              : "uic-th-td w-10"
                          }
                        >
                          {" "}
                          {(column.id === "displayName" || column.id === "firstName" || column.id === "lastName" || column.id === "email")    ? (
                            <>
                              <span className="custom-sorting">
                                <i
                                  className="fa fa-angle-down"
                                  onClick={(e) => {
                                    setSortType("Asc")
                                    setSortField(column.id)
                                  }}
                                ></i>
                                <i
                                  className="fa fa-angle-up"
                                  onClick={(e) => {
                                    setSortType("Desc")
                                    setSortField(column.id)
                                  }}
                                ></i>
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className="uic-table-td">
                    {dataLoader ? (
                      <div className="cstm-data-load">
                        <img src={Loader} />{" "}
                      </div>
                    ) : (
                      rows && 
                        rows.map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                              className="uic-th-td w-20"
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                return  (
                                  <TableCell
                                    key={column.id}
                                    className="uic-th-td w-10"
                                  >

                                    {value}
                                  </TableCell>
                                ) 
                              })}
                            </TableRow>
                          );
                        })
                    )}
                  </TableBody>
                </Table>
                {!dataLoader && rows.length === 0 ? (
                  <div className="no-data-found">
                    <p>No Data Found </p>
                  </div>
                ) : (
                  <></>
                )}{" "}
              </TableContainer>
              <TablePagination
                className="uic-pagination"
                rowsPerPageOptions={[20, 50, 100, 200]}
                component="div"
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </div>

      </div>
      <AlertComponent />
    </>
  );
};
export default CancelledUsers;
