import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import DeleteModalWithLoader from "./DeleteModalWithLoader";
import Services from "../../Services/auth.service.event";
import ImageCropperReact from "../ImageCropper/ImageCropperReact";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

const ProfileSelector = ({
  images,
  setImages,
  profileImg,
  setProfileImg,
  setIconCropedFileData,
}) => {
  const dispatch = useDispatch();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletionMessage, setDeletionMessage] = useState("");
  const [imgToDelete, setImgToDelete] = useState("");
  const [loader, setLoader] = useState(false);
  const [warningText, setWarningText] = useState(false);
  const [allIconData, setAllIconData] = useState([]);

  const closeBtn = useRef(null);

  //  image cropping state
  const [modalOpen, setModalOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [thumbnail, setThumbnail] = useState([]);
  const [fileDimension, setFileDimension] = useState();

  useEffect(() => {
    setWarningText(false);
  }, []);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file && file.type.startsWith("image/")) {
      thumbnailImages(file);
      const reader = new FileReader();
      reader.onload = () => setThumbnail(reader.result);
      reader.readAsDataURL(file);
      setWarningText(false);
    } else {
      setWarningText(true);
    }
  };
  // thumbnail image upload function
  const thumbnailImages = async (acceptedfile) => {
    setFileDimension(acceptedfile);
    dispatch({ type: "SETMODALINPROCESS" });
    setFileName(Math.random().toString() + acceptedfile.name);
    setModalOpen(true);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/png, image/gif, image/jpeg, image/jpg",
    maxSizeBytes: 10485760,
    onDrop,
  });

  //delete icon api call
  const handleDelete = () => {
    if (isBase64Image(imgToDelete)) {
      const deletedArr = images?.filter((imgs) => imgs !== imgToDelete);
      setImages(deletedArr);
      setImgToDelete("");
      setShowDeleteModal(false);
    } else {
      setLoader(true);
      Services.deleteActivityIcon({ icon: imgToDelete })
        .then((res) => {
          if (res.data.status) {
            if (profileImg === imgToDelete) setProfileImg("");
            setImages(
              images.filter((img) => {
                return img !== imgToDelete;
              })
            );
          } else {
            const iconData = res.data.data;
            setAllIconData(iconData);
            let msg = `${res.data.message}<br/>`;
            if (iconData && iconData.length > 0) {
              msg += `Icon is assigned to below events and its activity:<br/><br/>`;
            }
            setDeletionMessage(msg);
            setImgToDelete("");
          }
          setLoader(false);
          setShowDeleteModal(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  //handle close modal for delete
  const handleClose = () => {
    setShowDeleteModal(false);
    setImgToDelete("");
  };

  //called when we click on image and it direcly set to profile image
  const onSelectImg = (e, img) => {
    e.preventDefault();
    setProfileImg(img);
    closeBtn.current.click();
  };
  const isBase64Image = (image) => {
    return image.startsWith("data:image");
  };
  return (
    <div
      className="modal fade profile-selector-modal"
      id="profileSelector"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      {showDeleteModal && (
        <DeleteModalWithLoader
          message={"Do you want to delete this icon?"}
          handleDelete={handleDelete}
          onClose={handleClose}
          loader={loader}
        />
      )}
      {modalOpen && (
        <ImageCropperReact
          file={thumbnail}
          setModalOpen={setModalOpen}
          setcropedFile={(imageFile) => {
            setProfileImg(imageFile);
            setImages([imageFile, ...images]);
            setWarningText(false);
            closeBtn.current.click();
          }}
          setFile={setThumbnail}
          fileName={fileName}
          setcropedFileData={setIconCropedFileData}
          fileDimension={fileDimension}
          requiredImageAspectRatio={1 / 1}
        />
      )}

      {deletionMessage && (
        <div
          className="modal fade msg-popup delete-modal-with-loader show"
          id={"deletionMessage"}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="example"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="close"
                onClick={() => setDeletionMessage("")}
              >
                &times;
              </button>
              <span
                className="response-text text-start"
                dangerouslySetInnerHTML={{ __html: deletionMessage }}
              ></span>
              {allIconData.length > 0 &&
                allIconData.map((item) => (
                  <Link
                    to={`/events/detail?id=${item?.event._id}&activityId=${item?._id}`}
                    onClick={() => {
                      $("#profileSelector").modal("hide");
                      setDeletionMessage("");
                    }}
                  >
                    <span className="response-text text-start">
                      -{item.event.title} ({item.name})
                    </span>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      )}
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <button
            type="button"
            className="close"
            id="close-modal"
            data-dismiss="modal"
            aria-label="Close"
            ref={closeBtn}
          >
            &times;
          </button>

          <div className="cstm-profile-selector-top">
            <h1>Select icon</h1>
            <div>
              <button {...getRootProps()} className="btn">
                <input {...getInputProps()} />
                Upload new icon
              </button>
              <p style={{ color: warningText ? "#ff0404" : "" }}>Recommended size 100 x 100</p>
            </div>
          </div>

          <div className="cstm-profile-selector-all">
            {images.map((img, i) => {
              return (
                <label className="cstm-profile-selector">
                  <img
                    src={
                      typeof img === "object" ? URL.createObjectURL(img) : img
                    }
                    alt=""
                    onClick={(e) => {
                      onSelectImg(e, img);
                    }}
                  />
                  {typeof img !== "object" && (
                    <button
                      className="delete-modal-with-loader-close-icon"
                      onClick={() => {
                        setShowDeleteModal(true);
                        setImgToDelete(img);
                      }}
                    >
                      &times;
                    </button>
                  )}
                </label>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSelector;
