import React, { useEffect, useState } from 'react'
import AdminSidebar from '../AdminSidebar'
import { Link, useNavigate, useParams } from 'react-router-dom';
import PlanListing from './PlanManagement/PlanListing';
import CreatePlan from './PlanManagement/CreatePlan';
import EditPlan from './PlanManagement/EditPlan'
import Invite from './CollaboratorInvite/Invite';
import InviteList from './CollaboratorInvite/InviteList';
const index = () => {

    const { action } = useParams()
    const navigate = useNavigate()
    const [currentDiv, setCurrentDiv] = useState("");

    useEffect(() => {
        if (action !== undefined && action !== null) {
            if (action.length > 0 )
            {
                setCurrentDiv(action);
            }
            
        }else
        {
            setCurrentDiv("list");
        }

    }, [action])
    return (
        <>
            <div className="cstm-all-admin">
                <AdminSidebar />
                <div className="content-main">
                    <div className="users-info">
                        <div className="users-info-sidebar">
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <Link
                                            className={`btn-link collapsed ${currentDiv === "list" || currentDiv === 'detail' || currentDiv === "create" ? "sd-active" : ""}`}
                                            to={'/membership/list'}
                                        >
                                            Membership listing
                                        </Link>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <Link
                                            className={`btn-link collapsed ${currentDiv === "invitelist" || currentDiv === 'invite' ? "sd-active" : ""}`}
                                            to={'/membership/invitelist'}
                                        >
                                            Team-Users invitees
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {currentDiv === "list" ? (
                            <PlanListing />
                        ) : currentDiv === 'create' ? (
                            <CreatePlan />
                        ) : currentDiv === 'detail' ? (
                            <EditPlan />
                        ) : currentDiv === 'invitelist' ? (
                            <InviteList />
                        ) : currentDiv === 'invite' ? (
                            <Invite />
                        ) : <></>
                        }
                    </div>
                </div>
            </div >
        </>
    )
}

export default index