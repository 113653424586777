import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AdminSidebar from "../AdminSidebar";
import VideoStatistic from "../Statistic/VideoStatistic";
import CategoryStatistic from "./CategoryStatistic";
import TagsStatistic from "./TagsStatistic";
import SpeakerStatistic from "./SpeakerStatistic";
import EventStatistic from "./EventStatistic";
import PageNotFound from "../PageNotFound";
import UserStatistic from "./UserStatistic";
const Statistic = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { action } = useParams();
  const [selectedDiv, setselectedDiv] = useState("");
  const [selectedVideoId, setselectedVideoId] = useState("");
  const videoInprocess = useSelector((state) => {
    return state.ReduVideoInProcess;
  })
  const [changePage, setchangePage] = useState("");
  const [showPopup, setshowPopup] = useState(false);
  const [pageNotFound, setPageNotFound] = useState(false);
  useEffect(() => {
    if (action !== undefined && action.length > 0) setselectedDiv(action);
    if (action !== undefined && action !== null) {
      if (action !== "videostatistic" && action !== "categorystatistic" && action !== "speakerstatistic" && action !== "tagstatistic"
        && action !== "eventstatistic" && action !== "userstatistic") {
        setPageNotFound(true)
      } else {
        setPageNotFound(false)
      }
    }else
    {
      setselectedDiv("videostatistic");
    }

  }, [action]);
  return (
    <>
      {!pageNotFound && <div className="cstm-all-admin">
        <AdminSidebar />
        <div className="content-main">
          <div className="users-info">
            <div className="users-info-sidebar">
              <div className="accordion" id="accordionExample">
                <h3 className="cstm-video-statistic-title">Statistics</h3>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <Link
                      to={"/statistic/videostatistic"}
                      className={`btn-link collapsed ${selectedDiv === "videostatistic" ? "sd-active" : ""} `}
                    >
                      Videos
                    </Link>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <Link
                      to={"/statistic/categorystatistic"}
                      className={`btn-link collapsed ${selectedDiv === "categorystatistic" ? "sd-active" : ""} `}
                    >
                      Categories
                    </Link>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <Link
                      className={`btn-link collapsed ${selectedDiv === "speakerstatistic" ? "sd-active" : ""} `}
                      to={"/statistic/speakerstatistic"}
                    >
                      Speakers
                    </Link>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <Link
                      to={"/statistic/tagstatistic"}
                      className={`btn-link collapsed ${selectedDiv === "tagstatistic" ? "sd-active" : ""} `}
                    >
                      Tags
                    </Link>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <Link
                      className={`btn-link collapsed ${selectedDiv === "eventstatistic" ? "sd-active" : ""} `}
                      to={"/statistic/eventstatistic"}
                    >
                      Events
                    </Link>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <Link
                      className={`btn-link collapsed ${selectedDiv === "userstatistic" ? "sd-active" : ""} `}
                      to={"/statistic/userstatistic"}
                    >
                      User statistics
                    </Link>
                  </div>
                </div>

              </div>
            </div>
            {selectedDiv === "videostatistic" ? (
              <VideoStatistic />
            ) : selectedDiv === "categorystatistic" ? (
              <CategoryStatistic />
            ) : selectedDiv === "tagstatistic" ? (
              <TagsStatistic />
            ) : selectedDiv === "speakerstatistic" ? (
              <SpeakerStatistic />
            ) : selectedDiv === "eventstatistic" ? (
              <EventStatistic />
            ) : selectedDiv === "userstatistic" ? (
              <UserStatistic />
            ) : (
              <></>
            )}


          </div>
        </div>
        {showPopup && (
          <div className="desk-globle-model-box globle-model-box ">
            <div className="desk-globle-model-box-inner cstm-deactivate-account-only">

              <div className="cstm-deactivate-account-main">
                <div class="cstm-deactivate-account-main-title">
                  Video upload is still in progress, Are you sure want to leave?
                </div>

                <div className="ays-cancel-btn">
                  <button onClick={(e) => {
                    e.preventDefault();
                    dispatch({ type: "REMOVEINPROCESS" });
                    history(changePage);
                    setshowPopup(false);
                  }}>Yes</button><button onClick={(e) => {
                    e.preventDefault();
                    setshowPopup(false);
                  }}>Cancel</button>
                </div>

              </div>

            </div>{" "}
          </div>
        )}
      </div>}
      {pageNotFound && <><PageNotFound /> </>}
    </>
  );
};
export default Statistic;
