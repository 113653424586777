import React, { useEffect } from 'react'
import Header from '../../components/UserComponents/Dashboard/Header'
import LeftSidebar from '../../components/UserComponents/Dashboard/LeftSidebar'
import DocumentMainPage from './DocumentMainPage'
import "./Components/Document.css"

const index = () => {
  useEffect(() => { sessionStorage.setItem("previousurl", window.location.href) }, [])
  const cleanedPathname = window.location.pathname.replace(/\/$/, "");
  return (
    <>
      <Header />
      <div className="all-content-main-section">
        <LeftSidebar />
        <div className='all-content-main-section-in'>
          <div className='doc-management-main'>
            <DocumentMainPage />
          </div>
        </div>
      </div>
    </>
  )
}

export default index