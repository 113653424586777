import React, { useEffect, useRef, useState } from "react";
import {
  Navigate,
  useNavigate,
  Location,
  NavigationType,
  useLocation,
  useNavigationType,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import Frame from "../../../../Images/Frame.svg";
import { ContentState, EditorState, Modifier, convertToRaw } from "draft-js";
import authServicePartner from "../../../../Services/auth.service.partner";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "@tinymce/tinymce-react";
import authService from "../../../../Services/auth.service";
import Services from "../../../../Services/auth.service";
import Loader2 from "../../../../Images/loader2.gif";
import { stateToHTML } from "draft-js-export-html";
import authServiceEvent from "../../../../Services/auth.service.event";
import draftToHtml from "draftjs-to-html";
import Select from "react-select";
import Loader from "../../../../Images/loader.gif";
import SaveDraftModal from "../PartnerModals/SaveDraftModal";
import undo from "../../../../Images/undo.svg";
import TinyEditor from "../../../AdminCommonComponent/TinyEditor";
import AlertComponent from "../../../../Hooks/AlertComponent";
import Multiselect from "multiselect-react-dropdown";
import { useUserCountMutation } from "../../../../Services/ruleUserCount";
import AddRules from "../../../CommonRules/AddRules";
import ImageCropperReact from "../../../ImageCropper/ImageCropperReact";
import { CompressImageFunction } from "../../../AdminNotification/CommonFunction";

const EditPartnerInfo = () => {
  function createData(
    plan_name,
    plan_price,
    automatic_renewal,
    plan_status,
    recurring_timeframe,
    total_member_who_purchased_plan,
    _id
  ) {
    return {
      plan_name,
      plan_price,
      automatic_renewal,
      plan_status,
      recurring_timeframe,
      total_member_who_purchased_plan,
      _id,
    };
  }

  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const descriptionRef = useRef(null);
  const offerdescriptionRef = useRef(null);
  const claimdescriptionRef = useRef(null);
  const location = useLocation();
  const navType = useNavigationType();
  const [searchParams, setSearchParams] = useSearchParams();

  //redux states
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner);
  const NAVIGATEPAGE = useSelector((state) => {
    return state.partnerNavigate;
  });
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });

  const id = searchParams.get("id");
  const [partnerDetail, setPartnerDetail] = useState({
    companyName: "",
    contactName: "",
    PhoneNumber: "",
    email: "",
    website: "",
    facebook: "",
    linkedin: "",
    description: "",
    shortDescription: "",
    mdsPartner: false,
    partnerType: {
      value: "",
      label: "",
    },
    type: "",
    category: [],
    tag: [],
    offer_desc: "",
    offer_instru: "",
    value: "",
    status: "draft",
    logo: "",
    mobileBanner: "",
    webBanner: "",
    thumbnail: "",
    darkCompanyLogo: "",
    accessType: "",
    userAccessIds: "",
  });

  const [partnerDetailErr, setPartnerDetailErr] = useState({
    logo: "",
    companyName: "",
    contactName: "",
    description: "",
    offer_desc: "",
    offer_instru: "",
    mdsPartner: "",
    category: "",
    shortDescription: "",
    partnerType: "",
    type: "",
    status: "",
    mobileBanner: "",
    thumbnail: "",
    webBanner: "",
    value: "",
    darkCompanyLogo: "",
    accessType: "",
    userAccessIds: "",
  });
  const [logo, setLogo] = useState("");
  const [darkCompanyLogo, setDarkCompanyLogo] = useState("");
  const [thumbnail, setThumbnail] = useState([]);
  const [webBanner, setWebBanner] = useState([]);
  const [mobileBanner, setMobileBanner] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [inProcess, setInProcess] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [dataLoader, setDataLoader] = useState(true);
  const [subCategories, setSubCategories] = useState([]);
  const [saveInProcess, setSaveInProcess] = useState(false);
  const [badgeList, setBadgeList] = useState([]);
  const [createInprocess, setcreateInprocess] = useState(false);
  const [showalertAdd, setshowalertAdd] = useState(false);
  const [alertmsgAdd, setalertmsgAdd] = useState("");
  const [tagList, setTagList] = useState([]);
  const [addtag, setaddtag] = useState("");
  const [adderr, setadderr] = useState("");
  const [thumbnailHeight, setThumbnalHeight] = useState(0);
  const [thumbnailWidth, setThumbnalWidth] = useState(0);
  const [membership, setMembership] = useState([]);
  const [selecteduser, setSelectedUser] = useState([]);
  const [showOtherRules, setShowOtherRules] = useState(true);
  const [allGrp, setAllGrp] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [row, setrow] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [allUser, setAllUser] = useState([]);
  const [searchUsers, setSearchUsers] = useState("");
  const [totalUsers, setTotalUsers] = useState(0);
  const [isModal, setModal] = useState(false);
  const [ruleError, setRuleError] = useState(false);
  const [noRuleFieldSelected, setNoRuleFieldSelected] = useState(false);
  const [totalUserList, setTotalUserList] = useState([]);
  const [resetRulesFields, setResetRulesFields] = useState(false);
  const [dataFromRules, setDataFromRules] = useState([]);
  const [rulesDataForEdit, setRulesDataForEdit] = useState({});

  //  image cropping state
  const [thumbnailFile, setThumbnailFile] = useState([]);
  const [fileDimension, setFileDimension] = useState();
  const [fileName, setFileName] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [selectImageType, setSelectImageType] = useState("");
  const [parntnerImageFileData, setParntnerImageFileData] = useState({
    logoFileData: "",
    darkCompanyLogoFileData: "",
    thumbnailFileData: "",
    webBannerFileData: "",
    mobileBannerFileData: ""
  })
  const [requiredImageSize, setRequiredImageSize] = useState({
    logoForLightThemeWidth: 564,
    logoForLightThemeHeight: 216,
    logoForDarkThemeWidth: 564,
    logoForDarkThemeHeight: 216,
    thumbnailImageWidth: 1080,
    thumbnailImageHeight: 608,
    webBannerWidth: 2976,
    webBannerHeight: 576,
    mobileBannerWidth: 780,
    mobileBannerHeight: 200
  });
  const [requiredImageAspectRatio, setRequiredImageAspectRatio] = useState(16 / 9);

  const multiSelectRef = useRef();
  const latestRequest = useRef(0);

  //User count mutation
  const [userCountApi] = useUserCountMutation();

  const countUserApi = async (bodyData, requestId) => {
    try {
      const countRes = await userCountApi(bodyData);
      if (latestRequest.current === requestId) {
        setTotalUserList(countRes?.data?.data);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    const bodyData = {
      restrictedAccessUserId: dataFromRules.user_id,
      restrictedAccessMembershipPlanId: dataFromRules.membership_plan_id,
      restrictedAccessGroupId: dataFromRules.group_id,
      restrictedAccessTagId: dataFromRules.tags_id,
      restrictionAccess:
        dataFromRules.userAccess === "all" ? "public" : "restricted",
    };
    const requestId = ++latestRequest.current; // Increment the request ID
    countUserApi(bodyData, requestId);
  }, [dataFromRules]);

  //email regex
  const emailRegex = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  //weburl regex
  const webRegex = new RegExp(/^https?:\/\/.+/i);
  //facebook regex
  const facebookRegex = new RegExp(
    /^(https?:\/\/){0,1}(www\.){0,1}facebook\.com/
  );
  //linkedin regex
  const linkedinRegex = new RegExp(
    /^(https?:\/\/){0,1}(www\.){0,1}linkedin\.com/
  );

  // useEffect(() => {
  //   window.addEventListener("beforeunload", alertUser);
  //   window.history.pushState(null, null, window.location.href);
  //   window.onpopstate = function () {
  //     if (modalinprocess) {
  //       dispatch({ type: "PAGECHANGE", payload: true })
  //     }
  //   };
  //   return () => {
  //     window.removeEventListener("beforeunload", alertUser);
  //   };
  // }, [modalinprocess]);

  //alert user when user we leave or reload page
  // const alertUser = (e) => {
  //   if (modalInProcess) {
  //     e.preventDefault();
  //     e.returnValue = ""
  //     dispatch({ type: "PAGECHANGE", payload: true })
  //     return "";
  //   } else {
  //     window.location.reload(true);
  //   }
  // };
  // handle upload image in editor
  const uploadImageCallback = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve({ data: { link: reader.result } });
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  //editor toolbar common function
  const editorToolbar = {
    image: {
      uploadCallback: uploadImageCallback,
      previewImage: true,
      alt: { present: true, mandatory: false },
      inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
    },
    options: [
      "inline",
      "image",
      "blockType",
      "fontSize",
      "list",
      "link",
      "textAlign",
      "history",
    ],
    inline: { options: ["bold", "italic", "underline", "strikethrough"] },
  };

  //html to editor state
  function htmlToEditor(html) {
    const contentBlock = htmlToDraft(html.replace("<p>&nbsp;</p>", ""));
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }

  useEffect(() => {
    handleClearAllStates();
    setDataLoader(true);
    dispatch({ type: "PAGECHANGE", payload: false });
    categoryListApi();
    partnerDetailApi();
    badgeListApi();
  }, []);

  //called when page loads
  useEffect(() => {
    getAllPlanApi(1, 100, searchUsers);
    handleClearAllStates();
    // getDetailApi();
  }, []);

  // get all plan list api function
  async function getAllPlanApi(page, rowsPerPage, searchText, col, sort) {
    let query = `?page=${page}&limit=${rowsPerPage}&search=${searchText}`;
    query +=
      col !== undefined && col !== null && col !== ""
        ? `&sortField=${col}`
        : "";
    query +=
      sort !== undefined && sort !== null && sort !== ""
        ? `&sortType=${sort}`
        : "";
    try {
      const [getPlanRes, allUserRes, groupListRes] = await Promise.all([
        Services.getAllPlan(query),
        Services.getallusers(""),
        Services.getallgroup(),
      ]);
      if (getPlanRes.data.status) {
        const data = getPlanRes.data.data.plans;
        var arr = [];
        for (var i = 0; i < data.length; i++) {
          arr[i] = createData(
            data[i].plan_name,
            "$ " + data[i].plan_price.toLocaleString(),
            data[i].automatic_renewal,
            data[i].plan_status,
            data[i].recurring_timeframe,
            data[i].total_member_who_purchased_plan.length,
            data[i]._id
          );
        }
        setrow(arr);
      }
      if (groupListRes.data.status) {
        if (groupListRes.data.data.length > 0) {
          setAllGrp(groupListRes.data.data);
        }
      }
      if (allUserRes.status) {
        let userList = allUserRes.data;
        let arr = [];
        if (userList !== undefined && userList.length > 0) {
          userList
            .map((user) => {
              const firstName = user.first_name;
              const lastName = user.last_name;
              return { ...user, firstName, lastName };
            })
            .filter((user) => user.display_name !== null)
            .map((user) =>
              arr.push({
                value: user._id,
                label: `${user.display_name} (${user["Preferred Email"]})`,
              })
            );
          setAllUser(arr);
        }
      } else {
        setAllUser([]);
      }
    } catch (error) { }
  }

  //check description validation
  const checkDesciptionValue = (state) => {
    var text = descriptionRef.current.getContent();
    if (text.length > 0 && text !== "") {
      return true;
    } else {
      return false;
    }
  };

  const checkOfferDesc = () => {
    var text = offerdescriptionRef.current.getContent();
    if (text.length > 0 && text !== "") {
      return true;
    } else {
      return false;
    }
  };

  const checkOfferInstruc = () => {
    var text = claimdescriptionRef.current.getContent();
    if (text.length > 0 && text !== "") {
      return true;
    } else {
      return false;
    }
  };

  //catgorylist api call
  async function categoryListApi() {
    try {
      const [categoryRes, tagRes] = await Promise.all([
        authServicePartner.categoryList(""),
        Services.getalltags_byadmin(""),
      ]);
      if (categoryRes.status) {
        var arr = categoryRes.data.data;
        setCategoryData(arr);
      }
      if (tagRes.data.status) {
        setTagList(tagRes.data.data);
      }
    } catch (error) {
      return false;
    }
  }

  //partner detail api call
  async function partnerDetailApi() {
    try {
      const partnerDetailRes = await authServicePartner.getPartnerDetail(id);
      console.log("edit rules >>", partnerDetailRes);
      if (partnerDetailRes.status) {
        dispatch({
          type: "isMDSPartner",
          payload: partnerDetailRes.data.partnerDetail.isMDSPartner,
        });
        dispatch({ type: "REMOVEMODALINPROCESS" });
        setRulesDataForEdit({
          selectedUser:
            partnerDetailRes?.data?.partnerDetail?.restrictedAccessUserId,
          selectedGroups:
            partnerDetailRes?.data?.partnerDetail?.restrictedAccessGroupId,
          selectedMembership:
            partnerDetailRes?.data?.partnerDetail
              ?.restrictedAccessMembershipPlanId,
          selectedUserAccess:
            partnerDetailRes?.data?.partnerDetail?.restrictionAccess,
          selectedTags:
            partnerDetailRes?.data?.partnerDetail?.restrictedAccessTagId,
        });
        setPartnerDetail({
          ...partnerDetailRes.data.partnerDetail,
          partnerType: {
            value:
              partnerDetailRes.data.partnerDetail.partnerType !== undefined &&
                partnerDetailRes.data.partnerDetail.partnerType !== null &&
                partnerDetailRes.data.partnerDetail.partnerType !== "" &&
                partnerDetailRes.data.partnerDetail.partnerType._id &&
                partnerDetailRes.data.partnerDetail.partnerType.name !== "nobadge"
                ? partnerDetailRes.data.partnerDetail.partnerType._id
                : "",
            label:
              partnerDetailRes.data.partnerDetail.partnerType !== undefined &&
                partnerDetailRes.data.partnerDetail.partnerType !== null &&
                partnerDetailRes.data.partnerDetail.partnerType !== "" &&
                partnerDetailRes.data.partnerDetail.partnerType.name !== "nobadge"
                ? partnerDetailRes.data.partnerDetail.partnerType.name
                : "Select badge",
          },
          category: partnerDetailRes.data.partnerDetail.category,
          value: partnerDetailRes.data.partnerDetail.offerValue,
          type: partnerDetailRes.data.partnerDetail.MDSType,
          logo: partnerDetailRes.data.partnerDetail.companyLogo,
          contactName:
            partnerDetailRes.data.partnerDetail.contactInfo.contactName,
          PhoneNumber:
            partnerDetailRes.data.partnerDetail.contactInfo.phoneNumber,
          email: partnerDetailRes.data.partnerDetail.contactInfo.email,
          website: partnerDetailRes.data.partnerDetail.contactInfo.website,
          facebook: partnerDetailRes.data.partnerDetail.contactInfo.facebook,
          linkedin: partnerDetailRes.data.partnerDetail.contactInfo.linkedin,
          description: partnerDetailRes.data.partnerDetail.description,
          offer_desc: partnerDetailRes.data.partnerDetail.OfferDescription,
          offer_instru: partnerDetailRes.data.partnerDetail.OfferInstructions,
          mdsPartner: partnerDetailRes.data.partnerDetail.isMDSPartner,
          darkCompanyLogo: partnerDetailRes.data.partnerDetail.darkCompanyLogo,
          webBanner: partnerDetailRes.data.partnerDetail.webBanner,
          thumbnail: partnerDetailRes.data.partnerDetail.thumbnail,
          mobileBanner: partnerDetailRes.data.partnerDetail.mobileBanner,
        });
        const thumbnailImg = partnerDetailRes.data.partnerDetail.thumbnail;
        var height, width;
        const img = new Image();
        img.src = thumbnailImg;
        img.onload = function () {
          height = this.height;
          width = this.width;
          setThumbnalHeight(height);
          setThumbnalWidth(width);
        };

        if (partnerDetailRes.data.partnerDetail.subcategory)
          setSubCategories([
            ...partnerDetailRes.data.partnerDetail.subcategory.map((cat) => {
              return cat._id;
            }),
          ]);
        setDataLoader(false);
      } else {
        setDataLoader(false);
      }
    } catch (error) {
      setDataLoader(false);
      return false;
    }
  }

  //badge list api call
  async function badgeListApi() {
    try {
      const badgeListRes = await authServicePartner.allBadgeList("");
      if (badgeListRes.data.status) {
        // setBadgeList(badgeListRes.data.data)
        const badgeList = badgeListRes.data.data;
        let arr = [
          {
            value: "",
            label: "Select badge",
          },
        ];
        if (badgeList !== undefined && badgeList.length > 0) {
          badgeList.map((badges) => {
            if (badges.name !== "nobadge") {
              arr.push({
                value: badges._id,
                label: badges.name,
              });
            }
          });

          setBadgeList(arr);
        } else {
          setBadgeList([]);
        }
      }
    } catch (error) {
      return false;
    }
  }
  //call when partner type select for add partner
  const onhandleBadgeChange = (selectedOption) => {
    dispatch({ type: "SETMODALINPROCESS" });
    // setPartnerDetailErr({ ...partnerDetailErr, partnerType: "" })
    setPartnerDetail({ ...partnerDetail, partnerType: selectedOption });
  };
  //edipartner api
  async function editPartnerApi() {
    const formDataEditPartner = new FormData();
    let logoImageCompress;
    let darkCompanyLogoCompress;
    let thumbnailCompress;
    let webBannerCompress;
    let mobileBannerCompress;

    if (parntnerImageFileData?.logoFileData) {
      logoImageCompress = await CompressImageFunction(parntnerImageFileData?.logoFileData);
      logoImageCompress && formDataEditPartner.append("partnerIcon", logoImageCompress);
    }
    if (parntnerImageFileData?.darkCompanyLogoFileData) {
      darkCompanyLogoCompress = await CompressImageFunction(parntnerImageFileData?.darkCompanyLogoFileData);
      formDataEditPartner.append(
        "darkCompanyLogo",
        darkCompanyLogoCompress);
    }
    if (parntnerImageFileData?.thumbnailFileData) {
      thumbnailCompress = await CompressImageFunction(parntnerImageFileData?.thumbnailFileData);
      formDataEditPartner.append("thumbnail", thumbnailCompress);
    }
    if (parntnerImageFileData?.webBannerFileData) {
      webBannerCompress = await CompressImageFunction(parntnerImageFileData?.webBannerFileData);
      formDataEditPartner.append("webBanner", webBannerCompress);
    }
    if (parntnerImageFileData?.mobileBannerFileData) {
      mobileBannerCompress = await CompressImageFunction(parntnerImageFileData?.mobileBannerFileData);
      formDataEditPartner.append("mobileBanner", mobileBannerCompress);
    }

    //Data for rules
    dataFromRules?.userAccess?.length !== 0 &&
      formDataEditPartner.append(
        `restrictionAccess`,
        dataFromRules?.userAccess === "all" ? "public" : "restricted"
      );
    if (dataFromRules?.tags_id) {
      dataFromRules?.tags_id?.length > 0 &&
        dataFromRules?.tags_id?.map((id) => {
          formDataEditPartner.append(`restrictedAccessTagId[]`, id);
        });
    }
    if (dataFromRules?.group_id) {
      dataFromRules?.group_id.length > 0 &&
        dataFromRules?.group_id?.map((id) => {
          formDataEditPartner.append(`restrictedAccessGroupId[]`, id);
        });
    }
    if (dataFromRules?.membership_plan_id) {
      dataFromRules?.membership_plan_id.length > 0 &&
        dataFromRules?.membership_plan_id?.map((id) => {
          formDataEditPartner.append(`restrictedAccessMembershipPlanId[]`, id);
        });
    }
    dataFromRules.userAccess === "restricted" &&
      dataFromRules?.user_id.length > 0 &&
      dataFromRules?.user_id?.map((id) => {
        formDataEditPartner.append(`restrictedAccessUserId[]`, id);
      });

    if (partnerDetail.companyName) {
      formDataEditPartner.append("companyName", partnerDetail.companyName);
    }
    if (partnerDetail.shortDescription) {
      formDataEditPartner.append(
        "shortDescription",
        partnerDetail.shortDescription
      );
    }
    if (partnerDetail.contactName) {
      formDataEditPartner.append(
        "contactInfo[contactName]",
        partnerDetail.contactName
      );
    }
    if (partnerDetail.PhoneNumber) {
      formDataEditPartner.append(
        "contactInfo[phoneNumber]",
        partnerDetail.PhoneNumber
      );
    }
    if (partnerDetail.email) {
      formDataEditPartner.append("contactInfo[email]", partnerDetail.email);
    }
    if (partnerDetail.website) {
      formDataEditPartner.append("contactInfo[website]", partnerDetail.website);
    }
    if (partnerDetail.facebook) {
      formDataEditPartner.append(
        "contactInfo[facebook]",
        partnerDetail.facebook
      );
    }
    if (partnerDetail.linkedin) {
      formDataEditPartner.append(
        "contactInfo[linkedin]",
        partnerDetail.linkedin
      );
    }
    if (partnerDetail.mdsPartner) {
      formDataEditPartner.append("isMDSPartner", partnerDetail.mdsPartner);
    }
    if (partnerDetail.status) {
      formDataEditPartner.append("status", partnerDetail.status);
    }
    if (partnerDetail.type) {
      formDataEditPartner.append("MDSType", partnerDetail.type);
    }
    if (
      partnerDetail.partnerType.value !== undefined &&
      partnerDetail.partnerType.value !== "" &&
      partnerDetail.partnerType.value
    ) {
      formDataEditPartner.append(
        "partnerType",
        partnerDetail.partnerType.value
      );
    }
    if (partnerDetail.category.length) {
      partnerDetail.category.map((item) =>
        formDataEditPartner.append("category", item._id)
      );
    }
    if (subCategories.length) {
      subCategories.map((item) =>
        formDataEditPartner.append("subcategory", item)
      );
    }
    if (partnerDetail.tag.length > 0) {
      partnerDetail.tag.map((tag) =>
        formDataEditPartner.append("tag", tag._id)
      );
    }
    if (partnerDetail.value) {
      formDataEditPartner.append("offerValue", partnerDetail.value);
    }
    if (descriptionRef.current !== null) {
      const description = descriptionRef.current.getContent();
      formDataEditPartner.append("description", description);
    }
    if (offerdescriptionRef.current !== null) {
      const offer_desc = offerdescriptionRef.current.getContent();
      formDataEditPartner.append("OfferDescription", offer_desc);
    }
    if (claimdescriptionRef.current !== null) {
      const offer_instru = claimdescriptionRef.current.getContent();
      formDataEditPartner.append("OfferInstructions", offer_instru);
    }

    const editPartnerRes = await authServicePartner.editPartner(
      id,
      formDataEditPartner
    );
    try {
      if (editPartnerRes.data.status) {
        setInProcess(false);
        setResponseMessage(editPartnerRes.data.message);
        setTimeout(() => {
          navigate(
            NAVIGATEPAGE !== null && NAVIGATEPAGE !== ""
              ? NAVIGATEPAGE
              : "/partner/list?search="
          );
          setResponseMessage("");
        }, 2000);
      } else {
        setInProcess(false);
        setResponseMessage(editPartnerRes.data.message);
        setTimeout(() => {
          setResponseMessage("");
        }, 2000);
      }
      handleClearAllStates();
    } catch (error) {
      setInProcess(false);
      return false;
    }
  }

  //validation function
  const validate = () => {
    let isValid = false;
    let partnerError = {};
    if (partnerDetail.companyName.trim() === "") {
      isValid = true;
      partnerError.companyName = "Enter company name";
    }

    if (partnerDetail.shortDescription.trim() === "") {
      isValid = true;
      partnerError.shortDescription = "Enter short description";
    }
    if (logo.length === 0 && partnerDetail.logo.length === 0) {
      isValid = true;
      partnerError.logo = "Add logo for light theme";
    } else if (partnerDetailErr.logo !== "") {
      isValid = true;
      partnerError.logo = "Recommended size 375 x 144";
    } else {
      partnerError.logo = "";
    }
    if (darkCompanyLogo.length === 0 && partnerDetail.darkCompanyLogo.length === 0) {
      isValid = true;
      partnerError.darkCompanyLogo = "Add logo for dark theme";
    } else if (partnerDetailErr.darkCompanyLogo !== "") {
      isValid = true;
      partnerError.darkCompanyLogo = "Recommended size 375 x 144";
    } else {
      partnerError.darkCompanyLogo = "";
    }
    if (!checkDesciptionValue(partnerDetail.description)) {
      partnerError.description = "Enter description";
      isValid = true;
    } else {
      partnerError.description = "";
    }
    if (partnerDetail.email !== "" && !emailRegex.test(partnerDetail.email)) {
      isValid = true;
      partnerError.email = "Enter valid email";
    }
    if (partnerDetail.website !== "" && !webRegex.test(partnerDetail.website)) {
      isValid = true;
      partnerError.website = "Enter valid url";
    }
    if (
      partnerDetail.facebook !== "" &&
      !webRegex.test(partnerDetail.facebook)
    ) {
      isValid = true;
      partnerError.facebook = "Enter valid url";
    }
    if (
      partnerDetail.linkedin !== "" &&
      !webRegex.test(partnerDetail.linkedin)
    ) {
      isValid = true;
      partnerError.linkedin = "Enter valid url";
    }
    if (partnerDetail.mdsPartner) {
      if (partnerDetail.value.trim() === "") {
        isValid = true;
        partnerError.value = "Enter value";
      }
      // if (thumbnail.length === 0 && partnerDetail.thumbnail.length === 0) {
      //   isValid = true;
      //   partnerError.thumbnail = "Add thumbnail banner";
      // } else if (partnerDetailErr.thumbnail !== "") {
      //   isValid = true;
      //   partnerError.thumbnail = `Recommended size ${
      //     partnerDetail.type === "perk"
      //       ? "720 x 522"
      //       : partnerDetail.type === "offer"
      //       ? "624 x 336"
      //       : "720 x 522"
      //   }`;
      // } else {
      //   partnerError.thumbnail = "";
      // }
      if (webBanner.length === 0 && partnerDetail.webBanner.length === 0) {
        isValid = true;
        partnerError.webBanner = "Add web banner";
      } else if (partnerDetailErr.webBanner !== "") {
        isValid = true;
        partnerError.webBanner = "Recommended size 2256 x 576";
      } else {
        partnerError.webBanner = "";
      }
      if (partnerDetail.category.length === 0) {
        isValid = true;
        partnerError.category = "Enter category";
      }
      if (!checkOfferDesc(partnerDetail.offer_desc)) {
        partnerError.offer_desc = "Enter offer description";
        isValid = true;
      } else {
        partnerError.offer_desc = "";
      }
      if (!checkOfferInstruc(partnerDetail.offer_instru)) {
        partnerError.offer_instru = "Enter offer claim instruction";
        isValid = true;
      } else {
        partnerError.offer_instru = "";
      }
      // if (partnerDetail.type !== "perk" && partnerDetail.partnerType === "") {
      //   partnerError.partnerType = "Select partner type"
      //   isValid = true
      // }
      if (partnerDetail.status === "draft") {
        partnerError.status = "Select status";
        isValid = true;
      }
      if (partnerDetail.type === "") {
        partnerError.type = "Select type";
        isValid = true;
      }
    }
    if (Object.keys(partnerError).length > 0) {
      const emptyKeys = Object.keys(partnerError).filter(
        (key) => partnerError[key] !== ""
      );
      const inputElement = document.getElementsByName(emptyKeys[0])[0];
      if (inputElement) {
        inputElement.focus(); // Focus the input element
      }
    }
    setPartnerDetailErr(partnerError);
    return isValid;
  };

  //handle onchange for ismdspartner
  const handleMdsPartner = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setPartnerDetail({ ...partnerDetail, mdsPartner: e.target.checked });
    dispatch({ type: "isMDSPartner", payload: e.target.checked });
    switch (e.target.name) {
      case "mdsPartner":
        e.target.checked === false
          ? setPartnerDetailErr({
            ...partnerDetailErr,
            mdsPartner: "Select MDS partner",
          })
          : setPartnerDetailErr({
            ...partnerDetailErr,
            mdsPartner: "",
          });
        break;
    }
  };
  //handle onchange
  const handlePartnerDetailChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    const { name, value } = e.target;
    setPartnerDetail({ ...partnerDetail, [name]: value });
    switch (name) {
      case "companyName":
        value.trim() === ""
          ? setPartnerDetailErr({
            ...partnerDetailErr,
            companyName: "Enter company name",
          })
          : setPartnerDetailErr({ ...partnerDetailErr, companyName: "" });
        break;
      case "shortDescription":
        value.trim() === ""
          ? setPartnerDetailErr({
            ...partnerDetailErr,
            shortDescription: "Enter short description",
          })
          : setPartnerDetailErr({ ...partnerDetailErr, shortDescription: "" });
        break;
      case "category":
        value.trim() === ""
          ? setPartnerDetailErr({
            ...partnerDetailErr,
            category: "Enter category",
          })
          : setPartnerDetailErr({ ...partnerDetailErr, category: "" });
        break;
      // case "partnerType":
      //   value === "" ? setPartnerDetailErr({ ...partnerDetailErr, partnerType: "Select partnerType" }) : setPartnerDetailErr({ ...partnerDetailErr, partnerType: "" })
      //   break;
      case "type":
        value === ""
          ? setPartnerDetailErr({ ...partnerDetailErr, type: "Select type" })
          : value === "offer" && thumbnailHeight !== 336
            ? setPartnerDetailErr({
              ...partnerDetailErr,
              thumbnail: "Recommended size 720 x 552",
              type: "",
            })
            : value === "perk" && thumbnailHeight !== 522
              ? setPartnerDetailErr({
                ...partnerDetailErr,
                thumbnail: "Recommended size 720 x 522",
                type: "",
              })
              : setPartnerDetailErr({
                ...partnerDetailErr,
                thumbnail: "",
                type: "",
              });
        break;
      case "value":
        value === ""
          ? setPartnerDetailErr({ ...partnerDetailErr, value: "Enter value" })
          : setPartnerDetailErr({ ...partnerDetailErr, value: "" });
        break;
      case "status":
        value === ""
          ? setPartnerDetailErr({
            ...partnerDetailErr,
            status: "Select status",
          })
          : setPartnerDetailErr({ ...partnerDetailErr, status: "" });
        break;
      case "website":
        value !== "" && !webRegex.test(value)
          ? setPartnerDetailErr({
            ...partnerDetailErr,
            website: "Enter valid url",
          })
          : setPartnerDetailErr({ ...partnerDetailErr, website: "" });
        break;
      default:
        break;
    }
  };

  //handle edit partner
  const handleEditPartner = (e) => {
    e.preventDefault();
    if (!validate(partnerDetail) && !ruleError && !noRuleFieldSelected) {
      setInProcess(true);
      editPartnerApi();
    } else {
      noRuleFieldSelected && setRuleError(true);
    }
  };

  // handle tag onchange
  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setaddtag(e.target.value.trim());
    if (e.target.value.length > 0) {
      setadderr("");
    } else {
      setadderr("Enter tags! ");
    }
  };

  // handle create tag
  const handleCreate = () => {
    if (addtag.length > 0) {
      setcreateInprocess(true);

      Services.createtag({
        name: addtag.trim(),
      })
        .then((res) => {
          setcreateInprocess(false);
          if (res.data.status) {
            setshowalertAdd(true);
            setalertmsgAdd(res.data.message);
            categoryListApi();
            setTimeout(() => {
              setshowalertAdd(false);
              setalertmsgAdd("");
            }, 3000);
          } else {
            setshowalertAdd(true);
            setalertmsgAdd(res.data.message);
            setTimeout(() => {
              setshowalertAdd(false);
              setalertmsgAdd("");
            }, 3000);
          }
          handleClearAllStates();
          setaddtag("");
        })
        .catch((e) => {
          handleClearAllStates();
          setcreateInprocess(false);
          console.log(e);
        });
      setadderr("");
    } else {
      setadderr("Enter tags!");
    }
  };

  //handle save as draft partner
  const handleSaveDraft = (e) => {
    e.preventDefault();
    setPartnerDetail({ ...partnerDetail, status: "draft" });
    if (partnerDetail.companyName.trim() === "") {
      setPartnerDetailErr({
        ...partnerDetailErr,
        companyName: "Enter companyname",
      });
      dispatch({ type: "PAGECHANGE", payload: false });
    } else {
      dispatch({ type: "PAGECHANGE", payload: false });
      dispatch({ type: "REMOVEMODALINPROCESS" });
      setSaveInProcess(true);
      editPartnerApi();
    }
  };
  //past event for editor
  const handlePastedText = (text, editor, stateChangeFunctionCall) => {
    dispatch({ type: "SETMODALINPROCESS" });
    const contentState = editor.getCurrentContent();
    const selectionState = editor.getSelection();

    // Create a new modified content state with the pasted text
    const modifiedContentState = Modifier.replaceText(
      contentState,
      selectionState,
      text,
      editor
        .getCurrentInlineStyle()
        .filter((style) => style !== "backgroundColor")
    );

    // Create a new editor state with the modified content state
    const newEditorState = EditorState.push(
      editor,
      modifiedContentState,
      "insert-characters"
    );
    stateChangeFunctionCall(newEditorState);
  };
  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOP  CLOSE" });
  };

  // thumbnail image upload function
  const thumbnailImages = async (acceptedfile) => {
    setFileDimension(acceptedfile);
    dispatch({ type: "SETMODALINPROCESS" });
    setFileName(Math.random().toString() + acceptedfile.name);
    setModalOpen(true);
  };
  
  return (
    <>
      {responseMessage.length > 0 && (
        <div className="alert alert-info">{responseMessage}</div>
      )}
      {dataLoader ? (
        <div className="cstm-data-edit-load">
          <img src={Loader} />{" "}
        </div>
      ) : (
        <>
          <div className="group-info-user">
            {modalOpen && (
              <ImageCropperReact
                file={thumbnailFile}
                setModalOpen={setModalOpen}
                setFile={setThumbnailFile}
                fileName={fileName}
                fileDimension={fileDimension}
                setLogo={setLogo}
                selectImageType={selectImageType}
                setDarkCompanyLogo={setDarkCompanyLogo}
                setThumbnail={setThumbnail}
                setWebBanner={setWebBanner}
                setMobileBanner={setMobileBanner}
                parntnerImageFileData={parntnerImageFileData}
                setParntnerImageFileData={setParntnerImageFileData}
                requiredImageSize={requiredImageSize}
                requiredImageAspectRatio={requiredImageAspectRatio}
              // setRequiredImageSizeError={() => {
              //   const errorMessages = {
              //     logoForLightTheme: `Recommended size ${requiredImageSize.logoForLightThemeWidth} x ${requiredImageSize.logoForLightThemeHeight}`,
              //     logoForDarkTheme: `Recommended size ${requiredImageSize.logoForDarkThemeWidth} x ${requiredImageSize.logoForDarkThemeHeight}`,
              //     partnerThumbnailImage: `Recommended size ${requiredImageSize.thumbnailImageWidth} x ${requiredImageSize.thumbnailImageHeight}`,
              //     partnerWebBanner: `Recommended size ${requiredImageSize.webBannerWidth} x ${requiredImageSize.webBannerHeight}`,
              //     partnerMobileBanner: `Recommended size ${requiredImageSize.mobileBannerWidth} x ${requiredImageSize.mobileBannerHeight}`
              //   };

              //   const fieldMapping = {
              //     logoForLightTheme: 'logo',
              //     logoForDarkTheme: 'darkCompanyLogo',
              //     partnerThumbnailImage: partnerDetail.type === "perk" ? 'perkThumbnail' : partnerDetail.type === "offer" ? 'offerThumbnail' : 'thumbnail',
              //     partnerWebBanner: 'webBanner',
              //     partnerMobileBanner: 'mobileBanner'
              //   };
              //   const errorMessage = errorMessages[selectImageType];
              //   const errorField = fieldMapping[selectImageType];

              //   setPartnerDetailErr({
              //     ...partnerDetailErr,
              //     [errorField]: errorMessage
              //   });
              // }}
              />
            )}
            <div className="cstm-video-upload">
              {/* Light theme Dropzone */}
              <Dropzone
                onDrop={(acceptedFiles) => {
                  setSelectImageType("logoForLightTheme");
                  setRequiredImageAspectRatio(47 / 18)
                  const file = acceptedFiles[0];
                  if (file.type.startsWith("image/")) {
                    const reader = new FileReader();
                    reader.onload = function (event) {
                      const img = new Image();
                      img.onload = function () {
                        dispatch({ type: "SETMODALINPROCESS" });
                        thumbnailImages(file);
                        setThumbnailFile(reader.result);
                        setPartnerDetailErr({ ...partnerDetailErr, logo: "" });
                      };
                      img.src = event.target.result;
                    };
                    reader.readAsDataURL(file);
                  } else {
                    setPartnerDetailErr({
                      ...partnerDetailErr,
                      logo: "File type not supported. Please upload an image."
                    });
                  }
                }}
                name="logo"
                multiple={false}
                accept="image/png, image/gif, image/jpeg, image/jpg"
                maxSizeBytes="10485760"
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />

                    <div className="cstm-video-upload-img partner-logo-375-144">
                      <img
                        className={logo === "" && "cstm-vdo"}
                        src={
                          logo !== "" ? logo
                            : partnerDetail?.logo
                              ? partnerDetail?.logo
                              : Frame
                        }
                      />
                    </div>
                  </div>
                )}
              </Dropzone>
              <span className="error">{partnerDetailErr.logo}</span>
            </div>
            <div className="group-info-user-link">
              <div className="cover-image-upload-ttl">
                Logo for light theme*
              </div>
              <div className="cover-image-upload-size">
                {`Recommended size ${requiredImageSize.logoForLightThemeWidth} x ${requiredImageSize.logoForLightThemeHeight}`}
              </div>
            </div>
            <div className="cstm-video-upload">
              {/* Dark theme Dropzone */}
              <Dropzone
                onDrop={(acceptedFiles) => {
                  setSelectImageType("logoForDarkTheme")
                  setRequiredImageAspectRatio(47 / 18)
                  const file = acceptedFiles[0];
                  if (file.type.startsWith("image/")) {
                    const reader = new FileReader();
                    reader.onload = function (event) {
                      const img = new Image();
                      img.onload = function () {
                        dispatch({ type: "SETMODALINPROCESS" });
                        thumbnailImages(file);
                        setThumbnailFile(reader.result);
                        setPartnerDetailErr({ ...partnerDetailErr, darkCompanyLogo: "" });
                      };
                      img.src = event.target.result;
                    };
                    reader.readAsDataURL(file);
                  } else {
                    setPartnerDetailErr({
                      ...partnerDetailErr,
                      darkCompanyLogo: "File type not supported. Please upload an image."
                    });
                  }
                }}
                name="logo"
                multiple={false}
                accept="image/png, image/gif, image/jpeg, image/jpg"
                maxSizeBytes="10485760"
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />

                    <div className="cstm-video-upload-img partner-logo-375-144">
                      <img
                        className={darkCompanyLogo === "" && "cstm-vdo"}
                        src={
                          darkCompanyLogo !== "" ? darkCompanyLogo :
                            partnerDetail.darkCompanyLogo ?
                              partnerDetail.darkCompanyLogo :
                              Frame
                        }
                      />
                    </div>
                  </div>
                )}
              </Dropzone>
              <span className="error">{partnerDetailErr.darkCompanyLogo}</span>
            </div>
            <div className="group-info-user-link">
              <div className="cover-image-upload-ttl">Logo for dark theme*</div>
              <div className="cover-image-upload-size">
                {`Recommended size ${requiredImageSize.logoForDarkThemeWidth} x ${requiredImageSize.logoForDarkThemeHeight}`}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Company Name*</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  name="companyName"
                  value={partnerDetail.companyName}
                  onChange={handlePartnerDetailChange}
                />
              </div>
              <span className="error cstm-error4">
                {partnerDetailErr.companyName}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Short Description*</div>
              <div className="form-group-ct">
                <textarea
                  maxLength={250}
                  name="shortDescription"
                  value={partnerDetail.shortDescription}
                  onChange={handlePartnerDetailChange}
                />
                <span className="text-limitaion">
                  The maximum character limit is 250.
                </span>
              </div>
              <span className="error cstm-error4">
                {partnerDetailErr.shortDescription}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Description*</div>
              <div className="cstm-editor-tool-ds">
                <TinyEditor
                  valueRef={descriptionRef}
                  value={partnerDetail.description}
                  handleEditorChange={(value, editor) => {
                    // dispatch({ type: "SETMODALINPROCESS" })
                    setPartnerDetailErr({
                      ...partnerDetailErr,
                      description: "",
                    });
                  }}
                  handleKeyPress={() => {
                    dispatch({ type: "SETPARTNERINPROCESS" });
                  }}
                />
              </div>
              <span className="error">{partnerDetailErr.description}</span>
            </div>
          </div>
          <div className="row">
            <div class="col-sm-4 form-group">
              <div class="form-group-label">Contact Name</div>
              <div class="form-group-ct">
                <input
                  type="text"
                  name="contactName"
                  value={partnerDetail.contactName}
                  onChange={handlePartnerDetailChange}
                />
              </div>
              <span className="error cstm-error4">
                {partnerDetailErr.contactName}
              </span>
            </div>
            <div class="col-sm-4 form-group">
              <div class="form-group-label">Phone Number</div>
              <div class="form-group-ct">
                <input
                  type="text"
                  name="PhoneNumber"
                  value={partnerDetail.PhoneNumber}
                  onChange={handlePartnerDetailChange}
                />
              </div>
              <span className="error cstm-error4">
                {partnerDetailErr.PhoneNumber}
              </span>
            </div>
            <div class="col-sm-4 form-group">
              <div class="form-group-label">Email</div>
              <div class="form-group-ct">
                <input
                  type="text"
                  name="email"
                  value={partnerDetail.email}
                  onChange={handlePartnerDetailChange}
                />
              </div>
              <span className="error cstm-error4">
                {partnerDetailErr.email}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 form-group">
              <div className="form-group-label">Website</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  name="website"
                  value={partnerDetail.website}
                  onChange={handlePartnerDetailChange}
                />
              </div>
              <span className="error cstm-error4">
                {partnerDetailErr.website}
              </span>
            </div>
            <div className="col-sm-4 form-group">
              <div className="form-group-label">Facebook URL</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  name="facebook"
                  value={partnerDetail.facebook}
                  onChange={handlePartnerDetailChange}
                />
              </div>
              <span className="error cstm-error4">
                {partnerDetailErr.facebook}
              </span>
            </div>
            <div className="col-sm-4 form-group">
              <div className="form-group-label">Linkedin URL</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  name="linkedin"
                  value={partnerDetail.linkedin}
                  onChange={handlePartnerDetailChange}
                />
              </div>
              <span className="error cstm-error4">
                {partnerDetailErr.linkedin}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="cstm-chk-preregistration-container">
                <input
                  type="checkbox"
                  name="mdsPartner"
                  value={partnerDetail.mdsPartner}
                  checked={partnerDetail.mdsPartner}
                  id="chk-preregistration"
                  onChange={handleMdsPartner}
                />
                <label for="chk-preregistration">
                  Is this a partner of MDS?
                </label>
              </div>
            </div>
          </div>
          {partnerDetail.mdsPartner && (
            <>
              <div class="form-group">
                <div class="cstm-channel-type-title">Partner’s Type*</div>
                <div class="cstm-pm-list">
                  <label class="subcat-addvideo">
                    <input
                      type="radio"
                      value="perk"
                      name="type"
                      checked={partnerDetail.type === "perk"}
                      onChange={handlePartnerDetailChange}
                    />
                    Perk
                  </label>
                  <label class="subcat-addvideo">
                    <input
                      type="radio"
                      value="offer"
                      name="type"
                      checked={partnerDetail.type === "offer"}
                      onChange={handlePartnerDetailChange}
                    />
                    Offer
                  </label>
                </div>
                <span className="error cstm-pm-list-msg">
                  {partnerDetailErr.type}
                </span>
              </div>

              <AddRules
                title="Rules*"
                ruleError={ruleError}
                setRuleError={setRuleError}
                totalUserList={totalUserList}
                noRuleFieldSelected={noRuleFieldSelected}
                setNoRuleFieldSelected={setNoRuleFieldSelected}
                dataFromRules={dataFromRules}
                setDataFromRules={setDataFromRules}
                addedRules={rulesDataForEdit}
                showEventField={true}
                showTagsField={true}
              />

              <div class="form-group">
                <div class="cstm-channel-type-title partner-icon-rf-only">
                  Partner Status*{" "}
                  <a
                    className="status-undo"
                    onClick={() =>
                      setPartnerDetail({ ...partnerDetail, status: "draft" })
                    }
                  >
                    <img src={undo} />
                  </a>
                </div>
                <div class="cstm-pm-list">
                  <label class="subcat-addvideo">
                    <input
                      type="radio"
                      value="paused"
                      name="status"
                      checked={partnerDetail.status === "paused"}
                      onChange={handlePartnerDetailChange}
                    />
                    Pause
                  </label>
                  <label class="subcat-addvideo">
                    {" "}
                    <input
                      type="radio"
                      value="published"
                      name="status"
                      checked={partnerDetail.status === "published"}
                      onChange={handlePartnerDetailChange}
                    />
                    Live
                  </label>
                </div>
                <span className="error cstm-pm-list-msg">
                  {partnerDetailErr.status}
                </span>
              </div>
              {partnerDetail.type !== "perk" && (
                <div className="row">
                  <div class="col-sm-4 form-group">
                    <div class="cstm-channel-type-title">Partner's Badge</div>
                    <div className="form-group-ctst event-type-drop-only">
                      <Select
                        isSearchable={true}
                        options={badgeList}
                        onChange={onhandleBadgeChange}
                        value={partnerDetail.partnerType}
                        styles={{ zIndex: 9999 }}
                      />
                    </div>
                  </div>
                  {/* <select name='partnerType' value={partnerDetail.partnerType} onChange={handlePartnerDetailChange}>
                  <option value=''>Select Partner Badge</option>
                  {badgeList.map((badges) => (
                    <option value={badges._id}>{badges.name}</option>
                  ))}
                </select> */}
                  {/* <div class="cstm-pm-list">
                  {badgeList.map((badges) => (
                    <label class="subcat-addvideo"><input type='radio' value={badges._id} name='partnerType' checked={partnerDetail.partnerType === badges._id} onChange={handlePartnerDetailChange} />{badges.name}</label>
                  ))}
                </div> */}
                  <span className="error cstm-pm-list-msg">
                    {partnerDetailErr.partnerType}
                  </span>
                </div>
              )}
              <div className="row">
                <div className="col-sm-4 form-group">
                  <div className="form-group-label">Category*</div>
                  <div className="form-group-ct">
                    <input
                      list="category"
                      type="text"
                      name="browser"
                      onKeyUp={(e) => {
                        dispatch({ type: "SETMODALINPROCESS" });
                        var selType = categoryData.filter((type) => {
                          if (type.name === e.target.value) return type.name;
                        });
                        var eventType = partnerDetail.category.find(
                          (type) => type.name === e.target.value
                        );

                        if (selType.length > 0 && !eventType) {
                          setPartnerDetail({
                            ...partnerDetail,
                            category: [...partnerDetail.category, ...selType],
                          });
                          // setSubCategories([
                          //   ...subCategories,
                          //   ...selType[0].subcategory.map((cat) => {
                          //     return cat._id;
                          //   }),
                          // ]);
                          e.target.value = "";
                          setPartnerDetailErr({
                            ...partnerDetailErr,
                            category: "",
                          });
                        }
                      }}
                    />
                  </div>
                  <span className="error cstm-error4">
                    {partnerDetailErr.category}
                  </span>
                </div>
              </div>
              <div>
                {partnerDetail.category.map((type) => {
                  return (
                    <span className="topic-label-in">
                      <b>{type.name}</b>
                      <button
                        onClick={() => {
                          setPartnerDetail({
                            ...partnerDetail,
                            category: partnerDetail.category.filter(
                              (typeFilter) => {
                                if (typeFilter.name !== type.name)
                                  return typeFilter;
                              }
                            ),
                          });
                        }}
                      >
                        ×
                      </button>
                    </span>
                  );
                })}
                <datalist id="category">
                  {categoryData.map((item) => {
                    if (
                      partnerDetail.category.filter((category) => {
                        if (category.name === item.name) return category.name;
                      }).length === 0
                    )
                      return <option>{item.name}</option>;
                  })}
                </datalist>
              </div>
              {partnerDetail.category.filter((cat) => {
                if (cat.subcategory) return cat;
              }).length > 0 ? (
                <div className="row">
                  <div className="col-sm-12 form-group mb-0">
                    <div class="cstm-add-video-title-sub">
                      Select Subcategory
                    </div>
                    <div className="form-group-ctst">
                      {partnerDetail.category.map((cat) => {
                        if (cat.subcategory)
                          return cat.subcategory.map((sCat) => {
                            return (
                              <label className="subcat-addvideo">
                                <input
                                  type="checkbox"
                                  name="subcategory"
                                  value={sCat.name}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSubCategories([
                                        ...subCategories,
                                        sCat._id,
                                      ]);
                                    } else {
                                      setSubCategories([
                                        ...subCategories.filter((cat) => {
                                          if (cat !== sCat._id) return cat;
                                        }),
                                      ]);
                                    }
                                  }}
                                  checked={
                                    subCategories.filter((cat) => {
                                      if (cat === sCat._id) return cat;
                                    }).length >
                                      0 ===
                                      true
                                      ? true
                                      : false
                                  }
                                />
                                {sCat.name}
                              </label>
                            );
                          });
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="row">
                <div className="col-sm-4 form-group">
                  <div className="form-group-label">
                    Add Tag or{" "}
                    <a
                      className="create-speaker-link"
                      data-toggle="modal"
                      data-target="#addtag"
                    >
                      Create Tag
                    </a>
                  </div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      autoComplete="off"
                      list="tag"
                      onKeyUp={(e) => {
                        var selType = tagList.filter((type) => {
                          if (type.name === e.target.value) return type.name;
                        });
                        var eventType = partnerDetail.tag.find(
                          (type) => type.name === e.target.value
                        );

                        if (selType.length > 0 && !eventType) {
                          setPartnerDetail({
                            ...partnerDetail,
                            tag: [...partnerDetail.tag, ...selType],
                          });
                          e.target.value = "";
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div>
                {partnerDetail.tag.map((type) => {
                  return (
                    <span className="topic-label-in">
                      <b>{type.name}</b>
                      <button
                        onClick={() => {
                          setPartnerDetail({
                            ...partnerDetail,
                            tag: partnerDetail.tag.filter((typeFilter) => {
                              if (typeFilter.name !== type.name)
                                return typeFilter;
                            }),
                          });
                        }}
                      >
                        ×
                      </button>
                    </span>
                  );
                })}
                <datalist id="tag">
                  {tagList.map((tag) => {
                    if (
                      partnerDetail.tag.filter((category) => {
                        if (category.name === tag.name) return category;
                      }).length === 0
                    )
                      return <option>{tag.name}</option>;
                  })}
                </datalist>
              </div>
              <div className="row cstm-mds-partner-only">
                <div class="cstm-channel-type-title">{`Upload Thumbnail ${partnerDetail.type === "perk"
                  ? "For Perk"
                  : partnerDetail.type === "offer"
                    ? "For Offer"
                    : ""
                  }`}</div>
                <div class="col-sm-4 form-group">
                  <div className="group-info-user">
                    <div className="cstm-video-upload">
                      {/* Thumbnail image Dropzone */}
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          setSelectImageType("partnerThumbnailImage")
                          if (partnerDetail.type === "perk") {
                            setRequiredImageAspectRatio(16 / 9)
                          } else {
                            setRequiredImageAspectRatio(16 / 9)
                          }
                          const file = acceptedFiles[0];
                          if (file.type.startsWith("image/")) {
                            const reader = new FileReader();
                            reader.onload = function (event) {
                              const img = new Image();
                              img.onload = function () {
                                dispatch({ type: "SETMODALINPROCESS" });
                                thumbnailImages(file);
                                setThumbnailFile(reader.result);
                                setPartnerDetailErr({
                                  ...partnerDetailErr,
                                  perkThumbnail: ``,
                                  offerThumbnail: ``,
                                  thumbnail: ``
                                })
                              }
                              img.src = event.target.result;
                            };
                            reader.readAsDataURL(file);
                          } else {
                            setPartnerDetailErr({
                              ...partnerDetailErr,
                              thumbnail: "File type not supported. Please upload an image."
                            });
                          }
                        }}
                        name="thumbnail"
                        multiple={false}
                        accept="image/png, image/gif, image/jpeg, image/jpg"
                        maxSizeBytes="10485760"
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <div
                              className={`cstm-video-upload-img ${partnerDetail.type === "perk"
                                ? "partner-logo-720-522"
                                : "partner-logo-624-336"
                                }`}
                            >
                              <img
                                className={thumbnail.length === 0 && "cstm-vdo"}
                                src={
                                  thumbnail.length !== 0
                                    ? thumbnail :
                                    partnerDetail?.thumbnail ?
                                      partnerDetail?.thumbnail
                                      : Frame
                                }
                              />
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      {(
                        <span className="error">
                          {(partnerDetail.type === "" || partnerDetail.type === "offer")
                            ? partnerDetailErr?.offerThumbnail
                            : partnerDetail.type === "perk"
                              ? partnerDetailErr?.perkThumbnail
                              : ""}
                        </span>
                      )}
                    </div>
                    <div className="group-info-user-link">
                      <div className="cover-image-upload-ttl">
                        Thumbnail Image
                      </div>
                      {`Recommended size ${requiredImageSize.thumbnailImageWidth} x ${requiredImageSize.thumbnailImageHeight}`}
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 form-group">
                  <div className="group-info-user">
                    <div className="cstm-video-upload">
                      {/* Web banner Dropzone */}
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          setSelectImageType("partnerWebBanner");
                          setRequiredImageAspectRatio(31 / 6)
                          const file = acceptedFiles[0];
                          if (file.type.startsWith("image/")) {
                            const reader = new FileReader();
                            reader.onload = function (event) {
                              const img = new Image();
                              img.onload = function () {
                                dispatch({ type: "SETMODALINPROCESS" });
                                thumbnailImages(file);
                                setThumbnailFile(reader.result);
                                setPartnerDetailErr({ ...partnerDetailErr, webBanner: "" });
                              };
                              img.src = event.target.result;
                            };
                            reader.readAsDataURL(file);
                          } else {
                            setPartnerDetailErr({
                              ...partnerDetailErr,
                              webBanner: "File type not supported. Please upload an image."
                            });
                          }
                        }}
                        name="thumbnail"
                        multiple={false}
                        accept="image/png, image/gif, image/jpeg, image/jpg"
                        maxSizeBytes="10485760"
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <div className="cstm-video-upload-img partner-logo-2256-576">
                              <img
                                className={webBanner.length === 0 && "cstm-vdo"}
                                src={
                                  webBanner.length !== 0
                                    ? webBanner :
                                    partnerDetail?.webBanner ?
                                      partnerDetail?.webBanner
                                      : Frame
                                }
                              />
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <span className="error">
                        {partnerDetailErr.webBanner}
                      </span>
                    </div>
                    <div className="group-info-user-link">
                      <div className="cover-image-upload-ttl">
                        Banner (Web)*
                      </div>
                      {`Recommended size ${requiredImageSize.webBannerWidth} x ${requiredImageSize.webBannerHeight}`}
                    </div>
                  </div>
                </div>

                <div className="col-sm-4 form-group">
                  <div className="group-info-user">
                    <div className="cstm-video-upload">
                      {/* Mobile banner Dropzone */}
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          setSelectImageType("partnerMobileBanner");
                          setRequiredImageAspectRatio(39 / 10)
                          const file = acceptedFiles[0];
                          if (file.type.startsWith("image/")) {
                            const reader = new FileReader();
                            reader.onload = function (event) {
                              const img = new Image();
                              img.onload = function () {
                                dispatch({ type: "SETMODALINPROCESS" });
                                thumbnailImages(file);
                                setThumbnailFile(reader.result);
                                setPartnerDetailErr({ ...partnerDetailErr, mobileBanner: "" });
                              };
                              img.src = event.target.result;
                            };
                            reader.readAsDataURL(file);
                          } else {
                            setPartnerDetailErr({
                              ...partnerDetailErr,
                              mobileBanner: "File type not supported. Please upload an image."
                            });
                          }
                        }}
                        name="mobileBanner"
                        multiple={false}
                        accept="image/png, image/gif, image/jpeg, image/jpg"
                        maxSizeBytes="10485760"
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <div className="cstm-video-upload-img partner-logo-780-552">
                              <img
                                className={
                                  mobileBanner.length === 0 && "cstm-vdo"
                                }
                                src={
                                  mobileBanner.length !== 0
                                    ? mobileBanner :
                                    partnerDetail?.mobileBanner ?
                                      partnerDetail?.mobileBanner
                                      : Frame
                                }
                              />
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <span className="error">
                        {partnerDetailErr.mobileBanner}
                      </span>
                    </div>
                    <div className="group-info-user-link">
                      <div className="cover-image-upload-ttl">
                        Banner (Mobile)
                      </div>
                      {`Recommended size ${requiredImageSize.mobileBannerWidth} x ${requiredImageSize.mobileBannerHeight}`}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">Value*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      name="value"
                      value={partnerDetail.value}
                      onChange={handlePartnerDetailChange}
                      maxLength={50}
                    />
                  </div>
                  <span className="text-limitaion">
                    The maximum character limit is 50.
                  </span>
                  <span className="error cstm-error4">
                    {partnerDetailErr.value}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">Offer Description*</div>
                  <div className="cstm-editor-tool-ds">
                    <TinyEditor
                      valueRef={offerdescriptionRef}
                      value={partnerDetail.offer_desc}
                      handleEditorChange={(value, editor) => {
                        // dispatch({ type: "SETMODALINPROCESS" })
                        setPartnerDetailErr({
                          ...partnerDetailErr,
                          offer_desc: "",
                        });
                      }}
                      handleKeyPress={() => {
                        dispatch({ type: "SETMODALINPROCESS" });
                      }}
                    />
                  </div>
                  <span className="error">{partnerDetailErr.offer_desc}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">
                    Offer Claim Instructions*
                  </div>
                  <div className="cstm-editor-tool-ds">
                    <TinyEditor
                      valueRef={claimdescriptionRef}
                      value={partnerDetail.offer_instru}
                      handleEditorChange={(value, editor) => {
                        // dispatch({ type: "SETMODALINPROCESS" })
                        setPartnerDetailErr({
                          ...partnerDetailErr,
                          offer_instru: "",
                        });
                      }}
                      handleKeyPress={() => {
                        dispatch({ type: "SETMODALINPROCESS" });
                      }}
                    />
                  </div>
                  <span className="error">{partnerDetailErr.offer_instru}</span>
                </div>
              </div>
            </>
          )}
          <div className="cyp-btm-btn">
            {inProcess ? (
              <div className="loader-button1">
                <button>In Process...</button>
                <span className="loader-btn">
                  <img src={Loader2} />
                </span>
              </div>
            ) : (
              <button className="btn" onClick={handleEditPartner}>
                Update Partner
              </button>
            )}
            {partnerDetail.status === "draft" && (
              <>
                {saveInProcess ? (
                  <div className="loader-button1 save-draft-loader">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <button className="btn ms-4" onClick={handleSaveDraft}>
                    Save as draft
                  </button>
                )}
              </>
            )}
            <button
              className="btn2"
              onClick={() => {
                navigate(-1);
              }}
            >
              Discard
            </button>
          </div>
        </>
      )}
      <AlertComponent />
      <div
        className="modal fade edit-user-details-popup"
        id="addtag"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Create tag</h2>
              <button
                type="button"
                className="close"
                //data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#addtag").modal("hide");
                    handleCloseAddModel(e);
                  }
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {showalertAdd && (
                <div className="alert alert-info">{alertmsgAdd}</div>
              )}
              <div className="form-group">
                <div className="form-group-label">Tag name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="enter tags name"
                    name="tag"
                    onChange={handleAddChange}
                    value={addtag}
                  />
                </div>
                <span className="error">{adderr}</span>
              </div>
            </div>
            <div className="edit-user-details-footer cstm-create-button">
              {createInprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleCreate}
                  disabled={createInprocess}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPartnerInfo;
