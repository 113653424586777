import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import authService from "../../../../Services/auth.service";
import { Link, useLocation, useNavigate } from "react-router-dom";
import imgBigDelete from "../../../../Images/BackendImages/delete-icon.svg";
import imgdelete from "../../../../Images/BackendImages/delete.svg";
import editfill from "../../../../Images/BackendImages/edit.svg";
import authServicePartner from "../../../../Services/auth.service.partner";
import Services from "../../../../Services/auth.service";
import Loader from "../../../../Images/loader.gif";
import Pause from "../../../../Images/pause.svg";
import Loader2 from "../../../../Images/loader2.gif";
import hide from "../../../../Images/Hide.svg";
import show from "../../../../Images/Show.svg";
import Play from "../../../../Images/play.svg";
import moment from "moment";
import { useDispatch } from "react-redux";
import ClaimModal from "../PartnerModals/ClaimModal";
import copyimg from "../../../../Images/file-copy-line 4.svg";
import ViewsModal from "../PartnerModals/ViewsModal";
import ReviewModal from "../PartnerModals/ReviewModal";
import arrowimg from "../../../../Images/arow-left-right.png";
import arrowup from "../../../../Images/Arrow-Up.png";
import DatePicker from "react-datepicker";
import CloneModal from "../PartnerModals/CloneModal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const columns = [
  // { id: "logo", label: "Logo" },
  { id: "drag", label: "" },
  { id: "companyName", label: "Company name" },
  { id: "mdspartner", label: "MDS Partner" },
  { id: "partnerType", label: "Badge" },
  { id: "status", label: "Status" },
  { id: "createdAt", label: "Date created" },
  { id: "updatedAt", label: "Date updated" },
  { id: "pageView", label: "Page views" },
  { id: "claims", label: "Claims" },
  { id: "rating", label: "Rating" },
  { id: "approvedReviews", label: "Review count" },
  { id: "action", label: "Actions" },
];

const PartnerList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [allPartner, setAllPartner] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [dataLoader, setDataLoader] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [showAlert, setShowalert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isZoomImg, setIsZoomImg] = useState(false);
  const [zoomImg, setZoomImg] = useState(null);
  const [inProcess, setInProcess] = useState(false);
  const [totalData, setTotalData] = useState();
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [isClone, setClone] = useState(false);
  const [cloning, setCloning] = useState(false);
  const [badgeList, setBadgeList] = useState([]);
  const [selectedBadge, setSelectedBadge] = useState("");
  const [cloneSuccess, setCloneSuccess] = useState(false);
  const [cloneMsg, setCloneMsg] = useState("");
  const [claimModal, setClaimModal] = useState(false);
  const [ratingModal, setRatingmodal] = useState(false);
  const [claimId, setClaimId] = useState("");
  const location = useLocation().search;
  const search = new URLSearchParams(location).get("search");
  const tag = new URLSearchParams(location).get("tag");
  const searchedBadge = new URLSearchParams(location).get("badge");
  const rowsFromUrl = new URLSearchParams(location).get("perpage");
  const [filterType, setFilterType] = useState("lifetime");
  const [showCustomFilter, setShowCustomFilter] = useState(false);
  const [filterHeader, setFilterHeader] = useState("Lifetime");
  const [fromDate, setFromDate] = useState(
    moment(new Date()).subtract(1, "days").toDate()
  );
  const [toDate, setToDate] = useState(new Date());
  const [reviewName, setReviewName] = useState();
  const [isBadgeFilter, setIsBadgeFilter] = useState(false);
  const [selectedType, setSelectedType] = useState("all");
  const [selectedField, setSelectedField] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [tagList, setTagList] = useState([]);
  const [sortList, setSortList] = useState({
    sortField: "",
    sortType: "",
  });
  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [listIndex, setListIndex] = useState(-1);
  const [actualsearch, setActualsearch] = useState("");
  const [isChangeDate, setIsChangeDate] = useState(true);

  const fromDateRef = useRef(fromDate);
  const toDateRef = useRef(toDate);
  //function for get and store specific data in state
  useEffect(() => {
    setFromDate(moment(new Date()).subtract(1, "days").toDate());
    setToDate(new Date());
    badgeListApi();
    rowsFromUrl !== null && setRowsPerPage(rowsFromUrl);
    getTagList();
    getallusersSuggestionListApi();
  }, []);
  console.log("");
  // tag list api function
  async function getTagList() {
    try {
      const tagListRes = await authService.getalltags_byadmin("");
      if (tagListRes.data.status) {
        setTagList(tagListRes.data.data);
      }
    } catch (error) {
      return false;
    }
  }
  async function getallusersSuggestionListApi() {
    try {
      const getSuggestionRes = await authService.getPartnerSuggestionList();
      let data = getSuggestionRes.data.data;
      var arr = [];
      if (
        data &&
        data.length !== undefined &&
        data.length !== null &&
        data.length > 0
      ) {
        data.map((item) => arr.push({ name: item.companyName }));
        setSuggestionList(arr);
      }
    } catch (err) {}
  }
  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      var currentList =
        document.getElementById("suggestion-list").children[listIndex];
      // .firstElementChild.firstElementChild;
      if (currentList) {
        var text = currentList.innerText;
        setSearchText(text);
      }
    }
  }, [listIndex]);

  //intialize partner object
  function partnerCreateData(
    logo,
    companyName,
    mdspartner,
    partnerType,
    status,
    createdAt,
    updatedAt,
    pageView,
    claims,
    rating,
    approvedReviews,
    action
  ) {
    const dateDifference = moment().diff(moment(updatedAt), "days");
    return {
      logo,
      companyName,
      mdspartner,
      partnerType,
      status,
      createdAt,
      updatedAt,
      pageView,
      claims,
      rating,
      approvedReviews,
      action,
      dateDifference,
    };
  }
  //get all partner api call
  async function getAllPartnerApi(
    page,
    rowsPerPage,
    searchText,
    badge,
    fromdate,
    todate,
    filtertype,
    selectedType,
    selectedTag,
    col,
    sort,
    field
  ) {
    let query = "";
    query += `?limit=${rowsPerPage}&page=${page}`;
    query +=
      searchText !== undefined && searchText !== null && searchText !== ""
        ? `&search=${searchText}`
        : "";
    query +=
      badge !== undefined && badge !== null && badge !== ""
        ? `&badge=${badge}`
        : "";
    query +=
      filtertype !== undefined && filtertype !== null && filtertype !== ""
        ? `&filtertype=${filtertype}&fromdate=${fromdate}&todate=${todate}`
        : "";
    query +=
      selectedType !== undefined && selectedType !== null && selectedType !== ""
        ? `&mdstype=${selectedType}`
        : "";
    query +=
      selectedTag !== undefined && selectedTag !== null && selectedTag !== ""
        ? `&tag=${selectedTag}`
        : "";
    query +=
      col !== undefined && col !== null && col !== ""
        ? `&sortField=${col}`
        : "";
    query +=
      sort !== undefined && sort !== null && sort !== ""
        ? `&sortType=${sort}`
        : "";
    query +=
      field !== undefined && field !== null && field !== ""
        ? `&field=${field}`
        : "";
    try {
      const getPartnerRes = await authServicePartner.getAllPartner(query);
      if (getPartnerRes.data.status) {
        setIsChangeDate(false);
        setTotalData(getPartnerRes.data.data.totalMessages);
        var arr = getPartnerRes.data.data.partnerList;
        if (getPartnerRes.data.data.partnerList.length > 0) {
          var newArr = [];
          for (var i = 0; i < arr.length; i++) {
            newArr[i] = partnerCreateData(
              arr[i].companyLogo !== undefined &&
                arr[i].companyLogo !== null &&
                arr[i].companyLogo !== ""
                ? arr[i].companyLogo
                : "",
              arr[i].companyName !== undefined &&
                arr[i].companyName !== null &&
                arr[i].companyName != ""
                ? arr[i].companyName
                : "-",
              arr[i].isMDSPartner !== undefined &&
                arr[i].isMDSPartner !== null &&
                arr[i].isMDSPartner !== ""
                ? arr[i].isMDSPartner
                : "-",
              arr[i].partnerType !== undefined &&
                arr[i].partnerType !== null &&
                arr[i].partnerType !== "" &&
                arr[i].partnerType.name !== "nobadge"
                ? arr[i].partnerType
                : "-",
              arr[i].status !== undefined &&
                arr[i].status !== null &&
                arr[i].status !== ""
                ? arr[i].status
                : "-",
              moment(arr[i].createdAt).format("MMM DD, YYYY"),
              moment(arr[i].updatedAt).format("MMM DD, YYYY"),
              arr[i].pageView !== undefined &&
                arr[i].pageView !== null &&
                arr[i].pageView !== ""
                ? arr[i].pageView
                : 0,
              arr[i].claims !== undefined &&
                arr[i].claims !== null &&
                arr[i].claims !== ""
                ? arr[i].claims
                : 0,
              arr[i].rating !== undefined &&
                arr[i].rating !== null &&
                arr[i].rating !== "" &&
                arr[i].rating !== "0"
                ? arr[i].rating
                : "-",
              arr[i].approvedReviews !== undefined &&
                arr[i].approvedReviews !== null
                ? arr[i].approvedReviews
                : "-",
              arr[i]._id
            );
          }
          setRows(newArr);
          setAllPartner(newArr);
          setDataLoader(false);
          return newArr;
        } else {
          setRows(arr);
          setAllPartner(arr);
          setDataLoader(false);
          return arr;
        }
        setDataLoader(false);
      } else {
        setDataLoader(false);
      }
    } catch (error) {
      setDataLoader(false);
      return false;
    }
  }
  //called for badge selection list
  async function badgeListApi() {
    try {
      const badgeListRes = await authServicePartner.allBadgeList();
      if (badgeListRes.data.status) {
        let badges = badgeListRes.data.data.filter(
          (badge) => badge.name !== "nobadge"
        );
        setBadgeList(badges);
      }
    } catch (error) {
      return false;
    }
  }
  //partner delete function and api call
  async function partnerDeleteApi() {
    setInProcess(true);
    try {
      const partnerDeleteRes = await authServicePartner.deletePartner(deleteId);
      if (partnerDeleteRes.data.status) {
        // setDataLoader(true)
        getAllPartnerApi(
          page + 1,
          rowsPerPage,
          searchText,
          selectedBadge,
          fromDate,
          toDate,
          filterType,
          selectedType,
          selectedTag,
          sortList.sortField,
          sortList.sortType,
          selectedField
        );
        document.querySelector("#DeleteFromPartner .close").click();
        setInProcess(false);
        setShowalert(true);
        setAlertMsg(partnerDeleteRes.data.message);
        setTimeout(() => {
          setInProcess(false);
          setShowalert(false);
          setAlertMsg("");
        }, 3000);
      } else {
        document.querySelector("#DeleteFromPartner .close").click();
        setInProcess(false);
        setShowalert(true);
        setAlertMsg(partnerDeleteRes.data.message);
      }
    } catch (error) {
      setInProcess(false);
      document.querySelector("#DeleteFromPartner .close").click();
      return false;
    }
  }
  //for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  //when query string search
  useEffect(() => {
    const searchVar =
      search !== null ? search : searchText !== null ? searchText : "";
    search !== null && search !== "null"
      ? setSearchText(search)
      : setSearchText("");
    tag !== null && setSelectedTag(tag);
    setDataLoader(true);
    if (searchedBadge !== undefined && tag !== null && searchedBadge !== null) {
    } else {
      if (searchedBadge !== undefined && searchedBadge !== null) {
        setSelectedBadge(searchedBadge);
        setIsBadgeFilter(true);
        getAllPartnerApi(
          page + 1,
          rowsFromUrl !== null ? rowsFromUrl : rowsPerPage,
          searchText,
          searchedBadge,
          fromDate,
          toDate,
          filterType,
          selectedType,
          selectedTag,
          sortList.sortField,
          sortList.sortType,
          selectedField
        );
      } else {
        getAllPartnerApi(
          page + 1,
          rowsFromUrl !== null ? rowsFromUrl : rowsPerPage,
          searchVar,
          selectedBadge,
          fromDate,
          toDate,
          filterType,
          selectedType,
          tag !== null ? tag : selectedTag,
          sortList.sortField,
          sortList.sortType,
          selectedField
        );
      }
    }
  }, [page, rowsPerPage, searchedBadge]);

  //for pagination
  const handleChangeRowsPerPage = (event) => {
    // navigate(`/partner/list?search=${search}&&perpage=${rowsPerPage}`)
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //handle status update api function
  async function updatePartnerStatusApi(id, status) {
    setRows((prevData) =>
      prevData.map((item) => {
        if (item.action === id) {
          if (item.status === "published") {
            return { ...item, status: "paused" };
          } else {
            return { ...item, status: "published" };
          }
        }
        return item;
      })
    );
    let query = `${id}?status=${status}`;
    try {
      const updateRes = await authServicePartner.updatePartnerStatus(query);
      if (updateRes.data.status) {
        // getAllPartnerApi(page + 1, rowsPerPage)
      }
    } catch (error) {
      return false;
    }
  }

  //search using text
  const handleSearch = (text) => {
    setSearchText(text);
    setSuggestionBox(false);
    setDataLoader(true);
    getAllPartnerApi(
      page + 1,
      rowsPerPage,
      text,
      selectedBadge,
      fromDate,
      toDate,
      filterType,
      selectedType,
      selectedTag,
      sortList.sortField,
      sortList.sortType,
      selectedField
    );
    navigate(`/partner/list?search=${searchText}`);
  };

  //sorting function
  const handleSort = (col, direc) => {
    setDataLoader(true);
    setSortList({ ...sortList, sortField: col, sortType: direc });
    const samePageSortingHeadings = [
      "pageView",
      "claims",
      "rating",
      "approvedReviews",
    ];
    getAllPartnerApi(
      page + 1,
      rowsPerPage,
      searchText,
      selectedBadge,
      fromDate,
      toDate,
      filterType,
      selectedType,
      selectedTag,
      col,
      direc,
      selectedField
    ).then((res) => {
      if (samePageSortingHeadings.includes(col)) {
        const rowArr = [...res];
        if (direc === "Asc") {
          rowArr.sort((a, b) =>
            (a[col] !== "-" ? a[col] : 0) > (b[col] !== "-" ? b[col] : 0)
              ? 1
              : -1
          );
        } else {
          rowArr.sort((a, b) =>
            (a[col] !== "-" ? a[col] : 0) > (b[col] !== "-" ? b[col] : 0)
              ? -1
              : 1
          );
        }
        setRows(rowArr);
        setAllPartner(rowArr);
      } else {
        setRows(res);
        setAllPartner(res);
      }
    });
  };

  //hadnle zoom for logo
  const handleZoomImage = (e) => {
    setIsZoomImg(true);
    setZoomImg(e.target.src);
  };

  // function for partner clone api
  const handleClonePartner = async () => {
    setClone(false);
    setCloning(true);
    authServicePartner
      .clonePartner({ partnerId: claimId })
      .then((resPartner) => {
        if (resPartner.status) {
          setCloning(false);
          setCloneSuccess(true);
          getAllPartnerApi(
            1,
            rowsPerPage,
            searchText,
            selectedBadge,
            fromDate,
            toDate,
            filterType,
            selectedType,
            selectedTag,
            sortList.sortField,
            sortList.sortType,
            selectedField
          );
          setCloneMsg(resPartner.data.message);
          setTimeout(() => {
            setCloneSuccess(false);
            setCloneMsg("");
          }, 3000);
        }
      });
  };

  // filteration function by badge
  const handleBadgeSearch = (e) => {
    if (
      e.target.value !== undefined &&
      e.target.value !== null &&
      e.target.value !== ""
    ) {
      setIsBadgeFilter(true);
    } else {
      setIsBadgeFilter(false);
    }
    setSelectedBadge(e.target.value);
    setDataLoader(true);
    getAllPartnerApi(
      page + 1,
      rowsFromUrl !== null ? rowsFromUrl : rowsPerPage,
      searchText,
      e.target.value,
      fromDate,
      toDate,
      filterType,
      selectedType,
      selectedTag,
      sortList.sortField,
      sortList.sortType,
      selectedField
    );
  };

  //called when filter change
  const handleOnChangeFilterType = (e) => {
    setFilterType(e.target.value);
    const filter = e.target.value;
    setShowCustomFilter(false);
    let fromDateTime = "";
    let toDateTime = "";

    if (filter === "first24hrs") {
      var fromdate = moment().subtract(24, "hours").format("DD MMM HH:mm");
      var todate = moment().format("DD MMM HH:mm");

      setFilterHeader(fromdate + "-" + todate);
    } else {
      var addFilterCount = 0;
      if (filter === "past7days") {
        addFilterCount = 6;
      } else if (filter === "past28days") {
        addFilterCount = 27;
      } else if (filter === "past90days") {
        addFilterCount = 89;
      } else if (filter === "past365days") {
        addFilterCount = 364;
      }

      if (filter === "lifetime") {
        setFilterHeader("Lifetime");
      } else if (filter === "past365days") {
        var fromdate = moment()
          .subtract(addFilterCount, "days")
          .format("DD MMM, YYYY");
        var todate = moment().format("DD MMM, YYYY");
        setFilterHeader(fromdate + "-" + todate);
      } else if (filter === "custom") {
        setDataLoader(true);
        fromDateTime = moment(fromDate).format("YYYY-MM-DD");
        toDateTime = moment(toDate).format("YYYY-MM-DD");
        var fromdate = moment(fromDate).format("DD MMM, YYYY");
        var todate = moment(toDate).format("DD MMM, YYYY");
        search !== null
          ? getAllPartnerApi(
              1,
              rowsPerPage,
              search,
              selectedBadge,
              fromDateTime,
              toDateTime,
              filter,
              selectedType,
              selectedTag,
              sortList.sortField,
              sortList.sortType,
              selectedField
            )
          : getAllPartnerApi(
              1,
              rowsPerPage,
              searchText,
              selectedBadge,
              fromDateTime,
              toDateTime,
              filter,
              selectedType,
              selectedTag,
              sortList.sortField,
              sortList.sortType,
              selectedField
            );
        setFilterHeader(fromdate + "-" + todate);
        setShowCustomFilter(true);
      } else {
        var fromdate = moment()
          .subtract(addFilterCount, "days")
          .format("DD MMM");
        var todate = moment().format("DD MMM");
        setFilterHeader(fromdate + "-" + todate);
      }
    }
    if (filter !== "custom") {
      setDataLoader(true);
      search !== null
        ? getAllPartnerApi(
            1,
            rowsPerPage,
            search,
            selectedBadge,
            fromDate,
            toDate,
            filter,
            selectedType,
            selectedTag,
            sortList.sortField,
            sortList.sortType,
            selectedField
          )
        : getAllPartnerApi(
            1,
            rowsPerPage,
            searchText,
            selectedBadge,
            fromDate,
            toDate,
            filter,
            selectedType,
            selectedTag,
            sortList.sortField,
            sortList.sortType,
            selectedField
          );
    }
  };

  //check date changed or not
  const handleDateChange = (date, setDateFunction, dateRef) => {
    setIsChangeDate(false);
    if (date !== dateRef.current) {
      setDateFunction(date);
      setIsChangeDate(true);
    }
  };
  //called when calender close
  const handleCalenderClose = () => {
    if (filterType === "custom") {
      var fromdate = moment(fromDate).format("DD MMM, YYYY");
      var todate = moment(toDate).format("DD MMM, YYYY");
      setFilterHeader(fromdate + "-" + todate);
    }
    if (filterType === "custom") {
      if (
        moment(new Date(fromDate)).format("YYYY-MM-DD") >=
        moment(new Date(toDate)).format("YYYY-MM-DD")
      ) {
        setShowalert(true);
        setAlertMsg("Fromdate should be less than Todate!");
        setTimeout(() => {
          setShowalert(false);
          setAlertMsg("");
        }, 3000);
      } else {
        if (fromDate !== "" && toDate !== "" && isChangeDate) {
          setDataLoader(true);
          search !== null
            ? getAllPartnerApi(
                1,
                rowsPerPage,
                search,
                selectedBadge,
                fromDate,
                toDate,
                filterType,
                selectedType,
                selectedTag,
                sortList.sortField,
                sortList.sortType,
                selectedField
              )
            : getAllPartnerApi(
                1,
                rowsPerPage,
                searchText,
                selectedBadge,
                fromDate,
                toDate,
                filterType,
                selectedType,
                selectedTag,
                sortList.sortField,
                sortList.sortType,
                selectedField
              );
        }
      }
    }
  };

  //handle drag and reorder rows of partners
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const updatedRows = Array.from(rows);
    const [movedRow] = updatedRows.splice(result.source.index, 1);
    updatedRows.splice(result.destination.index, 0, movedRow);
    setRows(updatedRows);
    const selectedPartners = updatedRows.map((item) => item.action);
    const ids = {
      ids: selectedPartners,
    };
    authServicePartner.badgeWiseReorderPartner(ids);
  };

  // handle partner type filter change
  const handleOnChangePartnerType = (e) => {
    setSelectedType(e.target.value);
    setDataLoader(true);
    getAllPartnerApi(
      1,
      rowsPerPage,
      searchText,
      selectedBadge,
      fromDate,
      toDate,
      filterType,
      e.target.value,
      selectedTag,
      sortList.sortField,
      sortList.sortType,
      selectedField
    );
    setPage(0);
  };

  // handle partner type filter change
  const handleOnChangeStastic = (e) => {
    setSelectedField(e.target.value);
    setDataLoader(true);
    getAllPartnerApi(
      1,
      rowsPerPage,
      searchText,
      selectedBadge,
      fromDate,
      toDate,
      filterType,
      selectedType,
      selectedTag,
      sortList.sortField,
      sortList.sortType,
      e.target.value
    );
    setPage(0);
  };

  // handle partner type filter change
  const handleOnChangeTag = (e) => {
    setSelectedTag(e.target.value);
    setDataLoader(true);
    getAllPartnerApi(
      1,
      rowsPerPage,
      searchText,
      selectedBadge,
      fromDate,
      toDate,
      filterType,
      selectedType,
      e.target.value,
      sortList.sortField,
      sortList.sortType,
      selectedField
    );
    setPage(0);
  };

  const onEscCloseModal = (e) => {
    if (viewModalOpen && e.key === "Escape") {
      setViewModalOpen(false);
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", onEscCloseModal);
    }
    if (claimModal && e.key === "Escape") {
      setClaimModal(false);
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", onEscCloseModal);
    }

    if (ratingModal && e.key === "Escape") {
      setRatingmodal(false);
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", onEscCloseModal);
    }
  };
  document.addEventListener("keydown", onEscCloseModal);
  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main">
          <div className="uic-title">
            <h1>All partners</h1>
            <div className="uic-fl-btn">
              <button
                className="btn"
                onClick={() => {
                  dispatch({ type: "PAGECHANGE", payload: false });
                  dispatch({
                    type: "NAVIGATEPAGE",
                    payload: `${window.location.pathname}?search=`,
                  });
                  navigate("/partner/createpartner");
                }}
              >
                Add partner
              </button>
            </div>
          </div>
          <div className="uic-fl">
            <div className="uic-fl-search">
              <input
                type="text"
                placeholder="Search"
                className="search-user-input"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  var currentValue;
                  if (e.target.value) {
                    currentValue = e.target.value;
                  } else {
                    currentValue = e.target.value;
                    setSearchText(currentValue);
                  }
                  setActualsearch(currentValue);
                  if (
                    suggestionList !== undefined &&
                    suggestionList.length > 0
                  ) {
                    setListIndex(-1);
                    setSuggestionBox(true);
                    var searchResult = suggestionList.filter((user) => {
                      return user.name
                        .toLowerCase()
                        .includes(e.target.value.trim().toLowerCase());
                    });
                    setSuggestions(searchResult);
                  }
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(e.target.value);
                  } else if (e.key === "ArrowDown") {
                    const listLength =
                      suggestions.length > 15 ? 15 : suggestions.length;
                    if (listIndex < listLength - 1) {
                      setListIndex(listIndex + 1);
                    } else if (listIndex === listLength - 1) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    }
                  } else if (e.key === "ArrowUp") {
                    e.preventDefault();
                    const listLength =
                      suggestions.length > 15 ? 15 : suggestions.length;
                    if (listIndex === 0) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    } else if (listIndex === -1) {
                      setListIndex(listLength - 1);
                    } else {
                      setListIndex(listIndex - 1);
                    }
                  }
                }}
                onBlur={() => setSuggestionBox(false)}
              />
              {suggestionBox &&
                suggestions.length > 0 &&
                searchText.length > 0 && (
                  <div className="suggestion-box">
                    <ul id="suggestion-list">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onMouseDown={() => handleSearch(suggestion.name)}
                          className={
                            index === listIndex ? "selected-active" : ""
                          }
                        >
                          {searchText !== null &&
                          searchText.length > 0 &&
                          suggestion.name
                            .toLowerCase()
                            .startsWith(searchText.toLowerCase()) ? (
                            <>
                              <b>
                                {suggestion.name.slice(0, searchText.length)}
                              </b>
                              {suggestion.name &&
                                suggestion.name.slice(
                                  searchText.length,
                                  suggestion.name.length
                                )}{" "}
                            </>
                          ) : (
                            suggestion.name
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              <i
                className="fa fa-search"
                onClick={() => handleSearch(searchText)}
              ></i>
            </div>
            <div className="uic-fl-btn sort-by-mn">
              <div className="sort-by-mn-title">Filter by : </div>
              <div className="cstm-select-ap">
                <select value={selectedBadge} onChange={handleBadgeSearch}>
                  <option value="">All Badges</option>
                  {badgeList.map((badges) => (
                    <option value={badges._id}>{badges.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="uic-fl-btn sort-by-mn lifetime-only">
              {/* <div className="sort-by-mn-title">:</div> */}
              <div className="cstm-select-ap">
                <select onChange={handleOnChangeFilterType}>
                  <option
                    value="lifetime"
                    selected={filterType === "lifetime" ? true : false}
                  >
                    Lifetime
                  </option>
                  <option
                    value="first24hrs"
                    selected={filterType === "first24hrs" ? true : false}
                  >
                    Last 24 h
                  </option>
                  <option
                    value="past7days"
                    selected={filterType === "past7days" ? true : false}
                  >
                    Last 7 Days
                  </option>
                  <option
                    value="past28days"
                    selected={filterType === "past28days" ? true : false}
                  >
                    Last 30 Days
                  </option>
                  <option
                    value="past90days"
                    selected={filterType === "past90days" ? true : false}
                  >
                    Last 90 Days
                  </option>
                  <option
                    value="past365days"
                    selected={filterType === "past365days" ? true : false}
                  >
                    Last 365 Days
                  </option>
                  <option
                    value="custom"
                    selected={filterType === "custom" ? true : false}
                  >
                    Custom
                  </option>
                </select>
              </div>
              {filterType === "custom" && showCustomFilter ? (
                <div className="cstm-select-ap-drop cstm-datepicker-field ">
                  <DatePicker
                    showIcon
                    selected={fromDate}
                    onChange={(date) =>
                      handleDateChange(date, setFromDate, fromDateRef)
                    }
                    onCalendarClose={handleCalenderClose}
                  />
                  <img src={arrowimg} className="cstm-arrow-left-right" />
                  <DatePicker
                    showIcon
                    selected={toDate}
                    onChange={(date) =>
                      handleDateChange(date, setToDate, toDateRef)
                    }
                    onCalendarClose={handleCalenderClose}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            {/* {filterType !== "lifetime" && (
              <div className="uic-fl-btn sort-by-mn lifetime-only">
                <div className="cstm-select-ap">
                  <select
                    value={selectedField}
                    onChange={handleOnChangeStastic}
                  >
                    <option value="">Select</option>
                    <option value="pageView">Page views</option>
                    <option value="claim">Claims</option>
                    <option value="rating">Ratings</option>
                  </select>
                </div>
              </div>
            )} */}
            <div className="uic-fl-btn sort-by-mn lifetime-only">
              <div className="cstm-select-ap">
                <select
                  value={selectedType}
                  onChange={handleOnChangePartnerType}
                >
                  <option value="all">Select Type</option>
                  <option value="offer">Offer</option>
                  <option value="perk">Perk</option>
                </select>
              </div>
            </div>
            <div className="uic-fl-btn sort-by-mn lifetime-only">
              <div className="cstm-select-ap">
                <select value={selectedTag} onChange={handleOnChangeTag}>
                  <option value="">Select Tag</option>
                  {tagList.map((tag) => (
                    <option value={tag._id}>{tag.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {showAlert && <div className="alert alert-info">{alertMsg}</div>}
          <Paper className="uic-tb-mn">
            {!isBadgeFilter ? (
              <TableContainer className="uic-table-main">
                <Table className="uic-table">
                  <TableHead className="uic-table-th">
                    <TableRow>
                      {columns.map(
                        (col) =>
                          col.id !== "drag" && (
                            <TableCell
                              key={col.id}
                              align={col.align}
                              className="uic-th-td"
                            >
                              {col.id !== "action" &&
                              col.id !== "logo" &&
                              col.id !== "mdspartner" &&
                              col.id !== "drag" ? (
                                <>
                                  <span className="custom-sorting">
                                    <i
                                      className="fa fa-angle-down"
                                      onClick={(e) => {
                                        handleSort(col.id, "Asc");
                                      }}
                                    ></i>
                                    <i
                                      className="fa fa-angle-up"
                                      onClick={(e) => {
                                        handleSort(col.id, "Dec");
                                      }}
                                    ></i>
                                  </span>
                                </>
                              ) : (
                                ""
                              )}
                              {col.label}
                            </TableCell>
                          )
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody className="uic-table-td">
                    {dataLoader ? (
                      <div className="cstm-data-load">
                        <img src={Loader} />{" "}
                      </div>
                    ) : rows.length > 0 ? (
                      rows.map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            className={`uic-th-td ${
                              row.dateDifference > 180 ? "expired-only" : ""
                            }`}
                          >
                            {columns.map((column) => {
                              if (column.id != "drag") {
                                const value = row[column.id];
                                {
                                  /* <TableCell className="uic-th-td w-8" onClick={handleZoomImage} ></TableCell> : */
                                }
                                return column.id === "companyName" ? (
                                  <TableCell className="uic-th-td w-17">
                                    <div className="cstm-lg-ttl">
                                      {row.logo !== "" ? (
                                        <img
                                          className="cstm-url-link"
                                          src={row.logo}
                                          alt="image"
                                          onClick={handleZoomImage}
                                        />
                                      ) : (
                                        <div className="cstm-partner-avar">
                                          {row[column.id]
                                            ? row[column.id].charAt(0)
                                            : ""}
                                        </div>
                                      )}
                                      <Link
                                        to={`/partner/detail?id=${row.action}`}
                                        onClick={() => {
                                          dispatch({
                                            type: "PAGECHANGE",
                                            payload: false,
                                          });
                                          dispatch({
                                            type: "NAVIGATEPAGE",
                                            payload: `/partner/list?search=${search}&&perpage=${rowsPerPage}`,
                                          });
                                          dispatch({
                                            type: "isMDSPartner",
                                            payload: row.mdspartner,
                                          });
                                        }}
                                        className="cstm-view-users-count"
                                      >
                                        {value}
                                      </Link>
                                    </div>
                                  </TableCell>
                                ) : column.id === "mdspartner" ? (
                                  <TableCell className="uic-th-td w-9">
                                    {value === true ? "Yes" : "No"}
                                  </TableCell>
                                ) : column.id === "partnerType" ? (
                                  <TableCell className="uic-th-td w-7">
                                    {value === "nobadge"
                                      ? "-"
                                      : value.charAt(0).toUpperCase() +
                                        value.slice(1)}
                                  </TableCell>
                                ) : // column.id === 'status' ? <TableCell className="uic-th-td w-7">{value === "published" ? "Live" : value.charAt(0).toUpperCase() + value.slice(1)}</TableCell> :
                                column.id === "status" ? (
                                  <TableCell className="uic-th-td w-9">
                                    {value === "published" ? (
                                      <>
                                        <div className="partner-status-select">
                                          <a>
                                            <img src={show} />
                                          </a>
                                          <select
                                            className="status-select-only"
                                            disabled={row.status === "draft"}
                                            value={row.status}
                                            onChange={() =>
                                              updatePartnerStatusApi(
                                                row.action,
                                                row.status === "published"
                                                  ? "paused"
                                                  : "published"
                                              )
                                            }
                                          >
                                            <option value="published">
                                              Live
                                            </option>
                                            <option value="paused">
                                              Pause
                                            </option>
                                          </select>
                                        </div>
                                      </>
                                    ) : value === "paused" ? (
                                      <>
                                        <div className="partner-status-select">
                                          <a>
                                            <img src={hide} />
                                          </a>
                                          <select
                                            className="status-select-only"
                                            disabled={row.status === "draft"}
                                            value={row.status}
                                            onChange={() =>
                                              updatePartnerStatusApi(
                                                row.action,
                                                row.status === "published"
                                                  ? "paused"
                                                  : "published"
                                              )
                                            }
                                          >
                                            <option value="published">
                                              Live
                                            </option>
                                            <option value="paused">
                                              Pause
                                            </option>
                                          </select>
                                        </div>
                                      </>
                                    ) : (
                                      <a
                                        className="cstm-view-users-count"
                                        data-toggle="modal"
                                        data-target="#editEvent"
                                        onClick={() => {
                                          navigate(
                                            `/partner/detail?id=${row.action}`
                                          );
                                          dispatch({
                                            type: "PAGECHANGE",
                                            payload: false,
                                          });
                                          dispatch({
                                            type: "NAVIGATEPAGE",
                                            payload: `/partner/list?search=${search}&&perpage=${rowsPerPage}`,
                                          });
                                          dispatch({
                                            type: "isMDSPartner",
                                            payload: row.mdspartner,
                                          });
                                        }}
                                      >
                                        Edit Draft
                                      </a>
                                    )}
                                  </TableCell>
                                ) : column.id === "createdAt" ? (
                                  <TableCell className="uic-th-td w-10">
                                    {value}
                                  </TableCell>
                                ) : column.id === "updatedAt" ? (
                                  <TableCell className="uic-th-td w-10">
                                    {value}
                                  </TableCell>
                                ) : column.id === "approvedReviews" ? (
                                  <TableCell className="uic-th-td w-8">
                                    {value}
                                  </TableCell>
                                ) : column.id === "pageView" ? (
                                  <TableCell className="uic-th-td w-8">
                                    {" "}
                                    <a
                                      className="cstm-view-users-count"
                                      onClick={(e) => {
                                        setViewModalOpen(true);
                                        document.body.classList.add(
                                          "modal-open"
                                        );
                                        setClaimId(row.action);
                                      }}
                                    >
                                      {value}
                                    </a>
                                  </TableCell>
                                ) : column.id === "claims" ? (
                                  <TableCell className="uic-th-td w-7">
                                    <a
                                      className="cstm-view-users-count"
                                      onClick={() => {
                                        setClaimModal(true);
                                        document.body.classList.add(
                                          "modal-open"
                                        );
                                        setClaimId(row.action);
                                      }}
                                    >
                                      {value}
                                    </a>
                                  </TableCell>
                                ) : column.id === "rating" ? (
                                  <TableCell className="uic-th-td w-7">
                                    <a
                                      className={`${
                                        value > 0 ? "cstm-view-users-count" : ""
                                      }`}
                                      onClick={() => {
                                        value > 0 && setRatingmodal(true);
                                        value > 0 &&
                                          document.body.classList.add(
                                            "modal-open"
                                          );
                                        value > 0 && setClaimId(row.action);
                                        setReviewName(row.companyName);
                                      }}
                                                      >
                                      {value > 0
                                        ? Number.isInteger(value)
                                          ? value.toFixed(2)
                                          : value.toFixed(2)
                                        : "-"}
                                    </a>
                                  </TableCell>
                                ) : (
                                  <TableCell className="uic-th-td w-10 uic-table-icon ">
                                    <Tooltip title="Clone" arrow>
                                      <a
                                        data-toggle="modal"
                                        data-target="#clonePartner"
                                        onClick={() => {
                                          setClone(true);
                                          setClaimId(value);
                                        }}
                                      >
                                        <img src={copyimg} />
                                      </a>
                                    </Tooltip>
                                    <Link
                                      to={`/partner/detail?id=${value}`}
                                      onClick={() => {
                                        dispatch({
                                          type: "PAGECHANGE",
                                          payload: false,
                                        });
                                        dispatch({
                                          type: "NAVIGATEPAGE",
                                          payload: `/partner/list?search=${search}&&perpage=${rowsPerPage}`,
                                        });
                                        dispatch({
                                          type: "isMDSPartner",
                                          payload: row.mdspartner,
                                        });
                                      }}
                                    >
                                      <Tooltip title="Edit" arrow>
                                        <img src={editfill} />
                                      </Tooltip>
                                    </Link>
                                    <a
                                      data-toggle="modal"
                                      data-target="#DeleteFromPartner"
                                      onClick={() => setDeleteId(value)}
                                    >
                                      <Tooltip title="Delete" arrow>
                                        <img src={imgdelete} />
                                      </Tooltip>
                                    </a>
                                  </TableCell>
                                );
                              }
                            })}
                          </TableRow>
                        );
                      })
                    ) : (
                      <span className="no-data-found">No data found</span>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <DragDropContext onDragEnd={onDragEnd}>
                <TableContainer className="uic-table-main">
                  <Table className="uic-table">
                    <TableHead className="uic-table-th">
                      <TableRow>
                        {columns.map((col) => (
                          <TableCell
                            key={col.id}
                            align={col.align}
                            className="uic-th-td"
                          >
                            {col.id !== "action" &&
                            col.id !== "logo" &&
                            col.id !== "mdspartner" &&
                            col.id !== "drag" ? (
                              <>
                                <span className="custom-sorting">
                                  <i
                                    className="fa fa-angle-down"
                                    onClick={(e) => {
                                      handleSort(col.id, "Asc");
                                    }}
                                  ></i>
                                  <i
                                    className="fa fa-angle-up"
                                    onClick={(e) => {
                                      handleSort(col.id, "Dec");
                                    }}
                                  ></i>
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                            {col.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <Droppable droppableId="table">
                      {(provided) => (
                        <TableBody
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className="uic-table-td"
                        >
                          {dataLoader ? (
                            <div className="cstm-data-load">
                              <img src={Loader} />{" "}
                            </div>
                          ) : rows.length > 0 ? (
                            rows.map((row, index) => {
                              return (
                                <Draggable
                                  key={row.action}
                                  draggableId={row.action}
                                  index={index}
                                >
                                  {(provided) => (
                                    <TableRow
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                      className={`uic-th-td ${
                                        row.dateDifference > 180
                                          ? "expired-only"
                                          : ""
                                      }`}
                                    >
                                      {columns.map((column) => {
                                        const value = row[column.id];
                                        {
                                          /* <TableCell className="uic-th-td w-8" onClick={handleZoomImage} ></TableCell> : */
                                        }
                                        return column.id === "companyName" ? (
                                          <TableCell className="uic-th-td w-17">
                                            <div className="cstm-lg-ttl">
                                              {row.logo !== "" ? (
                                                <img
                                                  className="cstm-url-link"
                                                  src={row.logo}
                                                  alt="image"
                                                  onClick={handleZoomImage}
                                                />
                                              ) : (
                                                <div className="cstm-partner-avar">
                                                  {row[column.id]
                                                    ? row[column.id].charAt(0)
                                                    : ""}
                                                </div>
                                              )}
                                              <Link
                                                to={`/partner/detail?id=${row.action}`}
                                                onClick={() => {
                                                  dispatch({
                                                    type: "PAGECHANGE",
                                                    payload: false,
                                                  });
                                                  dispatch({
                                                    type: "NAVIGATEPAGE",
                                                    payload: `/partner/list?search=${search}&&perpage=${rowsPerPage}`,
                                                  });
                                                  dispatch({
                                                    type: "isMDSPartner",
                                                    payload: row.mdspartner,
                                                  });
                                                }}
                                                className="cstm-view-users-count"
                                              >
                                                {value}
                                              </Link>
                                            </div>
                                          </TableCell>
                                        ) : column.id === "mdspartner" ? (
                                          <TableCell className="uic-th-td w-9">
                                            {value === true ? "Yes" : "No"}
                                          </TableCell>
                                        ) : column.id === "partnerType" ? (
                                          <TableCell className="uic-th-td w-11">
                                            {value.charAt(0).toUpperCase() +
                                              value.slice(1)}
                                          </TableCell>
                                        ) : // column.id === 'status' ? <TableCell className="uic-th-td w-7">{value === "published" ? "Live" : value.charAt(0).toUpperCase() + value.slice(1)}</TableCell> :
                                        column.id === "drag" ? (
                                          <TableCell className="uic-th-td w-4">
                                            <i class="fas fa-arrows-alt banner-drag-drop"></i>
                                          </TableCell>
                                        ) : column.id === "status" ? (
                                          <TableCell className="uic-th-td w-9">
                                            {value === "published" ? (
                                              <>
                                                <div className="partner-status-select">
                                                  <a>
                                                    <img src={show} />
                                                  </a>
                                                  <select
                                                    className="status-select-only"
                                                    disabled={
                                                      row.status === "draft"
                                                    }
                                                    value={row.status}
                                                    onChange={() =>
                                                      updatePartnerStatusApi(
                                                        row.action,
                                                        row.status ===
                                                          "published"
                                                          ? "paused"
                                                          : "published"
                                                      )
                                                    }
                                                  >
                                                    <option value="published">
                                                      Live
                                                    </option>
                                                    <option value="paused">
                                                      Pause
                                                    </option>
                                                  </select>
                                                </div>
                                              </>
                                            ) : value === "paused" ? (
                                              <>
                                                <div className="partner-status-select">
                                                  <a>
                                                    <img src={hide} />
                                                  </a>
                                                  <select
                                                    className="status-select-only"
                                                    disabled={
                                                      row.status === "draft"
                                                    }
                                                    value={row.status}
                                                    onChange={() =>
                                                      updatePartnerStatusApi(
                                                        row.action,
                                                        row.status ===
                                                          "published"
                                                          ? "paused"
                                                          : "published"
                                                      )
                                                    }
                                                  >
                                                    <option value="published">
                                                      Live
                                                    </option>
                                                    <option value="paused">
                                                      Pause
                                                    </option>
                                                  </select>
                                                </div>
                                              </>
                                            ) : (
                                              <a
                                                className="cstm-view-users-count"
                                                data-toggle="modal"
                                                data-target="#editEvent"
                                                onClick={() => {
                                                  navigate(
                                                    `/partner/detail?id=${row.action}`
                                                  );
                                                  dispatch({
                                                    type: "PAGECHANGE",
                                                    payload: false,
                                                  });
                                                  dispatch({
                                                    type: "NAVIGATEPAGE",
                                                    payload: `/partner/list?search=${search}&&perpage=${rowsPerPage}`,
                                                  });
                                                  dispatch({
                                                    type: "isMDSPartner",
                                                    payload: row.mdspartner,
                                                  });
                                                }}
                                              >
                                                Edit Draft
                                              </a>
                                            )}
                                          </TableCell>
                                        ) : column.id === "createdAt" ? (
                                          <TableCell className="uic-th-td w-10">
                                            {value}
                                          </TableCell>
                                        ) : column.id === "updatedAt" ? (
                                          <TableCell className="uic-th-td w-10">
                                            {value}
                                          </TableCell>
                                        ) : column.id === "approvedReviews" ? (
                                          <TableCell className="uic-th-td w-10">
                                            {value}
                                          </TableCell>
                                        ) : column.id === "pageView" ? (
                                          <TableCell className="uic-th-td w-9">
                                            {" "}
                                            <a
                                              className="cstm-view-users-count"
                                              onClick={(e) => {
                                                setViewModalOpen(true);
                                                document.body.classList.add(
                                                  "modal-open"
                                                );
                                                setClaimId(row.action);
                                              }}
                                            >
                                              {value}
                                            </a>
                                          </TableCell>
                                        ) : column.id === "claims" ? (
                                          <TableCell className="uic-th-td w-7">
                                            <a
                                              className="cstm-view-users-count"
                                              onClick={() => {
                                                setClaimModal(true);
                                                document.body.classList.add(
                                                  "modal-open"
                                                );
                                                setClaimId(row.action);
                                              }}
                                            >
                                              {value}
                                            </a>
                                          </TableCell>
                                        ) : column.id === "rating" ? (
                                          <TableCell className="uic-th-td w-7">
                                            <a
                                              className={`${
                                                value > 0
                                                  ? "cstm-view-users-count"
                                                  : ""
                                              }`}
                                              onClick={() => {
                                                value > 0 &&
                                                  setRatingmodal(true);
                                                document.body.classList.add(
                                                  "modal-open"
                                                );
                                                value > 0 &&
                                                  setClaimId(row.action);
                                                setReviewName(row.companyName);
                                              }}
                                            >
                                              {value}
                                            </a>
                                          </TableCell>
                                        ) : (
                                          <TableCell className="uic-th-td w-9 uic-table-icon ">
                                            <Tooltip title="Clone" arrow>
                                              <a
                                                data-toggle="modal"
                                                data-target="#clonePartner"
                                                onClick={() => {
                                                  setClone(true);
                                                  setClaimId(value);
                                                }}
                                              >
                                                <img src={copyimg} />
                                              </a>
                                            </Tooltip>
                                            <a
                                              data-toggle="modal"
                                              data-target="#editEvent"
                                              onClick={() => {
                                                navigate(
                                                  `/partner/detail?id=${value}`
                                                );
                                                dispatch({
                                                  type: "PAGECHANGE",
                                                  payload: false,
                                                });
                                                dispatch({
                                                  type: "NAVIGATEPAGE",
                                                  payload: `/partner/list?search=${search}&&perpage=${rowsPerPage}`,
                                                });
                                                dispatch({
                                                  type: "isMDSPartner",
                                                  payload: row.mdspartner,
                                                });
                                              }}
                                            >
                                              <Tooltip title="Edit" arrow>
                                                <img src={editfill} />
                                              </Tooltip>
                                            </a>
                                            <a
                                              data-toggle="modal"
                                              data-target="#DeleteFromPartner"
                                              onClick={() => setDeleteId(value)}
                                            >
                                              <Tooltip title="Delete" arrow>
                                                <img src={imgdelete} />
                                              </Tooltip>
                                            </a>
                                          </TableCell>
                                        );
                                      })}
                                    </TableRow>
                                  )}
                                </Draggable>
                              );
                            })
                          ) : (
                            <span className="no-data-found">No data found</span>
                          )}
                        </TableBody>
                      )}
                    </Droppable>
                  </Table>
                </TableContainer>
              </DragDropContext>
            )}
            {!isBadgeFilter && !dataLoader && (
              <TablePagination
                className="uic-pagination"
                rowsPerPageOptions={[20, 50, 100, 200]}
                component="div"
                count={totalData}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Paper>
        </div>
      </div>
      <div
        className="modal fade msg-popup"
        id="DeleteFromPartner"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              &times;
            </button>
            <div className="msg-icon">
              <img src={imgBigDelete} />
            </div>
            <h1>Are you sure you want to delete this partner?</h1>
            <div className="row">
              <div className="col-6">
                {inProcess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <span className="msg-btn">
                    <button
                      type="button"
                      className="btn btn-red"
                      onClick={partnerDeleteApi}
                    >
                      Delete
                    </button>
                  </span>
                )}
              </div>
              <div className="col-6">
                <span className="msg-btn">
                  <button type="button" className="btn2" data-dismiss="modal">
                    Discard
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* zoomModel */}
      {isZoomImg && zoomImg !== null && (
        <div className="desk-globle-model-box globle-model-box cstm-zoom-img-popup cstm-company-logo">
          <div className="desk-globle-model-box-inner cstm-zoom-img-popup">
            <div className="modal-content">
              <div className="cstm-replace-pp">
                <div className="cstm-rpp-title">
                  Logo Image
                  <span
                    className="cstm-rpp-close-btn"
                    onClick={() => setIsZoomImg(false)}
                  >
                    &times;
                  </span>
                </div>

                {zoomImg && (
                  <div id="zoomImgContainer" className="zoomImgContainer">
                    <img id="zoomImg" src={zoomImg} className="cstmzoomImg" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {cloning && (
        <div className="cstm-clone-msg-container">
          <h3>Cloning Record...</h3>
          <p>Please wait while the record is being cloned.</p>
        </div>
      )}
      {cloneSuccess && (
        <div className="cstm-success-msg-container">
          <div className="cstm-success-msg-container-title">{cloneMsg}</div>
        </div>
      )}
      {viewModalOpen && (
        <ViewsModal
          filterType={filterType}
          fromDate={fromDate}
          toDate={toDate}
          setViewModalOpen={setViewModalOpen}
          claimId={claimId}
        />
      )}
      {claimModal && (
        <ClaimModal
          filterType={filterType}
          fromDate={fromDate}
          toDate={toDate}
          claimModal={claimModal}
          claimId={claimId}
          setClaimModal={setClaimModal}
        />
      )}
      <CloneModal
        id="clonePartner"
        setClone={setClone}
        handleClonePartner={handleClonePartner}
        setCloning={setCloning}
      />
      {ratingModal && (
        <ReviewModal
          filterType={filterType}
          fromDate={fromDate}
          toDate={toDate}
          reviewName={reviewName}
          setRatingmodal={setRatingmodal}
          claimId={claimId}
        />
      )}
    </>
  );
};

export default PartnerList;
