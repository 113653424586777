import React, { useEffect, useState } from "react";
import Header from "../../UserComponents/Dashboard/Header";
import { useNavigate, useParams } from "react-router-dom";
import LeftSidebar from "../../UserComponents/Dashboard/LeftSidebar";
import PartnerMainPage from "./PartnerMainPage";
import PartnerInfo from "./PartnerInfo";
import Partners from "./Partners";
import PageNotFound from "../../PageNotFound";
import AccessDeny from "../../AccessDeny";
import authService from "../../../Services/auth.service";
import PartnerListModal from "./RestrictionModal/PartnerListModal";
import PartnerMainModal from "./RestrictionModal/PartnerMainModal";
import RatingModal from "./RestrictionModal/RatingModal";
import SuccessModal from "./RestrictionModal/SuccessModal";
const index = () => {
  const { action, id } = useParams();
  const navigate = useNavigate();
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selected, setSelected] = useState("list");
  const [selectedDiv, setSelectedDiv] = useState("dashboard");
  const [leftSidebar, setLeftSidebar] = useState(true);
  const [topicSearch, setTopicSearch] = useState("");
  const [pageNotFound, setPageNotFound] = useState(false);
  const [isReview, setReview] = useState(false);
  const [isList, setList] = useState(false);
  const [partnerList, setPartnerList] = useState([]);
  const [allPartner, setAllPartner] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState([]);
  const [isRating, setRating] = useState(false);
  const [isSuccsess, setSuccess] = useState(false);
  const [reviewCount, setReviewCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [loader, setLoader] = useState(false);
  const [pendingReview, setPendingReview] = useState(0);
  const [cleanPathName, setCleanPathName] = useState("");

  // when acton from path is update this useEffect will call
  useEffect(() => {
    const cleanedPathname = window.location.pathname.replace(/\/$/, "");

    setCleanPathName(cleanedPathname);
    if (
      localStorage.getItem("idtoken") === null ||
      localStorage.getItem("idtoken").length === 0 ||
      localStorage.getItem("userid") === null ||
      localStorage.getItem("userid").length === 0 ||
      localStorage.getItem("username") === null ||
      localStorage.getItem("username").length === 0 ||
      localStorage.getItem("role") === null ||
      localStorage.getItem("role") !== "user" ||
      localStorage.getItem("logintime") === undefined ||
      localStorage.getItem("logintime") === null
    ) {
      window.location.href = "/";
    }
    if (action !== undefined) {
      setSelected(action);
    } else if (
      cleanedPathname !== "/partners" &&
      action !== "all-partners" &&
      id === undefined
    ) {
      setPageNotFound(true);
    } else {
      setPageNotFound(false);
    }
    if (localStorage.getItem("count") < 364) {
      authService
        .reviewCountByUser()
        .then((res) => {
          // partner review count error log
          authService.videodetailLog({ type: "partner_review_count_issue", response: res })
          if (res.data.data.countStatus) {
            setReviewCount(res.data.data.reviewCount);
            setPendingReview(res.data.data.reviewCount);
            setReview(true);
            document.body.classList.add("modal-open");
            if (action === "all-partners") {
              navigate(
                "/partners/all-partners?type=offer&&cat=all&&search=&&sortby=relevant&&badge=all"
              );
            }
          } else {
            setPendingReview(3);
            setReviewCount(3);
          }
        })
        .catch((err) => {
          return false;
        });
    }
    selectPartnerListApi(searchText);
    sessionStorage.setItem("previousurl", window.location.href);
  }, [action]);
  // partnerlist api function
  async function selectPartnerListApi(searchText) {
    setLoader(true);
    const partnerListRes = await authService.selectPartnerList(searchText);
    if (partnerListRes.data.status) {
      setLoader(false);
      setPartnerList(partnerListRes.data.data);
      setAllPartner(partnerListRes.data.data);
    }
  }
  const resourceList = localStorage.getItem("accessResources");
  const isCollaborator = JSON.parse(localStorage.getItem("isCollaborator"));
  const partnerResource =
    resourceList !== null &&
    JSON.parse(resourceList).find((resource) => resource.name === "Partners");

  // handle search partner
  const handleSearch = (text) => {
    setSearchText(text);
    if (allPartner !== undefined && allPartner.length > 0) {
      var searchResult = allPartner.filter((partner) => {
        return partner.companyName
          .toLowerCase()
          .includes(text.trim().toLowerCase());
      });
      setPartnerList(searchResult);
    }
  };

  return (
    <>
      {isCollaborator && partnerResource !== undefined && partnerResource ? (
        <>
          <Header
            setSelectedGroup={setSelectedGroup}
            setselectedDiv={setSelectedDiv}
            settopicSearch={setTopicSearch}
          />
          <div
            className={
              leftSidebar
                ? `all-content-main cstm-all-content-main  ${action !== "detail"
                  ? window.location.href.indexOf("partnerlibrary") ||
                    cleanPathName !== "/partners" > -1 ||
                    window.location.hostname ===
                    process.env.REACT_APP_TESTSITE_HOSTNAME
                    ? "cstm-all-library-content-main cstm-all-content-library-main-only"
                    : "cstm-all-library-content-main"
                  : ""
                } ${action === "detail"
                  ? "cstm-all-library-video-details"
                  : "mn-cnt3-wd"
                }`
                : "all-content-main mn-cnt-both"
            }
          >
            {action !== "detail" ? (
              <LeftSidebar
                setSelectedGroup={setSelectedGroup}
                setselectedDiv={setSelectedDiv}
                selectedDiv={selectedDiv}
                SelectedGroup={selectedGroup}
                leftsidebar={leftSidebar}
              />
            ) : (
              <></>
            )}
            <div
              className={
                selected === "listing" || action !== "detail"
                  ? "all-content-main-center all-content-main-center kpa"
                  : "all-content-main-center kpa"
              }
            >
              {cleanPathName === "/partners" ||
                selected === "all-partners" ||
                action === "all-partners" ||
                id === undefined ? (
                <Partners
                  isReview={isReview}
                  reviewCount={reviewCount}
                  setReview={setReview}
                />
              ) : id !== undefined && action === "all-partners" ? (
                <PartnerInfo reviewCount={reviewCount} setReview={setReview} />
              ) : (
                <></>
              )}
            </div>
          </div>
          {pageNotFound && <PageNotFound />}
        </>
      ) : isCollaborator !== null && !isCollaborator ? (
        <>
          <Header
            setSelectedGroup={setSelectedGroup}
            setselectedDiv={setSelectedDiv}
            settopicSearch={setTopicSearch}
          />
          <div
            className={
              leftSidebar
                ? `all-content-main cstm-all-content-main  ${action !== "detail"
                  ? window.location.href.indexOf("partnerlibrary") ||
                    cleanPathName === "/partners" > -1 ||
                    window.location.hostname ===
                    process.env.REACT_APP_TESTSITE_HOSTNAME
                    ? "cstm-all-library-content-main cstm-all-content-library-main-only"
                    : "cstm-all-library-content-main"
                  : ""
                } ${action === "detail"
                  ? "cstm-all-library-video-details"
                  : "mn-cnt3-wd"
                }`
                : "all-content-main mn-cnt-both"
            }
          >
            {action !== "detail" ? (
              <LeftSidebar
                setSelectedGroup={setSelectedGroup}
                setselectedDiv={setSelectedDiv}
                selectedDiv={selectedDiv}
                SelectedGroup={selectedGroup}
                leftsidebar={leftSidebar}
              />
            ) : (
              <></>
            )}
            <div
              className={
                selected === "listing" ||
                  action !== "detail" ||
                  cleanPathName === "/partners"
                  ? "all-content-main-center all-content-main-center"
                  : "all-content-main-center"
              }
            >
              {(cleanPathName === "/partners" ||
                selected === "all-partners" ||
                action === "all-partners") &&
                id === undefined ? (
                <Partners
                  isReview={isReview}
                  reviewCount={reviewCount}
                  setReview={setReview}
                />
              ) : id !== undefined && action === "all-partners" ? (
                <PartnerInfo reviewCount={reviewCount} setReview={setReview} />
              ) : (
                <></>
              )}
            </div>
          </div>
          {pageNotFound && <PageNotFound />}
        </>
      ) : isCollaborator && partnerResource === undefined ? (
        <AccessDeny />
      ) : (
        <></>
      )}
      <PartnerMainModal
        reviewCount={reviewCount}
        isReview={isReview}
        setReview={setReview}
        isList={isList}
        setList={setList}
      />
      <PartnerListModal
        setPendingReview={setPendingReview}
        pendingReview={pendingReview}
        loader={loader}
        setReview={setReview}
        isList={isList}
        setList={setList}
        partnerList={partnerList}
        setSelectedPartner={setSelectedPartner}
        selectedPartner={selectedPartner}
        setRating={setRating}
        handleSearch={handleSearch}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <RatingModal
        setPendingReview={setPendingReview}
        pendingReview={pendingReview}
        reviewCount={reviewCount}
        setReviewCount={setReviewCount}
        isRating={isRating}
        setRating={setRating}
        selectedPartner={selectedPartner}
        setList={setList}
        setSuccess={setSuccess}
      />
      <SuccessModal isSuccsess={isSuccsess} setSuccess={setSuccess} />
    </>
  );
};

export default index;
