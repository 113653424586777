import React from 'react'
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, Location,
    NavigationType,
    useLocation,
    useNavigationType, } from 'react-router-dom';

const SaveDraftModal = ({ handleSaveDraft }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
    const partnerinprocess = useSelector((state) => { return state.ReduPartnerInProcess })

    
    return (
        <>
            <div className="desk-globle-model-box globle-model-box ">
                <div className="desk-globle-model-box-inner cstm-are-you">
                    <span
                        className="close-draft"
                        onClick={() => dispatch({ type: "PAGECHANGE", payload: false })}
                    >
                        &times;
                    </span>
                    <div className="cstm-deactivate-account-main">
                        <div class="cstm-deactivate-account-main-title">
                            <p>Are you sure you want to leave this page?</p>
                            <p className='save-draft-text'>You have unsaved changes that you might want to save as a draft before you go. Your work will be lost if you leave without saving.</p>

                        </div>

                        <div className="ays-cancel-btn-main btn-text-draft">
                            <button className='btn'
                                onClick={handleSaveDraft}
                            >
                                Save as Draft
                            </button>
                            <button className='btn2'
                                onClick={(e) => {
                                    e.preventDefault();
                                    dispatch({ type: "PAGECHANGE", payload: false })
                                    dispatch({ type: "REMOVEPARTNERINPROCESS" })
                                    navigate(NAVIGATEPAGE)
                                }}
                            >
                                Leave Without Saving
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SaveDraftModal