import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import arrowimg from "../../../Images/arow-left-right.png";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useAddonsSuggestionListQuery } from "../../../Services/addons";

import ListAddons from "./ListAddons";
import CreateAddons from "./CreateAddons";
import EditAddons from "./EditAddons";

//Event Addon module
const AddOnsManagement = ({ guestTicketRedirect }) => {
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [listIndex, setListIndex] = useState(-1);
  const [actualsearch, setActualsearch] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionList, setSuggestionList] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [visibilityType, setVisibilityType] = useState("");
  const [fromDate, setFromDate] = useState(
    moment(new Date()).subtract(1, "days").toDate()
  );
  const [toDate, setToDate] = useState(new Date());
  const [filterType, setFilterType] = useState("");
  const [finalSearchedText, setFinalSearchedText] = useState("");
  const [fromDateIsoString, setFromDateIsoString] = useState();
  const [toDateIsoString, setToDateIsoString] = useState();

  //states
  const [isCloseAddModal, setCloseAddModal] = useState(false);
  const [editId, setEditId] = useState("");
  const [forceRender, setForceRender] = useState(false);

  //event id
  const location = useLocation();
  const eventId = new URLSearchParams(location.search).get("id");

  //get all the guest ticket suggestions
  const { data: suggestionData } = useAddonsSuggestionListQuery({
    id: eventId,
  });

  //custom date
  const [isChangeDate, setIsChangeDate] = useState(true);

  const fromDateRef = useRef(fromDate);
  const toDateRef = useRef(toDate);

  useEffect(() => {
    showMainTicketSuggestionList();
  }, [suggestionData]);

  //pricetype filtering
  const handleFilterPriceType = (e) => {
    setSelectedType(e.target.value);
  };

  //filtering based on visibility type
  const handleFilterVisibility = (e) => {
    setVisibilityType(e.target.value);
  };

  //filter date
  const handleOnChangeFilterType = (e) => {
    setFilterType(e.target.value);
    let fromDateNew = new Date(fromDate);
    let fromDateString = fromDateNew.toISOString();

    let todateNew = new Date(toDate);
    let toDateString = todateNew.toISOString();
    if (e.target.value === "custom") {
      setFromDateIsoString(fromDateString);
      setToDateIsoString(toDateString);
    } else {
      setFromDateIsoString("");
      setToDateIsoString("");
    }
  };
    //check date changed or not
    const handleDateChange = (date, setDateFunction, dateRef) => {
      setIsChangeDate(false)
      if (date !== dateRef.current) {
        setDateFunction(date);
        setIsChangeDate(true)
      }
    };
  //called when calender close
  const handleCalenderClose = (e) => {
    let fromDateNew = new Date(fromDate);
    let fromDateString = fromDateNew.toISOString();

    let todateNew = new Date(toDate);
    let toDateString = todateNew.toISOString();
    if (fromDateString !== "" && toDateString !== "" && isChangeDate) {
      setFromDateIsoString(fromDateString);
      setToDateIsoString(toDateString);
    }
  };

  //show addon suggestion list
  const showMainTicketSuggestionList = () => {
    if (suggestionData?.status) {
      let data = suggestionData.data;
      const arr = [];
      if (
        data &&
        data.length !== undefined &&
        data.length !== null &&
        data.length > 0
      ) {
        data.map((item) => arr.push({ name: item.name }));
        setSuggestionList(arr);
      }
    }
  };

  //search using text
  const handleSearch = (text) => {
    setSearchedText(text);
    setSuggestionBox(false);
    setFinalSearchedText(text);
  };

  // on arrow key up and down when list index change that time this useefect call
  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      let currentList =
        document.getElementById("suggestion-list").children[listIndex];
      if (currentList) {
        let text = currentList.innerText;
        setSearchedText(text);
      }
    }
  }, [listIndex]);

  //get the edit id
  const handleEditId = (id) => {
    setEditId(id);
    setForceRender((prev) => !prev);
  };

  return (
    <div>
      <div className="uic-title-rt-only">
        <h1></h1>
        <div className="uic-fl-btn">
          <button
            className="btn"
            data-toggle="modal"
            data-target="#CreateAddons"
            onClick={(e) => {
              setCloseAddModal(true);
              setTimeout(() => {
                setCloseAddModal(false);
              }, 1500);
            }}
          >
            Create Addon
          </button>
        </div>
      </div>
      <div className="uic-fl-search">
        <input
          type="text"
          placeholder="Search"
          className="search-user-input"
          value={searchedText}
          onChange={(e) => {
            setSearchedText(e.target.value);
            let currentValue;
            if (e.target.value) {
              currentValue = e.target.value;
            } else {
              currentValue = e.target.value;
              setSearchedText(currentValue);
            }
            setActualsearch(currentValue);
            if (suggestionList !== undefined && suggestionList.length > 0) {
              setListIndex(-1);
              setSuggestionBox(true);
              let searchResult = suggestionList.filter((user) => {
                return user.name
                  .toLowerCase()
                  .includes(e.target.value.trim().toLowerCase());
              });
              setSuggestions(searchResult);
            }
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              handleSearch(e.target.value);
            } else if (e.key === "ArrowDown") {
              const listLength =
                suggestions.length > 15 ? 15 : suggestions.length;
              if (listIndex < listLength - 1) {
                setListIndex(listIndex + 1);
              } else if (listIndex === listLength - 1) {
                setListIndex(-1);
                setSearchedText(actualsearch);
              }
            } else if (e.key === "ArrowUp") {
              e.preventDefault();
              const listLength =
                suggestions.length > 15 ? 15 : suggestions.length;
              if (listIndex === 0) {
                setListIndex(-1);
                setSearchedText(actualsearch);
              } else if (listIndex === -1) {
                setListIndex(listLength - 1);
              } else {
                setListIndex(listIndex - 1);
              }
            }
          }}
          onBlur={() => setSuggestionBox(false)}
        />

        {suggestionBox && suggestions.length > 0 && searchedText.length > 0 && (
          <div className="suggestion-box">
            <ul id="suggestion-list">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onMouseDown={() => handleSearch(suggestion.name)}
                  className={index === listIndex ? "selected-active" : ""}
                >
                  {searchedText !== null &&
                  searchedText.length > 0 &&
                  suggestion.name
                    .toLowerCase()
                    .startsWith(searchedText.toLowerCase()) ? (
                    <>
                      <b>{suggestion.name.slice(0, searchedText.length)}</b>
                      {suggestion.name &&
                        suggestion.name.slice(
                          searchedText.length,
                          suggestion.name.length
                        )}{" "}
                    </>
                  ) : (
                    suggestion.name
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}

        <i
          className="fa fa-search"
          onClick={() => handleSearch(searchedText)}
        ></i>
      </div>
      <div className="uic-fl-btn sort-by-mn">
        <div className="sort-by-mn-title">Filter by : </div>
        <div className="cstm-select-ap">
          <select value={selectedType} onChange={handleFilterPriceType}>
            <option value="all">Select Type</option>
            <option value="free">Free</option>
            <option value="paid">Paid</option>
          </select>
        </div>
      </div>
      <div className="uic-fl-btn sort-by-mn lifetime-only">
        <div className="cstm-select-ap">
          <select value={visibilityType} onChange={handleFilterVisibility}>
            <option value="all">Select visibility</option>
            <option value="Visible">Visible</option>
            <option value="hidden">Hidden</option>
            {/* <option value="custom">Custom</option> */}
          </select>
        </div>
      </div>

      <div className="uic-fl-btn sort-by-mn lifetime-only">
        <div className="cstm-select-ap">
          <select value={filterType} onChange={handleOnChangeFilterType}>
            <option value="">Lifetime</option>
            <option value="todays">Last 24 h</option>
            <option value="past7days">Last 7 Days</option>
            <option value="past30days">Last 30 Days</option>
            <option value="past90days">Last 90 Days</option>
            <option value="past365days">Last 365 days</option>
            <option value="custom">Custom</option>
          </select>
        </div>
        {filterType === "custom" ? (
          <div className="cstm-select-ap-drop documenlist-datepicker cstm-datepicker-field">
            <DatePicker
              showIcon
              selected={fromDate}
              onChange={(date) => handleDateChange(date, setFromDate, fromDateRef)}
              onCalendarClose={handleCalenderClose}
            />
            <img src={arrowimg} className="cstm-arrow-left-right" />
            <DatePicker
              showIcon
              selected={toDate}
              onChange={(date) => handleDateChange(date, setToDate, toDateRef)}
              onCalendarClose={handleCalenderClose}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <ListAddons
        guestTicketRedirect={guestTicketRedirect}
        handleEditId={handleEditId}
        finalSearchedText={finalSearchedText}
        selectedType={selectedType}
        visibilityType={visibilityType}
        filterType={filterType}
        fromDateIsoString={fromDateIsoString}
        toDateIsoString={toDateIsoString}
      />

      {/* add modal */}
      <CreateAddons
        isCloseAddModal={isCloseAddModal}
        forceRender={forceRender}
      />

      {/* //edit modal */}
      <EditAddons
        editId={editId}
        forceRender={forceRender}
        isCloseAddModal={isCloseAddModal}
      />
    </div>
  );
};

export default AddOnsManagement;
