import React, { useEffect, useState } from "react";
import UserInfoSideBar from "./UserInfoSideBar";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useDispatch } from "react-redux";
import Loader from "../../Images/loader.gif";
import authServiceCollaborator from "../../Services/auth.service.collaborator";
import { Link } from "react-router-dom";
import authService from "../../Services/auth.service";
import edit from "../../Images/BackendImages/edit.svg";

const columns = [
  { id: "userName", label: "Full name" },
  { id: "first_name", label: "First name" },
  { id: "last_name", label: "Last name" },
  { id: "Preferred Email", label: "Email" },
  { id: "_id", label: "Actions" },
];

const CollaboratorUsers = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [allList, setAllList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [dataLoader, setDataLoader] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);
  const [listIndex, setListIndex] = useState(-1);
  const [actualsearch, setActualsearch] = useState("");
  // pending collaborator list api
  async function getAcceptedCollaboratorsListApi(col, sort) {
    let query = "?isCollaborator=true";
    query +=
      col !== undefined && col !== null && col !== ""
        ? `&sortField=${col}`
        : "";
    query +=
      sort !== undefined && sort !== null && sort !== ""
        ? `&sortType=${sort}`
        : "";
    try {
      const collaboratorAcceptedListRes =
        await authServiceCollaborator.getAllCollaboratorUsers(query);
      if (collaboratorAcceptedListRes.status === 200) {
        let data = collaboratorAcceptedListRes.data;
        setAllList(data);
        setRows(data);
        setDataLoader(false);
      } else {
        setAllList([]);
        setRows([]);
        setDataLoader(false);
      }
    } catch (error) {
      setAllList([]);
      setRows([]);
      setDataLoader(false);
      return false;
    }
    try {
      const getSuggestionRes =
        await authService.getAcceptedCollaboratorsSuggestionList();
      let data = getSuggestionRes.data.data;
      var arr = [];
      if (
        data &&
        data.length !== undefined &&
        data.length !== null &&
        data.length > 0
      ) {
        data.map((item) => arr.push({ name: item.email }));
        setSuggestionList(arr);
      }
    } catch (err) {}
  }

  useEffect(() => {
    getAcceptedCollaboratorsListApi();
  }, []);

  const handleSearch = (text) => {
    setSearchText(text);
    setSuggestionBox(false);
    if (allList !== undefined && allList.length > 0) {
      var searchResult = allList.filter((invites) => {
        const searchTextLower = text.trim().toLowerCase();
        const inviteeNameLower = invites.userName.toLowerCase();
        const memberNameLower = invites.first_name.toLowerCase();
        const emailLower = invites["Preferred Email"].toLowerCase();

        return (
          inviteeNameLower.includes(searchTextLower) ||
          memberNameLower.includes(searchTextLower) ||
          emailLower.includes(searchTextLower)
        );
      });
      setRows(searchResult);
    }
  };

  //for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //for pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSort = (col, direc) => {
    const sortedResult = [...allList];
    if (direc === "asc") {
      sortedResult.sort((firstItem, secondItem) => {
        const itemA = firstItem[col];
        const itemB = secondItem[col];
        return itemA < itemB ? -1 : itemA > itemB ? 1 : 0;
      });
    } else if (direc === "desc") {
      sortedResult.sort((firstItem, secondItem) => {
        const itemA = firstItem[col];
        const itemB = secondItem[col];
        return itemA > itemB ? -1 : itemA < itemB ? 1 : 0;
      });
    }
    setRows(sortedResult);
  };
  return (
    <div className="users-info">
      <UserInfoSideBar />
      <div className="users-info-content">
        <div className="users-info-content-main">
          <div className="uic-title">
            <h1>Team-Users</h1>
          </div>
          <div className="uic-fl-mn">
            <div className="uic-fl-mn-left">
              <div className="uic-fl-search">
                <input
                  type="text"
                  placeholder="Search"
                  className="search-user-input"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    var currentValue;
                    if (e.target.value) {
                      currentValue = e.target.value;
                    } else {
                      currentValue = e.target.value;
                      setSearchText(currentValue);
                    }
                    setActualsearch(currentValue);
                    if (
                      suggestionList !== undefined &&
                      suggestionList.length > 0
                    ) {
                      setListIndex(-1);
                      setSuggestionBox(true);
                      var searchResult = suggestionList.filter((user) => {
                        return user.name
                          .toLowerCase()
                          .includes(e.target.value.trim().toLowerCase());
                      });
                      setSuggestions(searchResult);
                    }
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      handleSearch(e.target.value);
                    } else if (e.key === "ArrowDown") {
                      const listLength =
                        suggestions.length > 15 ? 15 : suggestions.length;
                      if (listIndex < listLength - 1) {
                        setListIndex(listIndex + 1);
                      } else if (listIndex === listLength - 1) {
                        setListIndex(-1);
                        setSearchText(actualsearch);
                      }
                    } else if (e.key === "ArrowUp") {
                      e.preventDefault();
                      const listLength =
                        suggestions.length > 15 ? 15 : suggestions.length;
                      if (listIndex === 0) {
                        setListIndex(-1);
                        setSearchText(actualsearch);
                      } else if (listIndex === -1) {
                        setListIndex(listLength - 1);
                      } else {
                        setListIndex(listIndex - 1);
                      }
                    }
                  }}
                  onBlur={() => setSuggestionBox(false)}
                />
                {suggestionBox &&
                  suggestions.length > 0 &&
                  searchText.length > 0 && (
                    <div className="suggestion-box">
                      <ul id="suggestion-list">
                        {suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onMouseDown={() => handleSearch(suggestion.name)}
                            className={
                              index === listIndex ? "selected-active" : ""
                            }
                          >
                            {searchText !== null &&
                            searchText.length > 0 &&
                            suggestion.name
                              .toLowerCase()
                              .startsWith(searchText.toLowerCase()) ? (
                              <>
                                <b>
                                  {suggestion.name.slice(0, searchText.length)}
                                </b>
                                {suggestion.name &&
                                  suggestion.name.slice(
                                    searchText.length,
                                    suggestion.name.length - 1
                                  )}{" "}
                              </>
                            ) : (
                              suggestion.name
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                <i className="fa fa-search" />
              </div>
            </div>
          </div>
          <Paper className="uic-tb-mn">
            <TableContainer className="uic-table-main">
              <Table className="uic-table">
                <TableHead className="uic-table-th">
                  <TableRow>
                    {columns.map((column, i) => (
                      <TableCell className="uic-th-td" key={column.id}>
                        {column.id !== "_id" && (
                          <>
                            <span className="custom-sorting">
                              <i
                                className="fa fa-angle-down"
                                onClick={(e) => {
                                  handleSort(column.id, "asc");
                                }}
                              ></i>
                              <i
                                className="fa fa-angle-up"
                                onClick={(e) => {
                                  handleSort(column.id, "desc");
                                }}
                              ></i>
                            </span>
                          </>
                        )}
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataLoader ? (
                    <div className="cstm-data-load">
                      <img src={Loader} />{" "}
                    </div>
                  ) : rows.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    ).length > 0 ? (
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, i) => {
                        return (
                          <TableRow>
                            {columns.map((col) => {
                              const value = row[col.id];
                              return col.id !== "_id" ? (
                                <TableCell className="uic-th-td w-18">
                                  {col.id === "userName" ? (
                                    <Link
                                      to={`/user/edituser?id=${row._id}`}
                                      onClick={() => {
                                        //dispatch({ type: "SETMODALINPROCESS" })
                                        dispatch({
                                          type: "SETSTAYONPAGE",
                                          payload: null,
                                        });
                                        // handleUpdate(value);
                                        dispatch({
                                          type: "NAVIGATEPAGE",
                                          payload: `/user/listing?search=${searchText}`,
                                        });
                                      }}
                                      className="cstm-view-users-count"
                                    >
                                      {value}
                                    </Link>
                                  ) : (
                                    value
                                  )}
                                </TableCell>
                              ) : (
                                <>
                                  <TableCell
                                    key={col.id}
                                    className="uic-th-td uic-table-icon w-10"
                                  >
                                    <Link
                                      to={`/user/edituser?id=${value}`}
                                      onClick={() => {
                                        //dispatch({ type: "SETMODALINPROCESS" })
                                        dispatch({
                                          type: "SETSTAYONPAGE",
                                          payload: null,
                                        });
                                        // handleUpdate(value);
                                        dispatch({
                                          type: "NAVIGATEPAGE",
                                          payload: `/user/listing?search=${searchText}`,
                                        });
                                      }}
                                    >
                                      <img src={edit} />
                                    </Link>
                                  </TableCell>
                                </>
                              );
                            })}
                          </TableRow>
                        );
                      })
                  ) : (
                    <span className="no-data-found">No data found</span>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className="uic-pagination"
              rowsPerPageOptions={[20, 50, 100, 200]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default CollaboratorUsers;
