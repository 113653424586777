import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import Cropper from "cropperjs";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import AdminSidebar from "../AdminSidebar";
//import api file
import Services from "../../Services/auth.service";
// Import images
import Loader from "../../Images/loader.gif";
import edit from "../../Images/BackendImages/edit.svg";
import Loader2 from "../../Images/loader2.gif";
import imgdelete from "../../Images/BackendImages/delete.svg";
import imgBigDelete from "../../Images/BackendImages/delete-icon.svg";
import AlertComponent from "../../Hooks/AlertComponent";
import "cropperjs/dist/cropper.min.css";
import UserInfoSideBar from "./UserInfoSideBar";
import authService from "../../Services/auth.service";
import ModalPopup from "../ModalPopup/ModalPopup";
import Button from "../Button/Button";

const columns = [
  { id: "name", label: "Full name" },
  { id: "firstName", label: "First name" },
  { id: "lastName", label: "Last name" },
  { id: "email", label: "Email" },
  { id: "company", label: "Company" },
  { id: "action", label: "Actions" },
];

function createData(name, firstName, lastName, email, company, action) {
  return {
    name,
    firstName,
    lastName,
    email,
    company,
    action,
  };
}
const AttendeeUsersList = () => {
  const [users, setUsers] = useState([]);
  const [rows, setrow] = useState([]);
  const [page, setPage] = useState(0);
  const [tblalluser, settblalluser] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [searchText, setSearchText] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [viewmodalOpen, setViewModalOpen] = useState(false);
  const [outputBase64, setOutput] = useState(null);
  const [tmpimage, setImage] = useState(null);
  const [tmpviewimage, setviewImage] = useState(null);
  const [cropedFile, setcropedFile] = useState();
  const [file, setFile] = useState("");
  const [dynamic_width, setdynamic_width] = useState(0);
  const [profileImg, setProfileImg] = useState("");
  const [guestIcon, setGuestIcon] = useState("");
  const [partnerIcon, setPartnerIcon] = useState("");
  const [speakerIcon, setSpeakerIcon] = useState("");
  const [fileName, setFileName] = useState("");
  const [profileType, setProfileType] = useState("");
  const [dataLoader, setDataLoader] = useState(false);
  const [actualsearch, setActualsearch] = useState("");
  const [isMenu, setMenu] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [listIndex, setListIndex] = useState(-1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalLoader, setDeleteModalLoader] = useState(false);
  const onDrop = (acceptedFiles) => {
    assignFiles(acceptedFiles);
  };
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: "image/png, image/gif, image/jpeg, image/jpg",
    maxSizeBytes: 10485760,
    onDrop,
  });
  const assignFiles = (acceptedFile) => {
    setModalOpen(true);
    setFileName(Math.random().toString() + acceptedFile[0].name);
    setFile(URL.createObjectURL(acceptedFile[0]));
  };
  const [selectedAttendeeId, setSelectedAttendeeId] = useState("");
  const [editModalLoader, setEditModalLoader] = useState(false);
  const [editEvent, setEditEvent] = useState({
    profilePic: "",
    guestIcon: "",
    partnerIcon: "",
    speakerIcon: "",
    name: "",
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    profession: "",
    phone: "",
    auth0Id: "",
    facebook: "",
    linkedin: "",
    passcode: "",
  });
  const [editErr, setEditErr] = useState({
    profilePic: "",
    name: "",
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    profession: "",
    phone: "",
    auth0IdId: "",
    facebook: "",
    linkedin: "",
    passcode: "",
  });
  const [showAlertEdit, setShowAlertEdit] = useState(false);
  const [alertMsgEdit, setAlertMsgEdit] = useState("");
  const [editInprocess, setEditInprocess] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const dispatch = useDispatch();
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const stayOnPage = useSelector((state) => {
    return state.ReduStayOnPage;
  });

  let history = useNavigate();
  const getAllAttendeeList = (searchText, col, sort) => {
    let query = `?search=${searchText}`;
    query +=
      col !== undefined && col !== null && col !== ""
        ? `&sortField=${col}`
        : "";
    query +=
      sort !== undefined && sort !== null && sort !== ""
        ? `&sortType=${sort}`
        : "";
    Services.getAllAttendeeList(query)
      .then((res) => {
        if (res.data.length > 0) {
          var arr = res.data.filter((u) => {
            return u;
          });
          setUsers(arr);
          var newarr = [];
          for (var i = 0; i < arr.length; i++) {
            newarr[i] = createData(
              arr[i].display_name !== undefined &&
                arr[i].display_name !== null
                ? arr[i].display_name
                : "",
              arr[i].first_name !== undefined &&
                arr[i].first_name !== null &&
                arr[i].first_name != ""
                ? arr[i].first_name
                : "-",
              arr[i].last_name !== undefined &&
                arr[i].last_name !== null &&
                arr[i].last_name != ""
                ? arr[i].last_name
                : "-",
              arr[i]["Preferred Email"] ?? "",
              arr[i].attendeeDetail && arr[i].attendeeDetail.company
                ? arr[i].attendeeDetail.company
                : "",
              arr[i]._id
            );
          }
          settblalluser(newarr);
          setrow(newarr);
          setDataLoader(false);
        } else {
          settblalluser([]);
          setrow([]);
          setDataLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleClearAllStates();
    setDataLoader(true);
    getAllAttendeeList(searchText);
    getallusersSuggestionListApi();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleSearch = (text) => {
    setDataLoader(true);
    setSearchText(text);
    getAllAttendeeList(text);
    setSuggestionBox(false);
  };
  async function getallusersSuggestionListApi() {
    try {
      const getSuggestionRes = await authService.getAllAttendeeSuggestionList();
      let data = getSuggestionRes.data;
      var arr = [];
      data.length > 0 &&
        data.map((item) =>
          arr.push({ name: `${item.first_name} ${item.last_name}` })
        );
      setSuggestionList(arr);
    } catch (err) { }
  }
  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      var currentList =
        document.getElementById("suggestion-list").children[listIndex];
      // .firstElementChild.firstElementChild;
      if (currentList) {
        var text = currentList.innerText;
        setSearchText(text);
      }
    }
  }, [listIndex]);

  const handleSort = (col, direc) => {
    setDataLoader(true);
    getAllAttendeeList(searchText, col, direc);
  };

  //Called when user click on update changes
  const handleEditSubmit = () => {
    var status = true;
    var temp = {};
    if (editEvent.name.trim().length === 0) {
      temp = { ...temp, name: "Enter name!" };
      status = false;
    }
    if (editEvent.email && editEvent.email.trim().length === 0) {
      temp = { ...temp, email: "Enter email!" };
      status = false;
    } else if (!isValidEmail(editEvent.email)) {
      temp = { ...temp, email: "Invalid email format!" };
      status = false;
    }

    if (status) {
      setEditInprocess(true);
      editApiCall();
    } else {
      setEditErr(temp);
    }
  };

  //Edit attendees api call
  const editApiCall = () => {
    const attendeeDetail = {
      name: editEvent.name,
      firstName: editEvent.firstName,
      lastName: editEvent.lastName,
      email: editEvent.email.trim(),
      company: editEvent.company,
      profession: editEvent.profession,
      phone: editEvent.phone,
      auth0Id: editEvent.auth0Id,
      facebook: editEvent.facebook,
      linkedin: editEvent.linkedin,
    };
    const formdata = new FormData();
    if (
      (profileImg || editEvent.profilePic) &&
      !speakerIcon &&
      !editEvent.speakerIcon &&
      !guestIcon &&
      !editEvent.guestIcon &&
      !partnerIcon &&
      !editEvent.partnerIcon
    ) {
      if (editEvent.profilePic) {
        formdata.append("profileImg", editEvent.profilePic);
        formdata.append("guestIcon", editEvent.profilePic);
        formdata.append("partnerIcon", editEvent.profilePic);
        formdata.append("speakerIcon", editEvent.profilePic);
      } else {
        formdata.append("profileImg", profileImg);
        formdata.append("guest", profileImg);
        formdata.append("partner", profileImg);
        formdata.append("speaker", profileImg);
      }
    } else if (
      (profileImg || editEvent.profilePic) &&
      (speakerIcon || editEvent.speakerIcon) &&
      !guestIcon &&
      !editEvent.guestIcon &&
      !partnerIcon &&
      !editEvent.partnerIcon
    ) {
      editEvent.speakerIcon &&
        formdata.append("speakerIcon", editEvent.speakerIcon);
      if (editEvent.profilePic) {
        formdata.append("profileImg", editEvent.profilePic);
        formdata.append("guestIcon", editEvent.profilePic);
        formdata.append("partnerIcon", editEvent.profilePic);
      } else {
        formdata.append("guest", profileImg);
        formdata.append("partner", profileImg);
      }
    } else {
      editEvent.profilePic &&
        formdata.append("profileImg", editEvent.profilePic);
      editEvent.guestIcon && formdata.append("guestIcon", editEvent.guestIcon);
      editEvent.partnerIcon &&
        formdata.append("partnerIcon", editEvent.partnerIcon);
      editEvent.speakerIcon &&
        formdata.append("speakerIcon", editEvent.speakerIcon);
    }
    formdata.append("userId", selectedId);
    formdata.append("attendeeDetail", JSON.stringify(attendeeDetail));
    formdata.append("auth0Id", attendeeDetail.auth0Id);
    formdata.append("email", attendeeDetail.email);
    formdata.append("passcode", editEvent.passcode);
    Services.updateAttendeeProfileByAdmin(formdata)
      .then((res) => {
        if (res.data.status) {
          setShowAlertEdit(true);
          setAlertMsgEdit(res.data.message);
          setTimeout(() => {
            setShowAlertEdit(false);
            setAlertMsgEdit("");
          }, 3000);
          getAllAttendeeList(searchText);
          setEditInprocess(false);
        } else {
          setShowAlertEdit(true);
          setAlertMsgEdit(res.data.message);
          setTimeout(() => {
            setShowAlertEdit(false);
            setAlertMsgEdit("");
          }, 3000);
        }
        handleClearAllStates();
        setEditInprocess(false);
        // Services.updateUserDetailInChatProjectRedis(selectedId);
      })
      .catch((e) => {
        console.log(e);
        setEditInprocess(false);
        handleClearAllStates();
      });
    setEditErr({
      profilePic: "",
      name: "",
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      profession: "",
      phone: "",
      auth0Id: "",
      facebook: "",
      linkedin: "",
      passcode: "",
    });
  };

  //close create attendees module
  const handleCloseEditModel = (e) => {
    handleClearAllStates();
    setEditEvent({
      profilePic: "",
      name: "",
      email: "",
      firstName: "",
      lastName: "",
      company: "",
      profession: "",
      phone: "",
      auth0Id: "",
      facebook: "",
      linkedin: "",
      passcode: "",
    });
    setEditErr({
      profilePic: "",
      name: "",
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      profession: "",
      phone: "",
      auth0Id: "",
      facebook: "",
      linkedin: "",
      passcode: "",
    });
  };

  function isNumeric(input) {
    return /^[0-9]{10}$/.test(input);
  }

  function isValidEmail(email) {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }

  //on change for Edit
  const handleEditChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setEditEvent({
      ...editEvent,
      [e.target.name]:
        e.target.name === "email" ? e.target.value.trim() : e.target.value,
    });
    if (e.target.value.length > 0) {
      setEditErr({ ...editErr, [e.target.name]: "" });
    }
  };
  useEffect(() => {
    if (outputBase64 != null) {
      const convertedUrlToFile = dataURLtoFile(outputBase64);
      setcropedFile(convertedUrlToFile);
    }
  }, [outputBase64]);

  useEffect(() => {
    if (tmpimage != null) {
      const cropper = new Cropper(tmpimage, {
        zoomable: false,
        responsive: false,
        restore: false,
        modal: true,
        scalable: false,
        minCropBoxWidth: 200,
        minCropBoxHeight: 200,
        viewMode: 3,
        aspectRatio: 1,
        highlight: true,
        crop: () => {
          const canvas = cropper.getCroppedCanvas();
          const base64Image = canvas.toDataURL("image/jpeg");
          if (base64Image != null) {
            setOutput(base64Image);
          } else {
            setOutput(null);
          }
        },
      });
    }
  }, [tmpimage]);

  // convert url to file
  const dataURLtoFile = () => {
    const arr = outputBase64.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) u8arr[n] = bstr.charCodeAt(n);

    return new File([u8arr], fileName, { type: mime });
  };
  function viewProfileImage(e) {
    setViewModalOpen(true);
    setviewImage(e.currentTarget);
  }
  // called on image load
  function onImageLoad(e) {
    setImage(e.currentTarget);
  }
  // assigning cropped image
  const CropDone = () => {
    setViewModalOpen(false);
    setModalOpen(false);

    setEditEvent({ ...editEvent, [profileType]: cropedFile });
  };
  const handleDeleteAttendee = () => {
    setDeleteModalLoader(true);
    Services.deleteAttendeefromAllAttendeeList(selectedAttendeeId)
      .then((response) => {
        if (response.data.status) {
          setDeleteModalLoader(false);
          setShowAlert(true);
          setShowDeleteModal(false);
          setAlertMsg(response.data.message);
          getAllAttendeeList(searchText);
          setTimeout(() => {
            setShowAlert(false);
            setAlertMsg("");
          }, 3000);
          var newAttendees = tblalluser.filter((attendee) => {
            if (attendee.action !== selectedAttendeeId) return attendee;
          });
          var newarr = [];
          for (var i = 0; i < newAttendees.length; i++) {
            newarr[i] = createData(
              newAttendees[i].name,
              newAttendees[i].email,
              newAttendees[i].company,
              newAttendees[i].action
            );
          }
          settblalluser(newarr);
          setrow(newarr);
        } else {
          setDeleteModalLoader(false);
          setShowAlert(true);
          setAlertMsg(response.data.message);
          setTimeout(() => {
            setShowAlert(false);
            setAlertMsg("");
          }, 3000);
        }
      })
      .catch((e) => {
        setDeleteModalLoader(false);
        setShowAlert(true);
        setAlertMsg(e.message);
        setTimeout(() => {
          setShowAlert(false);
          setAlertMsg("");
        }, 3000);
        console.log(e);
      });
  };
  //pop up state for do you want to leave....
  useEffect(() => {
    if (stayOnPage !== null && stayOnPage) {
      if ($("#editAttendee").hasClass("show")) {
        $("#editAttendee").modal("show");
      }
    } else if (stayOnPage !== null && !stayOnPage) {
      if ($("#editAttendee").hasClass("show")) {
        $("#editAttendee").modal("hide");
        handleCloseEditModel();
      }
    }
  }, [stayOnPage]);

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  const deleteAttendeeName = (deleteId) => {
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].action === deleteId) {
        return rows[i].name;
      }
    }
  };
  return (
    <>
      {/* <div className="cstm-all-admin">
        <AdminSidebar />

        <div className="content-main"> */}
      <div className="users-info">
        <UserInfoSideBar />
        <div className="users-info-content">
          <div className="users-info-content-main">
            <div className="uic-title">
              <h1>All users</h1>
            </div>
            <div className="uic-fl">
              <div className="uic-fl-search">
                <input
                  type="text"
                  placeholder="Search"
                  className="search-user-input"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    var currentValue;
                    if (e.target.value) {
                      currentValue = e.target.value;
                    } else {
                      currentValue = e.target.value;
                      setSearchText(currentValue);
                    }
                    setActualsearch(currentValue);
                    if (
                      suggestionList !== undefined &&
                      suggestionList.length > 0
                    ) {
                      setListIndex(-1);
                      setSuggestionBox(true);
                      var searchResult = suggestionList.filter((user) => {
                        return user.name
                          .toLowerCase()
                          .includes(e.target.value.trim().toLowerCase());
                      });
                      setSuggestions(searchResult);
                    }
                  }}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      handleSearch(e.target.value);
                    } else if (e.key === "ArrowDown") {
                      const listLength =
                        suggestions.length > 15 ? 15 : suggestions.length;
                      if (listIndex < listLength - 1) {
                        setListIndex(listIndex + 1);
                      } else if (listIndex === listLength - 1) {
                        setListIndex(-1);
                        setSearchText(actualsearch);
                      }
                    } else if (e.key === "ArrowUp") {
                      e.preventDefault();
                      const listLength =
                        suggestions.length > 15 ? 15 : suggestions.length;
                      if (listIndex === 0) {
                        setListIndex(-1);
                        setSearchText(actualsearch);
                      } else if (listIndex === -1) {
                        setListIndex(listLength - 1);
                      } else {
                        setListIndex(listIndex - 1);
                      }
                    }
                  }}
                  onBlur={() => setSuggestionBox(false)}
                />
                {suggestionBox &&
                  suggestions.length > 0 &&
                  searchText.length > 0 && (
                    <div className="suggestion-box">
                      <ul id="suggestion-list">
                        {suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onMouseDown={() => handleSearch(suggestion.name)}
                            className={
                              index === listIndex ? "selected-active" : ""
                            }
                          >
                            {searchText !== null &&
                              searchText.length > 0 &&
                              suggestion.name
                                .toLowerCase()
                                .startsWith(searchText.toLowerCase()) ? (
                              <>
                                <b>
                                  {suggestion.name.slice(0, searchText.length)}
                                </b>
                                {suggestion.name &&
                                  suggestion.name.slice(
                                    searchText.length,
                                    suggestion.name.length
                                  )}{" "}
                              </>
                            ) : (
                              suggestion.name
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                <i className="fa fa-search" onClick={handleSearch}></i>
              </div>
            </div>
            {showAlert && <div className="alert alert-info">{alertMsg}</div>}
            <Paper className="uic-tb-mn">
              <TableContainer className="uic-table-main">
                <Table className="uic-table">
                  <TableHead className="uic-table-th">
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={
                            column.id === "action"
                              ? "uic-th-td w-8"
                              : "uic-th-td w-10"
                          }
                        >
                          {" "}
                          {column.id !== "action" ? (
                            <>
                              <span className="custom-sorting">
                                <i
                                  className="fa fa-angle-down"
                                  onClick={(e) => {
                                    handleSort(column.id, "Asc");
                                  }}
                                ></i>
                                <i
                                  className="fa fa-angle-up"
                                  onClick={(e) => {
                                    handleSort(column.id, "Dec");
                                  }}
                                ></i>
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className="uic-table-td">
                    {dataLoader ? (
                      <div className="cstm-data-load">
                        <img src={Loader} />{" "}
                      </div>
                    ) : (
                      rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                              className="uic-th-td w-20"
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                return column.id !== "action" &&
                                  column.id === "name" ? (
                                  <TableCell
                                    key={column.id}
                                    className="uic-th-td w-10"
                                  >
                                    <Link
                                      to={`/user/edituser?id=${row.action}`}
                                      onClick={() => {
                                        //dispatch({ type: "SETMODALINPROCESS" })
                                        dispatch({
                                          type: "SETSTAYONPAGE",
                                          payload: null,
                                        });
                                        // handleUpdate(value);
                                        dispatch({
                                          type: "NAVIGATEPAGE",
                                          payload: `/user/attendees`,
                                        });
                                      }}
                                      className="cstm-view-users-count"
                                    >
                                      {value}
                                    </Link>
                                  </TableCell>
                                ) : column.id !== "action" ? (
                                  <TableCell
                                    key={column.id}
                                    className="uic-th-td w-10"
                                  >
                                    {value}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    key={column.id}
                                    className="uic-th-td uic-table-icon w-8"
                                  >
                                    <Link
                                      to={`/user/edituser?id=${value}`}
                                      onClick={() => {
                                        //dispatch({ type: "SETMODALINPROCESS" })
                                        dispatch({
                                          type: "SETSTAYONPAGE",
                                          payload: null,
                                        });
                                        // handleUpdate(value);
                                        dispatch({
                                          type: "NAVIGATEPAGE",
                                          payload: `/user/attendees`,
                                        });
                                      }}
                                      className="cstm-view-users-count"
                                    >
                                      <img src={edit} />
                                    </Link>

                                    <a
                                      // data-toggle="modal"
                                      // data-target="#deleteAttendeeConfirmBox"
                                      onClick={() => {
                                        setShowDeleteModal(true);
                                        var selectedAttendee = users.filter(
                                          (attendee) => {
                                            if (attendee._id === value)
                                              return attendee;
                                          }
                                        )[0];
                                        if (
                                          selectedAttendee &&
                                          selectedAttendee
                                            .event_participant_attendees_result
                                            .length === 0
                                        ) {
                                          setSelectedAttendeeId(value);
                                        } else {
                                          setSelectedAttendeeId("");
                                        }

                                        // if (
                                        //   selectedAttendee &&
                                        //   selectedAttendee.attendeeDetail &&
                                        //   selectedAttendee.attendeeDetail
                                        //     .evntData
                                        // ) {
                                        //   var eventsExists = false;
                                        //   for (
                                        //     let evntIndex = 0;
                                        //     evntIndex <
                                        //     selectedAttendee.attendeeDetail
                                        //       .evntData.length;
                                        //     evntIndex++
                                        //   ) {
                                        //     if (
                                        //       selectedAttendee.attendeeDetail
                                        //         .evntData[evntIndex].member ||
                                        //       selectedAttendee.attendeeDetail
                                        //         .evntData[evntIndex].partner ||
                                        //       selectedAttendee.attendeeDetail
                                        //         .evntData[evntIndex].speaker ||
                                        //       selectedAttendee.attendeeDetail
                                        //         .evntData[evntIndex].guest
                                        //     )
                                        //       eventsExists = true;
                                        //   }
                                        //   if (!eventsExists)
                                        //     setSelectedAttendeeId(value);
                                        //   else setSelectedAttendeeId("");
                                        // } else {
                                        //   setSelectedAttendeeId("");
                                        // }
                                      }}
                                    >
                                      <img src={imgdelete} />
                                    </a>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })
                    )}
                  </TableBody>
                </Table>
                {!dataLoader && rows.length === 0 ? (
                  <div className="no-data-found">
                    <p>No Data Found </p>
                  </div>
                ) : (
                  <></>
                )}{" "}
              </TableContainer>
              <TablePagination
                className="uic-pagination"
                rowsPerPageOptions={[20, 50, 100, 200]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </div>
        {/* editmodal */}
        <div
          className="modal fade edit-user-details-popup"
          id="editAttendee"
          tabIndex="-1"
          role="dialog"
          data-backdrop="static"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-keyboard="false"
        >
          <div className="modal-dialog" role="document">
            {!editModalLoader ? (
              <div className="modal-content">
                <div className="edit-user-details-header">
                  <h2>Edit Attendees</h2>
                  <button
                    type="button"
                    className="close"
                    //data-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => {
                      if (modalInProcess) {
                        dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                      } else {
                        $("#editAttendee").modal("hide");
                        handleCloseEditModel(e);
                      }
                    }}
                    id="editAttendeeModelId"
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  {showAlertEdit && (
                    <div className="alert alert-info">{alertMsgEdit}</div>
                  )}
                  <div className="cstm-uplaod-img-link">
                    <div className="form-group ">
                      <div className="form-group-ct">
                        <div className="cstm-profile-upload-img">
                          {editEvent.profilePic ? (
                            <img
                              className="cstm-ld-user-img"
                              src={
                                editEvent.profilePic
                                  ? URL.createObjectURL(editEvent.profilePic)
                                  : Image1
                              }
                              onClick={viewProfileImage}
                            />
                          ) : profileImg ? (
                            <img
                              className="cstm-ld-user-img"
                              src={profileImg}
                              onClick={viewProfileImage}
                            />
                          ) : (
                            <div className="cstm-edit-attendees-word">
                              {editEvent && editEvent.name
                                ? editEvent.name.charAt(0)
                                : ""}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="cstm-profile-upload-btn-sec">
                        <div className="cstm-profile-upload-btn">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <span
                              className="edit-pro-text2"
                              onClick={() => setProfileType("profilePic")}
                            >
                              Change member icon
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group cstm-edit-profile-img-userlis">
                      <div className="form-group-ct">
                        <div className="cstm-profile-upload-img">
                          {editEvent.speakerIcon ? (
                            <img
                              className="cstm-ld-user-img"
                              src={
                                editEvent.speakerIcon
                                  ? URL.createObjectURL(editEvent.speakerIcon)
                                  : Image1
                              }
                              onClick={viewProfileImage}
                            />
                          ) : speakerIcon ? (
                            <img
                              className="cstm-ld-user-img"
                              src={speakerIcon}
                              onClick={viewProfileImage}
                            />
                          ) : (
                            <div className="cstm-edit-attendees-word">
                              {editEvent && editEvent.name
                                ? editEvent.name.charAt(0)
                                : ""}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="cstm-profile-upload-btn-sec">
                        <div className="cstm-profile-upload-btn">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <span
                              className="edit-pro-text2"
                              onClick={() => setProfileType("speakerIcon")}
                            >
                              Change speaker icon
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group cstm-edit-profile-img-userlis">
                      <div className="form-group-ct">
                        <div className="cstm-profile-upload-img">
                          {editEvent.partnerIcon ? (
                            <img
                              className="cstm-ld-user-img"
                              src={
                                editEvent.partnerIcon
                                  ? URL.createObjectURL(editEvent.partnerIcon)
                                  : Image1
                              }
                              onClick={viewProfileImage}
                            />
                          ) : partnerIcon ? (
                            <img
                              className="cstm-ld-user-img"
                              src={partnerIcon}
                              onClick={viewProfileImage}
                            />
                          ) : (
                            <div className="cstm-edit-attendees-word">
                              {editEvent && editEvent.name
                                ? editEvent.name.charAt(0)
                                : ""}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="cstm-profile-upload-btn-sec">
                        <div className="cstm-profile-upload-btn">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <span
                              className="edit-pro-text2"
                              onClick={() => setProfileType("partnerIcon")}
                            >
                              Change partner icon
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group cstm-edit-profile-img-userlis">
                      <div className="form-group-ct">
                        <div className="cstm-profile-upload-img">
                          {editEvent.guestIcon ? (
                            <img
                              className="cstm-ld-user-img"
                              src={
                                editEvent.guestIcon
                                  ? URL.createObjectURL(editEvent.guestIcon)
                                  : Image1
                              }
                              onClick={viewProfileImage}
                            />
                          ) : guestIcon ? (
                            <img
                              className="cstm-ld-user-img"
                              src={guestIcon}
                              onClick={viewProfileImage}
                            />
                          ) : (
                            <div className="cstm-edit-attendees-word">
                              {editEvent && editEvent.name
                                ? editEvent.name.charAt(0)
                                : ""}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="cstm-profile-upload-btn-sec">
                        <div className="cstm-profile-upload-btn">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <span
                              className="edit-pro-text2"
                              onClick={() => setProfileType("guestIcon")}
                            >
                              Change guest icon
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span className="text-limitaion">
                      If only member or speaker image is uploaded then it will
                      be used as partner and guest image too.
                    </span>
                  </div>
                  {viewmodalOpen && (
                    <div className="desk-globle-model-box globle-model-box  cstm-rpp-view-profile">
                      <div className="desk-globle-model-box-inner cstm-vpp-popup">
                        <div className="modal-content">
                          <div className="cstm-replace-pp">
                            <div class="cstm-rpp-title">
                              View Profile Picture
                              <span
                                class="cstm-rpp-close-btn"
                                onClick={() => setViewModalOpen(false)}
                              >
                                ✖
                              </span>
                            </div>
                            <div className="cstm-cstm-vpp-photo">
                              {tmpviewimage && <img src={tmpviewimage.src} />}
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  )}

                  {modalOpen && (
                    <div className="desk-globle-model-box globle-model-box checksboxes-visible cstm-rpp-main">
                      <div className="desk-globle-model-box-inner edit-profile-cstm">
                        <div className="modal-content">
                          <div className="cstm-replace-pp">
                            <div className="cstm-rpp-title">
                              Replace Profile Picture
                              <span
                                className="cstm-rpp-close-btn"
                                onClick={() => setModalOpen(false)}
                              >
                                ✖
                              </span>
                            </div>

                            {file && (
                              <div
                                id="cropImgDis"
                                className="cropImgDis"
                                style={{ width: `${dynamic_width}px` }}
                              >
                                <img
                                  id="mainfile"
                                  className="cstmMainImage"
                                  src={file}
                                  onLoad={onImageLoad}
                                />

                                <div className="cstm-rpp-btn">
                                  <button
                                    className="cropButton btn2"
                                    onClick={CropDone}
                                  >
                                    Crop & Save{" "}
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  )}
                  <div className="form-group">
                    <div className="form-group-label">Name*</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter name"
                        name="name"
                        onChange={handleEditChange}
                        value={editEvent.name}
                      />
                      <span className="error">{editErr.name}</span>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">First Name</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter first name"
                        name="firstName"
                        onChange={handleEditChange}
                        value={editEvent.firstName}
                      />
                      <span className="error">{editErr.firstName}</span>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">Last Name</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter name"
                        name="lastName"
                        onChange={handleEditChange}
                        value={editEvent.lastName}
                      />
                      <span className="error">{editErr.lastName}</span>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">Email*</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter email"
                        name="email"
                        onChange={handleEditChange}
                        value={editEvent.email}
                      />
                      <span className="error">{editErr.email}</span>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">Passcode</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter passcode"
                        name="passcode"
                        onChange={handleEditChange}
                        value={editEvent.passcode}
                      />
                    </div>
                    <span className="error">{editErr.passcode}</span>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">Company</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter company"
                        name="company"
                        onChange={handleEditChange}
                        value={editEvent.company}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">Profession</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter profession"
                        name="profession"
                        onChange={handleEditChange}
                        value={editEvent.profession}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">Phone no.</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter phone no."
                        name="phone"
                        onChange={handleEditChange}
                        value={editEvent.phone}
                      />
                      <span className="error">{editErr.phone}</span>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">Auth0</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter auth0 id"
                        name="auth0Id"
                        onChange={handleEditChange}
                        value={editEvent.auth0Id}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">Facebook</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter facebook link"
                        name="facebook"
                        onChange={handleEditChange}
                        value={editEvent.facebook}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">Linkedin</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter linkedin link"
                        name="linkedin"
                        onChange={handleEditChange}
                        value={editEvent.linkedin}
                      />
                    </div>
                  </div>
                </div>

                <div className="edit-user-details-footer cstm-create-button">
                  {editInprocess ? (
                    <div className="loader-button1">
                      <button>In Process...</button>
                      <span className="loader-btn">
                        <img src={Loader2} />
                      </span>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn"
                      onClick={handleEditSubmit}
                    >
                      Update Changes
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <div className="edit-modal-loader">
                <img src={Loader} />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* Modal Delete */}
      <ModalPopup
        onClose={() => setShowDeleteModal(false)}
        isFooter={selectedAttendeeId.length === 0 ? false : true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        btn2variant={!deleteModalLoader ? (selectedAttendeeId.length > 0 ? "danger" : "secondary") : "secondary"}
        buttonText1={
          !deleteModalLoader && selectedAttendeeId.length > 0 ? (
            "Delete"
          ) : (
            !deleteModalLoader && selectedAttendeeId.length === 0 ? (
              // Render null or an empty fragment to hide the button when no attendee is selected
              null
            ) : (
              <img
                src={Loader2}
                width={50}
                height={15}
                className="btn-loader-img-cover"
              />
            )
          )
        }
        isOpen={showDeleteModal}
        className=""
        handleClick2ndButton={handleDeleteAttendee}
        headingLable={"Delete attendee"}
      >
        <div className="ctsm-modal-body">
          <p>
            {selectedAttendeeId.length === 0 ? "Can't delete attendee exists in events!" :
              `Are you sure you want to delete this 
           "${deleteAttendeeName(selectedAttendeeId)}"? This action cannot be
            undone.`
            }
          </p>
        </div>
        {selectedAttendeeId.length === 0 ? <div className={`twobuttons-btn-footer`}>
          <Button
            label={'Cancel'}
            handleClick={() => setShowDeleteModal(false)}
            size="medium"
            variant="secondary"
          />
        </div> : ''}
      </ModalPopup>
      <AlertComponent />
    </>
  );
};
export default AttendeeUsersList;
