import { useState, useEffect } from "react";
import Multiselect from "multiselect-react-dropdown";
import { useDispatch } from "react-redux";
import Services from "../../Services/auth.service";
import authServiceEvent from "../../Services/auth.service.event";
import authAdminNotification from "../../Services/auth.admin.notification";
import NotifiedUsertable from "../AdminNotification/NotifiedUsertable";
import Select from "react-select";
import { useGetTagsByAdminQuery } from "../../Services/tag-services";
import { setTag } from "@sentry/browser";

const AddRules = ({
  title,
  addedRules,
  ruleError,
  setRuleError,
  noRuleFieldSelected,
  setNoRuleFieldSelected,
  totalUserList,
  dataFromRules,
  setDataFromRules,
  resetRulesFields,
  showEventField,
  showTagsField,
}) => {
  const emptyObjectModal = {
    event: "",
    eventId: "",
    group: "",
    membership: "",
    Member: false,
    Guest: false,
    Speaker: false,
    Partner: false,
    Users: false,
    userAccess: "all",
    userAccessIds: "",
  };

  const emptyObjectErrModal = {
    event: "",
    group: "",
    membership: "",
    userAccess: "",
    userAccessIds: "",
    Users: "",
  };

  const [rulesDetailErr, setRulesDetailErr] = useState(emptyObjectErrModal);
  const [rulesDetail, setRulesDetail] = useState(emptyObjectModal);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [membership, setMembership] = useState([]);
  const [groups, setGroups] = useState([]);
  const [tags, setTags] = useState([]);
  const [allUser, setAllUser] = useState([]);
  const [allGrp, setAllGrp] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [row, setRow] = useState([]);
  const [isModal, setModal] = useState(false);
  const dispatch = useDispatch();

  //tag list hook
  const { data: tagList } = useGetTagsByAdminQuery();
  // useEffect will call on first render
  useEffect(() => {
    getAllPlanApi();
    getEventListApi();
  }, []);

  // reset rules fields
  useEffect(() => {
    setRulesDetail({ ...rulesDetail, userAccess: "all" });
    setRulesDetailErr({ ...rulesDetailErr, membership: "" });
  }, [resetRulesFields]);

  // get all plan list api function
  async function getAllPlanApi() {
    try {
      const [getPlanRes, allUserRes, groupListRes] = await Promise.all([
        Services.getAllPlan(""),
        Services.getallusers(""),
        Services.getallgroup(),
      ]);

      if (getPlanRes.data.status) {
        const data = getPlanRes.data.data.plans;
        setRow(data);
      }
      if (groupListRes?.data?.status) {
        if (groupListRes?.data?.data?.length > 0) {
          setAllGrp(groupListRes.data.data);
        }
      }
      if (allUserRes.status) {
        let userList = allUserRes.data;
        let arr = [];
        if (userList !== undefined && userList.length > 0) {
          userList
            .filter((user) => user.display_name !== null && user.display_name !== ""  )
            .map((user) =>
              arr.push({
                value: user._id,
                label: `${user.display_name} (${user["Preferred Email"]})`,
              })
            );
          setAllUser(arr);
        }
      } else {
        setAllUser([]);
      }
    } catch (error) {}
  }

  //formate user in the required form
  const formattedAllUsers = (userList) => {
    let arr = [];
    if (userList !== undefined && userList?.length > 0) {
      userList
        .map((user) => {
          const firstName = user.first_name;
          const lastName = user.last_name;
          const email = user["Preferred Email"];
          return { ...user, firstName, lastName, email };
        })
        .map((user) =>
          arr.push({
            value: user._id,
            label: `${user?.display_name} (${user["email"]})`,
          })
        );
    }
    return arr;
  };

  // event list api call
  const getEventListApi = () => {
    authServiceEvent
      .getAllEventsLimitedFiedls()
      .then((res) => {
        if (res.data.status) {
          let list = res.data.data;
          let arr = [{ value: "", label: "Select event" }];
          list.map((event) => {
            arr.push({
              value: event._id,
              label: event.title,
            });
          });

          setEventList(arr);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleEventChange = (selectedOption) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setRulesDetail({
      ...rulesDetail,
      eventId: selectedOption.value,
      event: selectedOption,
    });

    if (
      rulesDetail?.userAccess === "restricted" &&
      selectedUsers?.length === 0 &&
      membership?.length === 0 &&
      groups?.length === 0 &&
      selectedOption?.value?.length === 0 &&
      tags?.length === 0
    ) {
      setRuleError(true);
      setNoRuleFieldSelected(true);
    } else {
      setNoRuleFieldSelected(false);
      setRuleError(false);
    }
  };

  //get body data
  const getBodyData = async (data) => {
    setDataFromRules(data);
    // try {
    //   const userListRes = await authAdminNotification.notificationUserList(
    //     data
    //   );
    //   if (userListRes.data.status) {
    //     setTotalUsers(userListRes.data.data.length);
    //     setTotalUserList(userListRes.data.data);
    //   } else {
    //     setTotalUsers(0);
    //     setTotalUserList([]);
    //   }
    // } catch (error) {
    //   return false;
    // }
  };

  //handling onchange events
  const handleRulesChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setRulesDetail({ ...rulesDetail, [e.target.name]: e.target.value });
  };

  //called when Esc key press
  const onEscCloseModal = (e) => {
    if (isModal && e.key === "Escape") {
      setModal(false);
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", onEscCloseModal);
    }
  };
  document.addEventListener("keydown", onEscCloseModal);

  // useEffect call when user, membership, group and event select
  useEffect(() => {
    if (
      rulesDetail.userAccess === "restricted" &&
      selectedUsers?.length === 0 &&
      membership?.length === 0 &&
      groups?.length === 0 &&
      tags?.length === 0 &&
      rulesDetail.eventId?.length === 0
    ) {
      setRuleError(true);
    } else {
      setRuleError(false);
    }
    rulesBasedUserListApi();
  }, [
    groups,
    membership,
    selectedUsers,
    tags,
    rulesDetail.event,
    // rulesDetail.userAccess,
  ]);

  // api function for userlist based on selected rules
  async function rulesBasedUserListApi() {
    const usersIds =
      rulesDetail.userAccess === "restricted"
        ? selectedUsers?.map((item) => item.value)
        : rulesDetail.userAccess === "all"
        ? []
        : [];
    const plans = membership ? membership?.map((item) => item._id) : [];
    const groupsId = groups ? groups?.map((item) => item._id) : [];
    const tagsId = tags ? tags?.map((item) => item._id) : [];
    const userAccess = rulesDetail?.userAccess ? rulesDetail.userAccess : "all";
    let event;
    if (rulesDetail?.eventId) {
      event = [rulesDetail.eventId];
    } else {
      event = [];
    }
    const rulesDetails = {
      user_id: usersIds,
      membership_plan_id: plans,
      group_id: groupsId,
      tags_id: tagsId,
      event_id: event,
      userAccess: userAccess ? userAccess : "all",
    };
    getBodyData(rulesDetails);
  }

  // useEffect will call when user access is changed
  useEffect(() => {
    if (rulesDetail.userAccess === "all") {
      setSelectedUsers([]);
      setMembership([]);
      setGroups([]);
      setTags([]);
      setRulesDetail({ ...rulesDetail, eventId: "", event: "" });
      setNoRuleFieldSelected(false);
    } else {
      setNoRuleFieldSelected(true);
      if (
        addedRules &&
        ((!showEventField && addedRules?.selectedEvents?.length > 0) ||
          addedRules?.selectedGroups?.length > 0 ||
          addedRules?.selectedTags?.length > 0 ||
          addedRules?.selectedMembership?.length > 0 ||
          addedRules?.selectedUser?.length > 0)
        //    &&
        // dataFromRules &&
        // ((!showEventField && dataFromRules?.event_id > 0) ||
        //   dataFromRules?.group_id?.length > 0 ||
        //   dataFromRules?.membership_plan_id?.length > 0 ||
        //   dataFromRules?.user_id?.length > 0)
      ) {
        setNoRuleFieldSelected(false);
      }
    }
    rulesBasedUserListApi();
  }, [rulesDetail.userAccess, addedRules]);

  useEffect(() => {
    if (ruleError)
      setRulesDetailErr({
        ...rulesDetailErr,
        membership: "Please select rule!",
      });
    else setRulesDetailErr({ ...rulesDetailErr, membership: "" });
  }, [ruleError]);

  //get the rules while doing edit functionality
  useEffect(() => {
    if (addedRules) {
      let rules = rulesDetail;
      rules.userAccess = addedRules?.selectedUserAccess;
      rules.eventId =
        addedRules &&
        addedRules.selectedEvents &&
        addedRules.selectedEvents.length > 0
          ? addedRules.selectedEvents[0]._id
            ? addedRules.selectedEvents[0]._id
            : ""
          : "";

      rules.event =
        rules.eventId !== ""
          ? {
              value: addedRules.selectedEvents[0]._id,
              label: addedRules.selectedEvents[0].title,
            }
          : null;
      setRuleError(false);
      setNoRuleFieldSelected(false);
      setRulesDetail(rules);
      setMembership(addedRules?.selectedMembership);
      setGroups(addedRules?.selectedGroups);
      setTags(addedRules?.selectedTags);
      setSelectedUsers(formattedAllUsers(addedRules?.selectedUser));
    }
  }, [addedRules]);

  return (
    <>
      <div class="cstm-channel-type-title partner-icon-rf-only">{title}</div>
      <span className="error error-rule select-atleast-one-rule-from">
        {rulesDetailErr.membership}
      </span>

      <div
        className={`form-group cstm-admin-access-grp mb-2 ${
          rulesDetail.userAccess !== "restricted" && "mb-2"
        }`}
      >
        <div className="form-group-label">User access</div>
        <div className="form-group-ct">
          <select
            name="userAccess"
            value={rulesDetail.userAccess}
            onChange={handleRulesChange}
          >
            <option value="all">All</option>
            <option value="restricted">Restricted</option>
          </select>
        </div>
      </div>
      {rulesDetail.userAccess === "restricted" && (
        <>
          <div className="form-group cstm-admin-access-grp mb-2">
            <div className="form-group-label">Select groups</div>
            <div className="form-group-ct speakers-only">
              <Multiselect
                showArrow={true}
                placeholder="Select groups"
                displayValue="groupTitle"
                options={allGrp}
                selectedValues={groups}
                onSelect={(selectedlist) => {
                  dispatch({ type: "SETMODALINPROCESS" });
                  dispatch({ type: "SETSTAYONPAGE", payload: null });
                  setGroups(selectedlist);
                  setRulesDetailErr({
                    ...rulesDetailErr,
                    membership: "",
                  });
                  setRuleError(false);
                  setNoRuleFieldSelected(false);
                }}
                onRemove={(selectedlist) => {
                  dispatch({ type: "SETMODALINPROCESS" });
                  dispatch({ type: "SETSTAYONPAGE", payload: null });
                  setGroups(selectedlist);
                  if (
                    selectedlist?.length === 0 &&
                    membership?.length === 0 &&
                    rulesDetail?.userAccess === "restricted" &&
                    rulesDetail?.event?.length === 0
                  ) {
                    setRulesDetailErr({
                      ...rulesDetailErr,
                      membership: "Please select rule!",
                    });
                    setRuleError(true);
                    setNoRuleFieldSelected(true);
                  }
                }}
              />
            </div>
          </div>

          <div className="form-group cstm-admin-access-grp mb-2">
            <div className="form-group-label">Select plans</div>
            <div className="form-group-ct speakers-only">
              <Multiselect
                showArrow={true}
                placeholder="Select membership plans"
                displayValue="plan_name"
                options={row}
                selectedValues={membership}
                onSelect={(selectedlist) => {
                  dispatch({ type: "SETMODALINPROCESS" });
                  dispatch({ type: "SETSTAYONPAGE", payload: null });
                  setMembership(selectedlist);
                  setRulesDetail({
                    ...rulesDetail,
                    membership: "",
                  });
                  setRuleError(false);
                  setNoRuleFieldSelected(false);
                }}
                onRemove={(selectedlist) => {
                  dispatch({ type: "SETMODALINPROCESS" });
                  dispatch({ type: "SETSTAYONPAGE", payload: null });
                  setMembership(selectedlist);
                  if (
                    rulesDetail?.userAccess === "restricted" &&
                    selectedlist?.length === 0 &&
                    selectedUsers?.length === 0 &&
                    rulesDetail?.event?.length === 0
                  ) {
                    setRulesDetail({
                      ...rulesDetail,
                      membership: "Please select rule!",
                    });
                    setRuleError(true);
                    setNoRuleFieldSelected(true);
                  }
                }}
              />
            </div>
          </div>

          {!showEventField && (
            <div className="row">
              <div className="col-sm-12 form-group mb-2">
                <div className="form-group-label">Select event</div>
                <div className="form-group-ct event-type-drop-only">
                  <Select
                    isSearchable={true}
                    options={eventList}
                    value={rulesDetail.event}
                    styles={{ zIndex: 9999 }}
                    onChange={handleEventChange}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="form-group cstm-admin-access-grp cstm-admin-access-rules mb-2">
            <div className="form-group-label">Select users</div>
            <div className="form-group-ct speakers-only">
              <Multiselect
                showArrow={true}
                displayValue="label"
                placeholder="Select users"
                options={allUser}
                selectedValues={selectedUsers}
                onSelect={(selectedlist) => {
                  dispatch({ type: "SETMODALINPROCESS" });
                  dispatch({ type: "SETSTAYONPAGE", payload: null });
                  setSelectedUsers(selectedlist);
                  setRulesDetailErr({
                    ...rulesDetailErr,
                    userAccessIds: "",
                    membership: "",
                  });
                  setRuleError(false);
                  setNoRuleFieldSelected(false);
                }}
                onRemove={(selectedlist) => {
                  dispatch({ type: "SETMODALINPROCESS" });
                  dispatch({ type: "SETSTAYONPAGE", payload: null });
                  setSelectedUsers(selectedlist);
                  if (
                    rulesDetail?.userAccess === "restricted" &&
                    selectedlist?.length === 0 &&
                    membership?.length === 0 &&
                    selectedUsers?.length === 0 &&
                    rulesDetail?.event?.length === 0
                  ) {
                    setRulesDetailErr({
                      ...rulesDetailErr,
                      userAccessIds: "Please select users!",
                      membership: "Please select rule!",
                    });
                    setRuleError(true);
                    setNoRuleFieldSelected(true);
                  }
                }}
              />
            </div>
          </div>

          {showTagsField && (
            <div className="form-group cstm-admin-access-grp mb-2">
              <div className="form-group-label">Select Tags</div>
              <div className="form-group-ct speakers-only">
                <Multiselect
                  showArrow={true}
                  placeholder="Select tags"
                  displayValue="name"
                  options={tagList?.data}
                  selectedValues={tags}
                  onSelect={(selectedlist) => {
                    dispatch({ type: "SETMODALINPROCESS" });
                    dispatch({ type: "SETSTAYONPAGE", payload: null });
                    setTags(selectedlist);
                    setRulesDetailErr({
                      ...rulesDetailErr,
                      membership: "",
                    });
                    setRuleError(false);
                    setNoRuleFieldSelected(false);
                  }}
                  onRemove={(selectedlist) => {
                    dispatch({ type: "SETMODALINPROCESS" });
                    dispatch({ type: "SETSTAYONPAGE", payload: null });
                    setTags(selectedlist);
                    if (
                      selectedlist?.length === 0 &&
                      membership?.length === 0 &&
                      rulesDetail?.userAccess === "restricted" &&
                      rulesDetail?.event?.length === 0
                    ) {
                      setRulesDetailErr({
                        ...rulesDetailErr,
                        membership: "Please select rule!",
                      });
                      setRuleError(true);
                      setNoRuleFieldSelected(true);
                    }
                  }}
                />
              </div>
            </div>
          )}
        </>
      )}
      {totalUserList?.length > 0 && (
        <span
          className="notification-user-list d-block mb-4"
          onClick={() => setModal(true)}
        >
          Preview {totalUserList?.length} people who are in your audience right
          now
        </span>
      )}

      {isModal && (
        <NotifiedUsertable setModal={setModal} users={totalUserList} />
      )}
    </>
  );
};

export default AddRules;
