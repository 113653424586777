import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import auth from "../../Services/auth0services";
const AdminPostAuthenticate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const processHash = (hash) => {
    auth.parseHash({ hash }, function (err, result) {
      if (err) {
        console.log("there is an error");
        console.log(err);
      } else {
        if (result && result.accessToken) {
          const accesstoken = result.accessToken;
          const idtoken = result.idToken;
          localStorage.setItem("adminuserprofile", result.idTokenPayload ? result.idTokenPayload.picture : "")
          localStorage.setItem("adminaccessToken", accesstoken);
          localStorage.setItem("adminidtoken", idtoken);
          localStorage.setItem("logintime", new Date());
          navigate("/user/listing");
        }
      }
    });
  };

  useEffect(() => {
    if (location.hash) {
      processHash(location.hash);
    }
  }, [location]);

  return <></>;
};

export default AdminPostAuthenticate;
