import React, { useEffect, useRef, useState } from "react";
import { emptyObject } from "./CommonFunction";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Frame from "../../../Images/Frame.svg";
import Dropzone from "react-dropzone";
import { useCreateAddonsMutation } from "../../../Services/addons";
import TinyEditor from "../../AdminCommonComponent/TinyEditor";
import Loader2 from "../../../Images/loader2.gif";
import Cropper from "cropperjs";
import AlertComponent from "../../../Hooks/AlertComponent";
import { CompressImageFunction } from "../../AdminNotification/CommonFunction";
import ImageCropperReact from "../../ImageCropper/ImageCropperReact";

const CreateAddons = ({ isCloseAddModal, forceRender }) => {
  //hooks
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const descriptionRef = useRef(null);

  //redux states
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const stayOnPage = useSelector((state) => {
    return state.ReduStayOnPage;
  });

  const [showAlert, setShowAlert] = useState("");
  const [addEvent, setAddEvent] = useState(emptyObject);
  const [addErr, setAddErr] = useState(emptyObject);
  const [toDate, setToDate] = useState(new Date());
  const [validate, setValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAlertAdd, setShowAlertAdd] = useState(false);
  const [alertMsgAdd, setAlertMsgAdd] = useState("");
  const [createInprocess, setCreateInprocess] = useState(false);
  const [userConfirmationCheck, setUserConfirmationCheck] = useState(false);
  const [priceType, setPriceType] = useState("");

  //  image cropping state
  const [cropedFile, setcropedFile] = useState();
  const [fileName, setFileName] = useState("");
  const [modalOpen, setModalOpen] = useState();
  const [thumbnail, setThumbnail] = useState([]);
  const [cropedFileData, setcropedFileData] = useState();
  const [fileDimension, setFileDimension] = useState();
  const [requiredImageSize, setRequiredImageSize] = useState({ width: 480, height: 225 });
  const [requiredImageAspectRatio, setRequiredImageAspectRatio] = useState(16 / 9);

  //event id
  const eventId = new URLSearchParams(location.search).get("id");

  //add guest ticket api hook
  const [createAddons, { isError, isSuccess }] = useCreateAddonsMutation();

  //handle onchange for create addons
  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setAddEvent({ ...addEvent, [e.target.name]: e.target.value });
    if (e.target.name === "name") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "quantityPerOrder") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, quantityPerOrder: "" });
      }
      if (isNaN(e.target.value) || e.target.value <= 0) {
        setAddErr({
          ...addErr,
          quantityPerOrder: "Please enter a valid number",
        });
      } else if (e.target.value.includes(".")) {
        setAddErr({
          ...addErr,
          [e.target.name]: "Quantity should not be a decimal number",
        });
      }
    } else if (e.target.name === "maxQuantity") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
      if (isNaN(e.target.value)) {
        setAddErr({
          ...addErr,
          [e.target.name]: "Please enter a valid number",
        });
      }
    } else if (e.target.name === "minQuantity") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
      if (isNaN(e.target.value)) {
        setAddErr({
          ...addErr,
          [e.target.name]: "Please enter a valid number",
        });
      }
    } else if (e.target.name === "discount") {
      if (e.target.value.trim().length >= 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }

      if (
        isNaN(e.target.value) ||
        e.target.value < 0 ||
        e.target.value >= 100
      ) {
        setAddErr({
          ...addErr,
          [e.target.name]:
            "Discount should be a valid number between 1 and 100",
        });
      }
    } else if (e.target.name === "flatAmount") {
      if (e.target.value.trim().length >= 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }

      if (isNaN(e.target.value)) {
        setAddErr({
          ...addErr,
          [e.target.name]: "Flat Price should be a valid number greater than 0",
        });
      }
    } else if (e.target.name === "mainAmount") {
      if (e.target.value.trim().length >= 0) {
        setAddErr((prevAddErr) => ({
          ...prevAddErr,
          [e.target.name]: "",
        }));
      }
      if (isNaN(e.target.value) || e.target.value <= 0) {
        setAddErr((prevAddErr) => ({
          ...prevAddErr,
          [e.target.name]: "Please enter a valid amount",
        }));
      }
    }

    if (e.target.name === "maxQuantity") {
      if (Number(e.target.value) > Number(addEvent.minQuantity)) {
        setAddErr({ ...addErr, [e.target.name]: "", minQuantity: "" });
      } else if (Number(e.target.value) <= Number(addEvent.minQuantity)) {
        setAddErr({
          ...addErr,
          [e.target.name]:
            "Max quantity should be larger than the min quantity",
        });
      }
      if (e.target.value.includes(".")) {
        setAddErr({
          ...addErr,
          [e.target.name]: "Quantity should not be a decimal number",
        });
      }
    } else if (e.target.name === "minQuantity") {
      if (Number(e.target.value) < Number(addEvent.maxQuantity)) {
        setAddErr({ ...addErr, [e.target.name]: "", maxQuantity: "" });
      } else if (Number(e.target.value) >= Number(addEvent.maxQuantity)) {
        setAddErr({
          ...addErr,
          [e.target.name]:
            "Min quantity should not be larger than the max quantity",
        });
      }
      if (e.target.value.includes(".")) {
        setAddErr({
          ...addErr,
          [e.target.name]: "Quantity should not be a decimal number",
        });
      }
    }

    if (e.target.name === "variationType" && e.target.value !== "") {
      setAddErr({
        ...addErr,
        variationType: "",
      });
    }

    if (e.target.name === "priceType" && e.target.value !== "") {
      setAddErr({
        ...addErr,
        priceType: "",
      });
      if (e.target.value === "free") {
        setAddEvent((prevAddEvent) => {
          const updatedAddEvent = {
            ...prevAddEvent,
            flatAmount: "",
            discount: "",
            mainAmount: "",
            salePrice: "",
            saleEnd: "",
            salesEndTime: "",
            saleStart: "",
            salesStartTime: "",
          };
          return updatedAddEvent;
        });
      } else if (e.target.value === "paid") {
        setUserConfirmationCheck(false);
      }
    } else if (e.target.name === "priceType" && e.target.value === "") {
      setPriceType("");
      setUserConfirmationCheck(false);
    }

    if (e.target.name === "salePrice" && e.target.value !== "") {
      setAddErr({
        ...addErr,
        salePrice: "",
      });
    } else if (e.target.name === "salePrice" && e.target.value === "") {
      setAddEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          flatAmount: "",
          discount: "",
        };
        return updatedAddEvent;
      });
      setAddErr({ ...addErr, flatAmount: "", discount: "" });
    }

    if (e.target.value === "free") {
      setPriceType("free");
      setAddEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          paid: "",
        };
        return updatedAddEvent;
      });
      setAddErr({ ...addErr, [e.target.name]: "" });
    } else if (e.target.value === "paid") {
      setPriceType("paid");
      setAddEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          free: "",
        };
        return updatedAddEvent;
      });
      setAddErr({ ...addErr, [e.target.name]: "" });
    }

    if (e.target.name === "userConfirmation") {
      setUserConfirmationCheck(e.target.checked);
    }

    if (e.target.value === "flat") {
      setAddEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          discount: "",
        };
        return updatedAddEvent;
      });
      setAddErr({ ...addErr, discount: "", salePrice: "" });
    } else if (e.target.value === "percentage") {
      setAddEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          flatAmount: "",
        };
        return updatedAddEvent;
      });
      setAddErr({ ...addErr, flatAmount: "", salePrice: "" });
    }

    if (e.target.name === "mainAmount") {
      if (Number(e.target.value) > Number(addEvent.flatAmount)) {
        setAddErr((prevAddErr) => ({
          ...prevAddErr,
          flatAmount: "",
        }));
      } else if (Number(e.target.value) <= Number(addEvent.flatAmount)) {
        setAddErr((prevAddErr) => ({
          ...prevAddErr,
          flatAmount: "Flat Price should not be greater than main amount",
        }));
      }
    } else if (e.target.name === "flatAmount") {
      if (Number(e.target.value) < Number(addEvent.mainAmount)) {
        setAddErr((prevAddErr) => ({
          ...prevAddErr,
          flatAmount: "",
        }));
      } else if (Number(e.target.value) >= Number(addEvent.mainAmount)) {
        setAddErr((prevAddErr) => ({
          ...prevAddErr,
          flatAmount: "Flat Price should not be greater than main amount",
        }));
      }
    }

    if (e.target.name === "visibility") {
      setAddErr({ ...addErr, visibility: "" });
    }
    if (e.target.name === "visibility" && e.target.value !== "custom") {
      setAddEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          visibilityEndDate: "",
          visibilityStartDate: "",
          visibilityStartTime: "",
          visibilityEndTime: "",
        };
        return updatedAddEvent;
      });
    }

    if (e.target.name === "saleStart") {
      setAddErr({ ...addErr, saleStart: "" });
    }
    if (e.target.name === "saleEnd") {
      setAddErr({ ...addErr, saleEnd: "" });
    }
    if (e.target.name === "salesStartTime") {
      setAddErr({ ...addErr, salesStartTime: "" });
    }
    if (e.target.name === "salesEndTime") {
      setAddErr({ ...addErr, salesEndTime: "" });
    }
    if (e.target.name === "visibilityStartDate") {
      setAddErr({ ...addErr, visibilityStartDate: "" });
    }
    if (e.target.name === "visibilityEndDate") {
      setAddErr({ ...addErr, visibilityEndDate: "" });
    }
    if (e.target.name === "visibilityStartTime") {
      setAddErr({ ...addErr, visibilityStartTime: "" });
    }
    if (e.target.name === "visibilityEndTime") {
      setAddErr({ ...addErr, visibilityEndTime: "" });
    }

    if (
      e.target.name === "visibilityStartDate" ||
      e.target.name === "visibilityEndDate"
    ) {
      setAddErr((prevAddErr) => ({
        ...prevAddErr,
        visibilityStartDate:
          e.target.name === "visibilityStartDate"
            ? ""
            : prevAddErr.visibilityStartDate,
        visibilityEndDate:
          e.target.name === "visibilityEndDate"
            ? ""
            : prevAddErr.visibilityEndDate,
        visibilityStartTime:
          addEvent.visibilityStartDate === addEvent.visibilityEndDate
            ? ""
            : prevAddErr.visibilityStartTime,
        visibilityEndTime:
          addEvent.visibilityStartDate === addEvent.visibilityEndDate
            ? ""
            : prevAddErr.visibilityEndTime,
      }));
    }

    // Additional validation logic for start and end time
    if (
      e.target.name === "visibilityStartTime" ||
      e.target.name === "visibilityEndTime"
    ) {
      const startDate = addEvent.visibilityStartDate;
      const startTime =
        e.target.name === "visibilityStartTime"
          ? e.target.value
          : addEvent.visibilityStartTime;
      const endTime =
        e.target.name === "visibilityEndTime"
          ? e.target.value
          : addEvent.visibilityEndTime;

      // Compare start and end time only if both are defined and date is the same
      if (
        startDate &&
        startTime &&
        endTime &&
        startDate === addEvent.visibilityEndDate
      ) {
        const startDateTime = new Date(`${startDate}T${startTime}`);
        const endDateTime = new Date(`${startDate}T${endTime}`);

        if (startDateTime >= endDateTime) {
          setAddErr((prevAddErr) => ({
            ...prevAddErr,
            [e.target.name === "visibilityStartTime"
              ? "visibilityStartTime"
              : "visibilityEndTime"]: "Start time should be less than end time",
          }));
          return;
        } else {
          setAddErr((prevAddErr) => ({
            ...prevAddErr,
            visibilityEndTime: "",
            visibilityStartTime: "",
          }));
        }
      }
    }
  };

  //validation and api call on create addons
  const handleCreate = () => {
    let status = true;
    let temp = {};
    if (addEvent?.name?.trim().length === 0) {
      temp = { ...temp, name: "Enter name" };
      status = false;
    }

    if (
      isNaN(addEvent?.quantityPerOrder) ||
      addEvent?.quantityPerOrder.trim() === "" ||
      addEvent?.quantityPerOrder <= 0
    ) {
      temp = {
        ...temp,
        quantityPerOrder: "Please enter a valid number",
      };
      status = false;
    }

    if (addEvent?.quantityPerOrder.includes(".")) {
      temp = {
        ...temp,
        quantityPerOrder: "Quantity should not be a decimal number",
      };
      status = false;
    }

    if (isNaN(addEvent?.maxQuantity) || addEvent?.maxQuantity.trim() === "") {
      temp = {
        ...temp,
        maxQuantity: "Please enter a valid number",
      };
      status = false;
    }

    if (isNaN(addEvent?.minQuantity) || addEvent?.minQuantity.trim() === "") {
      temp = {
        ...temp,
        minQuantity: "Please enter a valid number",
      };
      status = false;
    }

    if (Number(addEvent?.minQuantity) > Number(addEvent?.maxQuantity)) {
      temp = {
        ...temp,
        minQuantity: "min quantity should not be larger than the max quantity ",
      };
      status = false;
    }

    if (addEvent?.minQuantity.includes(".")) {
      temp = {
        ...temp,
        minQuantity: "Quantity should not be a decimal number",
      };
      status = false;
    }
    if (addEvent?.maxQuantity.includes(".")) {
      temp = {
        ...temp,
        maxQuantity: "Quantity should not be a decimal number",
      };
      status = false;
    }

    if (
      addEvent?.priceType === "paid" &&
      (isNaN(addEvent?.mainAmount) ||
        addEvent?.mainAmount.trim() === "" ||
        addEvent?.mainAmount <= 0)
    ) {
      temp = {
        ...temp,
        mainAmount: "Please enter a valid amount",
      };
      status = false;
    }

    if (addEvent?.variationType === "") {
      temp = {
        ...temp,
        variationType: "Select variation type",
      };
      status = false;
    }

    if (addEvent?.priceType === "") {
      temp = {
        ...temp,
        priceType: "Select price type",
      };
      status = false;
    }

    if (addEvent?.salePrice === "percentage") {
      const discountValue = Number(addEvent?.discount);
      if (isNaN(discountValue) || discountValue <= 0 || discountValue >= 100) {
        temp = {
          ...temp,
          discount: "Discount should be a valid number between 1 and 100",
        };
        status = false;
      }
    } else if (addEvent?.salePrice === "flat") {
      const flatAmountValue = Number(addEvent?.flatAmount);
      if (isNaN(flatAmountValue) || flatAmountValue <= 0) {
        temp = {
          ...temp,
          flatAmount: "Flat Price should be a valid number greater than 0",
        };
        status = false;
      }
    }

    if (Number(addEvent?.flatAmount) > Number(addEvent?.mainAmount)) {
      temp = {
        ...temp,
        flatAmount: "Flat Price should not be greater than main amount",
      };
      status = false;
    }

    if (addEvent?.visibility === "") {
      temp = {
        ...temp,
        visibility: "Select visibility",
      };
      status = false;
    }

    if (addEvent?.priceType === "paid" && addEvent?.saleStart === "") {
      temp = {
        ...temp,
        saleStart: "Select start date",
      };
      status = false;
    }

    if (addEvent?.priceType === "paid" && addEvent?.saleEnd === "") {
      temp = {
        ...temp,
        saleEnd: "Select end date",
      };
      status = false;
    }

    if (addEvent?.priceType === "paid" && addEvent?.salesStartTime === "") {
      temp = {
        ...temp,
        salesStartTime: "Select start time",
      };
      status = false;
    }

    if (addEvent?.priceType === "paid" && addEvent?.salesEndTime === "") {
      temp = {
        ...temp,
        salesEndTime: "Select end time",
      };
      status = false;
    }

    if (
      addEvent?.visibility === "custom" &&
      addEvent?.visibilityStartDate === ""
    ) {
      temp = {
        ...temp,
        visibilityStartDate: "Select start date",
      };
      status = false;
    }
    if (
      addEvent?.visibility === "custom" &&
      addEvent?.visibilityEndDate === ""
    ) {
      temp = {
        ...temp,
        visibilityEndDate: "Select end date",
      };
      status = false;
    }
    if (
      addEvent?.visibility === "custom" &&
      addEvent?.visibilityStartTime === ""
    ) {
      temp = {
        ...temp,
        visibilityStartTime: "Select start time",
      };
      status = false;
    }
    if (
      addEvent?.visibility === "custom" &&
      addEvent?.visibilityEndTime === ""
    ) {
      temp = {
        ...temp,
        visibilityEndTime: "Select end time",
      };
      status = false;
    }

    if (addEvent?.visibilityEndTime || addEvent?.visibilityStartTime) {
      const startDate = addEvent.visibilityStartDate;
      // Compare start and end time only if both are defined and date is the same
      if (
        addEvent?.visibilityStartTime >= addEvent?.visibilityEndTime &&
        startDate === addEvent.visibilityEndDate
      ) {
        status = false;
        temp = {
          ...temp,
          visibilityEndTime: "Start time should be less than end time",
        };
      }
    }

    if (status) {
      createApiCall();
      setAddEvent(emptyObject);
      if (descriptionRef.current && descriptionRef.current.getContent) {
        descriptionRef.current.setContent("");
      }
    } else {
      setAddErr(temp);
    }
  };

  //Api calll on create addons
  const createApiCall = async () => {
    let priceType = "";
    if (addEvent?.priceType === "free") {
      priceType = "FREE";
    } else if (addEvent?.priceType === "paid") {
      priceType = "PAID";
    }

    let salePriceType;
    if (addEvent?.salePrice === "flat") {
      salePriceType = "FLAT";
    } else if (addEvent?.salePrice === "percentage") {
      salePriceType = "PERCENTAGE";
    }

    let salesValue;
    if (addEvent?.flatAmount) {
      salesValue = addEvent?.flatAmount;
    } else if (addEvent?.discount) {
      salesValue = addEvent?.discount;
    }

    let visibility;
    if (addEvent?.visibility === "public") {
      visibility = "VISIBLE";
    } else if (addEvent?.visibility === "hidden") {
      visibility = "HIDDEN";
    } else if (addEvent?.visibility === "custom") {
      visibility = "CUSTOM";
    }

    //convert string date to formatted date
    const dateConvertor = (date, time) => {
      if (!date) return;
      const combinedDateTimeString = date + "T" + time + ":00";
      const combinedDateTime = new Date(combinedDateTimeString);
      const isoFormattedDateTime = combinedDateTime.toISOString();
      return isoFormattedDateTime;
    };
    setCreateInprocess(true);
    const formdata = new FormData();
    let imageCompress;
    if (cropedFileData) {
      imageCompress = await CompressImageFunction(cropedFileData);
    }
    imageCompress && formdata.append("coverImage", imageCompress);
    // formdata.append("coverImage", cropedFile);
    formdata.append("name", addEvent?.name);
    formdata.append("details", descriptionRef?.current?.getContent());
    formdata.append("quantity", addEvent?.quantityPerOrder);
    formdata.append("type", priceType);
    formdata.append("salesValue", salesValue || 0);

    formdata.append("minimumQuantity", addEvent?.minQuantity);
    formdata.append("maximumQuantity", addEvent?.maxQuantity);
    formdata.append("visibility", visibility);

    formdata.append("userConfirmationRequired", userConfirmationCheck);
    formdata.append(
      "isAddVariation",
      addEvent?.variationType === "withVariation" ? true : false
    );
    formdata.append("price", addEvent.mainAmount || 0);
    formdata.append("eventId", eventId);

    if (salePriceType) {
      formdata.append("salesPriceType", salePriceType);
    }

    if (addEvent?.priceType === "paid") {
      formdata.append(
        "saleStart",
        dateConvertor(addEvent.saleStart, addEvent.salesStartTime)
      );
      formdata.append(
        "saleEnd",
        dateConvertor(addEvent.saleEnd, addEvent.salesEndTime)
      );
    }

    if (addEvent?.visibility === "custom") {
      formdata.append(
        "visibilityStartDate",
        dateConvertor(
          addEvent.visibilityStartDate,
          addEvent.visibilityStartTime
        )
      );
      formdata.append(
        "visibilityEndDate",
        dateConvertor(addEvent.visibilityEndDate, addEvent.visibilityEndTime)
      );
    }
    try {
      const createAddonsRes = await createAddons(formdata);
      if (createAddonsRes?.data?.data) {
        setAddEvent(emptyObject);
        setCreateInprocess(false);
        setTimeout(() => {
          $("#CreateAddons").modal("hide");
          handleCloseAddModel();
        }, 1500);
        setcropedFile();
        setShowAlertAdd(true);
        setAlertMsgAdd(createAddonsRes.data.message);
        setTimeout(() => {
          setShowAlertAdd(false);
          setAlertMsgAdd("");
        }, 3000);
        handleClearAllStates();
      }

      if (createAddonsRes?.data?.status === false) {
        setShowAlertAdd(true);
        setAlertMsgAdd(createAddonsRes?.data?.message);
        setTimeout(() => {
          setShowAlertAdd(false);
          setAlertMsgAdd("");
        }, 3000);
        setCreateInprocess(false);
        setTimeout(() => {
          $("#CreateAddons").modal("hide");
          handleCloseAddModel();
        }, 3000);
      }
    } catch (err) {
      console.log("addons add Error:", err);
    }
    setAddErr(emptyObject);
  };

  //close create session module
  const handleCloseAddModel = (e) => {
    if (descriptionRef.current) {
      descriptionRef.current.setContent("");
    }
    setAddEvent(emptyObject);
    setAddErr(emptyObject);
    setUserConfirmationCheck(false);
    setcropedFile();
    setCreateInprocess(false);
    handleClearAllStates();
  };

  //converting state to initial state
  useEffect(() => {
    if (isCloseAddModal) handleCloseAddModel();
  }, [isCloseAddModal]);

  //clear add state when click on edit main ticket
  useEffect(() => {
    handleCloseAddModel();
  }, [forceRender]);

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  //stayonpage  popup for do you want to leave page
  useEffect(() => {
    if (stayOnPage !== null && stayOnPage) {
      if ($("#CreateAddons").hasClass("show")) {
        $("#CreateAddons").modal("show");
      }
    } else if (stayOnPage !== null && !stayOnPage) {
      if ($("#CreateAddons").hasClass("show")) {
        $("#CreateAddons").modal("hide");
      }
    }
  }, [stayOnPage]);

  // thumbnail image upload function
  const thumbnailImages = async (acceptedfile) => {
    setFileDimension(acceptedfile);
    dispatch({ type: "SETMODALINPROCESS" });
    setFileName(Math.random().toString() + acceptedfile.name);
    setModalOpen(true);
  };

  return (
    <>
      {modalOpen && (
        <ImageCropperReact
          file={thumbnail}
          setModalOpen={setModalOpen}
          setcropedFile={setcropedFile}
          setFile={setThumbnail}
          fileName={fileName}
          setcropedFileData={setcropedFileData}
          fileDimension={fileDimension}
          requiredImageSize={requiredImageSize}
          requiredImageAspectRatio={requiredImageAspectRatio}
          setRequiredImageSizeError={() => setAddErr
            ({ ...addErr, image: "Recommended size 480 x 225" })}
        />
      )}
      <div
        className="modal fade edit-user-details-popup"
        id="CreateAddons"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Create Addon</h2>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#CreateAddons").modal("hide");
                    handleCloseAddModel();
                  }
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {showAlertAdd && (
                <div className="alert alert-info">{alertMsgAdd}</div>
              )}


              <div className="cstm-video-upload">
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    const file = acceptedFiles[0];
                    if (file.type.startsWith("image/")) {
                      const reader = new FileReader();
                      reader.onload = function (event) {
                        const img = new Image();
                        img.onload = function () {
                          setThumbnail(event.target.result);
                          thumbnailImages(file);
                          setAddErr({ ...addErr, image: "" })
                        };
                        img.src = event.target.result;
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                  name="thumbnail"
                  multiple={false}
                  accept="image/jpeg, image/png,  image/tiff, image/jpg"
                  maxSizeBytes={10485760}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />

                      <div className="cstm-video-upload-img">
                        {cropedFile ? (
                          <img className="channel-img w-100 h-100" src={cropedFile} alt="Uploaded video thumbnail" />
                        ) : (
                          <img className="empty-img" src={Frame} alt="Placeholder image" />
                        )}
                      </div>
                    </div>
                  )}
                </Dropzone>

                {/* Display error message for file selection */}
                {validate && (
                  <span
                    className="error cstm-error44"
                    style={{ marginTop: "5px" }}
                  >
                    {"Invalid file type. Please select an image file."}
                  </span>
                )}

                {/* Display error message related to notificationDetailErr.image */}
                {!validate && addErr.image && (
                  <span
                    className="error cstm-error44"
                    style={{ marginTop: "5px" }}
                  >
                    {addErr.image}
                  </span>
                )}
              </div>
              <div className="group-info-user-link">
                <div className="cover-image-upload-ttl">Upload image</div>
              </div>

              <div className="form-group">
                <div className="form-group-label">Name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter name"
                    name="name"
                    onChange={handleAddChange}
                    value={addEvent.name}
                  />
                  <span className="error">{addErr.name}</span>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">Description</div>
                  <div className="cstm-editor-tool-ds">
                    <TinyEditor
                      valueRef={descriptionRef}
                      value={addEvent.description}
                      handleEditorChange={(value, editor) => {
                        dispatch({ type: "SETMODALINPROCESS" });
                        setAddErr({
                          ...addErr,
                          description: "",
                        });
                      }}
                      handleKeyPress={() => {
                        dispatch({ type: "SETMODALINPROCESS" });
                      }}
                    />
                  </div>
                  <span className="error">{addErr.description}</span>
                </div>
              </div>

              <div className="form-group">
                <div className="form-group-label">Variation type*</div>
                <div className="form-group-ct">
                  <select
                    value={addEvent.variationType}
                    onChange={handleAddChange}
                    name="variationType"
                  >
                    <option value="">Select addons variation</option>
                    <option value="withVariation">Addons with variation</option>
                    <option value="withoutVariation">
                      Addons without variation
                    </option>
                  </select>
                </div>
                <span className="error cstm-error4">
                  {addErr.variationType}
                </span>
              </div>

              <div className="form-group cstm-admin-access-grp">
                <div className="form-group-label">Price*</div>
                <div className="form-group-ct">
                  <select
                    name="priceType"
                    value={addEvent.priceType}
                    onChange={handleAddChange}
                  >
                    <option value="">Select price</option>
                    <option value="free">Free</option>
                    <option value="paid">Paid</option>
                  </select>
                </div>
                <span className="error cstm-error4">{addErr.priceType}</span>
              </div>
              {addEvent.priceType === "free" && (
                <div className="row">
                  <div className="col-sm-12 form-group">
                    <div className="cstm-chk-preregistration-container">
                      <input
                        type="checkbox"
                        name="userConfirmation"
                        id="userConfirmation"
                        onChange={handleAddChange}
                        checked={userConfirmationCheck === true}
                      />
                      <label
                        for="chk-preregistration"
                        htmlFor="userConfirmation"
                        onChange={handleAddChange}
                      >
                        Free addon required confirmation from user for
                        headcount?
                      </label>
                    </div>
                  </div>
                </div>
              )}
              {addEvent.priceType === "paid" && (
                <>
                  <div className="form-group">
                    <div className="form-group-label">Amount*</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter amount"
                        name="mainAmount"
                        onChange={handleAddChange}
                        value={addEvent.mainAmount}
                      />
                      <span className="error">{addErr.mainAmount}</span>
                    </div>
                  </div>
                  <div className="form-group cstm-admin-access-grp">
                    <div className="form-group-label">Sale Price</div>
                    <div className="form-group-ct">
                      <select
                        name="salePrice"
                        value={addEvent.salePrice}
                        onChange={handleAddChange}
                      >
                        <option value="">Select sale price</option>
                        <option value="flat">Flat</option>
                        <option value="percentage">Percentage</option>
                      </select>
                    </div>
                    <span className="error cstm-error4">
                      {addErr.salePrice}
                    </span>
                  </div>

                  {addEvent.salePrice === "flat" && (
                    <>
                      <div className="form-group">
                        <div className="form-group-label">Flat($)</div>
                        <div className="form-group-ct">
                          <input
                            type="text"
                            placeholder="Enter flat price"
                            name="flatAmount"
                            onChange={handleAddChange}
                            value={addEvent?.flatAmount}
                          />
                          <span className="error">{addErr.flatAmount}</span>
                        </div>
                      </div>
                    </>
                  )}

                  {addEvent.salePrice === "percentage" && (
                    <>
                      <div className="form-group">
                        <div className="form-group-label">Discount(%)</div>
                        <div className="form-group-ct">
                          <input
                            type="text"
                            placeholder="Enter discount"
                            name="discount"
                            onChange={handleAddChange}
                            value={addEvent?.discount}
                          />
                          <span className="error">{addErr.discount}</span>
                        </div>
                      </div>
                    </>
                  )}

                  <div class="form-group">
                    <div class="cstm-channel-type-title partner-icon-rf-only">
                      Sale duration
                    </div>
                    <div className="form-group">
                      <div className="form-group-label">Start date*</div>
                      <div className="form-group-ct">
                        <input
                          type="date"
                          name="saleStart"
                          onChange={handleAddChange}
                          value={addEvent.saleStart}
                        />
                        <span className="error">{addErr.saleStart}</span>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="form-group-label">Start time*</div>
                      <div className="form-group-ct">
                        <input
                          type="time"
                          name="salesStartTime"
                          onChange={handleAddChange}
                          value={addEvent.salesStartTime}
                          disabled={
                            addEvent.saleStart.length === 0 ? true : false
                          }
                        />
                        <span className="error">{addErr.salesStartTime}</span>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="form-group-label">End date*</div>
                      <div className="form-group-ct">
                        <input
                          type="date"
                          name="saleEnd"
                          onChange={handleAddChange}
                          value={addEvent.saleEnd}
                          disabled={
                            addEvent.saleStart.length === 0 ? true : false
                          }
                          min={addEvent.saleStart}
                        />
                        <span className="error">{addErr.saleEnd}</span>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="form-group-label">End time*</div>
                    <div className="form-group-ct">
                      <input
                        type="time"
                        name="salesEndTime"
                        onChange={handleAddChange}
                        value={addEvent.salesEndTime}
                        disabled={addEvent.saleEnd.length === 0 ? true : false}
                      />
                      <span className="error">{addErr.salesEndTime}</span>
                    </div>
                  </div>
                </>
              )}

              <div className="form-group">
                <div className="form-group-label">Quantity per order*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter quantity per order"
                    name="quantityPerOrder"
                    onChange={handleAddChange}
                    value={addEvent.quantityPerOrder}
                  />
                  <span className="error">{addErr.quantityPerOrder}</span>
                </div>
              </div>

              <div className="form-group">
                <div className="form-group-label">Max. quantity*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter maximum quantity"
                    name="maxQuantity"
                    onChange={handleAddChange}
                    value={addEvent.maxQuantity}
                  />
                  <span className="error">{addErr.maxQuantity}</span>
                </div>
              </div>

              <div className="form-group">
                <div className="form-group-label">Min. quantity*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter minimum quantity"
                    name="minQuantity"
                    onChange={handleAddChange}
                    value={addEvent.minQuantity}
                  />
                  <span className="error">{addErr.minQuantity}</span>
                </div>
              </div>

              <div class="form-group">
                <div class="cstm-channel-type-title partner-icon-rf-only">
                  Select visibility*
                </div>
                <div class="cstm-pm-list">
                  <label class="subcat-addvideo">
                    <input
                      type="radio"
                      value="public"
                      name="visibility"
                      checked={addEvent?.visibility === "public"}
                      onChange={handleAddChange}
                    />
                    Public
                  </label>
                  <label class="subcat-addvideo">
                    {" "}
                    <input
                      type="radio"
                      value="hidden"
                      name="visibility"
                      checked={addEvent?.visibility === "hidden"}
                      onChange={handleAddChange}
                    />
                    Hidden
                  </label>
                  <label class="subcat-addvideo">
                    <input
                      type="radio"
                      value="custom"
                      name="visibility"
                      checked={addEvent?.visibility === "custom"}
                      onChange={handleAddChange}
                    />
                    Custom
                  </label>
                </div>
                <span className="error cstm-pm-list-msg">
                  {addErr.visibility}
                </span>
              </div>

              {addEvent?.visibility === "custom" && (
                <>
                  <div className="form-group">
                    <div className="form-group-label">Start date*</div>
                    <div className="form-group-ct">
                      <input
                        type="date"
                        name="visibilityStartDate"
                        onChange={handleAddChange}
                        value={addEvent.visibilityStartDate}
                      />
                      <span className="error">
                        {addErr.visibilityStartDate}
                      </span>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="form-group-label">Start time*</div>
                    <div className="form-group-ct">
                      <input
                        type="time"
                        name="visibilityStartTime"
                        onChange={handleAddChange}
                        value={addEvent.visibilityStartTime}
                        disabled={
                          addEvent.visibilityStartDate.length === 0
                            ? true
                            : false
                        }
                      />
                      <span className="error">
                        {addErr.visibilityStartTime}
                      </span>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="form-group-label">End date*</div>
                    <div className="form-group-ct">
                      <input
                        type="date"
                        name="visibilityEndDate"
                        onChange={handleAddChange}
                        value={addEvent.visibilityEndDate}
                        disabled={
                          addEvent.visibilityStartDate.length === 0
                            ? true
                            : false
                        }
                        min={addEvent.visibilityStartDate}
                      />
                      <span className="error">{addErr.visibilityEndDate}</span>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="form-group-label">End time*</div>
                    <div className="form-group-ct">
                      <input
                        type="time"
                        name="visibilityEndTime"
                        onChange={handleAddChange}
                        value={addEvent.visibilityEndTime}
                        disabled={
                          addEvent.visibilityStartTime.length === 0
                            ? true
                            : false
                        }
                      />
                      <span className="error">{addErr.visibilityEndTime}</span>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="edit-user-details-footer cstm-create-button">
              {createInprocess ? (
                <div className="loader-button1">
                  <button>In process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleCreate}
                  disabled={createInprocess}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <AlertComponent />
    </>
  );
};

export default CreateAddons;
