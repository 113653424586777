import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Loader from "../../../Images/loader.gif";
import Loader2 from "../../../Images/loader2.gif";
import Frame from "../../../Images/Frame.svg";
import Dropzone from "react-dropzone";
import Multiselect from "multiselect-react-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { emptyObject } from "./CommonFunctions";
import TinyEditor from "../../AdminCommonComponent/TinyEditor";
import AddRules from "../../CommonRules/AddRules";
import {
  useEditMainTicketMutation,
  useLazyGetTicketByTicketIdQuery,
} from "../../../Services/mainTicket";
import { CompressImageFunction } from "../../AdminNotification/CommonFunction";
import { useAddonsByEventIdQuery } from "../../../Services/addons";
import { useGuestTicketByEventIdQuery } from "../../../Services/guestTicket";
import ImageCropperReact from "../../ImageCropper/ImageCropperReact";

const EditMainTicket = ({ editId, forceRender, isCloseAddModal }) => {
  //hooks
  const editDescriptionRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();

  //redux states
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const stayOnPage = useSelector((state) => {
    return state.ReduStayOnPage;
  });

  const [editEvent, setEditEvent] = useState(emptyObject);
  const [editErr, setEditErr] = useState(emptyObject);
  const [allowGuestCheck, setAllowGuestCheck] = useState(false);
  const [checkoutCheck, setcheckoutCheck] = useState(false);
  const [addonAvailableCheck, setAddonAvailableCheck] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedGuestTickets, setSelectedGuestTickets] = useState([]);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [rulesDataForEdit, setRulesDataForEdit] = useState({});
  const [createInprocess, setCreateInprocess] = useState(false);
  const [validate, setValidate] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [ruleError, setRuleError] = useState(false);
  const [noRuleFieldSelected, setNoRuleFieldSelected] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [resetRulesFields, setResetRulesFields] = useState(false);
  const [dataFromRules, setDataFromRules] = useState([]);
  const [priceType, setPriceType] = useState("");
  const [cropedFile, setcropedFile] = useState();
  const [editLoader, setEditLoader] = useState(false);
  const [isErrorMessage, setErrorMessage] = useState(false);
  const [addErrorMessage, setAddErrorMessage] = useState("");
  const [showAlertAdd, setShowAlertAdd] = useState(false);
  const [alertMsgAdd, setAlertMsgAdd] = useState("");

  //  image cropping state
  const [fileDimension, setFileDimension] = useState("");
  const [modalOpen, setModalOpen] = useState();
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState("");
  const [cropedFileData, setcropedFileData] = useState();


  //event id
  const eventId = new URLSearchParams(location.search).get("id");
  //main ticket edit api
  const [editMainTicket] = useEditMainTicketMutation();

  //get main ticket by id
  const [mainTicketDataById] = useLazyGetTicketByTicketIdQuery();

  //get guest tickets list
  const { data: guestTicketList } = useGuestTicketByEventIdQuery({
    event_id: eventId,
    params: {
      page: 1,
      limit: 100,
    },
  });
  //get addons list
  const { data: addonList } = useAddonsByEventIdQuery({
    event_id: eventId,
    params: {
      page: 1,
      limit: 100,
    },
  });

  //clear edit state when click on create main ticket
  useEffect(() => {
    handleCloseAddModel();
  }, [isCloseAddModal]);

  useEffect(() => {
    handleEdit();
  }, [forceRender]);

  //close create session module
  const handleCloseAddModel = () => {
    if (editDescriptionRef.current) {
      editDescriptionRef.current.setContent("");
    }
    setResetRulesFields(true);
    setTimeout(() => {
      setResetRulesFields(false);
    }, 2000);
    setEditEvent(emptyObject);
    setEditErr(emptyObject);
    setcropedFile();
    setAllowGuestCheck(false);
    setcheckoutCheck(false);
    setAddonAvailableCheck(false);
    setIsChecked(false);
    setSelectedGuestTickets([]);
    setSelectedAddons([]);
    setCreateInprocess(false);
    handleClearAllStates();
  };

  // image upload
  const assignFiles = (acceptedFile) => {
    setFileDimension(acceptedFile[0]);
    dispatch({ type: "SETMODALINPROCESS" });
    setModalOpen(true);
    setFileName(Math.random().toString() + acceptedFile[0].name);
    setFile(URL.createObjectURL(acceptedFile[0]));
  };

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  //set the id while click on edit button
  const handleEdit = async () => {
    setEditLoader(true);
    setSelectedId(editId);
    try {
      const response = await mainTicketDataById({ ticketId: editId });
      if (response?.data?.status) {
        let data = response?.data?.data;
        let priceTypeCheck = data?.type === "FREE" ? "free" : "paid";

        let salePriceCheck =
          data?.salesPriceType === "FLAT"
            ? "flat"
            : data?.salesPriceType === "PERCENTAGE"
              ? "percentage"
              : "";

        let visibilityChange =
          data?.visibilityType === "VISIBLE"
            ? "public"
            : data?.visibilityType === "HIDDEN"
              ? "hidden"
              : "custom";

        const validDateFormate = (date) => {
          if (!date) return;
          const newDate = new Date(date);
          const formattedDate = `${newDate.getFullYear()}-${(
            newDate.getMonth() + 1
          )
            .toString()
            .padStart(2, "0")}-${newDate
              .getDate()
              .toString()
              .padStart(2, "0")}`;
          return formattedDate;
        };

        const getTime = (dateTimeString) => {
          let inputDate = new Date(dateTimeString);
          let hours = inputDate.getHours();
          hours = hours < 10 ? "0" + hours : hours;
          let minutes = inputDate.getMinutes();
          minutes = minutes < 10 ? "0" + minutes : minutes;
          let formattedTime = hours + ":" + minutes;
          return formattedTime;
        };

        let ticketAccess;
        if (data?.ticketAccess === "RESTRICTED") {
          ticketAccess = "restricted";
        } else if (data?.ticketAccess === "PUBLIC") {
          ticketAccess = "all";
        }

        let imgUrl =
          data?.thumbnail !== undefined && data?.thumbnail !== ""
            ? data?.thumbnail
            : "";
        setEditEvent({
          image: imgUrl,
          name: data?.name,
          description: data?.description,
          quantity: data?.quantity,
          priceType: priceTypeCheck,
          maxTickets: data?.maximumTicket,
          minTickets: data?.minimumTicket,
          salePrice: salePriceCheck,
          saleStart: data.salesStartDate
            ? validDateFormate(data.salesStartDate)
            : "",
          saleEnd: data.salesEndDate ? validDateFormate(data.salesEndDate) : "",
          salesValue: data?.salesValue ? data?.salesValue : "",
          visibility: visibilityChange,
          visibilityStartDate: data.visibilityStartDate
            ? validDateFormate(data.visibilityStartDate)
            : "",
          visibilityEndDate: data.visibilityEndDate
            ? validDateFormate(data.visibilityEndDate)
            : "",
          discount:
            salePriceCheck === "percentage"
              ? data?.salesValue
                ? data?.salesValue
                : ""
              : "",
          flatAmount:
            salePriceCheck === "flat"
              ? data?.salesValue
                ? data?.salesValue
                : ""
              : "",
          visibilityStartTime: data.visibilityStartDate
            ? getTime(data.visibilityStartDate)
            : "",
          visibilityEndTime: data.visibilityEndDate
            ? getTime(data.visibilityEndDate)
            : "",
          salesStartTime: data.salesStartDate
            ? getTime(data.salesStartDate)
            : "",
          salesEndTime: data.salesEndDate ? getTime(data.salesEndDate) : "",
          mainAmount: data.basePrice ? data.basePrice : "",
          cancellationDaysBeforeEvent: data?.cancellationDaysBeforeEvent,
          maxGuestAllowed: data?.guestPerTicket,
        });
        setPriceType(priceTypeCheck);
        setIsChecked(data?.isCancellation);
        setAllowGuestCheck(data?.isGuestAllow);
        setSelectedGuestTickets(data?.selectGuestTicket);
        setAddonAvailableCheck(data?.isAddOnAvailable);
        setSelectedAddons(data?.selectAddon);
        setRulesDataForEdit({
          selectedUser: data?.restrictedIndivisualUser,
          selectedGroups: data?.restrictedAccessGroups,
          selectedMembership: data?.restrictedAccessMemberships,
          selectedUserAccess: ticketAccess,
        });
        setEditLoader(false);
      }
    } catch (err) {
      console.log("Error:", err);
    }
  };

  //onChange event for edit main ticket
  const handleEditChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setEditEvent({ ...editEvent, [e.target.name]: e.target.value });
    if (e.target.name === "name") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "maxTickets") {
      if (e.target.value.trim().length >= 0) {
        setEditErr((prevAddErr) => ({
          ...prevAddErr,
          [e.target.name]: "",
        }));
      }
      if (isNaN(e.target.value) || e.target.value <= 0) {
        setEditErr((prevAddErr) => ({
          ...prevAddErr,
          [e.target.name]: "Please enter a valid amount",
        }));
      }
    } else if (e.target.name === "minTickets") {
      if (e.target.value.trim().length >= 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
      if (isNaN(e.target.value) || e.target.value <= 0) {
        setEditErr({
          ...editErr,
          [e.target.name]: "Enter a valid number",
        });
      }
    } else if (e.target.name === "discount") {
      if (e.target.value.trim().length >= 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }

      if (
        isNaN(e.target.value) ||
        e.target.value < 0 ||
        e.target.value >= 100
      ) {
        setEditErr({
          ...editErr,
          [e.target.name]:
            "Discount should be a valid number between 1 and 100",
        });
      }
    } else if (e.target.name === "flatAmount") {
      if (e.target.value.trim().length >= 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }

      if (isNaN(e.target.value)) {
        setEditErr({
          ...editErr,
          [e.target.name]: "Flat Price should be a valid number greater than 0",
        });
      }
    } else if (e.target.name === "mainAmount") {
      if (e.target.value.trim().length >= 0) {
        setEditErr((prevAddErr) => ({
          ...prevAddErr,
          [e.target.name]: "",
        }));
      }
      if (isNaN(e.target.value) || e.target.value <= 0) {
        setEditErr((prevAddErr) => ({
          ...prevAddErr,
          [e.target.name]: "Please enter a valid amount",
        }));
      }
    } else if (e.target.name === "quantity") {
      if (e.target.value.trim().length >= 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
      if (isNaN(e.target.value) || e.target.value <= 0) {
        setEditErr({
          ...editErr,
          [e.target.name]: "Please enter a valid quantity",
        });
      } else if (e.target.value.includes(".")) {
        setEditErr({
          ...editErr,
          [e.target.name]: "Quantity should not be a decimal number",
        });
      }
    } else if (e.target.name === "maxGuestAllowed") {
      if (e.target.value.trim().length >= 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
      if (isNaN(e.target.value) || e.target.value <= 0) {
        setEditErr({
          ...editErr,
          [e.target.name]: "Enter a valid number",
        });
      } else if (e.target.value.includes(".")) {
        setEditErr({
          ...editErr,
          [e.target.name]: "Max guest allowed should not be a decimal number",
        });
      }
    } else if (e.target.name === "cancellationDaysBeforeEvent") {
      if (e.target.value.trim().length >= 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
      if (isNaN(e.target.value) || e.target.value <= 0) {
        setEditErr({
          ...editErr,
          [e.target.name]: "Enter a valid number",
        });
      } else if (e.target.value.includes(".")) {
        setEditErr({
          ...editErr,
          [e.target.name]: "Cancellation days should not be a decimal number",
        });
      }
    }

    if (e.target.name === "maxTickets") {
      if (
        Number(e.target.value) > Number(editEvent.minTickets) &&
        !e.target.value.includes(".")
      ) {
        setEditErr({ ...editErr, [e.target.name]: "", minTickets: "" });
      } else if (
        Number(e.target.value) <= Number(editEvent.minTickets) &&
        !e.target.value.includes(".")
      ) {
        setEditErr({
          ...editErr,
          [e.target.name]:
            "Max quantity should be larger than the min quantity",
        });
      }
      if (e.target.value.includes(".")) {
        setEditErr({
          ...editErr,
          [e.target.name]: "Quantity should not be a decimal number",
        });
      }
    } else if (e.target.name === "minTickets") {
      if (Number(e.target.value) < Number(editEvent.maxTickets)) {
        setEditErr({ ...editErr, [e.target.name]: "", maxTickets: "" });
      } else if (Number(e.target.value) > Number(editEvent.maxTickets)) {
        setEditErr({
          ...editErr,
          [e.target.name]:
            "Min quantity should not be larger than the max quantity",
        });
      }
      if (e.target.value.includes(".")) {
        setEditErr({
          ...editErr,
          [e.target.name]: "Quantity should not be a decimal number",
        });
      } else if (e.target.value < 0) {
        setEditErr((prevAddErr) => ({
          ...prevAddErr,
          [e.target.name]: "Enter a valid number",
        }));
      }
    }

    if (e.target.name === "quantity") {
      if (Number(e.target.value) > Number(editEvent?.maxTickets)) {
        setEditErr((prevAddErr) => ({
          ...prevAddErr,
          maxTickets: "",
        }));
      } else if (Number(e.target.value) <= Number(editEvent.maxTickets)) {
        setEditErr((prevAddErr) => ({
          ...prevAddErr,
          maxTickets:
            "QTY should be less or equal to the total number of tickets available",
        }));
      }
    } else if (e.target.name === "maxTickets") {
      if (
        Number(e.target.value) < Number(editEvent.quantity) &&
        !e.target.value.includes(".")
      ) {
        setEditErr((prevAddErr) => ({
          ...prevAddErr,
          maxTickets: "",
        }));
      } else if (Number(e.target.value) >= Number(editEvent.quantity)) {
        setEditErr((prevAddErr) => ({
          ...prevAddErr,
          maxTickets:
            "QTY should be less or equal to the total number of tickets available",
        }));
      }
      if (e.target.value.includes(".")) {
        setEditErr((prevAddErr) => ({
          ...prevAddErr,
          [e.target.name]: "Quantity should not be a decimal number",
        }));
      } else if (e.target.value < 0) {
        setEditErr((prevAddErr) => ({
          ...prevAddErr,
          [e.target.name]: "Enter a valid number",
        }));
      }
    }

    if (e.target.name === "mainAmount") {
      if (Number(e.target.value) > Number(editEvent.flatAmount)) {
        setEditErr((prevAddErr) => ({
          ...prevAddErr,
          flatAmount: "",
        }));
      } else if (Number(e.target.value) <= Number(editEvent.flatAmount)) {
        setEditErr((prevAddErr) => ({
          ...prevAddErr,
          flatAmount: "Flat Price should not be greater than main amount",
        }));
      }
    } else if (e.target.name === "flatAmount") {
      if (Number(e.target.value) < Number(editEvent.mainAmount)) {
        setEditErr((prevAddErr) => ({
          ...prevAddErr,
          flatAmount: "",
        }));
      } else if (Number(e.target.value) >= Number(editEvent.mainAmount)) {
        setEditErr((prevAddErr) => ({
          ...prevAddErr,
          flatAmount: "Flat Price should not be greater than main amount",
        }));
      }
    }
    if (!isChecked) {
      setEditEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          cancellationDaysBeforeEvent: "",
        };
        return updatedAddEvent;
      });
      setEditErr((prevAddErr) => ({
        ...prevAddErr,
        cancellationDaysBeforeEvent: "",
      }));
    }

    if (e.target.name === "priceType" && e.target.value !== "") {
      setEditErr({
        ...editErr,
        priceType: "",
      });
      if (e.target.value === "free") {
        setEditEvent((prevAddEvent) => {
          const updatedAddEvent = {
            ...prevAddEvent,
            flatAmount: "",
            discount: "",
            mainAmount: "",
            salePrice: "",
            saleEnd: "",
            salesEndTime: "",
            saleStart: "",
            salesStartTime: "",
          };
          return updatedAddEvent;
        });
      }
    } else if (e.target.name === "priceType" && e.target.value === "") {
      setPriceType("");
    }

    if (e.target.name === "salePrice" && e.target.value === "") {
      setEditEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          saleEnd: "",
          salesEndTime: "",
          saleStart: "",
          salesStartTime: "",
        };
        return updatedAddEvent;
      });
    }

    if (e.target.name === "salePrice" && e.target.value !== "") {
      setEditErr({
        ...editErr,
        salePrice: "",
      });
    } else if (e.target.name === "salePrice" && e.target.value === "") {
      setEditEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          flatAmount: "",
          discount: "",
        };
        return updatedAddEvent;
      });
      setEditErr({ ...editErr, flatAmount: "", discount: "" });
    }

    if (e.target.value === "free") {
      setPriceType("free");
      setEditEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          paid: "",
        };
        return updatedAddEvent;
      });
      setEditErr({ ...editErr, [e.target.name]: "" });
    } else if (e.target.value === "paid") {
      setPriceType("paid");
      setEditEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          free: "",
        };
        return updatedAddEvent;
      });
      setEditErr({ ...editErr, [e.target.name]: "" });
    }

    if (e.target.name === "isCancellation") {
      setIsChecked(e.target.checked);
    }

    if (e.target.name === "allowGuests") {
      setAllowGuestCheck(e.target.checked);
      if (!e.target.checked) {
        setSelectedGuestTickets([]);
      }
    }

    if (e.target.name === "checkout") {
      setcheckoutCheck(e.target.checked);
    }

    if (e.target.name === "addonAvailable") {
      setAddonAvailableCheck(e.target.checked);
      if (!e.target.checked) {
        setSelectedAddons([]);
      }
    }

    if (e.target.value === "flat") {
      setEditEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          discount: "",
        };
        return updatedAddEvent;
      });
      setEditErr({ ...editErr, discount: "", salePrice: "" });
    } else if (e.target.value === "percentage") {
      setEditEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          flatAmount: "",
        };
        return updatedAddEvent;
      });
      setEditErr({ ...editErr, flatAmount: "", salePrice: "" });
    }

    if (e.target.name === "visibility") {
      setEditErr({ ...editErr, visibility: "" });
    }
    if (e.target.name === "visibility" && e.target.value !== "custom") {
      setEditEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          visibilityEndDate: "",
          visibilityStartDate: "",
          visibilityStartTime: "",
          visibilityEndTime: "",
        };
        return updatedAddEvent;
      });
    }

    if (e.target.name === "saleStart") {
      setEditErr({ ...editErr, saleStart: "" });
    }
    if (e.target.name === "saleEnd") {
      setEditErr({ ...editErr, saleEnd: "" });
    }
    if (e.target.name === "salesStartTime") {
      setEditErr({ ...editErr, salesStartTime: "" });
    }
    if (e.target.name === "salesEndTime") {
      setEditErr({ ...editErr, salesEndTime: "" });
    }
    if (e.target.name === "visibilityStartDate") {
      setEditErr({ ...editErr, visibilityStartDate: "" });
    }
    if (e.target.name === "visibilityEndDate") {
      setEditErr({ ...editErr, visibilityEndDate: "" });
    }
    if (e.target.name === "visibilityStartTime") {
      setEditErr({ ...editErr, visibilityStartTime: "" });
    }
    if (e.target.name === "visibilityEndTime") {
      setEditErr({ ...editErr, visibilityEndTime: "" });
    }

    if (
      e.target.name === "visibilityStartDate" ||
      e.target.name === "visibilityEndDate"
    ) {
      setEditErr((prevAddErr) => ({
        ...prevAddErr,
        visibilityStartDate:
          e.target.name === "visibilityStartDate"
            ? ""
            : prevAddErr.visibilityStartDate,
        visibilityEndDate:
          e.target.name === "visibilityEndDate"
            ? ""
            : prevAddErr.visibilityEndDate,
        visibilityStartTime:
          editEvent.visibilityStartDate === editEvent.visibilityEndDate
            ? ""
            : prevAddErr.visibilityStartTime,
        visibilityEndTime:
          editEvent.visibilityStartDate === editEvent.visibilityEndDate
            ? ""
            : prevAddErr.visibilityEndTime,
      }));
    }

    // Additional validation logic for start and end time
    if (
      e.target.name === "visibilityStartTime" ||
      e.target.name === "visibilityEndTime"
    ) {
      const startDate = editEvent.visibilityStartDate;
      const startTime =
        e.target.name === "visibilityStartTime"
          ? e.target.value
          : editEvent.visibilityStartTime;
      const endTime =
        e.target.name === "visibilityEndTime"
          ? e.target.value
          : editEvent.visibilityEndTime;

      // Compare start and end time only if both are defined and date is the same
      if (
        startDate &&
        startTime &&
        endTime &&
        startDate === editEvent.visibilityEndDate
      ) {
        const startDateTime = new Date(`${startDate}T${startTime}`);
        const endDateTime = new Date(`${startDate}T${endTime}`);

        if (startDateTime >= endDateTime) {
          setEditErr((prevAddErr) => ({
            ...prevAddErr,
            [e.target.name === "visibilityStartTime"
              ? "visibilityStartTime"
              : "visibilityEndTime"]: "Start time should be less than end time",
          }));
          return;
        } else {
          setEditErr((prevAddErr) => ({
            ...prevAddErr,
            visibilityEndTime: "",
            visibilityStartTime: "",
          }));
        }
      }
    }
  };

  //submit edited form
  const handleEditSubmit = () => {
    let status = true;
    let temp = {};
    if (editEvent?.name?.trim().length === 0) {
      temp = { ...temp, name: "Enter name" };
      status = false;
    }

    if (
      isNaN(editEvent?.maxTickets) ||
      String(editEvent?.maxTickets).trim() === "" ||
      editEvent?.maxTickets <= 0
    ) {
      temp = {
        ...temp,
        maxTickets: "Enter a valid number",
      };
      status = false;
    }

    if (
      isNaN(editEvent?.minTickets) ||
      String(editEvent?.minTickets).trim() === "" ||
      editEvent?.minTickets <= 0
    ) {
      temp = {
        ...temp,
        minTickets: "Enter a valid number",
      };
      status = false;
    }

    if (String(editEvent?.maxTickets).includes(".")) {
      temp = {
        ...temp,
        maxTickets: "Quantity should not be a decimal number",
      };
      status = false;
    }

    if (String(editEvent?.minTickets).includes(".")) {
      temp = {
        ...temp,
        minTickets: "Quantity should not be a decimal number",
      };
      status = false;
    }

    if (
      isNaN(editEvent?.quantity) ||
      String(editEvent.quantity).trim() === "" ||
      editEvent?.quantity <= 0
    ) {
      temp = {
        ...temp,
        quantity: "Please enter a valid quantity",
      };
      status = false;
    }

    if (String(editEvent?.quantity).includes(".")) {
      temp = {
        ...temp,
        quantity: "Quantity should not be a decimal number",
      };
      status = false;
    }

    if (
      allowGuestCheck &&
      (isNaN(editEvent?.maxGuestAllowed) ||
        String(editEvent?.maxGuestAllowed).trim() === "" ||
        editEvent?.maxGuestAllowed <= 0)
    ) {
      temp = {
        ...temp,
        maxGuestAllowed: "Enter a valid number",
      };
      status = false;
    }

    if (String(editEvent?.maxGuestAllowed).includes(".")) {
      temp = {
        ...temp,
        maxGuestAllowed: "Max guest allowed should not be a decimal number",
      };
      status = false;
    }

    if (Number(editEvent?.minTickets) > Number(editEvent?.maxTickets)) {
      temp = {
        ...temp,
        minTickets: "min quantity should not be larger than the max quantity ",
      };
      status = false;
    }

    if (Number(editEvent?.maxTickets) > Number(editEvent?.quantity)) {
      temp = {
        ...temp,
        maxTickets:
          "QTY should be less or equal to the total number of tickets available",
      };
      status = false;
    }

    if (
      editDescriptionRef.current &&
      editDescriptionRef.current.getContent &&
      editDescriptionRef.current.getContent().length > 2500
    ) {
      temp = {
        ...temp,
        description: "Description exceeds the character limit",
      };
      status = false;
    }

    if (addonAvailableCheck && selectedAddons.length <= 0) {
      temp = {
        ...temp,
        addonErrorMessage:
          "Please either uncheck the checkbox or provide a addons",
      };
      status = false;
    }

    if (allowGuestCheck && selectedGuestTickets.length <= 0) {
      temp = {
        ...temp,
        guestTicketErrorMessage:
          "Please either uncheck the checkbox or provide a guest tickets",
      };
      status = false;
    }

    if (Number(editEvent?.flatAmount) > Number(editEvent?.mainAmount)) {
      temp = {
        ...temp,
        flatAmount: "Flat Price should not be greater than main amount",
      };
      status = false;
    }

    if (editEvent?.priceType === "") {
      temp = {
        ...temp,
        priceType: "Select price type",
      };
      status = false;
    }

    if (
      editEvent?.priceType === "paid" &&
      (isNaN(editEvent?.mainAmount) ||
        editEvent?.mainAmount === "" ||
        editEvent?.mainAmount <= 0)
    ) {
      temp = {
        ...temp,
        mainAmount: "Please enter a valid amount",
      };
      status = false;
    }

    if (priceType === "paid" && editEvent?.salePrice === "percentage") {
      const discountValue = Number(editEvent?.discount);
      if (isNaN(discountValue) || discountValue <= 0 || discountValue >= 100) {
        temp = {
          ...temp,
          discount: "Discount should be a valid number between 1 and 100",
        };
        status = false;
      }
    } else if (priceType === "paid" && editEvent?.salePrice === "flat") {
      const flatAmountValue = Number(editEvent?.flatAmount);
      if (isNaN(flatAmountValue) || flatAmountValue <= 0) {
        temp = {
          ...temp,
          flatAmount: "Flat Price should be a valid number greater than 0",
        };
        status = false;
      }
    }

    if (isChecked && !editEvent?.cancellationDaysBeforeEvent) {
      temp = {
        ...temp,
        cancellationDaysBeforeEvent:
          "Please either uncheck the checkbox or provide a valid input value",
      };
      status = false;
    } else if (
      isChecked &&
      (isNaN(editEvent?.cancellationDaysBeforeEvent) ||
        editEvent?.cancellationDaysBeforeEvent === "" ||
        editEvent?.cancellationDaysBeforeEvent <= 0)
    ) {
      temp = {
        ...temp,
        cancellationDaysBeforeEvent: "Enter a valid number",
      };
      status = false;
    }

    if (String(editEvent?.cancellationDaysBeforeEvent).includes(".")) {
      temp = {
        ...temp,
        cancellationDaysBeforeEvent: "Quantity should not be a decimal number",
      };
      status = false;
    }

    if (editEvent?.visibility === "") {
      temp = {
        ...temp,
        visibility: "Select visibility",
      };
      status = false;
    }

    if (editEvent?.salePrice !== "" && editEvent?.saleStart === "") {
      temp = {
        ...temp,
        saleStart: "Select start date",
      };
      status = false;
    }

    if (editEvent?.salePrice !== "" && editEvent?.saleEnd === "") {
      temp = {
        ...temp,
        saleEnd: "Select end date",
      };
      status = false;
    }
    if (editEvent?.salePrice !== "" && editEvent?.salesStartTime === "") {
      temp = {
        ...temp,
        salesStartTime: "Select start time",
      };
      status = false;
    }
    if (editEvent?.salePrice !== "" && editEvent?.salesEndTime === "") {
      temp = {
        ...temp,
        salesEndTime: "Select end time",
      };
      status = false;
    }
    if (
      editEvent?.visibility === "custom" &&
      editEvent?.visibilityStartDate === ""
    ) {
      temp = {
        ...temp,
        visibilityStartDate: "Select start date",
      };
      status = false;
    }
    if (
      editEvent?.visibility === "custom" &&
      editEvent?.visibilityEndDate === ""
    ) {
      temp = {
        ...temp,
        visibilityEndDate: "Select end date",
      };
      status = false;
    }
    if (
      editEvent?.visibility === "custom" &&
      editEvent?.visibilityStartTime === ""
    ) {
      temp = {
        ...temp,
        visibilityStartTime: "Select start time",
      };
      status = false;
    }
    if (
      editEvent?.visibility === "custom" &&
      editEvent?.visibilityEndTime === ""
    ) {
      temp = {
        ...temp,
        visibilityEndTime: "Select end time",
      };
      status = false;
    }

    if (editEvent?.visibilityEndTime || editEvent?.visibilityStartTime) {
      const startDate = editEvent.visibilityStartDate;
      // Compare start and end time only if both are defined and date is the same
      if (
        editEvent?.visibilityStartTime >= editEvent?.visibilityEndTime &&
        startDate === editEvent.visibilityEndDate
      ) {
        status = false;
        temp = {
          ...temp,
          visibilityEndTime: "Start time should be less than end time",
        };
      }
    }
    if (status && !ruleError && !noRuleFieldSelected) {
      mainTicketEditApi();
    } else {
      setEditErr(temp);
      noRuleFieldSelected && setRuleError(true);
    }
  };
  //main ticket edit api
  const mainTicketEditApi = async () => {
    let priceType = "";
    if (editEvent?.priceType === "free") {
      priceType = "FREE";
    } else if (editEvent?.priceType === "paid") {
      priceType = "PAID";
    }
    let salePriceType;
    if (editEvent?.salePrice === "flat" && editEvent?.priceType !== "free") {
      salePriceType = "FLAT";
    } else if (
      editEvent?.salePrice === "percentage" &&
      editEvent?.priceType !== "free"
    ) {
      salePriceType = "PERCENTAGE";
    }

    let salesValue;
    if (editEvent?.flatAmount) {
      salesValue = editEvent?.flatAmount;
    } else if (editEvent?.discount) {
      salesValue = editEvent?.discount;
    }

    let visibility;
    if (editEvent.visibility === "public") {
      visibility = "VISIBLE";
    } else if (editEvent.visibility === "hidden") {
      visibility = "HIDDEN";
    } else if (editEvent.visibility === "custom") {
      visibility = "CUSTOM";
    }

    //convert string date to formatted date
    const dateConvertor = (date, time) => {
      if (!date) return;
      const combinedDateTimeString = date + "T" + time + ":00";
      const combinedDateTime = new Date(combinedDateTimeString);
      const isoFormattedDateTime = combinedDateTime.toISOString();
      return isoFormattedDateTime;
    };

    setCreateInprocess(true);
    const formdata = new FormData();
    let imageCompress;
    if (cropedFileData) {
      imageCompress = await CompressImageFunction(cropedFileData);
    }
    imageCompress && formdata.append("thumbnail", imageCompress);
    formdata.append("name", editEvent?.name);
    formdata.append("quantity", Number(editEvent?.quantity));
    formdata.append("type", priceType);
    formdata.append("isSpecialPrice", salesValue ? true : false);
    formdata.append("isGuestAllow", allowGuestCheck);
    selectedGuestTickets?.length > 0 &&
      selectedGuestTickets.map((guestTicket) => {
        formdata.append(`guestTickets`, guestTicket._id);
      });
    formdata.append("isAddOnAvailable", addonAvailableCheck);
    selectedAddons?.length > 0 &&
      selectedAddons.map((addon) => {
        formdata.append(`selectAddons`, addon._id);
      });
    formdata.append("basePrice", editEvent.mainAmount || 0);
    formdata.append("eventId", eventId);

    if (editDescriptionRef.current && editDescriptionRef.current.getContent) {
      formdata.append("description", editDescriptionRef.current.getContent());
    }

    formdata.append("minimumTicket", editEvent?.minTickets);
    formdata.append("maximumTicket", editEvent?.maxTickets);

    if (salePriceType !== undefined && editEvent?.priceType !== "free") {
      formdata.append("salesPriceType", salePriceType);
      formdata.append(
        "salesStartDate",
        dateConvertor(editEvent.saleStart, editEvent.salesStartTime)
      );
      formdata.append(
        "salesEndDate",
        dateConvertor(editEvent.saleEnd, editEvent.salesEndTime)
      );
    }

    if (isChecked) {
      formdata.append("isCancellation", isChecked);
      formdata.append(
        "cancellationDaysBeforeEvent",
        editEvent?.cancellationDaysBeforeEvent
      );
    }

    if (editEvent?.visibility === "custom") {
      formdata.append(
        "visibilityStartDate",
        dateConvertor(
          editEvent.visibilityStartDate,
          editEvent.visibilityStartTime
        )
      );
      formdata.append(
        "visibilityEndDate",
        dateConvertor(editEvent.visibilityEndDate, editEvent.visibilityEndTime)
      );
    }

    if (visibility !== undefined) {
      formdata.append("visibilityType", visibility);
    }
    if (allowGuestCheck) {
      formdata.append("guestPerTicket", editEvent?.maxGuestAllowed);
    }

    if (editEvent?.priceType === "paid") {
      formdata.append("salesValue", salesValue);
    }

    dataFromRules?.userAccess.length !== 0 &&
      formdata.append(
        `ticketAccess`,
        dataFromRules?.userAccess === "all" ? "PUBLIC" : "RESTRICTED"
      );
    if (dataFromRules?.group_id) {
      dataFromRules?.group_id.length > 0 &&
        dataFromRules?.group_id?.map((id) => {
          formdata.append(`restrictedAccessGroups`, id);
        });
    }
    if (dataFromRules?.membership_plan_id) {
      dataFromRules?.membership_plan_id.length > 0 &&
        dataFromRules?.membership_plan_id?.map((id) => {
          formdata.append(`restrictedAccessMemberships`, id);
        });
    }
    dataFromRules.userAccess === "restricted" &&
      dataFromRules?.user_id.length > 0 &&
      dataFromRules?.user_id?.map((id) => {
        formdata.append(`restrictedIndivisualUser`, id);
      });

    try {
      const editApiResponse = await editMainTicket({
        id: selectedId,
        body: formdata,
      });
      if (editApiResponse.data.data) {
        setShowAlertAdd(true);
        setAlertMsgAdd(editApiResponse?.data?.message);
        setTimeout(() => {
          document.querySelector("#editMainTicket .close").click();
          setShowAlertAdd(false);
          setAlertMsgAdd("");
          handleCloseAddModel();
        }, 3000);
        setCreateInprocess(false);
        handleClearAllStates();
      }
      if (editApiResponse?.data?.status === false) {
        setErrorMessage(true);
        setAddErrorMessage(editApiResponse?.data?.message);
        setTimeout(() => {
          setErrorMessage(false);
          setAddErrorMessage("");
        }, 4000);
        setCreateInprocess(false);
      }
    } catch (err) {
      console.log("Edit submit err:", err);
    }
  };
  //stayonpage  popup for do you want to leave page
  useEffect(() => {
    if (stayOnPage !== null && stayOnPage) {
      if ($("#editMainTicket").hasClass("show")) {
        $("#editMainTicket").modal("show");
      }
    } else if (stayOnPage !== null && !stayOnPage) {
      if ($("#editMainTicket").hasClass("show")) {
        $("#editMainTicket").modal("hide");
      }
    }
  }, [stayOnPage]);

  //setting the error message for addons, guest tickets and description
  useEffect(() => {
    if (
      editDescriptionRef.current &&
      editDescriptionRef.current.getContent &&
      editDescriptionRef.current.getContent().length > 2500
    ) {
      setEditErr((prevAddErr) => ({
        ...prevAddErr,
        description: "Description exceeds the character limit",
      }));
    }
    if (selectedAddons.length > 0) {
      setEditErr((prevAddErr) => ({
        ...prevAddErr,
        addonErrorMessage: "",
      }));
    }
    if (selectedGuestTickets.length > 0) {
      setEditErr((prevAddErr) => ({
        ...prevAddErr,
        guestTicketErrorMessage: "",
      }));
    }
  }, [selectedAddons, selectedGuestTickets, editDescriptionRef.current]);

  return (
    <>
      {modalOpen && (
        <ImageCropperReact
          file={file}
          setModalOpen={setModalOpen}
          setcropedFile={setcropedFile}
          setFile={setFile}
          fileName={fileName}
          setcropedFileData={setcropedFileData}
          fileDimension={fileDimension}
        />
      )}
      <div
        className="modal fade edit-user-details-popup"
        id="editMainTicket"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Edit Main Ticket</h2>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={(e) => {
                  console.log("modal in process >>>", modalInProcess);
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#editMainTicket").modal("hide");
                    handleCloseAddModel();
                  }
                }}
              >
                &times;
              </button>
            </div>
            {editLoader ? (
              <div className="cstm-data-load">
                <img src={Loader} />{" "}
              </div>
            ) : (
              <div className="modal-body">
                {showAlertAdd && (
                  <div className="alert alert-info">{alertMsgAdd}</div>
                )}
                {isErrorMessage && (
                  <div className="alert alert-danger">{addErrorMessage}</div>
                )}
                <div className="upload-image-wrapper">
                  <div className="cstm-video-upload">
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        // Check if any of the dropped files are not allowed
                        const disallowedFiles = acceptedFiles.filter((file) => {
                          const allowedImageTypes = [
                            "image/jpeg",
                            "image/png",
                            "image/tiff",
                            "image/jpg",
                            "image/svg",
                            "image/EPS",
                          ];
                          return !allowedImageTypes.includes(file.type);
                        });

                        if (disallowedFiles.length > 0) {
                          //  message if disallowed files are detected
                          setValidate(true);
                        } else {
                          // Proceed with file assignment if all files are allowed
                          assignFiles(acceptedFiles);
                          setValidate(false);
                        }
                      }}
                      name="thumbnail"
                      multiple={false}
                      accept="image/jpeg, image/png,  image/tiff, image/jpg"
                      maxSizeBytes={10485760}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />

                          <div className="cstm-video-upload-img">
                            {cropedFile ? (
                              <img className="channel-img w-100 h-100" src={cropedFile} alt="Uploaded video thumbnail" />
                            ) : editEvent.image !== undefined && editEvent.image !== "" ? (
                              <img className="channel-img w-100 h-100" src={editEvent.image} alt="Event image" />
                            ) : (
                              <img className="empty-img" src={Frame} alt="Placeholder image" />
                            )}
                          </div>

                        </div>
                      )}
                    </Dropzone>

                    {/* Display error message for file selection */}
                    {validate && (
                      <span
                        className="error cstm-error44"
                        style={{ marginTop: "5px" }}
                      >
                        {"Invalid file type. Please select an image file."}
                      </span>
                    )}

                    {/* Display error message related to notificationDetailErr.image */}
                    {editErr.image && (
                      <span
                        className="error cstm-error44"
                        style={{ marginTop: "2px" }}
                      >
                        {editErr.image}
                      </span>
                    )}
                  </div>
                  <div className="group-info-user-link upload-ticket-image">
                    <div className="cover-image-upload-ttl">Upload image</div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Name*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter name"
                      name="name"
                      onChange={handleEditChange}
                      value={editEvent.name}
                    />
                    <span className="error">{editErr.name}</span>
                  </div>
                </div>

                <div className="form-group">
                  <div className="form-group-label">Available quantity*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter available quantity"
                      name="quantity"
                      onChange={handleEditChange}
                      value={editEvent.quantity}
                    />
                    <span className="error">{editErr.quantity}</span>
                  </div>
                </div>

                <div className="form-group cstm-admin-access-grp">
                  <div className="form-group-label">Price type*</div>
                  <div className="form-group-ct">
                    <select
                      name="priceType"
                      value={editEvent.priceType}
                      onChange={handleEditChange}
                    >
                      <option value="">Select price</option>
                      <option value="free">Free</option>
                      <option value="paid">Paid</option>
                    </select>
                  </div>
                  <span className="error cstm-error4">{editErr.priceType}</span>
                </div>
                {editEvent.priceType === "paid" && (
                  <>
                    <div className="form-group">
                      <div className="form-group-label">Amount*</div>
                      <div className="form-group-ct">
                        <input
                          type="text"
                          placeholder="Enter amount"
                          name="mainAmount"
                          onChange={handleEditChange}
                          value={editEvent.mainAmount}
                        />
                        <span className="error">{editErr.mainAmount}</span>
                      </div>
                    </div>
                    <div className="form-group cstm-admin-access-grp">
                      <div className="form-group-label">Sale Price</div>
                      <div className="form-group-ct">
                        <select
                          name="salePrice"
                          value={editEvent.salePrice}
                          onChange={handleEditChange}
                        >
                          <option value="">Select sale price</option>
                          <option value="flat">Flat</option>
                          <option value="percentage">Percentage</option>
                        </select>
                      </div>
                      <span className="error cstm-error4">
                        {editErr.salePrice}
                      </span>
                    </div>

                    {editEvent.salePrice === "flat" && (
                      <>
                        <div className="form-group">
                          <div className="form-group-label">Flat($)*</div>
                          <div className="form-group-ct">
                            <input
                              type="text"
                              placeholder="Enter flat price"
                              name="flatAmount"
                              onChange={handleEditChange}
                              value={editEvent?.flatAmount}
                            />
                            <span className="error">{editErr.flatAmount}</span>
                          </div>
                        </div>
                      </>
                    )}

                    {editEvent.salePrice === "percentage" && (
                      <>
                        <div className="form-group">
                          <div className="form-group-label">Discount(%)*</div>
                          <div className="form-group-ct">
                            <input
                              type="text"
                              placeholder="Enter discount"
                              name="discount"
                              onChange={handleEditChange}
                              value={editEvent?.discount}
                            />
                            <span className="error">{editErr.discount}</span>
                          </div>
                        </div>
                      </>
                    )}
                    {(editEvent.salePrice === "percentage" ||
                      editEvent.salePrice === "flat") && (
                        <div class="form-group">
                          <div class="cstm-channel-type-title partner-icon-rf-only">
                            Sale duration
                          </div>
                          <div className="form-group">
                            <div className="form-group-label">Start date*</div>
                            <div className="form-group-ct">
                              <input
                                type="date"
                                name="saleStart"
                                onChange={handleEditChange}
                                value={editEvent.saleStart}
                              />
                              <span className="error">{editErr.saleStart}</span>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="form-group-label">Start time*</div>
                            <div className="form-group-ct">
                              <input
                                type="time"
                                name="salesStartTime"
                                onChange={handleEditChange}
                                value={editEvent.salesStartTime}
                                disabled={
                                  editEvent.saleStart.length === 0 ? true : false
                                }
                              />
                              <span className="error">
                                {editErr.salesStartTime}
                              </span>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="form-group-label">End date*</div>
                            <div className="form-group-ct">
                              <input
                                type="date"
                                name="saleEnd"
                                onChange={handleEditChange}
                                value={editEvent.saleEnd}
                                disabled={
                                  editEvent.saleStart.length === 0 ? true : false
                                }
                                min={editEvent.saleStart}
                              />
                              <span className="error">{editErr.saleEnd}</span>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="form-group-label">End time*</div>
                            <div className="form-group-ct">
                              <input
                                type="time"
                                name="salesEndTime"
                                onChange={handleEditChange}
                                value={editEvent.salesEndTime}
                                disabled={
                                  editEvent.saleEnd.length === 0 ? true : false
                                }
                              />
                              <span className="error">
                                {editErr.salesEndTime}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                  </>
                )}
                <div className="row">
                  <div className="col-sm-12 form-group">
                    <div className="form-group-label">Description</div>
                    <div className="cstm-editor-tool-ds">
                      <TinyEditor
                        valueRef={editDescriptionRef}
                        value={editEvent.description}
                        handleEditorChange={(value, editor) => {
                          dispatch({ type: "SETMODALINPROCESS" });
                          setEditErr({
                            ...editErr,
                            description: "",
                          });
                        }}
                        handleKeyPress={() => {
                          dispatch({ type: "SETMODALINPROCESS" });
                        }}
                      />
                    </div>
                    <span className="error">{editErr.description}</span>
                  </div>
                </div>

                <div className="form-group">
                  <div className="form-group-label">
                    Max. tickets per order*
                  </div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter maximum tickets"
                      name="maxTickets"
                      onChange={handleEditChange}
                      value={editEvent.maxTickets}
                    />
                    <span className="error">{editErr.maxTickets}</span>
                  </div>
                </div>

                <div className="form-group">
                  <div className="form-group-label">
                    Min. tickets per order*
                  </div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter minimum quantity"
                      name="minTickets"
                      onChange={handleEditChange}
                      value={editEvent.minTickets}
                    />
                    <span className="error">{editErr.minTickets}</span>
                  </div>
                </div>

                <div class="form-group">
                  <div class="cstm-channel-type-title partner-icon-rf-only">
                    Select visibility*
                  </div>
                  <div class="cstm-pm-list">
                    <label class="subcat-addvideo">
                      <input
                        type="radio"
                        value="public"
                        name="visibility"
                        checked={editEvent.visibility === "public"}
                        onChange={handleEditChange}
                      />
                      Public
                    </label>
                    <label class="subcat-addvideo">
                      {" "}
                      <input
                        type="radio"
                        value="hidden"
                        name="visibility"
                        checked={editEvent.visibility === "hidden"}
                        onChange={handleEditChange}
                      />
                      Hidden
                    </label>
                    <label class="subcat-addvideo">
                      <input
                        type="radio"
                        value="custom"
                        name="visibility"
                        checked={editEvent.visibility === "custom"}
                        onChange={handleEditChange}
                      />
                      Custom
                    </label>
                  </div>
                  <span className="error cstm-pm-list-msg">
                    {editErr.visibility}
                  </span>
                </div>

                {editEvent.visibility === "custom" && (
                  <>
                    <div className="form-group">
                      <div className="form-group-label">Start date*</div>
                      <div className="form-group-ct">
                        <input
                          type="date"
                          name="visibilityStartDate"
                          onChange={handleEditChange}
                          value={editEvent.visibilityStartDate}
                        />
                        <span className="error">
                          {editErr.visibilityStartDate}
                        </span>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="form-group-label">Start time*</div>
                      <div className="form-group-ct">
                        <input
                          type="time"
                          name="visibilityStartTime"
                          onChange={handleEditChange}
                          value={editEvent.visibilityStartTime}
                          disabled={
                            editEvent.visibilityStartDate.length === 0
                              ? true
                              : false
                          }
                        />
                        <span className="error">
                          {editErr.visibilityStartTime}
                        </span>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="form-group-label">End date*</div>
                      <div className="form-group-ct">
                        <input
                          type="date"
                          name="visibilityEndDate"
                          onChange={handleEditChange}
                          value={editEvent.visibilityEndDate}
                          disabled={
                            editEvent.visibilityStartDate.length === 0
                              ? true
                              : false
                          }
                          min={editEvent.visibilityStartDate}
                        />
                        <span className="error">
                          {editErr.visibilityEndDate}
                        </span>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="form-group-label">End time*</div>
                      <div className="form-group-ct">
                        <input
                          type="time"
                          name="visibilityEndTime"
                          onChange={handleEditChange}
                          value={editEvent.visibilityEndTime}
                          disabled={
                            editEvent.visibilityStartTime.length === 0
                              ? true
                              : false
                          }
                        />
                        <span className="error">
                          {editErr.visibilityEndTime}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <AddRules
                  title="Rules*"
                  ruleError={ruleError}
                  setRuleError={setRuleError}
                  totalUsers={totalUsers}
                  setTotalUsers={setTotalUsers}
                  noRuleFieldSelected={noRuleFieldSelected}
                  setNoRuleFieldSelected={setNoRuleFieldSelected}
                  dataFromRules={dataFromRules}
                  setDataFromRules={setDataFromRules}
                  addedRules={rulesDataForEdit}
                  showEventField={true}
                />
                <div className="form-group">
                  <div className="form-group-label">Application form</div>
                  <div className="form-group-ct">
                    <select
                      value={editEvent.applicationForm}
                      onChange={handleEditChange}
                      name="applicationForm"
                    >
                      <option value={""}>Select application form</option>
                      <option>Example</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 form-group">
                    <div className="cstm-chk-preregistration-container">
                      <input
                        type="checkbox"
                        name="isCancellation"
                        id="allowCancellation"
                        onChange={handleEditChange}
                        checked={isChecked === true}
                      />
                      <label
                        for="chk-preregistration"
                        htmlFor="allowCancellation"
                        onChange={handleEditChange}
                      >
                        Do you want to allow cancellation?
                      </label>
                    </div>
                  </div>
                </div>
                {isChecked && (
                  <>
                    <div className="row">
                      <div className="col-sm-12 form-group">
                        <div className="form-group-label">
                          Cancellation deadline
                        </div>
                        <div className="form-group-ct">
                          <input
                            type="text"
                            name="cancellationDaysBeforeEvent"
                            placeholder="Allow cancellation within xx days."
                            value={editEvent.cancellationDaysBeforeEvent}
                            onChange={handleEditChange}
                          />
                          <span className="error">
                            {editErr.cancellationDaysBeforeEvent}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="row">
                  <div className="col-sm-12 form-group">
                    <div className="cstm-chk-preregistration-container">
                      <input
                        type="checkbox"
                        name="allowGuests"
                        id="allowGuests"
                        onChange={handleEditChange}
                        checked={allowGuestCheck === true}
                      />
                      <label
                        for="chk-preregistration"
                        htmlFor="allowGuests"
                        onChange={handleEditChange}
                      >
                        Do you want to allow guests?
                      </label>
                    </div>
                  </div>
                </div>

                {allowGuestCheck && (
                  <>
                    <div className="row">
                      <div className="col-sm-12 form-group">
                        <div className="form-group-label">
                          Max. guest allowed*
                        </div>
                        <div className="form-group-ct">
                          <input
                            type="text"
                            name="maxGuestAllowed"
                            placeholder="Max guest Allowed for this ticket"
                            value={editEvent.maxGuestAllowed}
                            onChange={handleEditChange}
                          />
                          <span className="error">
                            {editErr.maxGuestAllowed}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="form-group cstm-admin-access-grp">
                      <div className="form-group-label">Guest tickets*</div>
                      <div className="form-group-ct speakers-only">
                        <Multiselect
                          showArrow={true}
                          placeholder="Select guest tickets"
                          displayValue="name"
                          options={guestTicketList?.data}
                          selectedValues={selectedGuestTickets}
                          onSelect={(selectedlist) => {
                            dispatch({ type: "SETMODALINPROCESS" });
                            dispatch({ type: "SETSTAYONPAGE", payload: null });
                            setSelectedGuestTickets(selectedlist);
                          }}
                          onRemove={(selectedlist) => {
                            dispatch({ type: "SETMODALINPROCESS" });
                            dispatch({ type: "SETSTAYONPAGE", payload: null });
                            setSelectedGuestTickets(selectedlist);
                          }}
                        />
                        <span className="error">
                          {editErr.guestTicketErrorMessage}
                        </span>
                      </div>
                    </div>
                  </>
                )}

                <div className="row">
                  <div className="col-sm-12 form-group">
                    <div className="cstm-chk-preregistration-container">
                      <input
                        type="checkbox"
                        name="checkout"
                        id="checkout"
                        onChange={handleEditChange}
                        checked={checkoutCheck === true}
                      />
                      <label
                        for="chk-preregistration"
                        htmlFor="checkout"
                        onChange={handleEditChange}
                      >
                        Required guest info while checkout?(
                        <b>In development</b>)
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 form-group">
                    <div className="cstm-chk-preregistration-container">
                      <input
                        type="checkbox"
                        name="addonAvailable"
                        id="addonAvailable"
                        onChange={handleEditChange}
                        checked={addonAvailableCheck === true}
                      />
                      <label
                        for="chk-preregistration"
                        htmlFor="addonAvailable"
                        onChange={handleEditChange}
                      >
                        Do you want to allow addons?
                      </label>
                    </div>
                  </div>
                </div>

                {addonAvailableCheck && (
                  <div className="form-group cstm-admin-access-grp">
                    <div className="form-group-label">Addons*</div>
                    <div className="form-group-ct speakers-only">
                      <Multiselect
                        showArrow={true}
                        placeholder="Select addons"
                        displayValue="name"
                        options={addonList?.data}
                        selectedValues={selectedAddons}
                        onSelect={(selectedlist) => {
                          dispatch({ type: "SETMODALINPROCESS" });
                          dispatch({ type: "SETSTAYONPAGE", payload: null });
                          setSelectedAddons(selectedlist);
                        }}
                        onRemove={(selectedlist) => {
                          dispatch({ type: "SETMODALINPROCESS" });
                          dispatch({ type: "SETSTAYONPAGE", payload: null });
                          setSelectedAddons(selectedlist);
                        }}
                      />
                      <span className="error">{editErr.addonErrorMessage}</span>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="edit-user-details-footer cstm-create-button">
              {createInprocess ? (
                <div className="loader-button1">
                  <button>In process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                !editLoader && (
                  <button
                    type="button"
                    className="btn"
                    onClick={handleEditSubmit}
                    disabled={createInprocess}
                  >
                    Update Changes
                  </button>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditMainTicket;
